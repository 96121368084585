<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://arcayazilim.com" target="_blank"> Arca Yazılım</a>
    <span class="d-none d-sm-inline-block">, Bütün hakları saklıdır.</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    Version 2.0 Build:{{version}}
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
