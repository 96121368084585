import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ContractDetail } from '../models/contractdetail';

@Injectable({
  providedIn: 'root'
})
export class EvoContractDetailService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(id:number):Observable<ContractDetail[]>{
    return this.httpClient.get<ContractDetail[]>(this.baseApiUrl+'GetContractDetailList/'+id);
  }
  get (id: number):Observable <ContractDetail> {
    return this.httpClient.get<ContractDetail>(this.baseApiUrl+'GetContractDetail/'+id);
  }
  add(contractDetail:ContractDetail):Observable<ContractDetail>{
    return this.httpClient.post<ContractDetail>(this.baseApiUrl+'AddContractDetail/',contractDetail);
  }
  update(contractDetail:ContractDetail):Observable<ContractDetail>{
    return this.httpClient.post<ContractDetail>(this.baseApiUrl+"UpdateContractDetail/",contractDetail);
  }
  delete(contractDetail:ContractDetail):Observable<ContractDetail>{
    return this.httpClient.post<ContractDetail>(this.baseApiUrl+"DeleteContractDetail/",contractDetail);
  }

}
