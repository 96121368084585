import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoGoal'
})
export class EvoGoalPipe implements PipeTransform {

  transform(value: any[], filterText: string): any {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p => {
      const name = p.name?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());

      return (name);
    });
  }

}
