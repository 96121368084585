<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Sözleşme Oluştur</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #ContractForm="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Başlangıç Tarihi Seçiniz</label>
                                            <input type="datetime-local" class="form-control" name="txtStartDate"
                                                [formControl]="startDateControl" required>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Bitiş Tarihi Seçiniz</label>
                                            <input type="datetime-local" class="form-control" name="txtEndDate"
                                            [formControl]="endDateControl" required>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" name="slcPartner" [(ngModel)]="partner.id"
                                                #slcPartner="ngModel" (ngModelChange)="changePartner()">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Proje Seçiniz</label>
                                            <select class="form-control" name="slcProject" [(ngModel)]="project.id"
                                                #slcProject="ngModel">
                                                <option value="0" selected>Proje Seçiniz</option>
                                                <option *ngFor="let item of projects" value="{{item.id}}">{{item.title}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <button type="submit" rippleEffect class="btn btn-success mr-1"
                                            [disabled]="!ContractForm.valid"
                                            (click)="add(startDateControl.value,endDateControl.value)">Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>