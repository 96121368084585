<div class="content-wrapper container-m mr-6">
  <div class="content-body">
    <div class="row">
      <div class="col-md-12">
        <div class="ShowTicket" style="margin-top: 3%;">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <div class="row col-12">
                    <div class="col-lg-11 col-md-11 col-12">
                      <h2 class="card-title">Talep Bilgileri</h2>
                    </div>

                    <div class="col-lg-1 col-md-1 col-12">
                      <div style="display:flex; justify-content: end;">
                        <div *ngIf="followerInformation==false">
                          <button class="btn btn-primary mr-2 btn-sm" title="Takip Et" (click)="follow()"
                            [disabled]="isButtonDisabled"><i data-feather="eye"></i></button>
                        </div>
                        <div *ngIf="followerInformation==true">
                          <button class="btn btn-secondary mr-2 btn-sm" (click)="unFollow(ticketUserType)"
                            title="Takibi Bırak"><i data-feather="eye-off"></i></button>
                        </div>

                        <button class="btn btn-info btn-sm mr-2" data-bs-toggle="modal" title="Takipçileri Görüntüle"
                          data-bs-target="#showticketusers"><i data-feather="users"></i></button>
                        <button class="btn btn-success btn-sm mr-2 saveButton"
                          (click)="update(txtSubject.value,txtExpectedTime.value,txtPartnerTicketNo.value)"
                          title="Kaydet"><i data-feather="save"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <thead class=" text-primary">
                        <th></th>
                        <th></th>
                        <th class="text-right"></th>

                        <th colspan="5"></th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Takip Numarası:</td>
                          <td class="DetailsLeftSide">
                            {{ticket.trackingNumber}}
                          </td>

                          <td> Müşteri ve Projesi:</td>
                          <td colspan="4"
                            *ngIf="userRole=='Admin' || userRole=='ConsultantUser' || userRole=='Consultant'"
                            class="DetailsRightSide" id="projectName">

                            {{ticketsPartner.name}} - {{ticketsProject.title}}
                          </td>


                          <td *ngIf="userRole=='Admin' || userRole=='ConsultantUser' || userRole=='Consultant'"
                            class="DetailsRightSide" id="projectName">

                            <button style="margin-left:25px;" (click)="linkRequest()" class="btn btn-outline-primary btn-sm"
                              data-bs-toggle="modal" data-bs-target="#showpartnerdetail" title="Bağlantı Bilgileri"><i
                                data-feather="info"></i></button>


                            <div class="modal fade" id="showpartnerdetail" tabindex="-1"
                              aria-labelledby="staticBackdropLabel" aria-hidden="true">
                              <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Bağlantı Bilgileri</h1>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                        data-feather="x-circle" class="mr-50"></i>Çıkış
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div *ngIf="!isSafely" class="row">
                                      <div class="col-md-8 mx-auto text-center">
                                        Güvenlik Kodunuzu giriniz
                                      <input type="text" [(ngModel)] = "securityKod" name="securityKod" class="form-control mx-auto" style="width: 200px; text-align: center;">
                                      </div> 

                                    </div>
                                    <div *ngIf="isSafely">
                                      <quill-editor name="quillMessage" #quillInformation="ngModel"
                                      [(ngModel)]="ticketDetail.information" placeholder="Bir bilgi giriniz..">
                                      <div quill-editor-toolbar>
                                        <span class="ql-formats">
                                          <button class="ql-bold" [title]="'Bold'"></button>
                                          <button class="ql-italic" [title]="'italic'"></button>
                                          <button class="ql-underline" [title]="'underline'"></button>
                                        </span>
                                        <span class="ql-formats">
                                          <select class="ql-align" [title]="'Alignment'">
                                            <option selected></option>
                                            <option value="center"></option>
                                            <option value="right"></option>
                                            <option value="justify"></option>
                                          </select>
                                        </span>
                                        <span class="ql-formats">
                                          <button class="ql-list" value="ordered" type="button"></button>
                                        </span>
                                        <span class="ql-formats">
                                          <button class="ql-link" value="ordered" type="button"></button>
                                        </span>
                                        <span class="ql-formats">
                                          <button class="ql-formula" value="ordered" type="button"></button>
                                          <button class="ql-code-block" value="ordered" type="button"></button>
                                        </span>
                                        <span class="ql-formats">
                                          <button class="ql-clean" value="ordered" type="button"></button>
                                        </span>
                                      </div>
                                    </quill-editor>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <div *ngIf="isSafely">
                                      <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                        (click)="updatePartnerInfo(quillInformation.value)"><i data-feather="check"
                                          class="mr-50"></i>Kaydet</button>
                                    </div>
                                    <div *ngIf="!isSafely">
                                      <button type="button" class="btn btn-success"
                                        (click)="applySecurityKod()"><i data-feather="check"
                                          class="mr-50"></i>Doğrula</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </td>
                          <td colspan="4" *ngIf="userRole=='Customer'|| userRole=='CustomerUser'"
                            class="DetailsRightSide" id="projectName">
                            {{ticketsPartner.name}} - {{ticketsProject.title}}
                          </td>
                        </tr>
                        <tr>
                          <td> Bölümü:</td>
                          <td class="DetailsLeftSide fixed-left-select">
                            <select class="form-control" required name="sclDepartment" [(ngModel)]="ticket.departmentId"
                              #sclDepartment="ngModel">
                              <option *ngFor="let item of departments" value="{{item.id}}">{{item.name}}</option>
                            </select>
                          </td>
                          <td>Oluşturulma Tarihi:</td>
                          <td colspan="3" class="DetailsRightSide">
                            {{ticket.createdDate |date:'dd-MM-yyyy HH:mm'}}
                          </td>

                          <td>Tahmini Süre (saat):</td>
                          <td>
                            <input style="width: 80px;" type="number" ngModel id="txtExpectedTime" class="form-control"
                              [(ngModel)]="ticket.expectedTime" name="txtExpectedTime" placeholder="Beklenen Süre"
                              #txtExpectedTime="ngModel" />
                          </td>
                        </tr>
                        <tr>
                          <td>Öncelik Düzeyi:</td>
                          <td class="fixed-left-select">
                            <select class="form-control" required name="slcPriority" [(ngModel)]="ticket.priorityId"
                              #slcPriority="ngModel">
                              <option *ngFor="let item of priorities" value="{{item.id}}">{{item.name}}</option>
                            </select>
                          </td>
                          <td>Talep Türü:</td>
                          <td class="fixed-right-select" colspan="3"
                            *ngIf="userRole=='Admin'||userRole=='ConsultantUser'|| userRole=='Consultant' ">
                            <select class="form-control" required name="slcTicketType" [(ngModel)]="ticket.typeId"
                              #slcTicketType="ngModel">
                              <option *ngFor="let item of ticketTypes" value="{{item.id}}">{{item.name}}</option>
                            </select>
                          </td>
                          <td colspan="3" *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                            <div *ngIf="ticket.typeId==1">Destek Talebi</div>
                            <div *ngIf="ticket.typeId==2">Müşteri Planı</div>
                            <div *ngIf="ticket.typeId==3">Proje Planı</div>
                          </td>

                          <td>Müşteri Talep No:</td>
                          <td>
                            <input style="width: 80px; " type="text" class="form-control"
                              [(ngModel)]="ticket.partnerTicketNo" name="txtPartnerTicketNo" id="txtPartnerTicketNo"
                              #txtPartnerTicketNo />
                          </td>
                        </tr>
                        <tr>

                          <td>Faturalandırma Durumu:</td>
                          <td class="fixed-left-select">
                            <div *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                              <div *ngIf="ticket.billTypeId==4">Faturalanmadı</div>
                              <div *ngIf="ticket.billTypeId==5">Faturalandı</div>
                              <div *ngIf="ticket.billTypeId==6">Feragat Edildi</div>
                            </div>
                            <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                              <select class="form-control" required name="slcBillType" [(ngModel)]="ticket.billTypeId"
                                #slcBillType="ngModel">
                                <option *ngFor="let item of billTypes" value="{{item.id}}">{{item.name}}</option>
                              </select>
                            </div>
                          </td>

                          <td>Durumu:</td>
                          <td colspan="4">
                            <select class="form-control" required name="sclStatus" [(ngModel)]="ticket.statusId"
                              #sclStatus="ngModel">
                              <option *ngFor="let item of statuses" value="{{item.id}}">{{item.name}}</option>
                              <option *ngIf="userRole!='Customer'&& userRole!='CustomerUser'" value="10">Test Sürecinde
                              </option>
                            </select>
                          </td>

                          <td>
                            <select class="form-control" required name="slcModule" [(ngModel)]="ticket.moduleId"
                              #slcModule="ngModel">
                              <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Konusu:</td>
                          <td colspan="7" class="DetailsLeftSide">
                            <textarea type="text" required id="txtSubject" class="form-control"
                              [(ngModel)]="ticket.subject" name="txtSubject" placeholder="Konu"
                              #txtSubject="ngModel"></textarea>
                          </td>

                        </tr>
                        <tr>
                          <td>Takipçi Bilgileri </td>
                          <td colspan="2">
                            <div class="d-flex mt-2 mb-0">
                              <div *ngFor="let item of ticketUserTypesByTicketId"
                                class="avatar ml-0 pull-up ng-star-inserted" style="width: 40px; height: 40px;"
                                [ngClass]="{'bg-light-warning': item.name=='Sorumlu', 'bg-light-primary': item.name=='Takipçi'}"
                                placement="top" ngbTooltip="{{item.userName}}" (click)="getTicketUserType(item.userId)"
                                data-bs-toggle="modal" data-bs-target="#updateticketusertype">

                                <div *ngIf="item.imageURL==null; else imageTemplate">
                                  <div class="avatar-content text-center"
                                    style="font-size: medium; margin: 5px 0px 0px 5px;">
                                    {{ getInitials(item.userName) }}
                                  </div>
                                </div>
                                <ng-template #imageTemplate>
                                  <div *ngIf="item.name=='Takipçi'; else followersTemplate">
                                    <div class="avatar" placement="top" style="border: 2px solid rgb(76, 76, 200);
                                    border-radius: 50%;
                                    padding: 1px;">
                                      <img src="../../../../assets/images/img/{{item.imageURL}}"
                                        style="border-radius:50px ;" width="35" height="35" />
                                    </div>
                                  </div>
                                  <ng-template #followersTemplate>
                                    <div class="avatar" placement="top" style="border: 2px solid orange;
                                    border-radius: 50%;
                                    padding: 1px;">
                                      <img src="../../../../assets/images/img/{{item.imageURL}}"
                                        style="border-radius:50px ;" width="35" height="35" />
                                    </div>
                                  </ng-template>
                                </ng-template>
                              </div>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>

                          <td colspan="2" class="DetailsRightSide">
                            <div class="row">
                              <div class="col-2">
                              </div>
                              <div class="col-10" style="text-align:center; ">
                                Talebi Açan Kullanıcı
                                <div class="badge badge-pill badge-light-success font-small-3 ">
                                  {{createdUser.firstName}} {{createdUser.lastName}}
                                </div>
                              </div>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
      </div>
    </div>


    <table class="table table-stripped table-hover" id="excel-table-ticketusertype" style="display: none;">
      <tbody>
        <tr>
          <td>Kullanıcı Adı</td>
          <td>Rolü</td>
        </tr>
        <tr *ngFor="let item of ticketUserTypesByTicketId">
          <td>{{item.userName}}</td>
          <td>{{item.name}}</td>
        </tr>
      </tbody>
    </table>

    <div class="modal fade" id="showticketusers" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Takipçi Bilgileri</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-2">
              <button class="btn btn-primary mr-2 btn-sm" data-bs-toggle="modal" title="Takipçi Ekle"
                data-bs-target="#addticketusertype"><i data-feather="plus"></i></button>
              <button class="btn btn-secondary btn-sm" (click)="exportExcelTicketUserType()" title="Excele Aktar"><i
                  data-feather="file-text"></i></button>
            </div>
            <div class="table-responsive">
              <table class="table table-stripped table-hover" style="text-align: center;">
                <thead>
                  <th>Kullanıcı Adı</th>
                  <th>Rolü</th>
                  <th>İşlemler</th>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ticketUserTypesByTicketId">
                    <td>{{item.userName}}</td>
                    <td>{{item.name}}</td>
                    <td>
                      <span *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                        <button class="btn btn-warning btn-sm mr-2" title="Güncelle" data-bs-toggle="modal"
                          data-bs-target="#updateticketusertype" (click)="getTicketUserType(item.userId)"><i
                            data-feather="edit"></i></button>
                        <span *ngIf="userRole=='Admin' || myId==item.userId ;else disabledDeleteUser">
                          <button class="btn btn-danger btn-sm" title="Sil" (click)="deleteTicketUser=item"
                            data-bs-toggle="modal" data-bs-target="#deleteticketusertype"><i
                              data-feather="trash"></i></button>
                        </span>
                        <ng-template #disabledDeleteUser>
                          <button class="btn btn-danger btn-sm" style="cursor: not-allowed;" title="Sil" disabled><i
                              data-feather="trash"></i></button>
                        </ng-template>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addticketusertype" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Takipçi Ekle</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış
            </button>
          </div>
          <form class="" #TicketUserForm="ngForm">
            <div class="modal-body">
              <div class="form-group">
                <div class="row mb-2">
                  <label for="name-vertical">Kullanıcıyı Seçiniz</label>
                  <select class="form-control" required name="slcUser" #slcUser="ngModel" [(ngModel)]="user.id">
                    <option value="{{myId}}" selected>{{userName}}</option>
                    <option *ngFor="let item of users" value="{{item.id}}">
                      {{item.firstName}} {{item.lastName}}</option>
                  </select>
                </div>
                <div class="row mb-2">
                  <label for="name-vertical">Rolü Seçiniz</label>
                  <select class="form-control" name="slcTicketUserType" [(ngModel)]="ticketUserType.id"
                    #slcTicketUserType="ngModel" required>
                    <option value="6" *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                      Sorumlu</option>
                    <option value="9">Takipçi</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <div class="col-12" *ngIf="!TicketUserForm.valid;else emptyAddButton">
                  <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                    disabled>Kaydet</button>
                </div>
              </div>
              <ng-template #emptyAddButton>
                <div>
                  <div class="col-12">
                    <button type="button" rippleEffect class="btn btn-success mr-1" (click)="addTicketUserType()"><i
                        data-feather="check" class="mr-50"></i> Kaydet</button>
                  </div>
                </div>
              </ng-template>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="updateticketusertype" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 rounded-circle" id="staticBackdropLabel">Takipçi Güncelle</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış
            </button>
          </div>
          <form class="" #UserOperationClaimForm="ngForm">
            <div class="modal-body">
              <div class="form-group">
                <div class="row mb-2">
                  <label for="name-vertical">Kullanıcıyı Seçiniz</label>
                  <select class="form-control" name="slcUpdateUser" [(ngModel)]="ticketDetail.userId"
                    #slcUpdateUser="ngModel">
                    <option *ngIf="userRole=='Customer'||userRole=='CustomerUser' else consultantUserTemplate"
                      value="{{myId}}">{{userName}}
                    </option>
                    <ng-template #consultantUserTemplate>
                      <option *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"
                        value="{{myId}}">{{userName}}
                      </option>
                    </ng-template>
                    <option *ngFor="let item of users" value="{{item.id}}">
                      {{item.firstName}} {{item.lastName}}</option>
                  </select>
                </div>
                <div class="row mb-2">
                  <label for="name-vertical">Rolü Seçiniz</label>
                  <select class="form-control" name="slcUpdateTicketUserType" [(ngModel)]="ticketDetail.typeId"
                    #slcUpdateTicketUserType="ngModel">
                    <option *ngFor="let item of ticketUserTypes " value="{{item.id}}">
                      {{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                  (click)="updateTicketUserType()"><i data-feather="check" class="mr-50"
                    data-bs-dismiss="modal"></i>Kaydet</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="deleteticketusertype" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Takipçi Bilgisini Sil</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <div class="modal-body">
            Kaydı Silmek İstediğinize Emin Misiniz?
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                (click)="deleteTicketUserType(deleteTicketUser.ticketUserId,deleteTicketUser.userId,deleteTicketUser.name)"><i
                  data-feather="trash" class="mr-50"></i>Sil</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row mb-4">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills" [activeId]="navoption">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Mesajlar</a>
          <ng-template ngbNavContent>
            <div *ngFor="let item of newTicketMessages">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12 ">
                    <div style="position: relative">
                      <div data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" title=""
                        class="pull-up m-0">
                        <div class="card rounded-lg ">
                          <div class="card-body ">
                            <div class="todo-title-wrapper">
                              <div class="todo-title-area">
                                <div class="d-flex justify-content-md-end">
                                  <div class="d-flex align-items-center justify-content-end ">
                                    <div ngbDropdown container="body" *ngIf="item[0].userName==userName">
                                      <button type="button" class="btn btn-sm " (click)="deletedMessageId=item[0].id"
                                        data-bs-toggle="modal" title="Mesajı Sil" data-bs-target="#deletemessage">
                                        <i data-feather="x-circle" class="mr-50"></i>
                                      </button>
                                      <!-- data-toggle="dropdown"  ngbDropdownToggle
                                        <div ngbDropdownMenu>
                                        <button ngbDropdownItem (click)="deletedMessageId=item[0].id"
                                          data-bs-toggle="modal" data-bs-target="#deletemessage"><i data-feather="trash"
                                            class="mr-50"></i><span>Sil</span>
                                        </button>
                                      </div> 
                                    -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="title-wrapper">
                                <span class="todo-title"
                                  *ngIf="item[0].text!='undefined'&& item[0].text!='[object Object]'; else emptyMessageTemplate">
                                  <div [innerHTML]="item[0].text"></div>
                                </span>
                                <ng-template>
                                  <div class="text-muted font-small-2">...</div>
                                </ng-template>
                                <hr>
                                <div *ngFor="let document of item">
                                  <div class="d-flex justify-content-md">
                                    <div
                                      class="d-flex align-items-center justify-content pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                      <div class="d-flex">
                                        <div *ngIf="document.documentUrl!=''">
                                          <div class="documents">
                                            <button
                                              class="badge badge-pill badge-primary font-small-3 font-weight-bold line-height-2 mb-25"
                                              (click)="downloadImage(document.documentUrl)">
                                              <i data-feather="file-text" class="mr-50"></i>
                                              {{document.documentUrl}}
                                              <span class="align-middle"></span></button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="todo-item-action">
                              <div class="d-flex justify-content-md-end">
                                <div
                                  class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                                  <div class="badge-wrapper mr-1">
                                    {{item[0].userName}}
                                  </div>
                                  <small class="text-nowrap text-muted mr-1">{{item[0].sendedDate|
                                    date:'dd-MM-YYYY HH:mm'}}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink (click)="getTicketDetailsByPermissions()">Aktivite</a>
          <ng-template ngbNavContent>
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">Aktivite Bilgileri</h2>
              </div>
              <div class="card-body">
                <div class="mb-2">
                  <div *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                    <button class="btn btn-secondary btn-sm" title="Excele Aktar" (click)="exportExcel()"><i
                        data-feather="file-text"></i><span></span></button>
                  </div>
                  <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                    <button class="btn btn-primary mr-2 btn-sm" title="Yeni Aktivite Ekle" data-bs-toggle="modal"
                      data-bs-target="#addticketactivity"><i data-feather="plus"></i><span></span></button>
                    <button class="btn btn-secondary btn-sm" title="Excele Aktar" (click)="exportExcel()"><i
                        data-feather="file-text"></i><span></span></button>
                  </div>

                </div>
                <table class="table" id="excel-table" style="text-align: center;display: none;">
                  <tbody>
                    <tr>
                      <td>Başlangıç Tarihi</td>
                      <td>Takip Numarası</td>
                      <td>Talep Konusu</td>
                      <td>Açıklama</td>
                      <td>Danışmman Adı</td>
                      <td>Modül</td>
                      <td>Süre</td>
                      <td>Aktivitenin Faturalandırma Durumu</td>
                      <td>Lokasyon Tipi</td>
                      <td>Partner Bilgisi</td>
                      <td>Partner Talep Numarası</td>
                      <td>Talep Durumu</td>
                      <td>Talep Faturalandırma Durumu</td>
                    </tr>
                    <tr *ngFor="let item of ticketActivities">
                      <td>{{item.startDate |date:'MM.dd.YYYY HH:mm'}}</td>

                      <td>{{ticket.trackingNumber}}</td>
                      <td>
                        <div [innerHTML]="item.ticketSubject"></div>
                      </td>
                      <td>
                        <div [innerHTML]="item.description"> </div>
                      </td>
                      <td>{{item.userName}}</td>
                      <td>{{item.moduleName}}</td>
                      <td>{{item.time}} Saat</td>
                      <td *ngIf="item.billTypeId==4">Faturalanmadı</td>
                      <td *ngIf="item.billTypeId==5">Faturalandı</td>
                      <td *ngIf="item.billTypeId==6">Feragat Edildi</td>
                      <td>{{item.locationTypeName}}</td>
                      <td>{{item.partnerName}}</td>
                      <td>{{item.partnerTicketNo}}</td>
                      <td>{{item.ticketStatusName}}</td>
                      <td>{{item.ticketBillTypeName}}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="table-responsive">
                  <table class="table table-hover table-stripped" style="text-align: center;">
                    <thead>
                      <th>Başlangıç Tarihi</th>
                      <th>Açıklaması</th>
                      <th>Aktivite Sahibi</th>
                      <th>Modülü</th>
                      <th>Süre</th>
                      <th>Faturalandırma</th>
                      <th *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"> İşlemler
                      </th>
                    </thead>
                    <tr *ngFor="let item of ticketActivities">
                      <td>{{item.startDate |date:'dd-MM-YYYY HH:mm'}}</td>
                      <td>
                        <div
                          [innerHTML]="item.description.length >40  ? item.description.substring(0, 40) + '...' : item.description">
                        </div>
                      </td>
                      <td>{{item.userName}}</td>
                      <td>{{item.moduleName}}</td>
                      <td>{{item.time}} Saat</td>

                      <td *ngIf="item.billTypeId==4">Faturalandırılmadı </td>
                      <td *ngIf="item.billTypeId==5">Faturalandı</td>
                      <td *ngIf="item.billTypeId==6">Feragat Edildi</td>

                      <td *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">

                        <div
                          *ngIf=" userRole=='Admin' || item.userId==myId || ticket.billTypeId!=4; else unavailableTemplate">
                          <button class="btn btn-primary btn-sm mr-2" title="Önizleme" data-bs-toggle="modal"
                            data-bs-target="#viewticketactivity" (click)="getTicketActivityforPreview(item.id)"><i
                              data-feather="eye"></i></button>
                          <button class="btn btn-warning btn-sm mr-2" title="Güncelle" data-bs-toggle="modal"
                            data-bs-target="#updateticketativity" (click)="getTicketActivity(item.id)"><i
                              data-feather="edit"></i></button>
                          <button class="btn btn-danger btn-sm" title="Sil" (click)="deletedTicketActivityId=item.id"
                            data-bs-toggle="modal" data-bs-target="#deleteticketactivity"><i
                              data-feather="trash"></i></button>
                        </div>
                        <ng-template #unavailableTemplate>
                          <button class="btn btn-primary btn-sm mr-2" title="Önizleme" data-bs-toggle="modal"
                            data-bs-target="#viewticketactivity" (click)="getTicketActivityforPreview(item.id)"><i
                              data-feather="eye"></i></button>
                          <button class="btn btn-warning btn-sm mr-2" title="Güncelle" disabled
                            style="cursor: not-allowed;"><i data-feather="edit"></i></button>
                          <button class="btn btn-danger btn-sm" title="Sil" disabled style="cursor: not-allowed;"><i
                              data-feather="trash"></i></button>
                        </ng-template>

                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink (click)="getTicketDetailsByPermissions()">Onay</a>
          <ng-template ngbNavContent>
            <div class="card">
              <div class="card-header">
                <h2 class="card-title">Onay Bilgileri</h2>
              </div>
              <div class="card-body">
                <div class="mb-2">
                  <div *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                    <div class="row">
                      <div class="col-11">
                        <button class="btn btn-secondary btn-sm " title="Excele Aktar" (click)="exportExcelEffort()"><i
                            data-feather="file-text"></i><span></span></button>
                      </div>
                    </div>

                  </div>
                  <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'">
                    <button class="btn btn-primary mr-2 btn-sm" title="Yeni Efor Ekle" data-bs-toggle="modal"
                      data-bs-target="#addeffort" (click)="openEfortModal()"><i
                        data-feather="plus"></i><span></span></button>
                    <button class="btn btn-secondary btn-sm mr-2" title="Excele Aktar" (click)="exportExcelEffort()"><i
                        data-feather="file-text"></i><span></span></button>
                  </div>
                </div>
                <table class="table table-hover table-stripped" id="excel-table-effort" style="display: none;">
                  <tr>
                    <td>Kullanıcı Bilgisi</td>
                    <td>Onaylayan Bilgisi</td>
                    <td>Açıklaması</td>
                    <td>Başlangıç Tarihi</td>
                    <td>Durum</td>
                    <td>Faturalandırma Durumu</td>
                    <td>Süre</td>
                  </tr>
                  <tr *ngFor="let item of efforts">
                    <td>{{item.userName}}</td>
                    <td>
                      <div [innerHTML]="item.description">
                      </div>
                    </td>
                    <td>{{item.startDate |date:'MM.dd.YYYY HH:mm'}}</td>
                    <td *ngIf="item.statusId==1">Onaylandı</td>
                    <td *ngIf="item.statusId==2">Onay Bekleniyor</td>
                    <td *ngIf="item.statusId==3">Reddedildi</td>
                    <td *ngIf="item.billTypeId==4">Faturalanmadı</td>
                    <td *ngIf="item.billTypeId==5">Faturalandı</td>
                    <td *ngIf="item.billTypeId==6">Feragat Edildi</td>
                    <td>{{item.time}} Saat</td>
                  </tr>
                </table>
                <div class="table-responsive">

                  <table class="table table-hover table-stripped" style="text-align: center;  overflow: auto; ">
                    <thead>
                      <th>Kullanıcı Bilgisi</th>
                      <th>Onaylayan Bilgisi</th>
                      <th>Açıklaması</th>
                      <th>Başlangıç Tarihi</th>
                      <th>Süre</th>
                      <th>Durum</th>
                      <th>İşlemler</th>
                    </thead>
                    <tbody *ngFor="let item of efforts">
                      <td>{{item.userName}}</td>
                      <td>{{item.partnerUserName}}</td>
                      <td>
                        <div
                          [innerHTML]="item.description.length >20  ? item.description.substring(0, 20) + '...' : item.description">
                        </div>
                      </td>
                      <td>{{item.startDate |date:'dd-MM-YYYY HH:mm'}}</td>
                      <td>{{item.time}} Saat</td>
                      <td *ngIf="item.statusId==1">Onaylandı</td>
                      <td *ngIf="item.statusId==2">Onay Bekleniyor</td>
                      <td *ngIf="item.statusId==3">Reddedildi</td>
                      <td>
                        <button class="btn btn-warning btn-sm mr-1" title="Güncelle" data-bs-toggle="modal"
                          data-bs-target="#updateeffort" (click)="getEffort(item.id)"><i data-feather="edit"
                            class=""></i></button>
                        <button *ngIf="userRole=='Admin' || item.userId ==myId;else emptyEfforDeleteButton"
                          class="btn btn-danger btn-sm" title="Sil" data-bs-toggle="modal"
                          data-bs-target="#deleteeffort" (click)="deletedEffortId=item.id"><i data-feather="trash"
                            class=""></i></button>
                        <ng-template #emptyEfforDeleteButton>
                          <button class="btn btn-danger btn-sm" title="Sil" style="cursor: not-allowed;" disabled><i
                              data-feather="trash" class=""></i></button>
                        </ng-template>
                      </td>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>Bağlantılı Talepler</a>
          <ng-template ngbNavContent>
            <div class="card">
              <core-card-snippet>
                <div class="card-title">
                  Yeni Bağlantı Talep
                </div>
                <div class="row mb-2">
                  <div class="col-5">
                    <div class="col-12">
                      <button class="btn btn-primary btn-sm mr-1" data-bs-toggle="modal"
                        data-bs-target="#addConnectionTicket" rippleEffect title="Yeni Bağlantı">
                        <i data-feather="plus" class="mr-0"></i><span class="d-none d-sm-inline-block"></span>
                      </button>
                    </div>
                  </div>
                  <div class="col-7">
                    <div class="search mt-1">
                      <div class="col-5 ml-auto">
                        <input type="text" class="form-control ml-auto" name="filterText" placeholder="Arama Yapın.."
                          [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
                      </div>
                    </div>
                  </div>

                </div>
                <ngx-datatable [rows]="connectedTicketList |evoTicket:filterText:fromDate:toDate" [rowHeight]="58"
                  class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                  [scrollbarH]="true" [limit]="basicSelectedOption">
                  <!-- <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                      let-selectFn="selectFn">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                          (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                        <label class="custom-control-label" for="headerChkbxRef"></label>
                      </div>
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                      let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                          (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                        <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                      </div>
                    </ng-template>
                  </ngx-datatable-column> -->
                  <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <div ngbDropdown container="body">
                          <button class="btn btn-danger btn-sm" (click)="deleteTicketConnectionId = row.id"
                            data-bs-toggle="modal" data-bs-target="#deleteConnectionTicket"><i data-feather="trash"
                              class=""></i></button>
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="150">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <div class="cell-line-height">
                          <a class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2 mb-25"
                            routerLink="/home/details/{{row.id}}" target="">#{{ name }}</a>
                          <!-- altına tatlı ve açık gri bir açıklama eklemek için kullanılabilr -->
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Öncelik" prop="priorityId" [width]="150">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <div class="badge badge-pill badge-light-danger mr-1" *ngIf="row.priorityId==1">Yüksek</div>
                        <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row.priorityId==2">Normal
                        </div>
                        <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="row.priorityId==3">Düşük
                        </div>
                      </div>
                      <span class="text-muted font-small-2"> {{ row.typeName }}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Konu" prop="subject" [width]="250">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{row.subject.length > 30 ? row.subject.slice(0, 30) + '...' : row.subject}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Bölüm" prop="departmentName" [width]="180">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{row.departmentName.length > 30 ? row.departmentName.slice(0, 30) + '...' : row.departmentName}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="240">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="text-muted font-small-2" *ngIf="row.partnerId==0; else partnerTemplate">
                        Atanmış Şirket bulunmamakta
                      </div>
                      <ng-template #partnerTemplate>
                        {{row.partnerName.length > 30 ? row.partnerName.slice(0, 30) + '...' : row.partnerName}}
                        <br>
                        <span *ngIf="row.partnerTicketNo!=null; else emptyPartnerTicketNoTemplate "
                          class="text-muted font-small-2"> {{ row.partnerTicketNo }}</span>
                        <ng-template #emptyPartnerTicketNoTemplate></ng-template>
                      </ng-template>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Oluşturma Tarihi" prop="createdDate" [width]="180">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{row.createdDate|date:'dd-MM-yyyy HH:mm'}}
                    </ng-template>
                  </ngx-datatable-column>

                </ngx-datatable>



              </core-card-snippet>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>

    <div class="modal fade" id="deleteConnectionTicket" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Bağlantısını Sil</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <div class="modal-body">
            Kaydı Silmek İstediğinize Emin Misiniz?
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteTicketConnection()"><i
                  data-feather="trash" class="mr-50"></i>Sil</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addticketactivity" data-bs-backdrop="static" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Yeni Aktivite Ekle</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <form class="" #AddActivityForm="ngForm">
            <div class="modal-body">
              <div class="form-group">
                <div class="row mb-2">
                  <label>Başlangıç Tarihi Seçiniz</label>
                  <input type="datetime-local" class="form-control" name="txtStartDate" [formControl]="startDateControl"
                    required>

                </div>

                <div class="row mb-2">
                  <label>Süre Giriniz</label>
                  <input type="number" class="form-control" required ngModel name="txtTime" placeholder="Süre"
                    #txtTime />
                </div>
                <div class="row mb-2">
                  <label for="moduleSelect">Modül Seçiniz</label>
                  <select class="form-control" required name="slcModule" [(ngModel)]="module.id" #slcModule="ngModel">
                    <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
                <div class="row mb-2">
                  <label for="prioritySelect">Lokasyon Tipi Seçiniz</label>
                  <select class="form-control" required name="slcLocationType" [(ngModel)]="locationType.id"
                    #slcLocationType="ngModel">
                    <option *ngFor="let item of locationTypes" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
                <div>
                  <quill-editor name="quillMessage" #quillDescription="ngModel" ngModel
                    placeholder="Bir mesaj giriniz..">

                    <div quill-editor-toolbar>
                      <span class="ql-formats">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'italic'"></button>
                        <button class="ql-underline" [title]="'underline'"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-align" [title]="'Alignment'">
                          <option selected></option>
                          <option value="center"></option>
                          <option value="right"></option>
                          <option value="justify"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-link" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-formula" value="ordered" type="button"></button>
                        <button class="ql-code-block" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-clean" value="ordered" type="button"></button>
                      </span>
                    </div>
                  </quill-editor>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <div class="col-12" *ngIf="!AddActivityForm.valid;else emptyAddActivityButton">
                  <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                    disabled>Kaydet</button>
                </div>
              </div>
              <ng-template #emptyAddActivityButton>
                <div>
                  <div class="col-12">
                    <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                      (click)="addActivity(startDateControl.value,quillDescription.value, txtTime.value)"><i
                        data-feather="check" class="mr-50"></i> Kaydet</button>
                  </div>
                </div>
              </ng-template>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal fade " id="updateticketativity" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Aktivite Güncelle</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <form class="" #UpdateActivityForm="ngForm">
            <div class="modal-body">
              <div class="form-group">
                <div class="row mb-2">
                  <label for="">Başlangıç Tarihini Giriniz:</label>
                  <input type="datetime-local" name="txtStartDate" [(ngModel)]="ticketActivity.startDate "
                    #txtStartDate="ngModel" class="form-control">
                </div>
                <div class="row mb-2">
                  <label for="">Süre Giriniz:</label>
                  <input type="number" name="txtTime" required [(ngModel)]="ticketActivity.time" #txtTime="ngModel"
                    class="form-control">
                </div>
                <div class="row mb-2">
                  <label for="moduleSelect">Modül Seçiniz</label>
                  <select class="form-control" required name="slcModule" [(ngModel)]="ticketActivity.moduleId"
                    #slcModule="ngModel">
                    <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
                <div class="row mb-2">
                  <label for="prioritySelect">Lokasyon Tipi Seçiniz</label>
                  <select class="form-control" required name="slcLocationType"
                    [(ngModel)]="ticketActivity.locationTypeId" #slcLocationType="ngModel">
                    <option *ngFor="let item of locationTypes" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
                <div class="row mb-2">
                  <label for="slcBillTypeForActivity">Faturalandırma Durumu Seçiniz</label>
                  <select class="form-control" required name="slcBillTypeForActivity"
                    [(ngModel)]="ticketActivity.billTypeId" #slcBillTypeForActivity="ngModel">
                    <option *ngFor="let item of billTypes" value="{{item.id}}">{{item.name}}</option>
                  </select>
                </div>
                <quill-editor name="quillMessage" #quillDescription="ngModel" [(ngModel)]="ticketActivity.description"
                  placeholder="Bir mesaj giriniz..">
                  <div quill-editor-toolbar ngModel required>
                    <span class="ql-formats">
                      <button class="ql-bold" [title]="'Bold'"></button>
                      <button class="ql-italic" [title]="'italic'"></button>
                      <button class="ql-underline" [title]="'underline'"></button>
                    </span>
                    <span class="ql-formats">
                      <select class="ql-align" [title]="'Alignment'">
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                      </select>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-list" value="ordered" type="button"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-link" value="ordered" type="button"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-formula" value="ordered" type="button"></button>
                      <button class="ql-code-block" value="ordered" type="button"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-clean" value="ordered" type="button"></button>
                    </span>
                  </div>
                </quill-editor>
              </div>
            </div>
            <div class="modal-footer">
              <div>
                <div class="col-12" *ngIf="!UpdateActivityForm.valid;else emptyUpdateActivityButton">
                  <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                    disabled>Kaydet</button>
                </div>
              </div>
              <ng-template #emptyUpdateActivityButton>
                <div>
                  <div class="col-12">
                    <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                      (click)="updateActivity()"><i data-feather="check" class="mr-50"></i> Kaydet</button>
                  </div>
                </div>
              </ng-template>
            </div>
          </form>

        </div>
      </div>
    </div>
    <div class="modal fade" id="deleteticketactivity" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Aktivitesini Sil</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <div class="modal-body">
            Kaydı Silmek İstediğinize Emin Misiniz?
          </div>
          <div class="modal-footer">
            <div>
              <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                (click)="deleteTicketActivity(deletedTicketActivityId)"><i data-feather="trash"
                  class="mr-50"></i>Sil</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="viewticketactivity" tabindex="-1" aria-labelledby="staticBackdropLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="staticBackdropLabel">Aktivite Önizlemesi</h1>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                class="mr-50"></i>Çıkış</button>
          </div>
          <div class="modal-body">
            <table class="table text-center">
              <thead class="text-primary">
                <th></th>
                <th>
                </th>
                <th class="text-right">
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3">{{ticketActivity.userName}}</td>

                  <td colspan="2">{{ticketActivity.time}} Saat</td>
                  <td>
                    <p class="verticalLine"> </p>
                  </td>
                  <td>
                    {{ticketActivity.startDate|date:'dd-MM-YYYY'}}
                    <br>
                    {{ticketActivity.startDate|date:'HH:mm'}}
                  </td>

                <tr>
                  <td colspan="5" rowspan="4">
                    <!-- <textarea class="form-control" rows="8" readonly>{{ticketActivity.description}}</textarea> -->
                    <div style="width: 500px;">
                      <quill-editor name="quillTicketActivity " id="quillTicketActivityDescription"
                        #quillTicketActivityDescription="ngModel" [(ngModel)]="ticketActivity.description">
                        <div quill-editor-toolbar ngModel required>
                        </div>
                      </quill-editor>
                    </div>
                  </td>
                  <td>
                    <p class="verticalLine"> </p>
                  </td>
                  <td>
                    {{ticketActivity.moduleName}}
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="verticalLine"></p>
                  </td>
                  <td>{{ticketActivity.locationTypeName}} Çalışma ile Yapılmıştır</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <div class="modal-footer">
          <div>
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
              (click)="deleteTicketActivity(deletedTicketActivityId)"><i data-feather="trash"
                class="mr-50"></i>Sil</button>
          </div>
        </div> -->
        </div>
      </div>
    </div>


    <div class="col-12">
      <div class="modal fade " id="addeffort" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Onay Bilgisi Oluştur</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"></i>
                Çıkış</button>
            </div>
            <form class="" #AddEffortForm="ngForm">

              <div class="modal-body p-3">
                <div class="form-group">
                  <div class="row mb-2" *ngIf="userRole=='Admin' || userRole=='Consultant'">
                    <label for="userSelect">Kullanıcı Seçiniz</label>
                    <select class="form-control" required name="slcEffortUserName" [(ngModel)]="user.id"
                      #slcEffortUserName="ngModel">
                      <option *ngIf="userRole=='Admin' || userRole=='Consultant'" value="{{myId}}">{{userName}}
                      </option>
                      <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}
                      </option>
                    </select>
                  </div>
                  <div class="row mb-2">
                    <label>Başlangıç Tarihi Seçiniz</label>
                    <input type="datetime-local" class="form-control" name="txtEffortCreateDate" #txtEffortCreateDate
                      [formControl]="startDateEffortControl" required>

                  </div>
                  <div class="row mb-2">
                    <label for="">Süre Giriniz:</label>
                    <input type="number" name="txtEffortTime" required #txtEffortTime ngModel class="form-control">
                  </div>

                  <quill-editor name="quillMessage" #quileEffortTDescription="ngModel" [(ngModel)]="effort.description"
                    placeholder="Bir mesaj giriniz..">
                    <div quill-editor-toolbar ngModel required>
                      <span class="ql-formats">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'italic'"></button>
                        <button class="ql-underline" [title]="'underline'"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-align" [title]="'Alignment'">
                          <option selected></option>
                          <option value="center"></option>
                          <option value="right"></option>
                          <option value="justify"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-link" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-formula" value="ordered" type="button"></button>
                        <button class="ql-code-block" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-clean" value="ordered" type="button"></button>
                      </span>
                    </div>
                  </quill-editor>
                </div>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!AddEffortForm.valid;else emptyAddEffortButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                </div>
                <ng-template #emptyAddEffortButton>
                  <div>
                    <div class="col-12">
                      <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                        (click)="addEffort(txtEffortTime.value,quileEffortTDescription.value, txtEffortCreateDate.value)"><i
                          data-feather="check" class="mr-50"></i> Kaydet</button>
                    </div>
                  </div>
                </ng-template>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="modal fade" id="updateeffort" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Onay Bilgisi Güncelle</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>

            <div *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
              <form class="" #UpdateEffortForCustomerForm="ngForm">

                <div class="modal-body">
                  <div class="form-group">
                    <div class="row mb-2 col-12">
                      <label for="" class="card-title">Kullanıcı:</label>
                      <select class="form-control" [(ngModel)]="effort.userId" #slcUpdateEffortUserName="ngModel"
                        style="cursor: not-allowed;" disabled>
                        <option *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"
                          value="{{myId}}">{{userName}}
                        </option>
                        <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}
                        </option>
                      </select>
                    </div>

                    <div class="row mb-2 col-12">
                      <label class="card-title">Oluşturulma Tarihi:</label>
                      <input type="datetime-local" class="form-control fixed-right-select" disabled
                        style="cursor: not-allowed;" [(ngModel)]="effort.startDate">
                    </div>

                    <div class="row mb-2 col-12">
                      <label class="card-title">Süre:</label>
                      <input type="number" class="form-control" [(ngModel)]="effort.time" disabled
                        style="cursor: not-allowed;">
                    </div>

                    <div class="row mb-2 col-12">
                      <label class="card-title">Onay Durumu:
                      </label>
                      <select class="form-control" required name="slcUpdateEffortStatus" [(ngModel)]="effort.statusId"
                        #slcUpdateEffortStatus="ngModel">
                        <option *ngFor="let item of effortStatuses" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </div>

                    <div class="row mb-2 col-12">
                      <label class="card-title ">Açıklama:
                        <br>
                        <br>
                        <label class="card-title" [innerHTML]="effort.description"></label></label>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <div>
                    <div class="col-12"
                      *ngIf="!UpdateEffortForCustomerForm.valid;else emptyUpdateEffortForCutomerButton">
                      <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                        disabled>Kaydet</button>
                    </div>
                  </div>
                  <ng-template #emptyUpdateEffortForCutomerButton>
                    <div>
                      <div class="col-12">
                        <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                          (click)="updateStatus()"><i data-feather="check" class="mr-50"></i> Kaydet</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </form>
            </div>
            <div *ngIf="userRole=='Admin' || userRole=='Consultant'">
              <form class="" #UpdateEffortForAdminForm="ngForm">

                <div class="modal-body">
                  <div class="form-group">
                    <div class="row mb-2">
                      <label for="slcUpdateEffortUserName">Kullanıcı Seçiniz</label>
                      <select class="form-control" required name="slcUpdateEffortUserName" [(ngModel)]="effort.userId"
                        #slcUpdateEffortUserName="ngModel">
                        <option *ngIf="userRole=='Admin' || userRole=='Consultant'" value="{{myId}}">{{userName}}
                        </option>
                        <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}
                        </option>
                      </select>
                    </div>
                    <div class="row mb-2">
                      <label for="">Başlangıç Tarihini Giriniz:</label>
                      <input type="datetime-local" required name="txtUpdateEffortTCreatedDate"
                        [(ngModel)]="effort.startDate" #txtUpdateEffortTCreatedDate="ngModel" class="form-control">
                    </div>
                    <div class="row mb-2" *ngIf="userRole=='ConsultantUser' || userRole=='Consultant'">
                      <label for="slcUpdateEffortStatus">Onay Durumu Seçiniz</label>
                      <select class="form-control" required disabled style="cursor: not-allowed;"
                        [(ngModel)]="effort.statusId">
                        <option *ngFor="let item of effortStatuses" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </div>

                    <div class="row mb-2" *ngIf="userRole=='Admin'">
                      <label for="slcUpdateEffortStatus">Onay Durumu Seçiniz</label>
                      <select class="form-control" required name="slcUpdateEffortStatus" [(ngModel)]="effort.statusId"
                        #slcUpdateEffortStatus="ngModel">
                        <option *ngFor="let item of effortStatuses" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </div>

                    <div class="row mb-2">
                      <label for="">Süre Giriniz:</label>
                      <input type="number" name="txtUpdateEffortTime" required [(ngModel)]="effort.time"
                        #txtUpdateEffortTime="ngModel" class="form-control">
                    </div>
                    <quill-editor name="quillMessage" #quillUpdateEffortTDescription="ngModel"
                      [(ngModel)]="effort.description" placeholder="Bir mesaj giriniz..">
                      <div quill-editor-toolbar ngModel required>
                        <span class="ql-formats">
                          <button class="ql-bold" [title]="'Bold'"></button>
                          <button class="ql-italic" [title]="'italic'"></button>
                          <button class="ql-underline" [title]="'underline'"></button>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-align" [title]="'Alignment'">
                            <option selected></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                          </select>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-list" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-link" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-formula" value="ordered" type="button"></button>
                          <button class="ql-code-block" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-clean" value="ordered" type="button"></button>
                        </span>
                      </div>
                    </quill-editor>
                  </div>
                </div>
                <div class="modal-footer">
                  <div>
                    <div class="col-12" *ngIf="!UpdateEffortForAdminForm.valid;else emptyUpdateEffortForAdminButton">
                      <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                        disabled>Kaydet</button>
                    </div>
                  </div>
                  <ng-template #emptyUpdateEffortForAdminButton>
                    <div>
                      <div class="col-12">
                        <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                          (click)="updateEffort()"><i data-feather="check" class="mr-50"></i> Kaydet</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </form>
            </div>
            <div *ngIf="userRole=='ConsultantUser'">
              <form class="" #UpdateEffortForConsultantForm="ngForm">

                <div class="modal-body">
                  <div class="form-group">
                    <div class="row mb-2">
                      <label for="slcUpdateEffortUserName">Kullanıcı Seçiniz</label>
                      <select class="form-control" required name="slcUpdateEffortUserName" [(ngModel)]="effort.userId"
                        #slcUpdateEffortUserName="ngModel">
                        <option value="{{myId}}">{{userName}} </option>
                      </select>
                    </div>
                    <div class="row mb-2">
                      <label for="">Başlangıç Tarihini Giriniz:</label>
                      <input type="datetime-local" required name="txtUpdateEffortTCreatedDate"
                        [(ngModel)]="effort.startDate" #txtUpdateEffortTCreatedDate="ngModel" class="form-control">
                    </div>
                    <div class="row mb-2" *ngIf="userRole=='ConsultantUser' || userRole=='Consultant'">
                      <label for="slcUpdateEffortStatus">Onay Durumu Seçiniz</label>
                      <select class="form-control" required disabled style="cursor: not-allowed;"
                        [(ngModel)]="effort.statusId">
                        <option *ngFor="let item of effortStatuses" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </div>
                    <div class="row mb-2" *ngIf="userRole=='Admin'">
                      <label for="slcUpdateEffortStatus">Onay Durumu Seçiniz</label>
                      <select class="form-control" required name="slcUpdateEffortStatus" [(ngModel)]="effort.statusId"
                        #slcUpdateEffortStatus="ngModel">
                        <option *ngFor="let item of effortStatuses" value="{{item.id}}">{{item.name}}</option>
                      </select>
                    </div>

                    <div class="row mb-2">
                      <label for="">Süre Giriniz:</label>
                      <input type="number" required name="txtUpdateEffortTime" [(ngModel)]="effort.time"
                        #txtUpdateEffortTime="ngModel" class="form-control">
                    </div>
                    <quill-editor name="quillMessage" #quillUpdateEffortTDescription="ngModel"
                      [(ngModel)]="effort.description" placeholder="Bir mesaj giriniz..">
                      <div quill-editor-toolbar ngModel required>
                        <span class="ql-formats">
                          <button class="ql-bold" [title]="'Bold'"></button>
                          <button class="ql-italic" [title]="'italic'"></button>
                          <button class="ql-underline" [title]="'underline'"></button>
                        </span>
                        <span class="ql-formats">
                          <select class="ql-align" [title]="'Alignment'">
                            <option selected></option>
                            <option value="center"></option>
                            <option value="right"></option>
                            <option value="justify"></option>
                          </select>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-list" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-link" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-formula" value="ordered" type="button"></button>
                          <button class="ql-code-block" value="ordered" type="button"></button>
                        </span>
                        <span class="ql-formats">
                          <button class="ql-clean" value="ordered" type="button"></button>
                        </span>
                      </div>
                    </quill-editor>
                  </div>
                </div>
                <div class="modal-footer">
                  <div>
                    <div class="col-12"
                      *ngIf="!UpdateEffortForConsultantForm.valid;else emptyUpdateEffortForConsultantButton">
                      <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                        disabled>Kaydet</button>
                    </div>
                  </div>
                  <ng-template #emptyUpdateEffortForConsultantButton>
                    <div>
                      <div class="col-12">
                        <button type="button" rippleEffect class="btn btn-success mr-1" data-bs-dismiss="modal"
                          (click)="updateEffort()"><i data-feather="check" class="mr-50"></i> Kaydet</button>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deleteeffort" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Onay Bilgisini Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="deleteEffort(deletedEffortId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deletemessage" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Mesajı Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Mesajı Herkesten Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="delete(deletedMessageId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-12">
      <section id="file-upload">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 p-3">
                <h2 class="card-title">Gönderi Oluştur</h2>
                <div style="height: auto;">
                  <div class="mb-2">
                    <button type="button" class="btn btn-primary btn-sm" #userbutton data-bs-toggle="modal"
                      data-bs-target="#usersmodal" style="display: none;">
                    </button>

                    <div class="modal fade" id="usersmodal" tabindex="-1" aria-labelledby="exampleModalLabel"
                      aria-hidden="true">
                      <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Kullanıcı Seçiniz</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                          </div>
                          <div class="modal-body">
                            <select class="form-control" name="slcMessageUser" [(ngModel)]="mentionUser.id"
                              #slcMessageUser="ngModel" autofocus>
                              <option value="0" selected>Bir Kullanıcı Seçiniz</option>
                              <option *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'"
                                value="{{myId}}">{{userName}}
                              </option>
                              <option *ngFor="let item of users" value="{{item.id}}">
                                {{item.firstName}} {{item.lastName}}</option>
                            </select>
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                              (click)="closeModal()">Kapat</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                              (click)="mentionUserChange()">Kaydet</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <quill-editor (onEditorCreated)="editorCreated($event)" name="quillMessage" #quillMessage="ngModel"
                    [(ngModel)]="message" placeholder="Bir mesaj giriniz.." [modules]="editorModules">
                    <div quill-editor-toolbar>
                      <span class="ql-formats">
                        <button class="ql-bold" [title]="'Bold'"></button>
                        <button class="ql-italic" [title]="'italic'"></button>
                        <button class="ql-underline" [title]="'underline'"></button>
                      </span>
                      <span class="ql-formats">
                        <select class="ql-align" [title]="'Alignment'">
                          <option selected></option>
                          <option value="center"></option>
                          <option value="right"></option>
                          <option value="justify"></option>
                        </select>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-list" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-link" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-formula" value="ordered" type="button"></button>
                        <button class="ql-code-block" value="ordered" type="button"></button>
                      </span>
                      <span class="ql-formats">
                        <button class="ql-clean" value="ordered" type="button"></button>
                      </span>
                    </div>

                  </quill-editor>
                  <span class="hints" *ngIf="quillMessage.maxLength==2">
                    Min 10 characters and note more than 400</span>
                </div>
                <div style="margin: 50px 0px 20px 0px;">
                  <fieldset class="form-group">
                    <label for="file-upload-multiple">Dosyalar</label>
                    <div class="custom-file">
                      <input class="custom-file-input" type="file" (change)="getDocuments($event)" #documents
                        id="file-upload-multiple" ng2FileSelect [uploader]="uploader" multiple />
                      <label class="custom-file-label" for="file-upload-multiple">Dosya Seçiniz</label>
                    </div>
                  </fieldset>
                </div>
                <div class="col-lg-12 overflow-auto">
                  <div class="d-flex justify-content-between mb-1">
                    <p>Seçilen Dosya Sayısı: {{ uploader?.queue?.length }}</p>
                  </div>

                  <table class="table">
                    <thead>
                      <tr>
                        <th width="50%">Adı</th>
                        <th>Boyutu</th>
                        <th>Aşaması</th>
                        <th>Durumu</th>
                        <th>İşlemler</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of uploader.queue">
                        <td>
                          <strong>{{ item?.file?.name }}</strong>
                        </td>
                        <td *ngIf="uploader.options.isHTML5" nowrap>
                          {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                        </td>
                        <td *ngIf="uploader.options.isHTML5">
                          <ngb-progressbar type="primary" [value]="item.progress"></ngb-progressbar>
                        </td>
                        <td class="text-center">
                          <span><i data-feather="check"></i></span>
                        </td>
                        <td nowrap>
                          <!-- <button type="button" class="btn btn-raised btn-outline-success btn-sm mr-1"
                            (click)="item.upload()" [disabled]="item.isReady || item.isUploading || item.isSuccess"
                            rippleEffect>
                            <span data-feather="upload"></span>
                          </button> -->
                          <button type="button" class="btn btn-raised btn-outline-primary btn-sm mr-1"
                            data-bs-toggle="modal" data-bs-target="#showpriviewimage" (click)="previewImage(item)"
                            rippleEffect>
                            <i data-feather="eye"></i>
                          </button>

                          <button type="button" class="btn btn-raised btn-outline-danger btn-sm "
                            (click)="item.remove()" rippleEffect>
                            <i data-feather="trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                  <div id="">
                    <p class="mt-1">Dosyanın Aşaması:</p>
                    <ngb-progressbar class="mb-1" type="primary" [value]="uploader.progress"></ngb-progressbar>
                    <!-- <button type="button" class="btn mr-1 btn-primary mb-1 mb-sm-0" (click)="uploader.uploadAll()"
                      [disabled]="!uploader.getNotUploadedItems().length" rippleEffect>
                      <span data-feather="upload" class="mr-25"></span> Hepsini Yükle
                    </button> -->
                    <button type="button" class="btn btn-outline-danger mb-1 mb-sm-0" (click)="uploader.clearQueue()"
                      [disabled]="!uploader.queue.length" rippleEffect>
                      <span data-feather="trash" class="mr-25"></span>Hepsini Sil
                    </button>
                  </div>
                </div>

                <div class="modal fade" id="showpriviewimage" tabindex="-1" aria-labelledby="staticBackdropLabel"
                  aria-hidden="true">
                  <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel"> Önizleme</h1>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                            data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                      </div>
                      <div class="modal-body">
                        <div class="modal-body">
                          <img *ngIf="selectedFileUrl" [src]="selectedFileUrl" class="img-fluid" alt="Dosya Önizleme">
                          Bu sayfa henüz geliştirme aşamasındadır.
                        </div>
                      </div>
                      <div class="modal-footer">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="userRole=='Customer'||userRole=='CustomerUser'">
                  <div class="row">
                    <div class="col-9"></div>
                    <div style="display: flex;justify-content: end;" class="col-3">

                      <select class="form-control btn btn-success " name="slcStatusForPartner" [(ngModel)]="status.id"
                        #slcStatusForPartner="ngModel" (change)="add(quillMessage.value)">
                        <option value="0" selected> Talebin Türünü Seçiniz </option>
                        <option value="0"><span class="bullet bullet-sm bullet-primary">Mesaj Gönder</span></option>
                        <option value="2"><span class="bullet bullet-sm bullet-warning">Danıışmandan Cevap
                            Bekle</span>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- <div style="display: flex;justify-content: end; margin-top:10px;">
                  <button type="button" class="btn btn-success" (click)="add(quillMessage.value)" rippleEffect> <i
                      data-feather="send" class="mr-50"></i>
                    Gönder
                  </button>
                </div> -->
              </div>
              <div *ngIf="userRole=='Admin'||userRole=='ConsultantUser' || userRole=='Consultant'" class="col-md-12">
                <div class="row">
                  <div class="col-9"></div>
                  <div style="display: flex;justify-content: end;" class="col-3">

                    <select class="form-control btn btn-success" name="sclStatus" [(ngModel)]="status.id"
                      #sclStatus="ngModel" (change)="add(quillMessage.value)">
                      <option value="0" selected>Talebin Durumunu Seçiniz </option>
                      <option value="0"><span class="bullet bullet-sm bullet-primary">Mesaj Gönder</span></option>
                      <option value="8"><span class="bullet bullet-sm bullet-success">Müşteriden Cevap
                          Bekleniyor</span>
                      </option>
                    </select>
                    <!--
                    <ng-select [items]="answerTypes" [(ngModel)]="selectedAnswerTypes" placeholder="Gönderi Türünü Seçiniz" bindLabel="name" bindValue="name">
                        <ng-template ng-option-tmp ng-label-tmp let-item="item">
                          <i
                          class="feather avatar-icon font-medium-1 icon-check-square"
                          ng-reflect-ng-class="[object Object]"></i>
                          {{ item.name }}
                        </ng-template>
                      </ng-select>

                    
                    
                      <div class="ng-select-container" disabled>
                        <div class="ng-value-container">
                          <span class="ng-placeholder">Gönderi Türünü Seçiniz</span>
                        </div>
                        <ng-select [items]="answerTypes" name="selectlabel" [(ngModel)]="answerType.id"
                          bindLabel="label" bindValue="label">
                          <ng-template (change)="add(quillMessage.value)" ng-option-tmp ng-label-tmp let-item="item">
                            <span *ngIf="item.id==1">
                              <span class="bullet bullet-sm mr-50 bullet-success"></span>
                              Çözüm Önerildi
                            </span>
                            <span *ngIf="item.id==3">
                              <span class="bullet bullet-sm mr-50 bullet-primary"></span>
                              Mesaj Gönder
                            </span>
                            <span *ngIf="item.id==2">
                              <span class="bullet bullet-sm mr-50 bullet-warning"></span>
                              Cevap Bekleniyor
                            </span>
                          </ng-template>
                        </ng-select>
                      </div>  -->

                    <!-- ************************************************************************************************** -->

                    <!-- ************************************************************************************************** -->

                    <!-- <ng-select [items]="answerTypes" style="width: 400px;" name="selectlabel" [(ngModel)]="answerType.id" >
                      Gönderi Türünü Seçiniz

                      <ng-template ng-option-tmp ng-label-tmp let-item="item">
                        <span *ngIf="item.id==1" selected>
                          <span class="bullet bullet-sm mr-50 bullet-success"></span>
                          Çözüm Önerildi
                        </span>
                        <span *ngIf="item.id==3">
                          <span class="bullet bullet-sm mr-50 bullet-primary"></span>
                          Mesaj Gönder
                        </span>
                        <span *ngIf="item.id==2">
                          <span class="bullet bullet-sm mr-50 bullet-warning"></span>
                          Cevap Bekleniyor
                        </span>
                      </ng-template>
                    </ng-select> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="col-md-1"></div>
</div>


<div class="modal fade" id="addConnectionTicket" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Bağlantı Ekle</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>

      <div class="modal-body">
        <form class="" #AddConnectionTicket="ngForm">
          <div class="modal-body">
            <button type="button" class="btn btn-primary btn-sm mr-1 mb-2" (click)="addStorageTicketId()" rippleEffect
              title="Yeni Talep" data-bs-dismiss="modal"><i data-feather="plus"></i></button>
            <div class="form-group">
              <div class="row mb-2">
                <label for="name-vertical">Talep Numarası Giriniz</label>
                <input type="number" (keyup)="getSeacrhTicket()" class="form-control" name="inputSearchId"
                  #inputSearchId="ngModel" [(ngModel)]="searchId">
                
                <ngx-datatable *ngIf="searchedTicket != null" style="width:100%; margin-top:5px"
                  [rows]="[searchedTicket]" [rowHeight]="58" class="bootstrap core-bootstrap" [limit]="10"
                  [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="10">
                  <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <button (click)="addConnectionTickets()" class="btn btn-primary btn-sm mr-1" type="button"><i
                            data-feather="plus"></i></button>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="150">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <div class="cell-line-height">
                          <a class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2 mb-25"
                            routerLink="/home/details/{{row.id}}" target="">#{{ name }}</a>

                          <!-- altına tatlı ve açık gri bir açıklama eklemek için kullanılabilr -->
                        </div>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Öncelik" prop="priorityId" [width]="150">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="d-flex align-items-center">
                        <div class="badge badge-pill badge-light-danger mr-1" *ngIf="row.priorityId==1">Yüksek</div>
                        <div class="badge badge-pill badge-light-primary mr-1" *ngIf="row.priorityId==2">Normal
                        </div>
                        <div class="badge badge-pill badge-light-secondary mr-1" *ngIf="row.priorityId==3">Düşük
                        </div>
                      </div>
                      <span class="text-muted font-small-2"> {{ row.typeName }}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Konu" prop="subject" [width]="250">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{(row.subject.length > 30 && row.subject != null) ? row.subject.slice(0, 30) + '...' : row.subject}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Bölüm" prop="departmentName" [width]="180">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{(row.departmentName.length > 30 && row.departmentName != null) ? row.departmentName.slice(0, 30) + '...' : row.departmentName}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="240">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      <div class="text-muted font-small-2" *ngIf="row.partnerId==0; else partnerTemplate">
                        Atanmış Şirket bulunmamakta
                      </div>
                      <ng-template #partnerTemplate>
                        {{(row.partnerName.length > 30 && row.partnerName != null) ? row.partnerName.slice(0, 30) + '...' : row.partnerName}}
                        <br>
                        <span *ngIf="row.partnerTicketNo!=null; else emptyPartnerTicketNoTemplate "
                          class="text-muted font-small-2"> {{ row.partnerTicketNo }}</span>
                        <ng-template #emptyPartnerTicketNoTemplate></ng-template>
                      </ng-template>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Oluşturma Tarihi" prop="createdDate" [width]="180">
                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                      {{row.createdDate|date:'dd-MM-yyyy HH:mm'}}
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="modal-footer">

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<button id="hiddenButton" style="display: none;" data-bs-toggle="modal" data-bs-target="#updateticketativity"></button>