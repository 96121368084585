import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Department } from 'app/evo/models/department';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-department-add-form',
  templateUrl: './evo-department-add-form.component.html',
  styles: [
  ]
})
export class EvoDepartmentAddFormComponent implements OnInit {

  constructor(
    private departmentService: EvoDepartmentService,
    private router: Router,
    private decodeService:DecodeService,
    private informationService: InformationService,
    private errorService: ErrorService
  ) { }

  department: Department = new Department();
  ngOnInit(): void {
    let item=this.decodeService.getRole();
    if(item!="Admin"){
      this.router.navigate(['/home']);
    }
  }
  add(name: string) {

    this.department = {
      id: 0,
      name: name
    };

    this.departmentService.add(this.department).subscribe((item: any) => {
      this.informationService.add(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/departments"]);
    }, 1500);
    });
  }
}
