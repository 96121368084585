import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { UserOpertaionClaim } from '../models/useroperationclaim';

@Injectable({
  providedIn: 'root'
})
export class EvoUseroperationclaimService {

  private baseApiUrl=environment.apiUrl;
  
  constructor(private httpClient:HttpClient) { }

  getList():Observable<UserOpertaionClaim[]>{
    return this.httpClient.get<UserOpertaionClaim[]>(this.baseApiUrl+'GetUserOperationClaimList');
  }
  getListByUserId(userId:number):Observable<UserOpertaionClaim[]>{
    return this.httpClient.get<UserOpertaionClaim[]>(this.baseApiUrl+'GetUserOperationClaimListByUserId/'+userId);
  }
  get (id: number):Observable <UserOpertaionClaim> {
    return this.httpClient.get<UserOpertaionClaim>(this.baseApiUrl+'GetUserOperationClaim/'+id);
  }
  add(userOperationClaim:UserOpertaionClaim):Observable<UserOpertaionClaim>{
    return this.httpClient.post<UserOpertaionClaim>(this.baseApiUrl+'AddUserOperationClaim/',userOperationClaim);
  }
  update(userOperationClaim:UserOpertaionClaim):Observable<UserOpertaionClaim>{
    return this.httpClient.post<UserOpertaionClaim>(this.baseApiUrl+"UpdateUserOperationClaim/",userOperationClaim);
  }
  delete(userOperationClaim:UserOpertaionClaim):Observable<UserOpertaionClaim>{
    return this.httpClient.post<UserOpertaionClaim>(this.baseApiUrl+"DeleteUserOperationClaim/",userOperationClaim);
  }
  
}
