import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { GuideSetting } from '../models/guide';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EvoGuideService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient) { }
  
  getList(): Observable<GuideSetting[]> {
    return this.httpClient.get<GuideSetting[]>(this.baseApiUrl + 'GetGuideList');
  }
  getListDto():Observable<GuideSetting[]>{
    return this.httpClient.get<GuideSetting[]>(this.baseApiUrl + 'GetGuideListDto')
  }

  getById(id: number): Observable<GuideSetting> {
    return this.httpClient.get<GuideSetting>(this.baseApiUrl + "GetGuide/" + id);
  }

  add(guide: any): Observable<any> {
    return this.httpClient.post<any>(this.baseApiUrl + "AddGuide", guide)
  }
  update(guide: GuideSetting): Observable<GuideSetting> {
    return this.httpClient.post<GuideSetting>(this.baseApiUrl + "UpdateGuide/", guide);
  }
  delete(id: number): Observable<GuideSetting> {
    return this.httpClient.post<GuideSetting>(this.baseApiUrl + "DeleteGuide/" + id, "");
  }
  guideDocumentUpdate(guide:any):Observable<any>{
    return this.httpClient.post<any>(this.baseApiUrl+"GuideDocumentUpdate/",guide);
  }
}
