export class UserOpertaionClaim {
    id: number;
    userId: number;
    operationClaimId: number;
    userName:string;
    operationClaimTitle:string;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}