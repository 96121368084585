import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;
  menu_customer: any;
  userRole: string;
  userId: number;
  _unsubscribeAll: Subject<any>;

  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];

  partnerListingPermission: boolean;
  expenseListingPermission: boolean;
  goalListingPermission: boolean;
  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor(private _changeDetectorRef: ChangeDetectorRef,
    private _coreMenuService: CoreMenuService,
    private decodeService: DecodeService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    
    this.userRole = this.decodeService.getRole();
    this.userId = this.decodeService.getUserId();
    this.getPermissions();

  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();
    this.menu_customer = this.menu_customer || this._coreMenuService.getCurrentMenu();
    if (this.userRole == "Consultant") {
      this.menu[2].hidden = true;
      this.menu[3].hidden = true;
      this.menu[4].hidden = true;
      this.menu[5].hidden = true;
      this.menu.splice(11,1)
      this.menu.splice(10,1)
      this.menu.splice(9,1)
    }
    
    else if (this.userRole == "ConsultantUser") {
      this.menu[0].hidden = true;
      this.menu[2].hidden = true;
      this.menu[3].hidden = true;
      this.menu[4].hidden = true;

      this.menu[5].hidden = true;
      this.menu.splice(11,1)
      this.menu.splice(10,1)
      this.menu.splice(9,1)

    }

    else if (this.userRole == "Customer") {
      this.menu[0].hidden = true;
      this.menu[1].hidden = true;
      this.menu[4].url = "/home/partnerusers/" + this.userId;
      this.menu[5].hidden = true;
      this.menu[6].hidden = true;
      this.menu[7].hidden = true;
      this.menu[8].hidden = true;
      this.menu.splice(11,1)
      this.menu.splice(10,1)
      this.menu.splice(9,1)
    }

    else if (this.userRole == "CustomerUser") {
      this.menu[0].hidden = true;
      this.menu[1].hidden = true;
      this.menu[4].hidden = true;
      this.menu[5].hidden = true;
      this.menu[6].hidden = true;
      this.menu[7].hidden = true;
      this.menu[8].hidden = true;
      this.menu.splice(11,1)
      this.menu.splice(10,1)
      this.menu.splice(9,1)
    }

    else if (this.userRole == "Admin") {
      this.menu[3].hidden = true;
      this.menu[5].hidden = true;


     }
    // this.menu[6].disabled =this.partnerListingPermission;
    // this.menu[11].disabled =this.expenseListingPermission;
    // this.menu[12].disabled =this.goalListingPermission;
    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
  getPermissions() {
    this.permissionService.getList().subscribe((items: any) => {
      this.permissions = items.data;
      this.checkPermissionUsers();
    });
  }

  checkPermissionUsers() {
    this.permissionUserService.getPermissionUserListByUserId(this.userId).subscribe((items: any) => {
      this.permissionUsers = items.data;

      this.partnerListingPermission = this.permissionUsers.some(user => user.permissionId === 10);
      this.expenseListingPermission = this.permissionUsers.some(user => user.permissionId === 11);
      this.goalListingPermission = this.permissionUsers.some(user => user.permissionId === 12);
      if (this.partnerListingPermission) {
        this.menu[10].children[0].children[0].disabled = true;
      } else {
        this.menu[10].children[0].children[0].disabled = false;
      }
  
      if (this.expenseListingPermission) {
        this.menu[6].disabled = true;
      } else {
        this.menu[6].disabled = false;
      }
  
      if (this.goalListingPermission) {
        this.menu[7].disabled = true;
      } else {
        this.menu[7].disabled = false;
      }
    });
  }
  
}
