export class Contract{
    id:number;
    contractNumber:string;
    projectId:number;
    projectName:string;
    partnerName:string;
    startDate:Date;
    endDate:Date;

    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}