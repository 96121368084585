<style>
    .filter-box{
        display: flex;
    }
    @media (max-width: 767px) {
      .filter-box {
        flex-direction: column;
      }
    }
</style>

<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <div class="d-flex justify-content-between filter-box">
            <div class="d-flex filter-box">
                <div class="filter mt-1">
                    <div class="card-body d-flex justify-content-center">
                        <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="syncWithGoogle()"
                            rippleEffect>
                            <span class="align-middle">Google Calendar ile Eşleştir</span>
                        </button>
                    </div>
                </div>
                <div class="filter mt-1">
                    <div class="card-body d-flex justify-content-center">
                        <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="AddEvent()" rippleEffect>
                            <span class="align-middle">Yeni Plan</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="d-flex filter-box">
                <div class="filter mt-1">
                    <div class="card-body d-flex justify-content-center">
                        <ng-select [multiple]="true" [items]="typeForFilter" [(ngModel)]="selectedTypes"
                            placeholder="Plan Türü Seçiniz " bindLabel="name" bindValue="id"
                            (change)="tableByFiltersId()">
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span class="ng-value-label">{{ item.name }}</span>
                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </ng-template>
                            <ng-template ng-header-tmp>
                            </ng-template>
                        </ng-select>
                    </div>

                </div>
                <div class="filter mt-1">
                    <div *ngIf="userRole!='ConsultantUser' ">
                        <div class="card-body d-flex justify-content-center">
                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                placeholder="Personel Seçiniz " bindLabel="firstName" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                        </div>

                    </div>
                </div>
            </div>
        </div>




        
        <div class="app-calendar overflow-hidden border">
            <div class="row no-gutters">
                <!-- <core-sidebar class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
                    name="calendar-main-sidebar" overlayClass="body-content-overlay" collapsibleSidebar="bs-gt-lg">
                    <div class="sidebar-wrapper">
                        <div class="card-body d-flex justify-content-center">
                            <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="AddEvent()"
                                rippleEffect>
                                <span class="align-middle">Yeni Plan</span>
                            </button>
                        </div>
                        <div class="card-body d-flex justify-content-center">
                            <button class="btn btn-primary btn-toggle-sidebar btn-block" (click)="syncWithGoogle()"
                                rippleEffect>
                                <span class="align-middle">Google Calendar ile Eşleştir</span>
                            </button>
                        </div>

                        <div class="card-body pb-0">
                            <h5 class="section-label mb-1">
                                <span class="align-middle">Filtre</span>
                            </h5>
                            <ng-select [multiple]="true" [items]="typeForFilter" [(ngModel)]="selectedTypes"
                                placeholder="Plan Türü Seçiniz " bindLabel="name" bindValue="id"
                                (change)="tableByFiltersId()">
                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                    <span class="ng-value-label">{{ item.name }}</span>
                                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                </ng-template>
                                <ng-template ng-header-tmp>
                                </ng-template>
                            </ng-select>
                            <br>
                            <div *ngIf="userRole!='ConsultantUser' ">
                                <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                    placeholder="Personel Seçiniz " bindLabel="firstName" bindValue="id"
                                    (change)="tableByFiltersId()">
                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                        <span class="ng-value-label">{{ item.firstName }} {{item.lastName}}</span>
                                        <span class="ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </ng-template>
                                    <ng-template ng-header-tmp>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </core-sidebar> -->
                <div class="col position-relative">
                    <div class="card shadow-none border-0 mb-0 rounded-0">
                        <div class="card-body pb-0">
                            <full-calendar [options]="calendarOptions"></full-calendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <core-sidebar class="modal modal-slide-in fade event-sidebar" name="calendar-event-sidebar"
            overlayClass="modal-backdrop" data-bs-toggle="modal" data-bs-target="#plandetail" hideOnEsc="true" style="z-index: 9999;">
            <div class="modal-dialog sidebar-lg" id="plandetail" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog sidebar-lg">
                    <div class="modal-content">
                        <div class="modal-header mb-1">
                            <h5 class="modal-title"> Plan {{ plan.id == null ? 'Oluştur' : 'Güncelle' }}</h5>
                        </div>
                        <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
                            <form class="event-form needs-validation"
                                (ngSubmit)="(PlanForm.form.valid); PlanForm.reset()" #PlanForm="ngForm">
                                <div class="form-group">
                                    <label for="name-vertical">Başlığını Giriniz</label>
                                    <input type="text" [maxlength]="50" required id="name-vertical" class="form-control" name="txtTitle"
                                        placeholder="Plan Başlığı" [(ngModel)]="plan.title" #txtTitle="ngModel" />
                                </div>

                                <div class="row">

                                    <div class="form-group position-relative col-8">
                                        <div class="form-group">
                                            <label for="name-vertical">Başlangıç Tarihini Giriniz</label>
                                            <input type="datetime-local" required id="name-vertical"
                                                class="form-control" name="txtStartDate" placeholder="Başlangıç Tarihi"
                                                [ngModel]="fromDate | date:'yyyy-MM-ddTHH:mm'"
                                                (ngModelChange)="fromDate = $event" #txtStartDate />
                                        </div>
                                    </div>

                                    <div class="col-4" style="text-align: center;">
                                        <div class="custom-control custom-control-success custom-switch">
                                            <p class="mb-50" click>Cumartesi Dahil</p>
                                            <input type="checkbox" checked (click)="changeSaturdayPlan()"
                                                class="custom-control-input" id="customSwitchSaturday" />
                                            <label class="custom-control-label" for="customSwitchSaturday"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group position-relative col-8">
                                        <label for="name-vertical">Bitiş Tarihini Giriniz</label>
                                        <input type="datetime-local" required id="name-vertical" class="form-control"
                                            name="txtEndDate" placeholder="Bitiş Tarihi"
                                            [ngModel]="toDate | date:'yyyy-MM-ddTHH:mm'"
                                            (ngModelChange)="toDate = $event" #txtEndDate />
                                    </div>
                                    <div class="col-4" style="text-align: center;">
                                        <div class="custom-control custom-control-success custom-switch">
                                            <p class="mb-50">Pazar Dahil</p>
                                            <input type="checkbox" checked (click)="changeSundayPlan()"
                                                class="custom-control-input" id="customSwitchSunday" />
                                            <label class="custom-control-label" for="customSwitchSunday"></label>
                                        </div>
                                    </div>
                                </div>
                                <br>
                                <div *ngIf="plan.typeId!=4" class="form-group position-relative">
                                    <label for="name-vertical">Plan Türünü Seçiniz</label>
                                    <select class="form-control" ngModel required name="slcType"
                                        [(ngModel)]="plan.typeId" #slcType="ngModel">
                                        <option *ngFor="let item of types" value="{{item.id}}">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="plan.typeId==1 || plan.typeId==2">
                                    <div class="form-group select2-primary">
                                        <label for="slcPartner" class="form-label">Partner Ekle</label>
                                        <select class="form-control" ngModel required name="slcPartner"
                                            [(ngModel)]="plan.partnerId" #slcPartner="ngModel">
                                            <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <br>
                                <div *ngIf="plan.typeId==1 || plan.typeId==2"
                                    class="custom-control custom-control-success custom-switch">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <div class="col-8">
                                            Uzaktan
                                            <input type="checkbox" (click)="changeLocationType()"
                                                [checked]="planIsInPlace  " class="custom-control-input"
                                                id="customSwitchLocationType" />
                                            <label class="custom-control-label"
                                                for="customSwitchLocationType"></label>Yerinde
                                        </div>
                                        <div class="col-1"></div>
                                    </div>
                                </div>

                                <div *ngIf="addMoreDetails else MoreDetailsTemplate">
                                    <div class="row">
                                        <div class="col-3"></div>
                                        <button type="submit" ngbTooltip="Şehir İçi veya Şehir Dışı Plan Bilgisi"
                                            class="btn btn-outline-success col-6 add-event-btn m-1 "
                                            (click)="addMoreDetails=true">
                                            <span class="align-middle">Daha Fazla</span>
                                        </button>
                                        <div class="col-3"></div>
                                    </div>
                                </div>
                                <ng-template #MoreDetailsTemplate>
                                    <span *ngIf="plan.typeId!=4 && plan.typeId!=3">
                                        <hr>
                                        <p style="text-align:center;"><i> Seyahat Planı</i></p>
                                        <div class="custom-control custom-control-primary custom-switch">
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-8">
                                                    Şehir İçi
                                                    <input type="checkbox" (click)="changeTripType()"
                                                        [checked]="planIsOutOfTown  " class="custom-control-input"
                                                        id="customSwitchTripType" />
                                                    <label class="custom-control-label"
                                                        for="customSwitchTripType"></label>Şehir Dışı
                                                </div>
                                                <div class="col-1"></div>
                                            </div>
                                        </div>
                                        <br>
                                        <div *ngIf="!planIsOutOfTown; else tripDetailTemplate"></div>
                                        <ng-template #tripDetailTemplate>
                                            <div class="row">
                                                <div class="col-3"></div>
                                                <div class="col-9">
                                                    <div class="demo-inline-spacing">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" [checked]="planHaveBusDetail"
                                                                [(ngModel)]="planHaveBusDetail"
                                                                class="custom-control-input" id="customCheck1"
                                                                name="Bus">
                                                            <label class="custom-control-label"
                                                                for="customCheck1">Otobüs</label>
                                                        </div>
                                                        <br>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" [checked]="planHaveFlightDetail"
                                                                [(ngModel)]="planHaveFlightDetail"
                                                                class="custom-control-input" id="customCheck2"
                                                                name="planHaveFlightDetail">
                                                            <label class="custom-control-label"
                                                                for="customCheck2">Uçak</label>
                                                        </div>
                                                        <br>
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" [checked]="planHaveHotelDetail"
                                                                [(ngModel)]="planHaveHotelDetail"
                                                                class="custom-control-input" id="customCheck3"
                                                                name="planHaveHotelDetail">
                                                            <label class="custom-control-label" for="customCheck3">Otel
                                                                Rezervasyonu</label>
                                                        </div>
                                                    </div>
                                                    <br>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </span>

                                </ng-template>

                                <!-- <ng-template #emptyDivision></ng-template>
                                <div *ngIf="plan.typeId!=4 && userRole!='ConsultantUser' && plan.id != null"
                                    class="form-group select2-primary">
                                    <label for="selectUser" class="form-label">Kullanıcı Ekle</label>
                                    <select class="form-control" ngModel name="slcUser" [(ngModel)]="plan.userId"
                                        #slcUser="ngModel">
                                        <option *ngFor="let item of users" value="{{item.id}}">
                                            {{item.firstName}}
                                            {{item.lastName}}</option>
                                    </select>
                                </div> -->
                                <!-- <div *ngIf="plan.typeId!=4 && userRole!='ConsultantUser'" class="form-group select2-primary">
                                    <label for="selectUser" class="form-label">Kullanıcı Ekle</label>
                                    <select class="form-control" ngModel name="slcUser" [(ngModel)]="plan.userId"
                                        #slcUser="ngModel">
                                        <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}}
                                            {{item.lastName}}</option>
                                    </select>
                                </div> -->
                                <!-- <div class="form-group">
                                    <div class="col-12"
                                        *ngIf="(plan.typeId!=4 && userRole!='ConsultantUser') && plan.id == null">
                                        <ng-select [multiple]="true" [items]="usersForPlan"
                                            [(ngModel)]="selectedUsersForPlan" placeholder="Kullanıcı Ekle "
                                            bindLabel="firstName" bindValue="id" (change)="selectMultiUser()">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label">{{ item.firstName }}
                                                    {{item.lastName}}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                            <ng-template ng-header-tmp>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div> -->
                                <div *ngIf="plan.typeId!=4 && userRole!='ConsultantUser'"
                                    class="form-group select2-primary">
                                    <label> Kullanıcı Ekle </label>
                                    <ng-multiselect-dropdown [placeholder]="'Kullanıcı Seç'" [data]="usersForPlan"
                                        [(ngModel)]="selectedUserIds" [settings]="dropdownSettings"
                                        [ngModelOptions]="{standalone: true}">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <label for="name-vertical">Açıklamasını Giriniz</label>
                                        <textarea type="text" id="name-vertical" class="form-control"
                                            name="txtDescription" [(ngModel)]="plan.description"
                                            placeholder="Plan Açıklaması" ngModel #txtDescription="ngModel"></textarea>
                                    </div>
                                </div>
                                <div *ngIf="plan.typeId==4; else changablePlans">
                                    <button type="submit" class="btn btn-success add-event-btn mr-1" disabled
                                        rippleEffect>
                                        Güncelle
                                    </button>
                                </div>
                                <ng-template #changablePlans>
                                    <div class="row" *ngIf="plan.id!=null ; else addTemmplate">
                                        <div class="col-4">
                                            <button type="submit" class="btn btn-success add-event-btn mr-"
                                                (click)="update()" rippleEffect>
                                                Güncelle
                                            </button>
                                        </div>
                                        <div class="col-5"> </div>
                                        <div class="col-2" *ngIf="canDelete">
                                            <button type="submit" class="btn btn-danger add-event-btn mr-1"
                                                (click)="delete(plan.id)" rippleEffect>
                                                Sil
                                            </button>
                                        </div>
                                    </div>

                                    <ng-template #addTemmplate>
                                        <button type="submit" class="btn btn-success add-event-btn mr-1"
                                            *ngIf="isDataEmpty !== false; else addEventRef" (click)="add()"
                                            rippleEffect>
                                            Ekle
                                        </button>
                                    </ng-template>
                                </ng-template>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </core-sidebar>

        <div class="modal fade" id="addusertoplan" tabindex="-1" aria-labelledby="staticBackdropLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcı Ekle</h1>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                            <!-- <div class="form-group">
                                <div class="col-12"
                                    *ngIf="(plan.typeId!=4 && userRole!='ConsultantUser') && plan.id == null">
                                    <ng-select [multiple]="true" [items]="usersForPlan"
                                        [(ngModel)]="selectedUsersForPlan" placeholder="Kullanıcı Ekle "
                                        bindLabel="firstName" bindValue="id" (change)="selectMultiUser()">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label">{{ item.firstName }}
                                                {{item.lastName}}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                        </ng-template>
                                    </ng-select>
                                </div> 
                            </div>-->

                        </div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                                (click)="addUserToPlan()"><i data-feather="check" class="mr-50"></i>Kaydet</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="form-group mb-2">
    <label for="">Departman</label>
    <ngx-select #txtSector class="form-control p-0" [formControl]="selectControlDepartment" [allowClear]="true"
        [items]="users" optionValueField="optionId" optionTextField="optionTitle" (ngModelChange)="changeSectorId()"
        placeholder="Sektör Seçin" required>
    </ngx-select>
    <small class="text-danger" *ngIf="plan.userId == undefined">Zorunlu
        Alan</small>
</div>
<div class="form-group mb-2">
    <label for="">Departman</label>
    <ngx-select #txtSector class="form-control p-0" [formControl]="selectControlDepartment" [allowClear]="true"
        [items]="mappedUsers" optionValueField="optionId" optionTextField="optionTitle"
        (ngModelChange)="changeSectorId()" placeholder="Sektör Seçin" required>
    </ngx-select>
</div> -->