import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Effort } from '../models/effort';

@Injectable({
  providedIn: 'root'
})
export class EvoEffortService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'GetEffortList/' );
  }
  getListByTicketId(ticketId:number):Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'GetEffortListByTicketId/'+ ticketId );
  }
  
  getUsersEfforts(userId:number):Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'GetUsersEfforts/'+ userId );
  }
  getUsersEffortsWithTicketId(userId:number,ticketId:number):Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'GetUsersEffortsWithTicketId/'+ userId +'/'+ ticketId);
  }
  restrictedEfforts(userId:number):Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'RestrictedEfforts/'+ userId );
  }
  restrictedEffortsWithTicketId(userId:number,ticketId:number):Observable<Effort[]>{
    return this.httpClient.get<Effort[]>(this.baseApiUrl+'RestrictedEffortsWithTicketId/'+ userId +'/'+ ticketId);
  }

  get (id: number):Observable <Effort> {
    return this.httpClient.get<Effort>(this.baseApiUrl+'GetEffort/'+id);
  }
  add(effort:Effort):Observable<Effort>{
    return this.httpClient.post<Effort>(this.baseApiUrl+'AddEffort/',effort);
  }
  update(effort:Effort):Observable<Effort>{
    return this.httpClient.post<Effort>(this.baseApiUrl+"UpdateEffort/",effort);
  }
  updateStatus(effort:Effort):Observable<Effort>{
    return this.httpClient.post<Effort>(this.baseApiUrl+"UpdateEffortStatus/",effort);
  }
  delete(effort:Effort):Observable<Effort>{
    return this.httpClient.post<Effort>(this.baseApiUrl+"DeleteEffort/",effort);
  }
}
