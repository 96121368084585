import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Partner } from '../models/partner';

@Injectable({
  providedIn: 'root'
})
export class EvoPartnerService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Partner[]>{
    return this.httpClient.get<Partner[]>(this.baseApiUrl+'GetPartnerList');
  }
  getSupplierList():Observable<Partner[]>{
    return this.httpClient.get<Partner[]>(this.baseApiUrl+'GetSupplierList');
  }
  get (id: number):Observable <Partner> {
    return this.httpClient.get<Partner>(this.baseApiUrl+'GetPartner/'+id);
  }
  add(partner:Partner):Observable<Partner>{
    return this.httpClient.post<Partner>(this.baseApiUrl+'AddPartner/',partner);
  }
  update(partner:Partner):Observable<Partner>{
    return this.httpClient.post<Partner>(this.baseApiUrl+"UpdatePartner/",partner);
  }
  delete(partner:Partner):Observable<Partner>{
    return this.httpClient.post<Partner>(this.baseApiUrl+"DeletePartner/",partner);
  }
}
