import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { PermissionUser } from '../models/permissionuser';

@Injectable({
  providedIn: 'root'
})
export class EvoPermissionUserService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.baseApiUrl+'GetPermissionUserList/' );
  }

  getPermissionUserListByPermissionId(permissionId:number, userId:number){
    return this.httpClient.get(this.baseApiUrl+'GetPermissionUserListByPermissionId/'+permissionId+'/'+userId );
  }
  
  getPermissionUserListByUserId(userId:number){
    return this.httpClient.get(this.baseApiUrl+'GetPermissionUserListByUserId/'+userId );
  }

  get(id: number) {
    return this.httpClient.get(this.baseApiUrl + 'GetPermissionUser/' + id);
  }

  add(permissionUser: any) {
    return this.httpClient.post(this.baseApiUrl + 'AddPermissionUser/', permissionUser);
  }

  update(permissionUser: any) {
    return this.httpClient.post(this.baseApiUrl + "UpdatePermissionUser/", permissionUser);
  }

  delete(permissionUser: any) {
    return this.httpClient.post(this.baseApiUrl + "DeletePermissionUser/" , permissionUser);
  }

}
