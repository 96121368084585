import { InMemoryDbService } from 'angular-in-memory-web-api';
import { DashboardFakeData } from './dashboard.data';
import { EcommerceFakeData } from './ecommerce.data';

export class FakeDbService implements InMemoryDbService {
  createDb(): any {
    return {

      // E-Commerce
      'ecommerce-products': EcommerceFakeData.products,
      'ecommerce-relatedProducts': EcommerceFakeData.relatedProducts,
      'ecommerce-userWishlist': EcommerceFakeData.userWishlist,
      'ecommerce-userCart': EcommerceFakeData.userCart,

      // Dashboard
      'dashboard-data': DashboardFakeData.data
    };
  }
}
