import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Module } from 'app/evo/models/module';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-module-list',
  templateUrl: './evo-module-list.component.html',
})
export class EvoModuleListComponent implements OnInit {

  constructor(private moduleService: EvoModuleService,
    private transactionService: EvoTransactionService,

    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService: DecodeService,
    private helperService: HelperService) { }

  myId: number;

  modules: Module[] = [];
  module: Module = new Module();

  public basicSelectedOption: number = 10;

  filterText: string = "";

  deletedModuleId: number;

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 7, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
    }
  }

  list() {
    this.moduleService.getListDto().subscribe((item: any) => {
      this.modules = item.data;
      this.informationService.list(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }


  delete() {
    this.moduleService.get(this.deletedModuleId).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
      this.moduleService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        this.list();
      });
    });
  }
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Modüller";
    this.helperService.exportExcel(element, title);
  }
}
