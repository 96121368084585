export class Project{
    id:number;
    title:string;
    description:string;
    partnerId:number;
    partnerName:string;
    statusId:number;
    statusName:string;

    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}