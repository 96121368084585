import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketConnection } from '../models/ticketconnection';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Ticket } from '../models/ticket';

@Injectable({
  providedIn: 'root'
})
export class EvoTicketconnectionService {
  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  add(ticketConnection: TicketConnection): Observable<TicketConnection> {
    return this.httpClient.post<TicketConnection>(this.baseApiUrl + "AddTicketConnection", ticketConnection)
  }
  delete(ticketConnection: TicketConnection): Observable<TicketConnection> {
    return this.httpClient.post<TicketConnection>(this.baseApiUrl + "DeleteTicketConnection", ticketConnection)
  }
  update(ticketConnection: TicketConnection): Observable<TicketConnection> {
    return this.httpClient.post<TicketConnection>(this.baseApiUrl + "UpdateTicketConnection", ticketConnection)
  }
  getConnectedTickets(ticketId: number): Observable<Ticket[]> {
    return this.httpClient.get<Ticket[]>(this.baseApiUrl + "getListByTicketId?ticketid=" + ticketId)
  }
  GetByTicketIds(connectedTicketid: number, ticketId: number): Observable<TicketConnection> {
    return this.httpClient.get<TicketConnection>(this.baseApiUrl + "GetByTicketIds/ConnectedTicketId/" + connectedTicketid + "/ticketId/" + ticketId);
  }
}
