import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TaskStatus } from '../models/tasskstatus';

@Injectable({
  providedIn: 'root'
})
export class EvoTaskStatusService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<[]>{
    return this.httpClient.get<[]>(this.baseApiUrl+'GetTaskStatusList');
  }
  checkTaskInformation(taskId:number):Observable<any>{
    return this.httpClient.get<any>(this.baseApiUrl+'CheckTaskInformation/'+taskId);
  }
  getFirstOrDefaulTaskStatus(id:number):Observable<any>{
    return this.httpClient.get<any>(this.baseApiUrl+'GetFirstOrDefaulTaskStatus/'+id);
  }
  get(id:number):Observable<any>{
    return this.httpClient.get<any>(this.baseApiUrl+'GetTaskStatus/'+id);
  }
  add(taskStatus:TaskStatus):Observable<TaskStatus>{
    return this.httpClient.post<TaskStatus>(this.baseApiUrl+'AddTaskStatus',taskStatus);
  }
  update(taskStatus:TaskStatus):Observable<TaskStatus>{
    return this.httpClient.post<TaskStatus>(this.baseApiUrl+'UpdateTaskStatus',taskStatus);
  }
  delete(id:number):Observable<TaskStatus>{
    return this.httpClient.post<TaskStatus>(this.baseApiUrl+'DeleteTaskStatus/'+id,"");
  }


}
  


