import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-customer-list',
  templateUrl: './evo-customer-list.component.html',
  styles: [
  ]
})
export class EvoPartnerListComponent implements OnInit {

  constructor(private partnerService: EvoPartnerService,
    private decodeService: DecodeService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
    private transactionService: EvoTransactionService,

    private router: Router,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,

  ) { }

  public basicSelectedOption: number = 10;

  myId: number;
  userRole: string;
  partners: Partner[];
  filterText: string = "";
  deletedPartnerId: number;

  permissions: Permission[] = [];
  permissionUsers: PermissionUser[] = [];

  listingRestriction: boolean;

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();
    this.getPermissions();
  }

  list() {
    this.partnerService.getList().subscribe((items: any) => {
      this.partners = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  getPermissions() {
    this.permissionUserService.getPermissionUserListByPermissionId(10, this.myId).subscribe((items: any) => {
      this.listingRestriction = !items.data;
      if (this.listingRestriction) {
        this.list();
      }
      else {
        this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
        this.router.navigate(['/home']);
        this.transactionService.saveTransaction(0, 8, 5, this.myId).subscribe((res: any) => {
         
        });
      }
    });

  }

  delete(id: number) {
    if (this.userRole == "Admin") {
      this.partnerService.get(id).subscribe((item: any) => {
        item.data.updatedBy = 0;
        item.data.deletedBy = this.myId;
        this.partnerService.delete(item.data).subscribe((item: any) => {
          this.informationService.delete(item.message)
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            this.list();
          }, 1500);
        });
      });
    }
    else {
      this.informationService.warning("Bu İşlemi Gerçekleşirmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 8, 5, this.myId).subscribe((res: any) => {
      });
    }

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Müşteriler";
    this.helperService.exportExcel(element, title);
  }

}
