import { Component, OnInit } from '@angular/core';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Plan } from 'app/evo/models/plan';
import { HelperService } from 'app/evo/services/helper.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { InformationService } from 'app/evo/services/information.service';
import { ErrorService } from 'app/evo/services/error.service';
import { Router } from '@angular/router';
import { User } from 'app/evo/models/user';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { PlanType } from 'app/evo/models/plantype';
import { FormBuilder } from '@angular/forms';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-evo-vacationday-list',
  templateUrl: './evo-vacationday-list.component.html',
  styles: [
  ]
})
export class EvoVacationdayListComponent implements OnInit {

  public hoveredDate: NgbDate | null = null;
  public fromDateExample: NgbDate | null;
  public toDateExample: NgbDate | null;


  constructor(private planService: EvoPlanService,
    private userService: EvoUserService,
    private decodeService: DecodeService,
    private helperService: HelperService,
    private informationService: InformationService,
    private errorService: ErrorService,
    private router: Router,
    private formBuilder: FormBuilder,
    public formatter: NgbDateParserFormatter
  ) { }

  public basicSelectedOption: number = 10;
  userVacationDayCounts:any[];
  userId: number;
  filterText: string = "";
  userRole: string;
  deletedPlanId: number;
  plan: Plan = new Plan();

  fromDate: Date;
  toDate: Date;
  selectedTypes: any[] = [];

  users: User[] = [];
  vacationDays: Plan[] = [];
  vacationDaysByUserId: Plan[] = [];

  types: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzinler" }
  ];


  ngOnInit(): void {
    this.userId = this.decodeService.getUserId();
    this.getUserVacationDayCount()
    this.userRole = this.decodeService.getRole();
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });


    if (this.userRole == "Admin") {
      this.getVacationDayList();
    }
    else if (this.userRole == "Consultant" || this.userRole == "ConsultantUser") {
      this.getVacationDayListByUserId(this.userId);
    }
    else if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
    }
  }
  user_vacations :any[];
  uservacations(userid : number){
    this.user_vacations = this.vacationDays.filter(p=> p.userId == userid);
  }

  getUserVacationDayCount(){
    this.planService.getUserVacationDayCount().subscribe((res:any)=>{
      this.userVacationDayCounts = res.data;
    })
  }

  getVacationDayList() {

    this.planService.getVacationList().subscribe((items: any) => {
      this.vacationDays = items.data;

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });

  }
  getVacationDayListByUserId(userId: number) {
    this.planService.getVacationDayListByUserId(this.userId).subscribe((items: any) => {
      this.vacationDaysByUserId = items.data;

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "İzin Günleri Listesi";
    this.helperService.exportExcel(element, title);
  }

  updateVacationDays() {
    this.plan.updatedBy = this.userId;
    this.plan.deletedBy = 0;
    this.planService.update(this.plan).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/vacationdays"]);
        this.ngOnInit();
      }, 1500);
    });
  }

  getMyVacationDay(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      this.plan = item.data;

    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  deleteVacationDays(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.userId;
      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          if (this.userRole === 'Admin') {
            this.getVacationDayList();
          } else {
            this.getVacationDayListByUserId(this.userId);
          }
        }, 500);
      });
    });
  }
  // tableByFiltersId() {
  //   this.planService.getVacationList().subscribe((items: any) => {
  //     let filteredItems = items.data;

  //     if (this.selectedTypes.length > 0) {
  //       filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
  //     }
  //     if (this.fromDate && this.toDate) {
  //       filteredItems = filteredItems.filter(item =>
  //         new Date(item.startDate) >= this.fromDate &&
  //         new Date(item.startDate) <= this.toDate ||
  //         new Date(item.endDate) >= this.fromDate &&
  //         new Date(item.endDate) <= this.toDate
  //       );
  //     }
  //     this.vacationDays = filteredItems;
  //   });
  // }

  // checkType(value: any): value is NgbDate {
  //   return value instanceof NgbDate;
  // }


  // tableByDates() {
  //   var tempDate : any = {};
  //   var datePipe = new DatePipe("en-US");

  //   if (this.fromDateExample instanceof NgbDate){
  //     tempDate = {day: this.fromDateExample.day , month:this.fromDateExample.month -1, year:this.fromDateExample.year};
  //   }
  //   else{
  //     var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
  //     tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear()}
  //   }

  //   this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

  //   if (this.toDateExample == null)
  //     this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
  //   else
  //     var tempToDate : any = {};

  //     if (this.toDateExample instanceof NgbDate){
  //       tempToDate = {day: this.toDateExample.day , month:this.toDateExample.month -1, year:this.toDateExample.year};
  //     }
  //     else{
  //       var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
  //       tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear()}
  //     }

  //     this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

  //   if (!this.fromDate == null || !this.toDate == null) {
  //     this.getVacationDayList();
  //   } else {
  //     this.planService.getList().subscribe((items: any) => {
  //       this.vacationDays = items.data.filter(item =>
  //         new Date(item.startDate) >= this.fromDate &&
  //         new Date(item.startDate) <= this.toDate ||
  //         new Date(item.endDate) >= this.fromDate &&
  //         new Date(item.endDate) <= this.toDate
  //       );
  //     });
  //   }
  //   this.tableByFiltersId();
  // }

  // onDateSelection(date: NgbDate) {
  //   if (!this.fromDateExample && !this.toDateExample) {
  //     this.fromDateExample = date;
  //   } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
  //     this.toDateExample = date;
  //   } else {
  //     this.toDateExample = null;
  //     this.fromDateExample = date;
  //   }
  //   this.tableByDates();
  // }


}
