export class ContractDetail{
    id:number;
    contractId:number;
    userId:number;
    moduleId:number;
    userName:string;
    moduleName:string;
    price:number;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}