import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Content } from '../models/content';

@Injectable({
  providedIn: 'root'
})
export class EvoContentService {
  
  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(id:number):Observable<Content[]>{
    return this.httpClient.get<Content[]>(this.baseApiUrl+'GetContentList/'+id);
  }
  getListByTransactionId(id:number):Observable<Content[]>{
    return this.httpClient.get<Content[]>(this.baseApiUrl+'GetContentListByTransactionId/'+id);
  }
  
  get (id: number):Observable <Content> {
    return this.httpClient.get<Content>(this.baseApiUrl+'GetContent/'+id);
  }
  add(content:Content):Observable<Content>{
    return this.httpClient.post<Content>(this.baseApiUrl+'AddContent/',content);
  }
  update(content:Content):Observable<Content>{
    return this.httpClient.post<Content>(this.baseApiUrl+"UpdateContent/",content);
  }
  delete(id:number):Observable<Content>{
    return this.httpClient.post<Content>(this.baseApiUrl+"DeleteContent/"+ id,"");
  }
  
}
