import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-operationclaim',
  templateUrl: './evo-operationclaim-add-form.component.html',
  styles: [
  ]
})
export class EvoOperationClaimAddFormComponent implements OnInit {

  constructor(
    private operationClaimService:EvoOperationClaimService,
    private router:Router,
    private informationService:InformationService,
    private errorService:ErrorService,
    ) { }
  operationClaim:OperationClaim;
  ngOnInit(): void {
  }

  add(name:string, description:string){

    this.operationClaim={
      id:0,
      name:name,
      description:description
    };
  

    this.operationClaimService.add(this.operationClaim).subscribe((item: any) => {
      this.informationService.add(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/operationclaims"]);
    }, 1500);
    });
  }
}
