import { Component, OnInit } from '@angular/core'

import { locale as en } from './i18n/en'
import { locale as fr } from './i18n/fr'
import { locale as de } from './i18n/de'
import { locale as pt } from './i18n/pt'
import { FlatpickrOptions } from 'ng2-flatpickr'


@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html',
  styleUrls: ['./sample.component.scss']
})
export class SampleComponent implements OnInit {
  // public
  public basicTP = { hour: 13, minute: 30 };

  constructor() {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  public dateTimeOptions: FlatpickrOptions = {
    altInput: true,
    enableTime: true
  };

  
  ngOnInit() {
    // content header
  }
}
