<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Sözleşme Düzenle</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #ContractForm="ngForm">
                                <div class="row">

                                    <div class="col-10">
                                        <label for="name-vertical">Sözleşme No:</label>
                                        <br>
                                        <label class="card-title">{{contract.contractNumber}} </label>
                                    </div>
                                    <div class="col-2 justify-content-end d-flex">
                                        <div class="col-12">
                                            <button type="submit" rippleEffect class="btn btn-success btn-sm"
                                                [disabled]="!ContractForm.valid" (click)="update()" title="Kaydet"><i
                                                    data-feather="save"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="name-vertical">Başlangıç Tarihi Seçiniz</label>
                                            <input type="datetime-local" class="form-control" name="txtStartDate"
                                                [(ngModel)]="contract.startDate" #txtStartDate="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" name="slcPartner" [(ngModel)]="partner.id"
                                                #slcPartner="ngModel" (ngModelChange)="changePartner()">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="name-vertical">Bitiş Tarihi Seçiniz</label>
                                            <input type="datetime-local" class="form-control" name="txtEndDate"
                                                [(ngModel)]="contract.endDate" #txtEndDate="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label for="name-vertical">Proje Seçiniz</label>
                                            <select class="form-control" name="slcProject" [(ngModel)]="project.id"
                                                #slcProject="ngModel">
                                                <option value="0">Bir Proje Seçiniz</option>
                                                <option *ngFor="let item of projects" value="{{item.id}}">{{item.title}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                    <div class="content-wrapper container-xxl p-0">
                        <div class="content-body">
                            <section id="ngx-datatable-kitchen-sink">
                                <core-card-snippet>
                                    <div class="row mb-2">
                                        <div class="col-1 mr-0 ml-1" style="margin-top: 5px;">
                                            <button class="btn btn-sm btn-primary" title="Yeni Sözleşme Detayı Ekle"
                                                data-bs-toggle="modal" data-bs-target="#addcontractdetail"><i
                                                    data-feather="plus"></i></button>

                                        </div>
                                        <div class="col-2 ml-0">
                                            <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>

                                    <h4 class="card-title">Danışman Listesi</h4>

                                    <ngx-datatable [rows]="contractDetails " [rowHeight]="58"
                                        class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                        [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
                                        <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false"
                                            [draggable]="false" [resizeable]="false">

                                            <ng-template ngx-datatable-header-template let-value="value"
                                                let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="allRowsSelected"
                                                        (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                                    <label class="custom-control-label" for="headerChkbxRef"></label>
                                                </div>
                                            </ng-template>
                                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex"
                                                let-value="value" let-isSelected="isSelected"
                                                let-onCheckboxChangeFn="onCheckboxChangeFn">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input"
                                                        [checked]="isSelected" (change)="onCheckboxChangeFn($event)"
                                                        id="rowChkbxRef{{ rowIndex }}" />
                                                    <label class="custom-control-label"
                                                        for="rowChkbxRef{{rowIndex}}"></label>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Danışman Adı" prop="name" [width]="250">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.userName}}
                                                <br />
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Modül" prop="module" [width]="150">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.moduleName}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Tutar" prop="price" [width]="160">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.price}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                <div class="d-flex align-items-center">
                                                    <div ngbDropdown container="body">
                                                        <a ngbDropdownToggle href="javascript:void(0);"
                                                            class="hide-arrow" id="dropdownBrowserState"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <i data-feather="more-vertical"
                                                                class="text-primary cursor-pointer mr-50"></i>
                                                        </a>
                                                        <div ngbDropdownMenu class="dropdown-menu-right"
                                                            aria-labelledby="dropdownBrowserState">
                                                            <button (click)="getContractDetail(row.id)"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#updatecontractdetail"
                                                                ngbDropdownItem><i data-feather="edit-2"
                                                                    class="mr-50"></i><span>Güncelle</span></button>

                                                            <button ngbDropdownItem href="javascript:void(0);"
                                                                (click)="deletedContractDetailId=row.id"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#deletecontractdetail">
                                                                <i data-feather="trash"
                                                                    class="mr-50"></i><span>Sil</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <button ngbDropdownItem (click)="getContractDetail(row.id)"
                                                        data-bs-toggle="modal" data-bs-target="#updatecontractdetail"
                                                        title="Güncelle"><i data-feather="edit"
                                                            class="text-primary cursor-pointer"></i></button>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                    <div class="modal fade" id="addcontractdetail" tabindex="-1"
                                        aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Sözleşme
                                                        Detayı Ekle</h1>
                                                    <button type="button" class="btn btn-secondary"
                                                        data-bs-dismiss="modal"><i data-feather="x-circle"
                                                            class="mr-50"></i>Çıkış</button>
                                                </div>
                                                <form #ContractDetailAddForm="ngForm">
                                                    <div class="modal-body">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Tutar Giriniz</label>
                                                                <input type="number" class="form-control" ngModel
                                                                    name="txtPrice" #txtPrice="ngModel" required />
                                                                <small class="text-danger"
                                                                    *ngIf="txtPrice.invalid&& txtPrice.touched">Tutar
                                                                    bilgisi
                                                                    bırakılamaz!</small>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Modül Seçiniz</label>
                                                                <select class="form-control" name="slcModule"
                                                                    [(ngModel)]="module.id" #slcModule="ngModel"
                                                                    required>
                                                                    <option *ngFor="let item of modules"
                                                                        value="{{item.id}}">{{item.name}}
                                                                    </option>
                                                                </select>
                                                                <small class="text-danger"
                                                                    *ngIf="slcModule.invalid&& slcModule.touched">Modül
                                                                    bilgisi
                                                                    boş
                                                                    bırakılamaz!</small>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Danışman Seçiniz</label>
                                                                <select class="form-control" name="slcUser"
                                                                    [(ngModel)]="user.id" #slcUser="ngModel">
                                                                    <option *ngFor="let item of users"
                                                                        value="{{item.id}}">
                                                                        {{item.firstName}} {{item.lastName}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div>
                                                            <button type="button" class="btn btn-success"
                                                                data-bs-dismiss="modal"
                                                                [disabled]="!ContractDetailAddForm.valid"
                                                                (click)="addContractDetail(txtPrice.value)"><i
                                                                    data-feather="check"
                                                                    class="mr-50"></i>Kaydet</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="updatecontractdetail" tabindex="-1"
                                        aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Sözleşme
                                                        Detayı Güncelle</h1>
                                                    <button type="button" class="btn btn-secondary"
                                                        data-bs-dismiss="modal"><i data-feather="x-circle"
                                                            class="mr-50"></i>Çıkış</button>
                                                </div>
                                                <form #ContractDetailUpdateForm="ngForm">
                                                    <div class="modal-body">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Tutar Giriniz</label>
                                                                <input type="number" class="form-control" name="txtTime"
                                                                    [(ngModel)]="contractDetail.price"
                                                                    #txtTime="ngModel" required />
                                                                <small class="text-danger"
                                                                    *ngIf="txtPrice.invalid&& txtPrice.touched">Tutar
                                                                    bilgisi
                                                                    bırakılamaz!</small>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Modül Seçiniz</label>
                                                                <select class="form-control" name="slcModule"
                                                                    [(ngModel)]="contractDetail.moduleId"
                                                                    #slcModule="ngModel" required>
                                                                    <option *ngFor="let item of modules"
                                                                        value="{{item.id}}">{{item.name}}
                                                                    </option>
                                                                </select>
                                                                <small class="text-danger"
                                                                    *ngIf="slcModule.invalid&& slcModule.touched">Modül
                                                                    bilgisi
                                                                    boş
                                                                    bırakılamaz!</small>
                                                            </div>
                                                        </div>


                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="name-vertical">Danışman Seçiniz</label>
                                                                <select class="form-control" name="slcUser"
                                                                    [(ngModel)]="contractDetail.userId"
                                                                    #slcUser="ngModel">
                                                                    <option *ngFor="let item of users"
                                                                        value="{{item.id}}">
                                                                        {{item.firstName}} {{item.lastName}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <div>
                                                            <button type="button" class="btn btn-success"
                                                                [disabled]="!ContractDetailUpdateForm.valid"
                                                                data-bs-dismiss="modal"
                                                                (click)="updateContractDetail()"><i data-feather="check"
                                                                    class="mr-50"></i>Kaydet</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="deletecontractdetail" tabindex="-1"
                                        aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-lg">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Sözleşme
                                                        Detayı Sil</h1>
                                                    <button type="button" class="btn btn-secondary"
                                                        data-bs-dismiss="modal"><i data-feather="x-circle"
                                                            class="mr-50"></i>Çıkış</button>
                                                </div>
                                                <div class="modal-body">
                                                    Kaydı Silmek İstediğinize Emin Misiniz?
                                                </div>
                                                <div class="modal-footer">
                                                    <div>
                                                        <button type="button" class="btn btn-danger"
                                                            data-bs-dismiss="modal"
                                                            (click)="deleteContractDetail(deletedContractDetailId)"><i
                                                                data-feather="trash" class="mr-50"></i>Sil</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </core-card-snippet>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>