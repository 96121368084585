import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Department } from 'app/evo/models/department';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-department-update-form',
  templateUrl: './evo-department-update-form.component.html',
  styles: [
  ]
})
export class EvoDepartmentUpdateFormComponent implements OnInit {

  department: Department;

  constructor(
    private departmentService: EvoDepartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private informationService: InformationService,
    private decodeService:DecodeService,
    private errorService: ErrorService,
  ) { }
  id: number;

  ngOnInit(): void {
    let item=this.decodeService.getRole();
    if(item!="Admin"){
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.params['Id'];

    this.departmentService.get(this.id).subscribe((item: any) => {
      this.department = item.data;
    });
  }
  update() {
    this.departmentService.update(this.department).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/departments"]);
      }, 1500);
    });
  }
}
