export class Task {
    id: number;
    title: string;
    description: string;
    userId: number;
    typeId: number;
    startDate: Date;
    endDate: Date;
    isReccuren: boolean;
    isImportant: boolean;
    isCompleted: boolean;
    statusId: number;
    statusName
    createdBy: number;
    createdDate: Date;
    updatedBy: number;
    deletedBy: number;
    frequencyTypeId? :number; 
}