import { Pipe, PipeTransform } from '@angular/core';
import { last } from 'rxjs/operators';

@Pipe({
  name: 'evoTodo'
})
export class EvoTodoPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const title = p.title.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const decription = p.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase())
      return (decription || title);
    });
  }

}