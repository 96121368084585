import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { GuideSetting } from 'app/evo/models/guide';
import { GuideType } from 'app/evo/models/guideType';
import { MailSetting } from 'app/evo/models/mailSetting';
import { SystemSetting } from 'app/evo/models/systemsetting';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoGuideService } from 'app/evo/services/evo-guide.service';
import { EvoSystemSettingService } from 'app/evo/services/evo-system-setting.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { MailSettingService } from 'app/evo/services/mail-setting.service';

@Component({
  selector: 'app-evo-system-settings',
  templateUrl: './evo-system-settings.component.html',
  styles: [
  ]
})
export class EvoSystemSettingsComponent implements OnInit {
  public passwordTextTypeNew = false;
  public basicSelectedOption = 10;
  previewImageSrc: string | undefined;

  mailSettingForm: FormGroup;
  guideSettingForm: FormGroup;
  mailSettingUpdateForm: FormGroup;
  guideSettingUpdateForm: FormGroup;

  mailSettingId: number = 0;
  guideSettingId: number = 0;

  deletedMailSettingId: number = 0;
  deletedGuideSettingId: number = 0;

  id: number;
  userRole: string;
  documents: any[] = [];
  alertClose: boolean = false;
  isMailActiveted: boolean;

  user: User = new User();
  guideType:GuideType = new GuideType();

  mailSettings: MailSetting[] = [];
  guideSettings: GuideSetting[] = [];
  systemSettings: SystemSetting[];
  guideTypes: GuideType[]=[
    {id:1,name:"Admin"},
    {id:2,name:"Danışman"},
    {id:3,name:"Cari"}
  
  ];

  mailSetting: MailSetting = new MailSetting();
  guideSetting: GuideSetting = new GuideSetting();
  systemSetting: SystemSetting = new SystemSetting();


  constructor(
    private decodeService: DecodeService,
    private router: Router,
    private userService: EvoUserService,
    private informationService: InformationService,
    private errorService: ErrorService,
    private mailSettingService: MailSettingService,
    private systemSettingService: EvoSystemSettingService,
    private guideSettingService: EvoGuideService,
    private formBuilder: FormBuilder,

    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    this.userRole = this.decodeService.getRole();
    if (this.userRole != "Admin") {
   
      this.getGuideList();
    }
    else {
      this.list();
      this.getGuideList();
    }
    this.mailSettingForm = this.formBuilder.group({
      txtAddName: ['', Validators.required],
      txtAddMail: ['', [Validators.required, Validators.email]],
      txtAddUrl: ['', Validators.required],
      txtAddSmtp: ['', Validators.required],
      txtAddPort: ['', Validators.required],
      txtAddPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],

    });
    this.mailSettingUpdateForm = this.formBuilder.group({
      txtUpdateName: ['', Validators.required],
      txtUpdateMail: ['', [Validators.required, Validators.email]],
      txtUpdateUrl: ['', Validators.required],
      txtUpdateSmtp: ['', Validators.required],
      txtUpdatePort: ['', Validators.required],
      txtUpdatePassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],

    });
    this.guideSettingForm = this.formBuilder.group({
      slcType: ['', Validators.required],
      txtGuideDocument: ['', Validators.required],
      txtGuideUrl: ['',],
    });
    this.guideSettingUpdateForm = this.formBuilder.group({
      slcUpdateType: ['', Validators.required],
      txtGuideUpdateUrl: ['',],
    });
  }

  list() {
    this.mailSettingService.getList().subscribe((res: any) => {
      this.mailSettings = res.data;
      this.getFirstOrDefaultValue();
    })
  }


  uploadImage() {
    if (this.documents.length == 0) {
      this.informationService.warning("Lütfen bir dosya seçiniz.");
      return;
    }
    let formData = new FormData();
    formData.append("id", this.systemSetting.id.toString());
    for (let i = 0; i < this.documents.length; i++) {
      formData.append("document", this.documents[i]);
    }
    this.systemSettingService.companyImageUpdate(formData).subscribe((res: any) => {
      this.informationService.update(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    });
  }

  getFirstOrDefaultValue() {
    this.systemSettingService.getFirstOrDefaultValue().subscribe((res: any) => {
      this.systemSetting = res.data;
      this.isMailActiveted = res.data.isMailActivated;
      if (this.systemSetting.id == 0 || this.systemSetting == null) {
        this.informationService.add("İlk Girişiniz mi? Lütfen Bekleyiniz... Varsayılan Bir Şirket Oluşturuyor");
        this.systemSetting = {
          id: 0,
          companyName: "ARCA YAZILIM",
          isMailActivated: false,
          mailParameterId: null,
          companyImageUrl: "",
          guideId: null
        };
        this.systemSettingService.add(this.systemSetting).subscribe((res: any) => {
          this.informationService.add(res.message);
          this.getFirstOrDefaultValue();
        }, (err) => {
          this.errorService.errorHandler(err);
        });
      }

    }, (err) => {
      this.errorService.errorHandler(err);


    }, () => {
      setTimeout(() => {
      }, 100);
    });



  }

  update() {
    this.mailSetting = {
      id: this.mailSettingId,
      name: this.mailSettingUpdateForm.value.txtUpdateName,
      urlAddress: this.mailSettingUpdateForm.value.txtUpdateUrl,
      mail: this.mailSettingUpdateForm.value.txtUpdateMail,
      password: this.mailSettingUpdateForm.value.txtUpdatePassword,
      smtp: this.mailSettingUpdateForm.value.txtUpdateSmtp,
      port: this.mailSettingUpdateForm.value.txtUpdatePort
    };
    this.mailSettingService.update(this.mailSetting).subscribe((items: any) => {
      this.informationService.update(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  updateSystemSetting() {
    if (this.documents.length == 0) {
      this.systemSetting.companyImageUrl = this.systemSetting.companyImageUrl;
    }
    this.systemSettingService.update(this.systemSetting).subscribe((res: any) => {
      this.informationService.update(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 100);
    });
  }
  guideDocumentUpdate() {
    if (this.documents.length == 0) {
      this.guideSetting = {
        id: this.guideSettingId,
        typeId: this.guideSettingUpdateForm.value.slcUpdateType,
        url: ""
      };
      this.guideSettingService.update(this.guideSetting).subscribe((res: any) => {
        this.informationService.update(res.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 100);
      });
    }

    else {
      let formData = new FormData();
      formData.append("id", this.guideSettingId.toString());
      formData.append("typeId", this.guideSettingUpdateForm.value.slcUpdateType.toString());
      formData.append("document", this.documents[0]);
      this.guideSettingService.guideDocumentUpdate(formData).subscribe((res: any) => {
        this.informationService.update(res.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 100);
      });
    }
  }

  getDocument(event: any) {
    this.documents = event.target.files;
  }
  getGuideList() {
    this.guideSettingService.getListDto().subscribe((res: any) => {
      this.guideSettings = res.data;
  
      // Filter the guideSettings based on userrole and typeId
      if (this.userRole === 'CustomerUser' || this.userRole === 'Customer') {
        this.guideSettings = this.guideSettings.filter((guideSetting: any) => {
          return guideSetting.typeId === 3;
        });
      }
      if (this.userRole === 'ConsultantUser' || this.userRole === 'Consultant') {
        this.guideSettings = this.guideSettings.filter((guideSetting: any) => {
          return guideSetting.typeId === 2;
        });
        
      }
    });
  }
  
  getMailSetting(id: number) {
    this.mailSettingId = id;
    this.mailSettingService.getById(id).subscribe((items: any) => {
      this.mailSettingUpdateForm = this.formBuilder.group({
        txtUpdateName: [items.data.name, Validators.required],
        txtUpdateMail: [items.data.mail, [Validators.required, Validators.email]],
        txtUpdateUrl: [items.data.urlAddress, Validators.required],
        txtUpdateSmtp: [items.data.smtp, Validators.required],
        txtUpdatePort: [items.data.port, Validators.required],
        txtUpdatePassword: ["", [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/)]],
      });
      this.mailSetting = items.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }
  getGuideSetting(id: number) {
    this.guideSettingId = id;
    this.guideSettingService.getById(id).subscribe((items: any) => {
      this.guideSettingUpdateForm = this.formBuilder.group({
        slcUpdateType: [items.data.typeId, Validators.required],
      });
      this.mailSetting = items.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }


  add() {
    this.mailSetting = {
      id: 0,
      name: this.mailSettingForm.value.txtAddName,
      urlAddress: this.mailSettingForm.value.txtAddUrl,
      mail: this.mailSettingForm.value.txtAddMail,
      password: this.mailSettingForm.value.txtAddPassword,
      smtp: this.mailSettingForm.value.txtAddSmtp,
      port: this.mailSettingForm.value.txtAddPort
    };
    this.mailSettingService.add(this.mailSetting).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 100);
    });
  }

  addGuideSetting() {
    let formData = new FormData();
    formData.append("id", "0");
    formData.append("typeId", this.guideSettingForm.value.slcType.toString());
    formData.append("document", this.documents[0]);
    this.guideSettingService.add(formData).subscribe((res: any) => {
      this.informationService.add(res.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 100);
    });
  }

  delete(id: number) {
    this.mailSettingService.delete(id).subscribe((items: any) => {
      this.informationService.delete(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  deleteGuideSetting(id: number) {
    this.guideSettingService.delete(id).subscribe((item: any) => {
      this.informationService.delete(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    })
  }



  togglePasswordTextTypeNew() {
    this.passwordTextTypeNew = !this.passwordTextTypeNew;
  }
  changeMailIsActive() {
    this.isMailActiveted = !this.isMailActiveted;
    this.systemSetting.isMailActivated = this.isMailActiveted;
  }

  previewFile(event: any) {
    this.getDocument(event);
    const fileInput = this.el.nativeElement.querySelector('#file-upload');
    const file = fileInput.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.previewImageSrc = reader.result as string;
        this.cdr.detectChanges();
      };
      reader.readAsDataURL(file);
    } else {
      this.previewImageSrc = undefined;
    }
  }

  downloadDocument(url: string, name: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  }
}

