import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { User } from 'app/evo/models/user';
import { UserOpertaionClaim } from 'app/evo/models/useroperationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { EvoUseroperationclaimService } from 'app/evo/services/evo-useroperationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-useroperationclaim',
  templateUrl: './evo-useroperationclaim-add-form.component.html',
  styles: [
  ]
})
export class EvoUserOperationClaimAddFromComponent implements OnInit {

  constructor(private userService:EvoUserService,
    private operationClaimService:EvoOperationClaimService, 
    private userOperationClaimServcie:EvoUseroperationclaimService,
    private router:Router,
    private decodeService:DecodeService,
    private informationService:InformationService,
    private errorService:ErrorService,
    ) { }

  userOperationClaim:UserOpertaionClaim;
  
  operationClaims:OperationClaim[]=[];
  operationClaim:OperationClaim=new OperationClaim();

  myId:number;
  users:User[]=[];
  user:User=new User();

  ngOnInit(): void {
    this.myId=this.decodeService.getUserId();
    this.userService.getList().subscribe((res:any)=>{
      this.users=res.data;
    });

    this.operationClaimService.getList().subscribe((res:any)=>{

      this.operationClaims=res.data;
    });
    this.getOperationClaims();
  }

  getUsers(){

  }

  getOperationClaims(){
   
  }

  add(){
    this.userOperationClaim={
      id:0,
      userId:this.user.id,
      operationClaimId:this.operationClaim.id,
      userName:"",
      operationClaimTitle:"",

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };

    this.userOperationClaimServcie.add(this.userOperationClaim).subscribe((item: any) => {
      this.informationService.add(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/useroperationclaims"]);
    }, 1500);
    });
  }
}
