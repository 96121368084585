 <section id="ngx-datatable-kitchen-sink">
     <core-card-snippet>
         <h4 class="card-title">İzinlerin Listesi</h4>
         <div class="row">
             <div class="firstRow mt-1">
                 <div style="margin-left:9px; margin-bottom: 20px;">
                     <div class="col-12">
                         <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                             <option value="10">10</option>
                             <option value="25">25</option>
                             <option value="50">50</option>
                             <option value="100">100</option>
                         </select>
                     </div>
                 </div>
             </div>
             <div class="search mt-1">
                 <div class="col-12">
                     <input type="text" class="form-control mr-1" name="filterText" placeholder="İzin Günü Arayın.."
                         [(ngModel)]="filterText">
                 </div>
             </div>

<!-- 
             <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
                <form class="form-inline">
                    <div class="form-group">
                        <div class="input-group w-0">
                            <input name="datepicker" class="form-control" ngbDatepicker
                                #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                [startDate]="fromDateExample!" />
                            <ng-template #rangeSelectionDP let-date let-focused="focused">
                                <span class="custom-day" type="datetime" [class.focused]="focused"
                                    [class.range]="isRange(date)"
                                    [class.faded]="isHovered(date) || isInside(date)"
                                    (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                    {{ date.day }}
                                </span>
                            </ng-template>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input #dpFromDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                name="dpFromDate" [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample" (ngModelChange)="fromDateExample = $event; tableByDates();"/>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                    (click)="datepicker.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group ml-sm-2">
                        <div class="input-group">
                            <input #dpToDate class="form-control" type="date" placeholder="dd-MM-yyyy"
                                name="dpToDate" (change)="tableByDates()"
                                [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample" (ngModelChange)="toDateExample = $event; tableByDates();" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary feather icon-calendar"
                                    (click)="datepicker.toggle()" type="button" rippleEffect></button>
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->
         </div>
         <div class="row">
             <div class="col-12 mb-2 ml-2">
                
                 <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                     <i data-feather="file-text" class=""></i>
                 </button>
             </div>
         </div>
         <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
            <thead>
                <tr>
                    <td>Kullanıcı Adı</td>
                    <td>Plan Türü</td>
                    <td>Başlangıç Tarihi</td>
                    <td>Bitiş Tarihi</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of vacationDays">
                    <td>{{item.userName}}</td>
                    <td>{{item.planTypeName}}</td>
                    <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                    <td [innerHTML]="item.endDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                </tr>
            </tbody>
        </table>
         <ngx-datatable *ngIf="userRole === 'Admin'" [rows]="vacationDays |evoPlan:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
             [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
             <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                 [resizeable]="false">
                 <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                     let-selectFn="selectFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                             (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                         <label class="custom-control-label" for="headerChkbxRef"></label>
                     </div>
                 </ng-template>
                 <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                     let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                             (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                         <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                     </div>
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="250">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.userName}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Plan Türü" prop="title" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{ row.planTypeName}}
                 </ng-template>
             </ngx-datatable-column>
             <!-- <ngx-datatable-column name="Açıklama" prop="description" [width]="340">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     <div
                         [innerHTML]="row.description?.length > 25 ? row.description.substring(0, 25) + '...' : row.description">
                     </div>
                 </ng-template>
             </ngx-datatable-column> -->
             
             <!-- <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.partnerName}}
                 </ng-template>
             </ngx-datatable-column> -->
             <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.startDate | date:'dd-MM-yyyy HH:mm'}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.endDate | date:'dd-MM-yyyy HH:mm'}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                        <div ngbDropdown container="body">
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right"
                                aria-labelledby="dropdownBrowserState">
                                <a (click)="getMyVacationDay(row.id)"ngbDropdownItem data-bs-toggle="modal"
                                data-bs-target="#updatevacationday"><i
                                        data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                <button ngbDropdownItem href="javascript:void(0);"
                                    (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                    data-bs-target="#deleteplan">
                                    <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                </button>
                            </div>
                        </div>
                        <!-- <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem title="Güncelle"><i
                                data-feather="edit" class="text-primary cursor-pointer"></i></a> -->
                                <button ngbDropdownItem (click)="getMyVacationDay(row.id)" data-bs-toggle="modal"
                                data-bs-target="#updatevacationday"><i data-feather="edit"
                                    class="mr-50"></i><span></span>
                            </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
         </ngx-datatable>
         <ngx-datatable *ngIf="userRole === 'Admin'" [rows]="userVacationDayCounts |evoPlan:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
             [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
             <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                 [resizeable]="false">
                 <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                     let-selectFn="selectFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                             (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                         <label class="custom-control-label" for="headerChkbxRef"></label>
                     </div>
                 </ng-template>
                 <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                     let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                             (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                         <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                     </div>
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="250">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.username}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Toplan Gün" prop="title" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    14
                 </ng-template>
             </ngx-datatable-column>
             <!-- <ngx-datatable-column name="Açıklama" prop="description" [width]="340">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     <div
                         [innerHTML]="row.description?.length > 25 ? row.description.substring(0, 25) + '...' : row.description">
                     </div>
                 </ng-template>
             </ngx-datatable-column> -->
             
             <!-- <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.partnerName}}
                 </ng-template>
             </ngx-datatable-column> -->
             <ngx-datatable-column name="Kullanılan Gün" prop="vacationDate" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.vacationDate}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Kalan Gün" prop="vacationDate" [width]="200">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{14 - row.vacationDate}}
                </ng-template>
            </ngx-datatable-column>
             
             <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                        <div ngbDropdown container="body">
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right"
                                aria-labelledby="dropdownBrowserState">
                                <a (click)="getMyVacationDay(row.id)"ngbDropdownItem data-bs-toggle="modal"
                                data-bs-target="#updatevacationday"><i
                                        data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                <button ngbDropdownItem href="javascript:void(0);"
                                    (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                    data-bs-target="#deleteplan">
                                    <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                </button>
                            </div>
                        </div>
                        <!-- <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem title="Güncelle"><i
                                data-feather="edit" class="text-primary cursor-pointer"></i></a> -->
                                <button ngbDropdownItem (click)="uservacations(row.userId)" data-bs-toggle="modal"
                                data-bs-target="#updatevacationday"><i data-feather="edit"
                                    class="mr-50"></i><span></span>
                            </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
         </ngx-datatable>
         <ngx-datatable *ngIf="userRole != 'Admin'" [rows]="vacationDaysByUserId |evoPlan:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
             [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
             <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                 [resizeable]="false">
                 <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                     let-selectFn="selectFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                             (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                         <label class="custom-control-label" for="headerChkbxRef"></label>
                     </div>
                 </ng-template>
                 <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                     let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                     <div class="custom-control custom-checkbox">
                         <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                             (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                         <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                     </div>
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="250">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.userName}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Plan Türü" prop="title" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    {{ row.planTypeName}}
                 </ng-template>
             </ngx-datatable-column>
             <!-- <ngx-datatable-column name="Açıklama" prop="description" [width]="340">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     <div
                         [innerHTML]="row.description?.length > 25 ? row.description.substring(0, 25) + '...' : row.description">
                     </div>
                 </ng-template>
             </ngx-datatable-column> -->
             
             <!-- <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.partnerName}}
                 </ng-template>
             </ngx-datatable-column> -->
             <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.startDate | date:'dd-MM-yyyy HH:mm'}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="200">
                 <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                     {{ row.endDate | date:'dd-MM-yyyy HH:mm'}}
                 </ng-template>
             </ngx-datatable-column>
             <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                    <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                        <div ngbDropdown container="body">
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right"
                                aria-labelledby="dropdownBrowserState">
                                <a (click)="getMyVacationDay(row.id)"ngbDropdownItem data-bs-toggle="modal"
                                data-bs-target="#updatevacationday"><i
                                        data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                        <button ngbDropdownItem href="javascript:void(0);"
                                        (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                        data-bs-target="#deleteplan">
                                        <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                    </button>
                            </div>
                        </div>
                        <!-- <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem title="Güncelle"><i
                                data-feather="edit" class="text-primary cursor-pointer"></i></a> -->
                                <button ngbDropdownItem (click)="getMyVacationDay(row.id)" data-bs-toggle="modal"
                                            data-bs-target="#updatevacationday"><i data-feather="edit"
                                                class="mr-50"></i><span></span>
                                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
         </ngx-datatable>
     </core-card-snippet>
  
 </section>

 <div class="modal fade" id="updatevacationday" tabindex="-1" aria-labelledby="staticBackdropLabel"
 aria-hidden="true">
 <div class="modal-dialog modal-lg">
     <div class="modal-content">
         <div class="modal-header">
             <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcı İzin Detayları</h1>
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                     data-feather="x-circle" class="mr-50"></i>Çıkış</button>
         </div>
         <div class="modal-body">
               <table class="table">
                <thead>
                    <td>Kullanıcı Adı</td>
                    <td>Başlangıç Tarihi</td>
                    <td>Bitiş Tarihi</td>
                </thead>
                <tbody *ngFor="let item of user_vacations">
                    <td>{{item.userName}}</td>
                    <td>{{ item.startDate | date:'dd-MM-yyyy HH:mm'}}</td>
                    <td>{{ item.endDate | date:'dd-MM-yyyy HH:mm'}}</td>
                    
                </tbody>
               </table>
         </div>
     </div>
 </div>
</div>



 <div class="modal fade" id="deleteplan" tabindex="-1" aria-labelledby="staticBackdropLabel"
 aria-hidden="true">
 <div class="modal-dialog modal-lg">
     <div class="modal-content">
         <div class="modal-header">
             <h1 class="modal-title fs-5" id="staticBackdropLabel">Planı Sil</h1>
             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                     data-feather="x-circle" class="mr-50"></i>Çıkış</button>
         </div>
         <div class="modal-body">
             Kaydı Silmek İstediğinize Emin Misiniz?
         </div>
         <div class="modal-footer">
             <div>
                 <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                     (click)="deleteVacationDays(deletedPlanId)"><i data-feather="trash"
                         class="mr-50"></i>Sil</button>
             </div>
         </div>
     </div>
 </div>
</div>