<style>
  .task {
    display: flex;
  }

  @media screen and (width < 992px) {
    .tasks {
      flex-direction: column;
    }
  }
</style>

<div class="container">
  <div class="row card" style="width: 100%;min-height: 40px;">
    <div class="filter">
      <div class="card-body d-flex">
        <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#addTaskModal"
          (click)="createNewTask()">
          <span class="align-middle">Yeni Görev</span>
        </button>

        <div class="search mt-1">
          <div class="col-12">
            <input type="text" class="form-control mr-1" name="filterText" placeholder="Görev Arayın.."
              [(ngModel)]="filterText">
          </div>
        </div>
        <div class="filter mt-1">
          <div class="col-12"
            *ngIf="userRole=='Admin' || userRole=='Consultant' || userRole=='ConsultantUser'else emptyTemplate">
            <ng-select [multiple]="true" [items]="frequencyTypes" [(ngModel)]="selectedFrequencies"
              placeholder="Tekrar Türü Seçiniz" bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <span class="ng-value-label"> {{ item.name }}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </ng-template>
              <ng-template ng-header-tmp>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <ng-template #emptyTemplate>
          <div class="col-2"></div>
        </ng-template>
        <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
          <form class="form-inline">
            <div class="form-group">
              <div class="input-group w-0">
                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                  [dayTemplate]="rangeSelectionDP" outsideDays="hidden" [startDate]="fromDateExample!" />
                <ng-template #rangeSelectionDP let-date let-focused="focused">
                  <span class="custom-day" type="datetime" [class.focused]="focused" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpFromDate"
                  [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                  (ngModelChange)="fromDateExample = $event; tableByFiltersId();" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                    type="button" rippleEffect></button>
                </div>
              </div>
            </div>
            <div class="form-group ml-sm-2">
              <div class="input-group">
                <input class="form-control" type="date" placeholder="dd-MM-yyyy" name="dpToDate"
                  [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                  (ngModelChange)="toDateExample = $event; tableByFiltersId();" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()"
                    type="button" rippleEffect></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>

  <div class="d-flex tasks">
    <div class="col-lg-4 col-md-8 col-12 ">
      <div class="card card-employee-task p-3">
        <div class="card-header">
          <h4 class="card-title">Başlamamış Görevler</h4>
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 1,tasks)"
          style="min-height: 400px; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="employee-task d-flex justify-content-between align-items-center"
              *ngFor="let item of tasks|evoTodo:filterText | paginate: { itemsPerPage: 5, currentPage: nostartpage,id:'nostart' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item,tasks,'move')">
              <div class="media">
                <div class="media-body my-auto">
                  <span (click)="openTodo(item.id); $event.stopPropagation()" data-bs-toggle="modal"
                    data-bs-target="#updateModal">
                    <h6 class="mb-0">{{item.title}}</h6>
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="badge-wrapper mr-1">
                  <div class="badge badge-pill badge-light-success text-capitalize" *ngIf="item.typeId==1">Düşük
                  </div>
                  <div class="badge badge-pill badge-light-primary text-capitalize" *ngIf="item.typeId==2">Normal
                  </div>
                  <div class="badge badge-pill badge-light-danger text-capitalize" *ngIf="item.typeId==3">Yüksek
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <small class="text-muted mr-75">{{getTurkishMonth(item.startDate)}} {{item.startDate
                  |date: "dd"}}</small>
                  <div class="employee-task-chart-primary-1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <pagination-controls id="nostart" (pageChange)="nostartpage = $event"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-8 col-12 ">
      <div class="card card-employee-task p-3">
        <div class="card-header">
          <h4 class="card-title">İşlemdeki Görevler</h4>
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 2,inprogressTasks)"
          style="min-height: 400px;  display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="employee-task d-flex justify-content-between align-items-center"
              *ngFor="let item of inprogressTasks|evoTodo:filterText | paginate: { itemsPerPage: 5, currentPage: progresspage,id:'progress' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item,inprogressTasks,'move')">
              <div class="media">
                <div class="media-body my-auto">
                  <span (click)="openTodo(item.id); $event.stopPropagation()" data-bs-toggle="modal"
                    data-bs-target="#updateModal">
                    <h6 class="mb-0">{{item.title}}</h6>
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="badge-wrapper mr-1">
                  <div class="badge badge-pill badge-light-success text-capitalize" *ngIf="item.typeId==1">Düşük
                  </div>
                  <div class="badge badge-pill badge-light-primary text-capitalize" *ngIf="item.typeId==2">Normal
                  </div>
                  <div class="badge badge-pill badge-light-danger text-capitalize" *ngIf="item.typeId==3">Yüksek
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <small class="text-muted mr-75">{{getTurkishMonth(item.startDate)}} {{item.startDate
                  |date: "dd"}}</small>
                  <div class="employee-task-chart-primary-1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <pagination-controls id="progress" (pageChange)="progresspage = $event"></pagination-controls>
          </div>
        </div>
      </div>



    </div>
    <div class="col-lg-4 col-md-8 col-12">
      <div class="card card-employee-task p-3">
        <div class="card-header">
          <h4 class="card-title">Tamamlanmış Görevler</h4>
        </div>
        <div class="card-body" dndDropzone class="task-column" (dndDrop)="onDrop($event, 4,doneTasks)"
          style="min-height: 400px; display: flex; flex-direction: column; justify-content: space-between;">
          <div>
            <div class="employee-task d-flex justify-content-between align-items-center"
              *ngFor="let item of doneTasks|evoTodo:filterText | paginate: { itemsPerPage: 5, currentPage: donepage,id:'done' }"
              [dndDraggable]="item" (dndCopied)="onDragged(item,doneTasks,'move')">
              <div class="media">
                <div class="media-body my-auto">
                  <span (click)="openTodo(item.id); $event.stopPropagation()" data-bs-toggle="modal"
                    data-bs-target="#updateModal">
                    <h6 class="mb-0">{{item.title}}</h6>
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="badge-wrapper mr-1">
                  <div class="badge badge-pill badge-light-success text-capitalize" *ngIf="item.typeId==1">Düşük
                  </div>
                  <div class="badge badge-pill badge-light-primary text-capitalize" *ngIf="item.typeId==2">Normal
                  </div>
                  <div class="badge badge-pill badge-light-danger text-capitalize" *ngIf="item.typeId==3">Yüksek
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <small class="text-muted mr-75">{{getTurkishMonth(item.startDate)}} {{item.startDate
                  |date: "dd"}}</small>
                  <div class="employee-task-chart-primary-1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="has-text-centered">
            <pagination-controls (pageChange)="donepage = $event" id="done"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="updateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Görev Detay</h5>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="name-vertical">Başlığını Giriniz</label>
          <input type="text" required id="name-vertical" class="form-control" name="txtTitle"
            placeholder="Görev Başlığı" [(ngModel)]="task.title" #txtTitle="ngModel" />
        </div>
        <div class="form-group position-relative">
          <div class="form-group">
            <label for="name-vertical">Başlangıç Tarihini Giriniz</label>
            <input type="datetime-local" required id="name-vertical" class="form-control" name="txtStartDate"
              placeholder="Başlangıç Tarihi" [ngModel]="task.startDate | date:'yyyy-MM-ddTHH:mm'"
              (ngModelChange)="task.startDate = $event" #txtStartDate />
          </div>
        </div>
        <div class="form-group position-relative">
          <label for="name-vertical">Bitiş Tarihini Giriniz</label>
          <input type="datetime-local" required id="name-vertical" class="form-control" name="txtEndDate"
            placeholder="Bitiş Tarihi" [ngModel]="task.endDate | date:'yyyy-MM-ddTHH:mm'"
            (ngModelChange)="task.endDate = $event" #txtEndDate />
        </div>

        <div class="form-group position-relative mb-2">
          <label for="name-vertical">Bir Görev Türü Seçiniz</label>
          <select class="form-control" ngModel required name="slcTask" [(ngModel)]="task.typeId" #slcTask="ngModel">
            <option *ngFor="let item of taskTypes" value="{{item.id}}">{{item.name}}
            </option>
          </select>
        </div>
        <div class="row col-12" *ngIf="!task.isReccuren else denemetemp">
          <!-- <div class="col-6" style="text-align: center;">
            <div class="custom-control custom-control-success custom-switch">
              <p class="mb-50" click>Tekrarlı Görev</p>
              <input type="checkbox" [checked]="task.isReccuren" (click)="changeRecurreType()"
                class="custom-control-input" id="customSwitchRecurre" />
              <label class="custom-control-label" for="customSwitchRecurre"></label>
            </div>
          </div> -->
        </div>
        <ng-template #denemetemp>
          <span *ngFor="let item of frequencyTypes" class="badge bg-info text-dark">
            <span *ngIf="item.id == recurre.frequencyTypeId">{{item.name}} Tekrarlı Görev </span>
          </span>
        </ng-template>
        <!-- <div *ngIf="task.isReccuren == false ">
          <div class="form-group position-relative">
            <label for="name-vertical">Tekrar Türünü Seçiniz</label>
            <select class="form-control" ngModel required name="slcFrequencyType" [(ngModel)]="recurre.frequencyTypeId"
              #slcFrequencyType="ngModel">
              <option *ngFor="let item of frequencyTypes" value="{{item.id}}">{{item.name}}
              </option>
            </select>
          </div>
        </div> -->
        <br>
        <div class="form-group mt-2">
          <div class="form-group">
            <label for="name-vertical">Açıklamasını Giriniz</label>
            <textarea type="text" id="name-vertical" class="form-control" name="txtDescription"
              [(ngModel)]="task.description" placeholder="Görevin Açıklaması" ngModel
              #txtDescription="ngModel"></textarea>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <div class="form-group my-1">
          <button type="button" data-bs-toggle="modal" data-bs-target="#deleteTask" class="btn btn-danger delete-btn delete-todo-item mr-1" *ngIf="isDataEmpty === false"
            rippleEffect>
            Sil
          </button>
          <button type="button" class="btn btn-success update-btn update-todo-item mr-1" *ngIf="isDataEmpty === false"
            (click)="updateTodo()" rippleEffect>
            Güncelle
          </button>
          <button type="button" id="updatemodalclose" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteTask" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">Görevi
          Sil</h1>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        Kaydı Silmek İstediğinize Emin Misiniz?
      </div>
      <div class="modal-footer">
        <div>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteTodo()"><i data-feather="trash"
              class="mr-50"></i>Sil</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="addTaskModal" tabindex="-1" aria-labelledby="addTaskModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addTaskModal">Görev Detay</h5>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
            class="mr-50"></i>Çıkış</button>
      </div>
      <div class="modal-body">
        <form #todoForm="ngForm">
          <div class="form-group">
            <label for="name-vertical">Başlığını Giriniz</label>
            <input type="text" required id="name-vertical" class="form-control" name="taskTitle"
              placeholder="Görev Başlığı" [(ngModel)]="task1.title" />
          </div>
          <div class="form-group position-relative">
            <div class="form-group">
              <label for="name-vertical">Başlangıç Tarihini Giriniz</label>
              <input type="datetime-local" required id="name-vertical" class="form-control" name="taskstartdate"
                placeholder="Başlangıç Tarihi" [ngModel]="task1.startDate | date:'yyyy-MM-ddTHH:mm'"
                (ngModelChange)="task1.startDate = $event" #taskstartdate />
            </div>
          </div>
          <div class="form-group position-relative">
            <label for="name-vertical">Bitiş Tarihini Giriniz</label>
            <input type="datetime-local" required id="name-vertical" class="form-control" name="txtEndDate"
              placeholder="Bitiş Tarihi" [ngModel]="task1.endDate | date:'yyyy-MM-ddTHH:mm'"
              (ngModelChange)="task1.endDate = $event" #txtEndDate />
          </div>

          <div class="form-group position-relative">
            <label for="name-vertical">Bir Görev Türü Seçiniz</label>
            <select class="form-control" ngModel required name="slcTask" [(ngModel)]="task1.typeId" #slcTask="ngModel">
              <option *ngFor="let item of taskTypes" value="{{item.id}}">{{item.name}}
              </option>
            </select>
          </div>
          <div class="row col-12">
            <div class="col-6" style="text-align: center;">
              <div class="custom-control custom-control-success custom-switch">
                <p class="mb-50" click>Tekrarlı Görev</p>
                <input type="checkbox" [checked]="task1.isReccuren" (click)="changeRecurreType()"
                  class="custom-control-input" id="customSwitchRecurre" />
                <label class="custom-control-label" for="customSwitchRecurre"></label>
              </div>
            </div>
          </div>

          <div *ngIf="task1.isReccuren==true">
            <div class="form-group position-relative">
              <label for="name-vertical">Tekrar Türünü Seçiniz</label>
              <select class="form-control" ngModel required name="slcFrequencyType"
                [(ngModel)]="recurre1.frequencyTypeId" #slcFrequencyType="ngModel">
                <option *ngFor="let item of frequencyTypes" value="{{item.id}}">{{item.name}}
                </option>
              </select>
            </div>
          </div>
          <br>
          <div class="form-group">
            <div class="form-group">
              <label for="name-vertical">Açıklamasını Giriniz</label>
              <textarea required type="text" id="name-vertical" class="form-control" name="txtDescription"
                [(ngModel)]="task1.description" placeholder="Görevin Açıklaması" ngModel
                #txtDescription="ngModel"></textarea>
              <button type="submit" class="btn btn-success add-todo-item mr-1" *ngIf="isDataEmpty === true"
                (click)="addTodo1()" rippleEffect>
                Kaydet
              </button>
              <button type="button" id="addmodalclose" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="form-group my-1">

        </div>
      </div>
    </div>
  </div>
</div>