<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Plan Oluşur</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #PlanForm="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlığını Giriniz</label>
                                            <input type="text" ngModel required id="name-vertical" class="form-control"
                                                name="txtTitle" placeholder="Plan Başlığı" #txtTitle="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTitle.invalid&& txtTitle.touched">Plan Başlığı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Açıklamasını Giriniz</label>
                                            <textarea type="text" ngModel id="name-vertical" class="form-control"
                                                name="txtDescription" placeholder="Plan Açıklaması" #txtDescription="ngModel" ></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlangıç Tarihini Giriniz</label>
                                            <input type="datetime-local" ngModel required id="name-vertical" class="form-control"
                                                name="txtStartDate" placeholder="Kullanıcı Soyadı" #txtStartDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtStartDate.invalid&& txtStartDate.touched">Plan Başlangıç Tarhi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Bitiş Tarihini Giriniz</label>
                                            <input type="datetime-local" ngModel required id="name-vertical" class="form-control"
                                                name="txtEndDate" placeholder="Mail Adresi" #txtEndDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtEndDate.invalid&& txtEndDate.touched">Plan Bitiş Tarhi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Türünü Seçiniz</label>
                                            <select class="form-control" ngModel required name="slcType" [(ngModel)]="type.id" #slcType="ngModel">
                                                <option *ngFor="let item of types" value="{{item.id}}">{{item.name}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-12" *ngIf="type.id==1 ||type.id==2">
                                        <div class="form-group">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" ngModel required name="slcPartner" [(ngModel)]="partner.id" #slcPartner="ngModel">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select class="form-control" ngModel required name="slcUser" [(ngModel)]="user.id" #slcUser="ngModel">
                                                <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}</option>
                                              </select>
                                        </div>
                                    </div>
                                  
                                    <div class="col-12">
                                        <button type="submit" rippleEffect class="btn btn-success mr-1"
                                            [disabled]="!PlanForm.valid" (click)="add(txtTitle.value,txtDescription.value,txtStartDate.value,txtEndDate.value)">Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>