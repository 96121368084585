<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Plan Güncelle</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #PlanForm="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlığını Giriniz</label>
                                            <input type="text" [(ngModel)]="plan.title" required id="name-vertical"
                                                class="form-control" name="txtTitle" placeholder="Plan Başlığı"
                                                #txtTitle="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtTitle.invalid&& txtTitle.touched">Kullanıcı Adı boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Açıklamasını Giriniz</label>
                                            <input type="text" [(ngModel)]="plan.description"  
                                                id="name-vertical" class="form-control" name="txtDescription"
                                                placeholder="Plan Açıklaması" #txtDescription="ngModel" />

                                            <!-- <small class="text-danger"
                                                *ngIf="txtDescription.invalid&& txtDescription.touched">Kullanıcı Adı
                                                boş
                                                bırakılamaz!</small> -->
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Başlangıç Tarihini Giriniz</label>
                                            <input type="datetime-local" [(ngModel)]="plan.startDate" required
                                                id="name-vertical" class="form-control" name="txtStartDate"
                                                placeholder="Kullanıcı Soyadı" #txtStartDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtStartDate.invalid&& txtStartDate.touched">Plan Başlangıç Tarhi
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Bitiş Tarihini Giriniz</label>
                                            <input type="datetime-local" [(ngModel)]="plan.endDate" required
                                                id="name-vertical" class="form-control" name="txtEndDate"
                                                placeholder="Mail Adresi" #txtEndDate="ngModel" />

                                            <small class="text-danger"
                                                *ngIf="txtEndDate.invalid&& txtEndDate.touched">Plan Bitiş Tarhi boş
                                                bırakılamaz!</small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group" *ngIf="plan.typeId==1 ||plan.typeId==2">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" [(ngModel)]="plan.partnerId" required
                                                name="slcPartner" #slcPartner="ngModel">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select class="form-control" [(ngModel)]="plan.userId" required
                                                name="slcUser" #slcUser="ngModel">
                                                <option *ngFor="let item of users" value="{{item.id}}">
                                                    {{item.firstName}} {{item.lastName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Plan Türünü Seçiniz</label>
                                            <select class="form-control" required name="slcType"
                                                [(ngModel)]="plan.typeId" #slcType="ngModel">
                                                <option *ngFor="let item of types" value="{{item.id}}">{{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" rippleEffect class="btn btn-success mr-1"
                                            [disabled]="!PlanForm.valid" (click)="update()">Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>