import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoPartner'
})
export class EvoPartnerPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const name = p.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const title=p.title.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const mailAddress=p.mailAddress.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const phoneNumber=String(p.phoneNumber).includes(filterText);
      
      return (name+mailAddress+title+phoneNumber);
    });
  }

}
