import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Project } from 'app/evo/models/project';
import { ProjectStatus } from 'app/evo/models/projectstatus';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-project-add-form',
  templateUrl: './evo-project-add-form.component.html',
  styles: [
  ]
})
export class EvoProjectAddFormComponent implements OnInit {

  constructor(
    private projectService: EvoProjectService,
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,

    private router: Router,
    private informationService: InformationService,
    private errorService:ErrorService,
    private decodeService:DecodeService,
  ) { }
  partners: Partner[] = [];

  project: Project = new Project;
  partner: Partner = new Partner();
    
  projectStatus:ProjectStatus=new ProjectStatus();
  projectStatuses: ProjectStatus[] = [
    { id: 1, name: "Devam Ediyor" },
    { id: 2, name: "Tamamlandı" }
  ];
  myId:number;
  ngOnInit(): void {
    this.myId=this.decodeService.getUserId();
    let item=this.decodeService.getRole();
    if(item!="Admin"){
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 3, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.partnerService.getList().subscribe((res:any)=>{
      this.partners = res.data;
    });
  }
  add(title: string, description: string) {

    this.project = {
      id: 0,
      title: title,
      description: description,
      partnerId: this.partner.id,
      partnerName: "",
      statusId: 1,
      statusName: "",

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };

    this.projectService.add(this.project).subscribe((item: any) => {
      this.informationService.add(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/projects"]);
    }, 1500);
    });
  }
}
