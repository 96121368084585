import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { EventRef } from 'app/evo/models/calender';
import { Partner } from 'app/evo/models/partner';
import { User } from 'app/evo/models/user';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';

@Component({
  selector: 'app-evo-plan-detail-event-sidebar',
  templateUrl: './evo-plan-detail-event-sidebar.component.html',
})
export class EvoPlanDetailEventSidebarComponent implements OnInit {

  @ViewChild('startDatePicker') startDatePicker;
  @ViewChild('endDatePicker') endDatePicker;

  public event: EventRef;
  public isDataEmpty;

  constructor(
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: EvoPlanService
  ) { }

  partners: Partner[] = [];
  users: User[] = [];

  partner: Partner = new Partner();
  user: User = new User();

  public selectLabel = [
    { label: 'Business', bullet: 'primary' },
    { label: 'Personal', bullet: 'danger' },
    { label: 'Family', bullet: 'warning' },
    { label: 'Holiday', bullet: 'success' },
    { label: 'ETC', bullet: 'info' }
  ];
  public selectGuest = [
    { name: 'Jane Foster', avatar: 'assets/images/avatars/1-small.png' },
    { name: 'Donna Frank', avatar: 'assets/images/avatars/3-small.png' },
    { name: 'Gabrielle Robertson', avatar: 'assets/images/avatars/5-small.png' },
    { name: 'Lori Spears', avatar: 'assets/images/avatars/7-small.png' },
    { name: 'Sandy Vega', avatar: 'assets/images/avatars/9-small.png' },
    { name: 'Cheryl May', avatar: 'assets/images/avatars/11-small.png' }
  ];
  public startDateOptions = {
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    enableTime: true
  };
  public endDateOptions = {
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    enableTime: true
  };

  toggleEventSidebar() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
  }
  addEvent(eventForm) {
    if (eventForm.valid) {
      eventForm.form.value.start = this.startDatePicker.flatpickrElement.nativeElement.children[0].value;
      eventForm.form.value.end = this.endDatePicker.flatpickrElement.nativeElement.children[0].value;

        
      //this._calendarService.addEvent(eventForm.form.value);
      this.toggleEventSidebar();  
    }
  }

  updateEvent() {
    this.toggleEventSidebar();
    this.event.start = this.startDatePicker.flatpickrElement.nativeElement.children[0].value;
    this.event.end = this.endDatePicker.flatpickrElement.nativeElement.children[0].value;
    //this._calendarService.postUpdatedEvent(this.event);
  }

  deleteEvent() {
    //this._calendarService.deleteEvent(this.event);
    this.toggleEventSidebar();
  }
  ngOnInit(): void {
    
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });

    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });

    this._calendarService.onCurrentEventChange.subscribe(response => {
      this.event = response;

      if (Object.keys(response).length > 0) {
        this.event = response;
        this.isDataEmpty = false;
        if (response.id === undefined) {
          this.isDataEmpty = true;
        }
      }
      else {
        this.event = new EventRef();

        setTimeout(() => {
          this.startDatePicker.flatpickr.clear();
          this.endDatePicker.flatpickr.clear();
        });
        this.isDataEmpty = true;
      }
    });
  }
}