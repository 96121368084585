import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Permission } from 'app/evo/models/permission';
import { PermissionUser } from 'app/evo/models/permissionuser';
import { Priority } from 'app/evo/models/priority';
import { StatusFilter } from 'app/evo/models/statusfilter';
import { Ticket } from 'app/evo/models/ticket';
import { TicketUser } from 'app/evo/models/ticketuser';
import { TicketUserType } from 'app/evo/models/ticketusertype';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoTicketService } from 'app/evo/services/evo-ticket.service';
import { EvoTicketUserService } from 'app/evo/services/evo-ticketuser.service';
import { EvoTicketUserTypeService } from 'app/evo/services/evo-ticketusertype.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-evo-ticket-list-forcreator',
    templateUrl: './evo-ticket-list-forcreator.component.html',
    styles: [
    ]
})

export class EvoTicketListForCreator {
    @ViewChild(DatatableComponent) table: DatatableComponent;

    private tempData = [];
  
    filterText: string = "";
    fromDate: Date;
    toDate: Date;
    deletedTicketId: number;
  
    partnerId: number = 0;
    priorityId: number = 0;
    statusId: number = 0;
  
    public customFooterDPdata: NgbDateStruct;
    public basicTP = { hour: 13, minute: 30 };
    public basicSelectedOption: number = 10;
  
    public selectCustomHeaderFooterSelected = [];
  
    public hoveredDate: any | null = null;
    public fromDateExample: any = null;
    public toDateExample: any = null;
  
    priorities: Priority[] = [
      { id: 1, name: "Yüksek" },
      { id: 2, name: "Normal" },
      { id: 3, name: "Düşük" },
    ];
    public selectCustomHeaderFooter = this.priorities;
    userRole: string;
    userName: string;
    myId: number;
  
    followerInformation: boolean = false;
    firstLevelRestriction: boolean;
    secondLevelRestriction: boolean;
  
    tickets: Ticket[];
    users: User[] = [];
    consultants: User[] = [];
    partners: Partner[] = [];
    ticketUsers: TicketUser[];
    permissions: Permission[] = [];
    selectedPartners: Partner[] = [];
    selectedPriorities: Priority[] = [];
    permissionUsers: PermissionUser[] = [];
    ticketUserTypesByTicketId: TicketUserType[] = [];
    selectedStatuses: any[] = [];
  
    ticket: Ticket = new Ticket();
    user: User = new User();
    partner: Partner = new Partner();
    ticketUser: TicketUser = new TicketUser();
    ticketUserType: TicketUserType = new TicketUserType();
    date: Date = new Date();
  
    statusFilter: StatusFilter[] = [
      // { id: "1-1", name: "Yeni Oluşturuldu", typeName: "Talep Durumu" },
      // { id: "2-1", name: "Danışmandan Cevap Bekleniyor", typeName: "Talep Durumu" },
      // { id: "8-1", name: "Müşteriden Cevap Bekleniyor", typeName: "Talep Durumu" },
      // { id: "10-1", name: "Test Sürecinde", typeName: "Talep Durumu" },
      // { id: "4-1", name: "Tamamlandı", typeName: "Talep Durumu" },
      // { id: "5-1", name: "İptal Edildi", typeName: "Talep Durumu" },
  
      { id: "4-2", name: "Faturalanmadı", typeName: "Fatura Durumu" },
      { id: "5-2", name: "Faturalandı", typeName: "Fatura Durumu" },
      { id: "6-2", name: "Feragat Edildi", typeName: "Fatura Durumu" },
  
      { id: "1-3", name: "Destek Talebi", typeName: "Talep Tipi" },
      { id: "2-3", name: "Müşteri Planı", typeName: "Talep Tipi" },
      { id: "3-3", name: "Proje Planı", typeName: "Talep Tipi" },
    ];
  
    constructor(
      private ticketService: EvoTicketService,
      private ticketUserService: EvoTicketUserService,
      private userService: EvoUserService,
      private partnerService: EvoPartnerService,
      private partnerUserService: EvoPartnerUserService,
      private ticketUserTypeService: EvoTicketUserTypeService,
      private permissionService: EvoPermissionService,
      private permissionUserService: EvoPermissionUserService,
  
      public formatter: NgbDateParserFormatter,
      private informationService: InformationService,
      private helperService: HelperService,
      private decodeService: DecodeService,
      private errorService: ErrorService,
    ) { }
  
    public dateTimeOptions: FlatpickrOptions = {
      altInput: true,
      enableTime: true
    };
  
    ngOnInit(): void {
      this.userName = this.decodeService.getUserName();
      this.userRole = this.decodeService.getRole();
      this.myId = this.decodeService.getUserId();
      this.ticketUserService.getList().subscribe((items: any) => {
        this.ticketUsers = items.data;
      });
  
      this.userService.getList().subscribe((items: any) => {
        this.users = items.data;
        this.consultants = items.data;
        const index = this.consultants.findIndex(user => user.id === this.myId);
        if (index !== -1) {
          this.consultants.splice(index, 1);
        }
      });
  
      this.partnerService.getList().subscribe((items: any) => {
        this.partners = items.data;
      });
      this.getPermissions();
    }
    ticketsCount: number;
    list(){
      this.ticketService.getListByCreatorId(this.myId).subscribe((items: any) => {
        this.tickets = items.data; 
        console.table(items.data)
        this.ticketsCount = this.tickets.length;
        this.informationService.list(items.message)
      })
    }
    // list() {
    //   if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
    //     (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
    //     this.ticketService.getListByCreatorId(this.myId).subscribe((items: any) => {
    //       this.tickets = items.data; 
    //       this.ticketsCount = this.tickets.length;
    //       this.informationService.list(items.message)
    //     }, (err: any) => {
    //       this.errorService.errorHandler(err);
    //     });
    //   }
  
    //   if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
    //     (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
    //     this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
    //       this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
    //         this.tickets = res.data;
    //         this.ticketsCount = this.tickets.length;
    //         this.informationService.list(res.message)
    //       }, (err: any) => {
    //         this.errorService.errorHandler(err);
    //       })
    //     });
    //   }
    //   if (this.firstLevelRestriction && !this.secondLevelRestriction) {
    //     this.userService.get(this.myId).subscribe((res: any) => {
    //       if (res.data.partnerId == null) {
    //         this.informationService.warning("Kullanıcıya Verilen İzinlerde Tedarikçi Ataması Yapılmamıştır.");
    //         return;
    //       }
    //       else {
    //         this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
    //           this.tickets = res.data;
    //           this.ticketsCount = this.tickets.length;
    //           this.informationService.list(res.message)
    //         }, (err: any) => {
    //           this.errorService.errorHandler(err);
    //         })
    //       }
    //     });
    //   }
    // }
  
    getPermissions() {
      this.permissionService.getList().subscribe((items: any) => {
        this.permissions = items.data;
        this.checkPermissionUsers();
      });
    }
  
    checkPermissionUsers() {
      this.permissionUserService.getPermissionUserListByUserId(this.myId).subscribe((items: any) => {
        this.permissionUsers = items.data;
  
        if (this.permissionUsers.some(user => user.permissionId === 1)) {
          this.firstLevelRestriction = true;
        } else {
          this.firstLevelRestriction = false;
        }
  
        if (this.permissionUsers.some(user => user.permissionId === 2)) {
          this.secondLevelRestriction = true;
        } else {
          this.secondLevelRestriction = false;
        }
        this.list();
      });
    }
  
  
    delete(id: number) {
      this.ticketService.get(id).subscribe((item: any) => {
        item.data.deletedBy = this.myId;
        item.data.updatedBy = 0;
  
        this.ticketService.delete(item.data).subscribe((item: any) => {
          this.informationService.delete(item.message)
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            this.list();
          }, 1500);
        });
      });
    }
  
    saveTicket(id: number) {
      this.ticketService.get(id).subscribe((item: any) => {
        this.ticket = item.data;
      });
    }
  
    getTicketUserTypeList(ticketId: number) {
      this.ticketUserTypeService.getListByTicketId(ticketId).subscribe((res: any) => {
        this.ticketUserTypesByTicketId = res.data;
        for (let item = 0; item < this.ticketUserTypesByTicketId.length; item++) {
          if (this.myId == this.ticketUserTypesByTicketId[item].userId) {
            this.followerInformation = true;
            this.ticketUserType = this.ticketUserTypesByTicketId[item];
            break;
          }
        }
      });
    }
    addTicketUser() {
      let flag = 0;
  
      for (let i = 0; i < this.ticketUserTypesByTicketId.length; i++) {
  
        if (this.ticketUserTypesByTicketId[i].userId == this.user.id) {
          flag = 1;
        }
      }
  
      if (flag == 0) {
        this.ticketUser = {
          id: 0,
          ticketId: this.ticket.id,
          userId: this.user.id,
          typeId: 0,
          ticketUserTypeId: 6,
          typeName: "",
          userFirstName: "",
          userLastName: "",
          trackingNumber: "",
          subject: "",
          priorityId: 0,
          statusId: 0,
          departmentId: 0,
          departmentName: "",
          partnerId: 0,
          partnerName: "",
          partnerTicketNo: "",
          information: "",
          projectId: 0,
          projectName: "",
          ticketTypeId: 0,
          ticketTypeName: "",
          expectedTime: 0,
          moduleId: 0,
          moduleName: "",
  
          createdBy: this.myId,
          createdDate: new Date(),
          createdByUserName: "",
          updatedDate: new Date(),
          updatedBy: 0,
          deletedBy: 0,
        }
        this.ticketUserService.add(this.ticketUser).subscribe((item: any) => {
          this.informationService.add(item.message)
  
          this.ticket = {
            id: this.ticket.id,
            trackingNumber: this.ticket.trackingNumber,
            subject: this.ticket.subject,
            priorityId: this.ticket.priorityId,
            projectId: this.ticket.projectId,
            projectName: "",
            statusId: 2,
            departmentName: "",
            partnerId: 0,
            partnerName: "",
            partnerTicketNo: this.ticket.partnerTicketNo,
            typeId: this.ticket.typeId,
            typeName: "",
            billTypeId: this.ticket.billTypeId,
            billTypeName: "",
  
            moduleId: this.ticket.moduleId,
            moduleName: this.ticket.moduleName,
  
            answerTypeId: this.ticket.answerTypeId,
            answerTypeName: "",
            expectedTime: this.ticket.expectedTime,
            departmentId: this.ticket.departmentId,
            createdBy: this.ticket.createdBy,
            createdDate: this.ticket.createdDate,
            createdByUserName: "",
            updatedBy: this.myId,
            deletedBy: 0,
          }
          this.ticketService.update(this.ticket).subscribe((res: any) => {
          });
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        });
      }
      else {
        this.informationService.warning("Bu kullanıcı zaten eklenmiş");
      }
    }
  
    tableByFiltersId() {
      if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.ticketService.getListByCreatorId(this.myId).subscribe((items: any) => {
          let filteredItems = items.data;
  
          if (this.selectedPartners.length > 0) {
            filteredItems = filteredItems.filter(item => this.selectedPartners.includes(item.partnerId));
          }
          if (this.selectedPriorities.length > 0) {
            filteredItems = filteredItems.filter(item => this.selectedPriorities.includes(item.priorityId));
          }
          if (this.selectedStatuses.length > 0) {
            const filteredBy = {
              status: [],
              billType: [],
              ticketType: []
            };
  
            for (const selectedStatus of this.selectedStatuses) {
              const [id, type] = selectedStatus.split("-").map(Number);
  
              switch (type) {
                case 2:
                  filteredBy.billType.push(id);
                  break;
                case 3:
                  filteredBy.ticketType.push(id);
                  break;
              }
            }
  
  
            if (filteredBy.billType.length > 0) {
              filteredItems = filteredItems.filter(item => filteredBy.billType.includes(item.billTypeId));
            }
  
            if (filteredBy.ticketType.length > 0) {
              filteredItems = filteredItems.filter(item => filteredBy.ticketType.includes(item.typeId));
            }
          }
          if (this.fromDate && this.toDate) {
            filteredItems = filteredItems.filter(item =>
              new Date(item.createdDate) >= new Date(this.fromDate) &&
              new Date(item.createdDate) <= new Date(this.toDate)
            );
          }
  
          this.tickets = filteredItems;
        });
      }
      if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
        (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
        this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
          this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
            let filteredItems = res.data;
  
            if (this.selectedPriorities.length > 0) {
              filteredItems = filteredItems.filter(item => this.selectedPriorities.includes(item.priorityId));
            }
            if (this.selectedStatuses.length > 0) {
              const filteredBy = {
                status: [],
                billType: [],
                ticketType: []
              };
  
              for (const selectedStatus of this.selectedStatuses) {
                const [id, type] = selectedStatus.split("-").map(Number);
  
                switch (type) {
                  case 2:
                    filteredBy.billType.push(id);
                    break;
                  case 3:
                    filteredBy.ticketType.push(id);
                    break;
                }
              }
              if (filteredBy.billType.length > 0) {
                filteredItems = filteredItems.filter(item => filteredBy.billType.includes(item.billTypeId));
              }
  
              if (filteredBy.ticketType.length > 0) {
                filteredItems = filteredItems.filter(item => filteredBy.ticketType.includes(item.typeId));
              }
            }
            if (this.fromDate && this.toDate) {
              filteredItems = filteredItems.filter(item =>
                new Date(item.createdDate) >= new Date(this.fromDate) &&
                new Date(item.createdDate) <= new Date(this.toDate)
              );
            }
  
            this.tickets = filteredItems;
          });
        });
      }
      if (this.firstLevelRestriction && !this.secondLevelRestriction) {
        this.userService.get(this.myId).subscribe((res: any) => {
          this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
            let filteredItems = res.data;
  
            if (this.selectedPriorities.length > 0) {
              filteredItems = filteredItems.filter(item => this.selectedPriorities.includes(item.priorityId));
            }
            if (this.selectedStatuses.length > 0) {
              const filteredBy = {
                status: [],
                billType: [],
                ticketType: []
              };
  
              for (const selectedStatus of this.selectedStatuses) {
                const [id, type] = selectedStatus.split("-").map(Number);
  
                switch (type) {
                  case 2:
                    filteredBy.billType.push(id);
                    break;
                  case 3:
                    filteredBy.ticketType.push(id);
                    break;
                }
              }
              if (filteredBy.billType.length > 0) {
                filteredItems = filteredItems.filter(item => filteredBy.billType.includes(item.billTypeId));
              }
  
              if (filteredBy.ticketType.length > 0) {
                filteredItems = filteredItems.filter(item => filteredBy.ticketType.includes(item.typeId));
              }
            }
            if (this.fromDate && this.toDate) {
              filteredItems = filteredItems.filter(item =>
                new Date(item.createdDate) >= new Date(this.fromDate) &&
                new Date(item.createdDate) <= new Date(this.toDate)
              );
            }
  
            this.tickets = filteredItems;
          });
        });
      }
  
    }
  
    checkType(value: any): value is NgbDate {
      return value instanceof NgbDate;
    }
  
    tableByDates() {
      var tempDate : any = {};
      var datePipe = new DatePipe("en-US");
  
      if (this.fromDateExample instanceof NgbDate){
        tempDate = {day: this.fromDateExample.day , month:this.fromDateExample.month -1, year:this.fromDateExample.year};
      }
      else{
        var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
        tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear()}
      }
  
      this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);
  
      if (this.toDateExample == null)
        this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
      else
        var tempToDate : any = {};
  
        if (this.toDateExample instanceof NgbDate){
          tempToDate = {day: this.toDateExample.day , month:this.toDateExample.month -1, year:this.toDateExample.year};
        }
        else{
          var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
          tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear()}
        }
  
        this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);
  
      if (!this.fromDate || !this.toDate) {
        this.list();
      } else {
        if ((this.userRole == "Admin" || this.userRole == "Consultant" || this.userRole == "ConsultantUser") &&
          (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
          this.ticketService.getListByCreatorId(this.myId).subscribe((items: any) => {
            this.tickets = items.data.filter(item =>
              new Date(item.createdDate) >= this.fromDate &&
              new Date(item.createdDate) <= this.toDate
            );
          });
        }
  
        if ((this.userRole == "Customer" || this.userRole == "CustomerUser") &&
          (this.firstLevelRestriction == false && this.secondLevelRestriction == false)) {
          this.partnerUserService.getByUserId(this.myId).subscribe((res: any) => {
            this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
              this.tickets = res.data.filter(item =>
                new Date(item.createdDate) >= this.fromDate &&
                new Date(item.createdDate) <= this.toDate
              );
            });
          });
        }
        if (this.firstLevelRestriction && !this.secondLevelRestriction) {
          this.userService.get(this.myId).subscribe((res: any) => {
            this.partnerUserService.getMyPartnerTicketList(res.data.partnerId).subscribe((res: any) => {
              this.tickets = res.data.filter(item =>
                new Date(item.createdDate) >= this.fromDate &&
                new Date(item.createdDate) <= this.toDate
              );
            });
          });
        }
      }
      this.tableByFiltersId();
    }
  
    isHovered(date: NgbDate) {
      return (
        this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
      );
    }
    isInside(date: NgbDate) {
      return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
    }
  
    isRange(date: NgbDate) {
      return (
        date.equals(this.fromDateExample) ||
        (this.toDateExample && date.equals(this.toDateExample)) ||
        this.isInside(date) ||
        this.isHovered(date)
      );
    }
  
    onDateSelection(date: NgbDate) {
      if (!this.fromDateExample && !this.toDateExample) {
        this.fromDateExample = date;
      } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
        this.toDateExample = date;
      } else {
        this.toDateExample = null;
        this.fromDateExample = date;
      }
      this.tableByDates();
    }
  
    exportExcel() {
      let element = document.getElementById("excel-table");
      let title = "Yeni Oluşturulmuş Talepler";
      this.helperService.exportExcel(element, title);
    }
}