import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoTask'
})
export class EvoTaskPipe implements PipeTransform {

  transform(value: any, filterText: string): any {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const title = p.title.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const description = p.description.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (description+title);
    });
  }

}
