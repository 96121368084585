import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-module-update-form',
  templateUrl: './evo-module-update-form.component.html',
})
export class EvoModuleUpdateFormComponent implements OnInit {

  module: Module = new Module();
  users:User[]=[];
  id: number;
  myId:number;

  constructor(
    private moduleService: EvoModuleService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,
    
    private router: Router,
    private route: ActivatedRoute,
    private decodeService:DecodeService,
    private informationService: InformationService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    let item=this.decodeService.getRole();
    this.myId=this.decodeService.getUserId();
    this.id = this.route.snapshot.params['Id'];

    if(item!="Admin"){
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 7, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.moduleService.get(this.id).subscribe((item: any) => {
      this.module = item.data;
    });
    this.userService.getList().subscribe((items: any) => {
      this.users = items.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  update() {
    this.module.updatedBy=this.decodeService.getUserId();
    this.module.deletedBy=0;
    this.moduleService.update(this.module).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/modules"]);
      }, 1000);
    });
  }

}
