<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Kullanıcı Oluştur</h4>

                        <div class="card-body">
                            <form [formGroup]="userForm" (ngSubmit)="add()">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Ad</label>
                                            <input type="text" formControlName="txtFirstName" class="form-control"
                                                placeholder="Kullanıcının Adı" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtFirstName').hasError('required') && userForm.get('txtFirstName').touched">
                                                Kullanıcının Adı boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Soyad</label>
                                            <input type="text" formControlName="txtLastName" class="form-control"
                                                placeholder="Kullanıcının Soyadı" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtLastName').hasError('required') && userForm.get('txtLastName').touched">
                                                Kullanıcının Soyadı boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Başlığı</label>
                                            <input type="text" formControlName="txtTitle" class="form-control"
                                                placeholder="Kullanıcının Başlığı" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtTitle').hasError('required') && userForm.get('txtTitle').touched">
                                                Kullanıcının Başlığı boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Adı</label>
                                            <input type="text" formControlName="txtUserName" class="form-control"
                                                placeholder="Kullanıcı Adı" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtUserName').hasError('required') && userForm.get('txtUserName').touched">
                                                Kullanıcı Adı boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcın Telefon Numarası</label>
                                            <input type="text" formControlName="txtPhoneNumber" class="form-control"
                                                placeholder="Telefon Numarası" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtPhoneNumber').hasError('required') && userForm.get('txtPhoneNumber').touched">
                                                Telefon Numarası boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcın Mail Adresi</label>
                                            <input type="text" formControlName="txtMailAddress" class="form-control"
                                                placeholder="Mail Adresi" />
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtMailAddress').hasError('required') && userForm.get('txtMailAddress').touched">
                                                Mail Adresi boş bırakılamaz!
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtMailAddress').hasError('email')">
                                                Geçerli bir mail adresi giriniz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Tipi</label>
                                            <select class="form-control" formControlName="slcUserType">
                                                <option *ngFor="let item of userTypes" [value]="item.id">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('slcUserType').hasError('required') && userForm.get('slcUserType').touched">
                                                Kullanıcı Tipi boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12" *ngIf="userForm.get('slcUserType').value == 2">
                                        <div class="form-group">
                                            <label for="tracking-number-vertical">Tedarikçi Seçiniz</label>
                                            <select class="form-control" formControlName="slcSupplier">
                                                <option *ngFor="let item of suppliers" [value]="item.id">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('slcSupplier').hasError('required') && userForm.get('slcSupplier').touched">
                                                Bir Tedarikçi seçmeniz gerekiyor!
                                            </small>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcın Cinsiyeti</label>
                                            <select class="form-control" formControlName="slcGender">
                                                <option *ngFor="let item of genders" [value]="item.id">{{item.name}}
                                                </option>
                                            </select>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('slcGender').hasError('required') && userForm.get('slcGender').touched">
                                                Kullanıcının Cinsiyeti boş bırakılamaz!
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Bu Yıl için İzin Günü Sayısı</label>
                                            <input type="number" formControlName="txtVacationCount" class="form-control"
                                                placeholder="Bu Yıl için İzin Günü Sayısı" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcının Şifresi</label>
                                            <div class="input-group form-password-toggle input-group-merge">

                                                <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                                    formControlName="txtPassword" class="form-control"
                                                    placeholder="Kullanıcı Şifresi" />
                                                <div class="input-group-append">
                                                    <span class="input-group-text cursor-pointer"><i
                                                            class="feather font-small-4" [ngClass]="{
                                'icon-eye-off': passwordTextTypeNew,
                                'icon-eye': !passwordTextTypeNew
                              }" (click)="togglePasswordTextTypeNew()"></i></span>
                                                </div>


                                            </div>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtPassword').hasError('required') && userForm.get('txtPassword').touched">
                                                Kullanıcı Şifresi boş bırakılamaz!
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtPassword').hasError('minlength')">
                                                Şifre en az 8 karakter olmalıdır.
                                            </small>
                                            <small class="text-danger"
                                                *ngIf="userForm.get('txtPassword').hasError('pattern')">
                                                Şifre en az bir büyük harf, bir küçük harf ve bir rakam içermelidir.
                                            </small>
                                        </div>

                                    </div>

                                    <div class="col-12">
                                        <button type="submit" [disabled]="userForm.invalid" rippleEffect
                                            class="btn btn-success mr-1">Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>