<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="basic-vertical-layouts">
        <div class="row match-height">
          <div class="col-md-2"></div>
          <div class="col-md-8 col-12">
            <core-card-snippet>
              <h4 class="card-title">Yetki Güncelle</h4>
              <div class="card-body">
                <form class="form form-vertical" #OperationClaimForm="ngForm">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name-vertical">Yetkinin Adını Giriniz</label>
                        <input type="text" [(ngModel)]="operationClaim.name" required id="name-vertical" class="form-control" name="txtName"
                          placeholder="Yetki adı" #txtName="ngModel" />
                        <small class="text-danger" *ngIf="txtName.invalid&& txtName.touched">Yetki adı boş
                          bırakılamaz!</small>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="name-vertical">Açıklamasını Giriniz</label>
                        <input type="text" [(ngModel)]="operationClaim.description" required id="description-vertical" class="form-control" name="txtDescription"
                          placeholder="Açıklaması" #txtDescription="ngModel" />
  
                        <small class="text-danger" *ngIf="txtDescription.invalid&& txtDescription.touched">Açıklama boş
                          bırakılamaz!</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" rippleEffect class="btn btn-success" [disabled]="!OperationClaimForm.valid"
                      (click)="update()">Kaydet</button>
                  </div>
                </form>
              </div>
            </core-card-snippet>
          </div>
        </div>
      </section>
    </div>
  </div>