import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { GoalStatus } from '../models/goalstatus';

@Injectable({
  providedIn: 'root'
})
export class EvoGoalStatusService {
  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList(): Observable<GoalStatus[]> {
    return this.httpClient.get<GoalStatus[]>(this.baseApiUrl + 'GetGoalStatusList');
  }
  get(id: number): Observable<GoalStatus> {
    return this.httpClient.get<GoalStatus>(this.baseApiUrl + 'GetGoalStatus/' + id);
  }
  add(goalStatus: GoalStatus): Observable<GoalStatus> {
    return this.httpClient.post<GoalStatus>(this.baseApiUrl + 'AddGoalStatus/', goalStatus);
  }
  update(goalStatus: GoalStatus): Observable<GoalStatus> {
    return this.httpClient.post<GoalStatus>(this.baseApiUrl + "UpdateGoalStatus/", goalStatus);
  }
  delete(id: number): Observable<GoalStatus> {
    return this.httpClient.post<GoalStatus>(this.baseApiUrl + "DeleteGoalStatus/" + id, "");
  }

  

}
