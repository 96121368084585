import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { MailSetting } from '../models/mailSetting';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MailSettingService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList(): Observable<MailSetting[]> {
    return this.httpClient.get<MailSetting[]>(this.baseApiUrl + 'GetMailSettingList');
  }
  getById(id: number): Observable<MailSetting> {
    return this.httpClient.get<MailSetting>(this.baseApiUrl + "GetMailSetting/" + id);
  }
  add(mailSetting: MailSetting): Observable<MailSetting> {
    return this.httpClient.post<MailSetting>(this.baseApiUrl + "AddMailSetting", mailSetting)
  }
  update(mailSetting: MailSetting): Observable<MailSetting> {
    return this.httpClient.post<MailSetting>(this.baseApiUrl + "UpdateMailSetting/", mailSetting);
  }
  delete(id: number): Observable<MailSetting> {
    return this.httpClient.post<MailSetting>(this.baseApiUrl + "DeleteMailSetting/" + id, "");
  }
  
}
