export class TicketUser{
    id:number;
    ticketId:number;
    userId:number;
    typeId:number;
    ticketUserTypeId:number;
    typeName:string;
    userFirstName:string;
    userLastName:string;
    trackingNumber:string;
    subject:string;
    priorityId:number;
    statusId:number;
    ticketTypeId:number;
    ticketTypeName:string;
    departmentId:number;
    departmentName:string;
    partnerName:string;
    partnerId:number;
    information:string;
    projectId:number;
    projectName:string;
    moduleId:number;
    moduleName:string;
    expectedTime:number;
    partnerTicketNo:string;
    createdBy:number;
    createdDate:Date;
    createdByUserName:string;
    updatedDate:Date;
    
    updatedBy:number;
    deletedBy:number;
    
}