import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Project } from 'app/evo/models/project';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-project-list',
  templateUrl: './evo-project-list.component.html',
  styles: [
  ]
})
export class EvoProjectListComponent implements OnInit {

  constructor(private projectService: EvoProjectService,
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,
    
    private informationService: InformationService,
    private decodeService: DecodeService,
    private router: Router,
    private helperService: HelperService,
    private errorService: ErrorService,
  ) { }
  public basicSelectedOption: number = 10;
  myId:number;
  userRole: string;
  partnerId: number = 0;

  projects: Project[];
  partners: Partner[] = [];

  partner: Partner = new Partner();
  filterText: string = "";
  deletedProjectId: number;

  ngOnInit(): void {
    this.userRole = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });
    let item = this.decodeService.getRole();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 3, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
    }
  }
  list() {
    this.projectService.getList().subscribe((items: any) => {
      this.projects = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.projectService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
      this.projectService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1500);
      });
    });
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Projeler";
    this.helperService.exportExcel(element, title);
  }


  tableByFiltersId() {
    this.projectService.getList().subscribe((items: any) => {
      let filteredItems = items.data;

      if (this.partnerId != 0) {
        filteredItems = filteredItems.filter(item => item.partnerId == this.partnerId);
      }
      this.projects = filteredItems;
    });
  }
}
