export class Ticket{
    id:number;
    trackingNumber:string;
    subject:string;
    priorityId:number;
    projectId:number;
    projectName:string;
    statusId:number;
    typeId:number;
    typeName:string;
    billTypeId:number;
    billTypeName:string;
    moduleId:number;
    moduleName:string;
    answerTypeId:number;
    answerTypeName:string;
    departmentName:string;
    partnerName:string;
    partnerId:number;
    departmentId:number;
    expectedTime:number;
    partnerTicketNo:string;
    createdBy:number;
    createdDate:Date;
    createdByUserName:string;
    
    updatedBy:number;
    deletedBy:number;
}