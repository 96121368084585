import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class DecodeService {

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor() { }

  getUserId(): number{
    let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
    var userId=Object.keys(decode).filter(p=>p.endsWith("/nameidentifier"))[0];
    return +decode[userId];  
  }

  getUserName(): string{
    let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
    var name = Object.keys(decode).filter(p=> p.endsWith("/name"))[0];
    return decode[name];
  }

  getRole():string{
    let decode = this.jwtHelper.decodeToken(localStorage.getItem("adminToken"));
    var role = Object.keys(decode).filter(p=> p.endsWith("/role"))[0];
    return decode[role];
  }


}
