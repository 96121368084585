import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Goal } from '../models/goal';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvoGoalService {
  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.baseApiUrl+'GetGoalList/' );
  }
  getGoalListByStatusId(statusId:number){
    return this.httpClient.get(this.baseApiUrl+'GetGoalListByStatusId/'+statusId );
  }
  getStatusList(){
    return this.httpClient.get(this.baseApiUrl+'GetGoalStatusList/' );
  }
  get(id: number): Observable<Goal> {
    return this.httpClient.get<Goal>(this.baseApiUrl + 'GetGoal/' + id);
  }
  add(goal: Goal): Observable<Goal> {
    return this.httpClient.post<Goal>(this.baseApiUrl + 'AddGoal/', goal);
  }
  update(goal: Goal): Observable<Goal> {
    return this.httpClient.post<Goal>(this.baseApiUrl + "UpdateGoal/", goal);
  }
  delete(id: number): Observable<Goal> {
    return this.httpClient.post<Goal>(this.baseApiUrl + "DeleteGoal/" + id, "");
  }
  
}
