import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  // {
  //   id: 'dashboard',
  //   title: 'Dashboard',
  //   type: 'collapsible',
  //   // role: ['Admin'], //? To hide collapsible based on user role
  //   icon: 'home',
  //   badge: {
  //     title: '2',
  //     translate: 'MENU.DASHBOARD.BADGE',
  //     classes: 'badge-light-warning badge-pill'
  //   },
  //   children: [
  //     {
  //       id: 'analytics',
  //       title: 'Analytics',
  //       translate: 'MENU.DASHBOARD.ANALYTICS',
  //       type: 'item',
  //      // role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
  //       icon: 'circle',
  //       url: 'dashboard/analytics'
  //     },
  //     {
  //       // If role is not assigned will be display to all
  //       id: 'ecommerce',
  //       title: 'eCommerce',
  //       translate: 'MENU.DASHBOARD.ECOMMERCE',
  //       type: 'item',
  //       icon: 'circle',
  //       url: 'dashboard/ecommerce'
  //     }
  //   ]
  // },


  // {
  //   id: 'tickets',
  //   title: 'Tüm Talepler',
  //   type: 'item',
  //   icon: 'circle',
  //   classes: "font-small-4 mt-2",
  //   url: '/home/tickets'
  // },
  // {
  //   id: 'mytickets',
  //   title: 'Benim Bildirdiklerim',
  //   type: 'item',
  //   icon: 'circle',
  //   classes: "font-small-4 ",
  //   url: '/home/mytickets'
  // },
  // {
  //   id: 'myticketlist',
  //   title: 'Benim Taleplerim',
  //   type: 'item',
  //   icon: 'circle',
  //   classes: "font-small-4",
  //   url: '/home/tickets'
  // },
  // {
  //   id: 'no-start',
  //   title: 'Başlanmayan Talepler',
  //   type: 'item',
  //   icon: 'circle',
  //   classes: "font-small-4 mb-5",
  //   url: '/home/no-start'
  // },



  {
    id: 'ticket',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'tickets',
        title: 'Talepler',
        type: 'collapsible',
        icon: 'server',
        children: [
          {
            id: 'ticketlist',
            title: 'Bütün Talepler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/tickets'
          },
          {
            id: 'myticketlist',
            title: 'Taleplerim',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/tickets'
          },
          {
            id: 'mytickets',
            title: 'Benim Bildirdiklerim',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/mytickets'
          },

          {
            id: 'no-start',
            title: 'Başlanmayan Talepler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/no-start'
          },
        ]
      }]
  },
  // {
  //   id: 'ticketlist',
  //   title: 'Bütün Talepler',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'server',
  //   url: '/home/tickets'
  // },
  // {
  //   id: 'myticketlist',
  //   title: 'Taleplerim',
  //   type: 'item',
  //   classes: "pull-up",
  //   icon: 'eye',
  //   url: '/home/tickets'
  // },
  {
    id: 'ticketactivitylist',
    title: 'Aktiviteler',
    type: 'item',
    classes: "pull-up",
    icon: 'clock',
    url: '/home/ticketactivities'
  },

  {
    id: 'userlist',
    title: 'Danışmanlar',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/users'
  },

  {
    id: 'partneruserlist',
    title: 'Kullanıcılarım',
    type: 'item',
    classes: "pull-up",
    icon: 'users',
    url: '/home/partnerusers/'
  },
  {
    id: 'planlist',
    title: 'Planlar',
    type: 'item',
    classes: "pull-up",
    icon: 'calendar',
    url: '/home/plans'
  },
  {
    id: 'operationclaimlist',
    title: 'Yetkiler',
    type: 'item',
    classes: "pull-up",
    icon: 'key',
    url: '/home/operationclaims'
  },
  {
    id: 'expenelist',
    title: 'Masraflar',
    type: 'item',
    classes: "pull-up",
    icon: 'credit-card',
    url: '/home/expenses'
  },
  {
    id: 'goalList',
    title: 'Hedef Kartları',
    type: 'item',
    classes: "pull-up",
    icon: 'compass',
    url: '/home/goals'
  },
  {
    id: 'competenceList',
    title: 'Yetkinlikler',
    type: 'item',
    classes: "pull-up",
    icon: 'bar-chart-2',
    url: '/home/competences'
  },

  {
    id: 'partner',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'partnersCollapsible',
        title: 'Cari',
        type: 'collapsible',
        icon: 'briefcase',
        children: [
          {
            id: 'partnerlist',
            title: 'Cariler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/partners'
          },
          {
            id: 'projectlist',
            title: 'Projeler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/projects'
          },
          {
            id: 'contractlist',
            title: 'Sözleşmeler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/contracts'
          },
        ]
      }]
  },
  {
    id: 'definition',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'definitions',
        title: 'Tanımlar',
        type: 'collapsible',
        icon: 'book-open',
        children: [
          {
            id: 'modulelist',
            title: 'Moduller',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/modules'
          },
          {
            id: 'departmentlist',
            title: 'Bölümler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/departments'
          },
          {
            id: 'vacationlist',
            title: 'Tatiller',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/vacations'
          },
        ]
      }]
  },
  {
    id: 'reports',
    type: 'section',
    title: '',
    icon: 'package',
    children: [
      {
        id: 'reportlist',
        title: 'Raporlar',
        type: 'collapsible',
        icon: 'list',
        children: [
          {
            id: 'ticketsstatuswihnew',
            title: 'Atanmamış Talepler',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithpartner',
            title: 'Müşteriden Cevap Beklenen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithconsultant',
            title: 'Danışmandan Cevap Beklenen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'ticketstatuswithcanselled',
            title: 'İptal Edilmiş',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'effortslist',
            title: 'Efor Onayı Bekleyen',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: ' '
          },
          {
            id: 'transactionreportlist',
            title: 'İşlem Raporları',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/transactions'
          },

          {
            id: 'vacationdays',
            title: 'İzin Günleri',
            type: 'item',
            icon: 'circle',
            classes: "font-small-4",
            url: '/home/vacationdays'
          },
        ]
      }]
  }
]
