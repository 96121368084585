import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { PartnerUser } from 'app/evo/models/partneruser';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerUserService } from 'app/evo/services/evo-customer-user.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-customer-user-update-form',
  templateUrl: './evo-customer-user-update-form.component.html',
  styles: [
  ]
})
export class EvoPartnerUserUpdateFormComponent implements OnInit {

  partnerUser: PartnerUser = new PartnerUser();
  user: User = new User();
  partner: Partner = new Partner();

  constructor(
    private partnerUserService: EvoPartnerUserService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private route: ActivatedRoute,
    private router: Router,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private errorService: ErrorService
  ) { }

  users: User[] = [];
  partners: Partner[] = [];
  myId: number ;
  id: number;

  ngOnInit(): void {
    this.id = this.route.snapshot.params['Id'];
    this.myId = this.decodeService.getUserId();
    this.partnerUserService.get(this.id).subscribe((item: any) => {
      this.partnerUser = item.data;
    });
    this.partnerService.getList().subscribe((res: any) => {
      this.partners = res.data;
    });
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data;
    });
  }
  update() {
    this.partnerUser.updatedBy=this.myId;
    this.partnerUser.deletedBy=0;
    this.partnerUserService.update(this.partnerUser).subscribe((item: any) => {
      this.informationService.update(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/partnerusers"]);
    }, 1500);
    });
  }
}
