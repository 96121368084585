<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-12">
          <core-card-snippet>
            <h4 class="card-title">Talep Oluştur</h4>
            <div class="card-body">
              <form class="form form-vertical" #TicketForm="ngForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="subject-vertical">Konusu</label>
                      <input type="text" ngModel required id="txtSubject" class="form-control" name="txtSubject"
                        placeholder="Konu" #txtSubject="ngModel" />
                      <span *ngIf="txtSubject.invalid && txtSubject.touched" class="invalid-form">
                        <small class="form-text text-danger">
                          Bir Konu girmeniz gerekiyor!
                        </small>
                      </span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="prioritySelect">Öncelik Seviyesi Seçiniz</label>
                      <select class="form-control" ngModel required name="slcPriority" [(ngModel)]="priority.id"
                        #slcPriority="ngModel">
                        <option *ngFor="let item of priorities" value="{{item.id}}">{{item.name}}</option>
                      </select>
                      <small class="text-danger" *ngIf="slcPriority.invalid&& slcPriority.touched">Bir Öncelik Seviyesi
                        seçmeniz gerekiyor!</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="departmentSelect">Bölümü Seçiniz</label>
                          <select class="form-control" ngModel required name="sclDepartment" [(ngModel)]="department.id"
                            #sclDepartment="ngModel">
                            <option *ngFor="let item of departments" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclDepartment.invalid && sclDepartment.touched">Bir Bölüm
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="moduleSelect">Modül Seçiniz</label>
                          <select class="form-control" required name="slcModule" [(ngModel)]="module.id"
                            #slcModule="ngModel">
                            <option *ngFor="let item of modules" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="slcModule.invalid && slcModule.touched">Bir Modül
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="departmentSelect">Talep Tipi Seçiniz</label>
                          <select class="form-control" required name="sclType" [(ngModel)]="type.id" #sclType="ngModel">
                            <option *ngFor="let item of ticketTypes" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclType.invalid && sclType.touched">Bir Tip
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" *ngIf="role == 'Admin' || role == 'Consultant' || role == 'ConsultantUser'">
                    <div class="form-group">
                      <label for="name-vertical">Cari Seçiniz</label>
                      <select class="form-control" required name="slcPartner" [(ngModel)]="partner.id"
                        #slcPartner="ngModel" (ngModelChange)="changePartner()">
                        <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="departmentSelect">Proje Seçiniz</label>
                          <select class="form-control" required name="slcProject" [(ngModel)]="project.id"
                            #slcProject="ngModel">
                            <option value="0" selected>Proje Seçiniz</option>
                            <option *ngIf="isLoading"><span class="sr-only">Loading...</span></option>
                            <option *ngFor="let item of projects" (click)="changeProject(item)" value="{{item.id}}">
                              {{item.title}}
                              {{item.partnerName}}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="col-12" *ngIf="role=='Customer'|| role=='CustomerUser'">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="departmentSelect">Proje Seçiniz</label>
                          <select class="form-control" required name="slcProject" [(ngModel)]="project.id"
                            #slcProject="ngModel">
                            <option *ngFor="let item of projects" value="{{item.id}}">{{item.title}}
                              {{item.partnerName}}</option>
                          </select>
                        </div>
                        <!-- <div class="col-12 mr-0 ">
                          <label for="departmentSelect">Proje Seçiniz</label>
                          <select class="form-control" required name="slcProject" [(ngModel)]="project.id"
                            #slcProject="ngModel">
                            <option *ngFor="let item of projects" value="{{item.id}}">{{item.title}}</option>
                          </select>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="subject-vertical">Müşteri Talep Numarası</label>
                      <input type="text" ngModel id="txtPartnerTicketNo" class="form-control" name="txtPartnerTicketNo"
                        placeholder="Müşteri Talep Numarası" #txtPartnerTicketNo="ngModel" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0">
                          <label for="txtExpectedTime">Tahmini Tamamlanma Süresi Giriniz </label>
                          <input type="number" ngModel id="txtExpectedTime" class="form-control" name="txtExpectedTime"
                            placeholder="Tahmini Tamamlanma Süresi (saat)" #txtExpectedTime="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" style="margin-top:90px; " *ngIf="!TicketForm.valid;else emptyAddButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                </div>
                <ng-template #emptyAddButton>
                  <div class="col-12" style="margin-top:90px;">
                    <button type="button" rippleEffect class="btn btn-success mr-1"
                      (click)="add(txtSubject.value,txtExpectedTime.value,txtPartnerTicketNo.value)">Kaydet</button>
                  </div>
                </ng-template>
              </form>
            </div>
          </core-card-snippet>
        </div>
        <!-- <div class="modal fade" id="adddepartment" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Bölüm Oluştur</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label for="name-vertical">Bölümün Adını Giriniz</label>
                  <input type="text" ngModel id="name-vertical" class="form-control" name="fname"
                    placeholder="Bölüm adı" #txtName="ngModel" />

                </div>
              </div>
              <div class="modal-footer">
                <div>
                  <button type="button" class="btn btn-success" data-bs-dismiss="modal"
                    (click)="addDepartment(txtName.value)"><i data-feather="check" class="mr-50"></i>Kaydet</button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>
  </div>
</div>