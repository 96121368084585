import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Contract } from '../models/contract';

@Injectable({
  providedIn: 'root'
})
export class EvoContractService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Contract[]>{
    return this.httpClient.get<Contract[]>(this.baseApiUrl+'GetContractList');
  }
  get (id: number):Observable <Contract> {
    return this.httpClient.get<Contract>(this.baseApiUrl+'GetContract/'+id);
  }
  add(contract:Contract):Observable<Contract>{
    return this.httpClient.post<Contract>(this.baseApiUrl+'AddContract/',contract);
  }
  update(contract:Contract):Observable<Contract>{
    return this.httpClient.post<Contract>(this.baseApiUrl+"UpdateContract/",contract);
  }
  delete(contract:Contract):Observable<Contract>{
    return this.httpClient.post<Contract>(this.baseApiUrl+"DeleteContract/",contract);
  }
}
