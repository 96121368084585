<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row nav-vertical d-flex">
                <div class="col-md-3 mb-2 mb-md-0">
                    <ul ngbNav #navVertical="ngbNav" class="nav nav-pills flex-column nav-left">
                        <li class="nav-item" ngbNavItem *ngIf="userRole == 'Admin'">
                            <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-general" data-toggle="pill"
                                href="#account-vertical-general" aria-expanded="true">
                                <i data-feather="user" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">Sistem Bilgileri</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="media">
                                            <a href="javascript:void(0);" class="mr-25">
                                                <img *ngIf="previewImageSrc || systemSetting.companyImageUrl"
                                                    [src]="previewImageSrc || '../../../../assets/images/logo/' + systemSetting.companyImageUrl"
                                                    class="rounded mr-50" alt="profile image" height="70"
                                                    width="auto" />
                                            </a>
                                            <div class="media-body mt-75 ml-1">
                                                <fieldset class="form-group">
                                                    <label for="file-upload">Giriş Ekranı Resmi Seçin</label>
                                                    <div class="custom-file">
                                                        <input class="custom-file-input" type="file"
                                                            (change)="previewFile($event)" #document id="file-upload" />
                                                        <label class="custom-file-label">
                                                            {{documents[0] == null ? "Dosya Seçiniz" :
                                                            documents[0].name}}
                                                        </label>
                                                    </div>
                                                </fieldset>

                                                <button (click)="uploadImage()" for="account-upload"
                                                    class="btn btn-sm btn-success" rippleEffect>
                                                    Yükle
                                                </button>
                                                <br>
                                                <br>
                                                <p>Sadece PNG, JPG, JPEG Formatında Ve Maksimum 5Mb Büyüklüğünde</p>
                                            </div>
                                        </div>

                                        <form class="validate-form mt-2">
                                            <div class="row">
                                                <div class="col-12 col-sm-9">
                                                    <div class="form-group">
                                                        <label for="name-vertical">Mail Adresi Seçiniz </label>
                                                        <select name="slcMailAddress" class="form-control form-select"
                                                            #slcMailAddress="ngModel"
                                                            [(ngModel)]="systemSetting.mailParameterId">
                                                            <option *ngFor="let item of mailSettings"
                                                                value="{{item.id}}">
                                                                {{item.mail}}
                                                            </option>

                                                        </select>
                                                        <div
                                                            *ngIf="isMailActiveted && systemSetting.mailParameterId==null">
                                                            <small class="text-danger">Uyarı! Mail Sistemini Aktif
                                                                Etmek için Yan Sekmede Bulunan "Mail
                                                                Bilgilerinden" Emin Olduğunuz Bir Mail Adresi
                                                                Girmeniz ve Bu Sekme İçerisinde ise Hangi Maili
                                                                Kullanacağınızı
                                                                Seçmeniz Gerekli </small>
                                                            <!-- <small *ngIf="mailSettings==null" class="text-danger">
                                                            Mail Sistemini Aktif
                                                            Etmek için Yan Sekmede Bulunan Mail
                                                            Bilgilerinden Emin Olduğunuz Bir Mail Adresi
                                                            Girmeniz ve Bu Sekmede de Hangi Maili Kullanacağınızı
                                                            Seçmeniz Gerekli</small> -->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-12 col-sm-3 mt-2">
                                                    <div class="form-group">
                                                        <div class="custom-control-success custom-switch">
                                                            <input type="checkbox" (change)="changeMailIsActive()"
                                                                [checked]="systemSetting.isMailActivated"
                                                                class="custom-control-input"
                                                                id="customSwitchCustomer" />
                                                            <label class="custom-control-label"
                                                                for="customSwitchCustomer">
                                                                <span *ngIf="isMailActiveted ">Mail Aktif</span>

                                                                <span *ngIf="!isMailActiveted">Mail Aktif
                                                                    Değil</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-9">
                                                    <div class="form-group">
                                                        <label for="account-name">Şirket Adı:</label>
                                                        <input type="text" class="form-control" name="txtcompanyName"
                                                            [(ngModel)]="systemSetting.companyName"
                                                            #txtFirstName="ngModel" placeholder="Name" />
                                                    </div>
                                                </div>
                                                <div *ngIf="systemSetting.mailParameterId==null && isMailActiveted else saveSettingsTemplate"
                                                    class="col-12">
                                                    <button type="button" class="btn btn-success mt-2 cursor-block"
                                                        disabled rippleEffect>Kaydet</button>
                                                </div>
                                                <ng-template #saveSettingsTemplate>
                                                    <div class="col-12">
                                                        <button type="button" class="btn btn-success mt-2"
                                                            (click)="updateSystemSetting()" rippleEffect>Kaydet</button>
                                                    </div>
                                                </ng-template>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="nav-item" ngbNavItem *ngIf="userRole == 'Admin'">
                            <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-password" data-toggle="pill"
                                href="" aria-expanded="false">
                                <i data-feather="lock" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">Mail Bilgileri</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <!-- <form class="validate-form">
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="account-old-password">Eski Şifre:</label>
                                                        <div class="input-group form-password-toggle input-group-merge">
                                                            <input [type]="passwordTextTypeOld ? 'text' : 'password'"
                                                                class="form-control" id="account-old-password"
                                                                name="txtOldPassowrd" placeholder="Eski Şifre"
                                                                 #txtOldPassowrd />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text cursor-pointer"><i
                                                                        class="feather font-small-4" [ngClass]="{
                                        'icon-eye-off': passwordTextTypeOld,
                                        'icon-eye': !passwordTextTypeOld
                                      }" (click)="togglePasswordTextTypeOld()"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="account-new-password">Yeni Şifre</label>
                                                        <div class="input-group form-password-toggle input-group-merge">
                                                            <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                                                id="account-new-password" name="txtNewPassowrd"
                                                                class="form-control" placeholder="Yeni Şifre"
                                                                #txtNewPassowrd />
                                                            <div class="input-group-append">
                                                                <span class="input-group-text cursor-pointer"><i
                                                                        class="feather font-small-4" [ngClass]="{
                                        'icon-eye-off': passwordTextTypeNew,
                                        'icon-eye': !passwordTextTypeNew
                                      }" (click)="togglePasswordTextTypeNew()"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <button type="button" class="btn btn-success mr-1 mt-1"
                                                        (click)="passwordCheck(txtOldPassowrd.value,txtNewPassowrd.value)"
                                                        rippleEffect>Kaydet</button>
                                                </div>
                                            </div>
                                        </form> -->
                                        <div class="content-wrapper container-xxl p-0">
                                            <div class="content-body">
                                                <section id="ngx-datatable-kitchen-sink">
                                                    <core-card-snippet>
                                                        <h4 class="card-title">Mail Listesi</h4>
                                                        <div class="row">
                                                            <div class="firstRow mt-1">
                                                                <div style="margin-left:9px; margin-bottom: 20px;">
                                                                    <div class="col-12">
                                                                        <select class="form-control form-select"
                                                                            [(ngModel)]="basicSelectedOption">
                                                                            <option value="10">10</option>
                                                                            <option value="25">25</option>
                                                                            <option value="50">50</option>
                                                                            <option value="100">100</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="search mt-1">
                                                                <div class="col-12">
                                                                    <input type="text" class="form-control mr-1"
                                                                        name="filterText" placeholder="Mail Arayın.."
                                                                        [(ngModel)]="filterText"
                                                                        [ngModelOptions]="{standalone:true}">
                                                                </div>
                                                            </div>
                                                            <!-- <div class="filter mt-1">
                                                                <div class="col-12">
                                                                    <ng-select [multiple]="true" [items]="userTypes" [(ngModel)]="selectedUserTypes"
                                                                        placeholder="Kullanıcı Türü Seçiniz" bindLabel="name" bindValue="id"
                                                                        (change)="tableByFiltersId()">
                                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                                            <span class="ng-value-label">{{ item.name }}</span>
                                                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                            </div> -->
                                                        </div>



                                                        <div class="row">
                                                            <div class="col-12 mb-1 ml-1">
                                                                <button class="btn btn-primary btn-sm mr-1"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#addmailsetting" rippleEffect
                                                                    title="Yeni Mail Bilgisi">
                                                                    <i data-feather="plus" class="mr-0"></i><span
                                                                        class="d-none d-sm-inline-block"></span>
                                                                </button>
                                                                <!-- <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                                                                    <i data-feather="file-text" class=""></i>
                                                                </button> -->
                                                            </div>
                                                        </div>
                                                        <!-- <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                                                            <thead>
                                                                <th>Kullanıcı Adı</th>
                                                                <th>Mail Adresi</th>
                                                                <th>Adı</th>
                                                                <th>Soyadı</th>
                                                                <th>Başlığı</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of users">
                                                                    <td>{{item.userName}}</td>
                                                                    <td>{{item.mailAddress}}</td>
                                                                    <td>{{item.firstName}}</td>
                                                                    <td>{{item.lastName}}</td>
                                                                    <td>{{item.title}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table> -->
                                                        <ngx-datatable [rows]="mailSettings|evoMailSetting:filterText"
                                                            [rowHeight]="58" class="bootstrap core-bootstrap"
                                                            [limit]="10" [headerHeight]="40" [footerHeight]="50"
                                                            [scrollbarH]="true" [limit]="basicSelectedOption">
                                                            <ngx-datatable-column [width]="70" [sortable]="false"
                                                                [canAutoResize]="false" [draggable]="false"
                                                                [resizeable]="false">
                                                                <ng-template ngx-datatable-header-template
                                                                    let-value="value"
                                                                    let-allRowsSelected="allRowsSelected"
                                                                    let-selectFn="selectFn">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox"
                                                                            class="custom-control-input"
                                                                            [checked]="allRowsSelected"
                                                                            (change)="selectFn(!allRowsSelected)"
                                                                            id="headerChkbxRef" />
                                                                        <label class="custom-control-label"
                                                                            for="headerChkbxRef"></label>
                                                                    </div>
                                                                </ng-template>
                                                                <ng-template ngx-datatable-cell-template
                                                                    let-rowIndex="rowIndex" let-value="value"
                                                                    let-isSelected="isSelected"
                                                                    let-onCheckboxChangeFn="onCheckboxChangeFn">
                                                                    <div class="custom-control custom-checkbox">
                                                                        <input type="checkbox"
                                                                            class="custom-control-input"
                                                                            [checked]="isSelected"
                                                                            (change)="onCheckboxChangeFn($event)"
                                                                            id="rowChkbxRef{{ rowIndex }}" />
                                                                        <label class="custom-control-label"
                                                                            for="rowChkbxRef{{rowIndex}}"></label>
                                                                    </div>
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Mail Başlığı" prop="name"
                                                                [width]="200">
                                                                <ng-template let-row="row" let-name="value"
                                                                    ngx-datatable-cell-template>
                                                                    {{ row.name}}
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                            <ngx-datatable-column name="Mail Adresi" prop="mail"
                                                                [width]="270">
                                                                <ng-template let-row="row" let-name="value"
                                                                    ngx-datatable-cell-template>
                                                                    {{ row.mail.length >30 ?
                                                                    row.mail.substring(0,30)+"..." : row.mail}}
                                                                </ng-template>
                                                            </ngx-datatable-column>

                                                            <ngx-datatable-column name="Url Adres" prop="urlAddress"
                                                                [width]="200">
                                                                <ng-template let-row="row" let-name="value"
                                                                    ngx-datatable-cell-template>
                                                                    {{ row.urlAddress.length >20?
                                                                    row.urlAddress.substring(0,20)+"..." : row.mail}}
                                                                </ng-template>
                                                            </ngx-datatable-column>



                                                            <ngx-datatable-column name="İşlemler" [width]="120"
                                                                [sortable]="false">
                                                                <ng-template let-row="row" let-name="value"
                                                                    ngx-datatable-cell-template>
                                                                    <div class="d-flex align-items-center">
                                                                        <div ngbDropdown container="body">
                                                                            <a ngbDropdownToggle
                                                                                href="javascript:void(0);"
                                                                                class="hide-arrow"
                                                                                id="dropdownBrowserState"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false">
                                                                                <i data-feather="more-vertical"
                                                                                    class="text-primary cursor-pointer mr-50"></i>
                                                                            </a>
                                                                            <div ngbDropdownMenu
                                                                                class="dropdown-menu-right"
                                                                                aria-labelledby="dropdownBrowserState">
                                                                                <button ngbDropdownItem
                                                                                    (click)="getMailSetting(row.id)"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#updatemailsetting"><i
                                                                                        data-feather="edit"
                                                                                        class="mr-50"></i><span>Güncelle</span>
                                                                                </button>
                                                                                <button ngbDropdownItem
                                                                                    (click)="deletedMailSettingId=row.id"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#deletemailsetting">
                                                                                    <i data-feather="trash"
                                                                                        class="mr-50"></i><span>Sil</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        <button ngbDropdownItem
                                                                            (click)="getMailSetting(row.id)"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#updatemailsetting"><i
                                                                                data-feather="edit"
                                                                                class="text-primary cursor-pointer"></i><span></span>
                                                                        </button>
                                                                    </div>
                                                                </ng-template>
                                                            </ngx-datatable-column>
                                                        </ngx-datatable>
                                                    </core-card-snippet>

                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>

                       

                        <li class="nav-item" ngbNavItem>
                            <a ngbNavLink class="nav-link d-flex py-75" id="account-pill-password" data-toggle="pill"
                                href="" aria-expanded="false">
                                <i data-feather="book-open" class="font-medium-3 mr-1"></i>
                                <span class="font-weight-bold">Kılavuz Bilgileri</span>
                            </a>
                            <ng-template ngbNavContent>

                                <core-card-snippet [snippetCode]="_snippetCodeSwiperCoverflow">
                                    <div class="card-title mt-1 ml-1">
                                        Kılavuzların Listesi
                                    </div>
                                    <div class="row mb-2" *ngIf="userRole == 'Admin'">
                                        <div class="col-5">
                                            <div class="col-12" >
                                                <button class="btn btn-success btn-sm mr-1" data-bs-toggle="modal"
                                                    data-bs-target="#addguidesetting" rippleEffect
                                                    title="Yeni Kılavuz Bilgisi">
                                                    <i data-feather="plus" class="mr-0"></i><span
                                                        class="d-none d-sm-inline-block"></span>
                                                </button>

                                            </div>
                                        </div>
                                        <div class="col-5">

                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <swiper class="swiper-coverflow swiper-container " [config]="swiperCoverflow">
                                            <div *ngFor="let item of guideSettings">
                                                <div class="card cursor-pointer" style="border-radius:50px">
                                                    <div class="card-title mt-2">
                                                        <span *ngIf="item.typeId== 1" class="text-center ml-4">
                                                            Admin Kılavuzu
                                                        </span>
                                                        <span *ngIf="item.typeId== 2" class="text-center ml-4">
                                                            Danışman Kılavuzu
                                                        </span>
                                                        <span *ngIf="item.typeId== 3" class="text-center ml-4">
                                                            Cari Kılavuzu
                                                        </span>
                                                        <div class="text-center mr-3 mt-2">
                                                            <button
                                                                (click)="downloadDocument('../../../../assets/images/guides/'+item.url,item.url)"
                                                                for="account-upload" class="btn btn-sm btn-success"
                                                                rippleEffect>
                                                                Klavuzu indir
                                                            </button>
                                                        </div>

                                                        <div class="d-flex justify-content-md-end" *ngIf="userRole == 'Admin'">
                                                            <div class="d-flex align-items-center justify-content-end">
                                                                <div ngbDropdown container="body">
                                                                    <button type="button" class="btn btn-sm"
                                                                        ngbDropdownToggle data-toggle="dropdown">
                                                                        <i data-feather="more-vertical"></i>
                                                                    </button>
                                                                    <div ngbDropdownMenu>
                                                                        <button ngbDropdownItem
                                                                            (click)="getGuideSetting(item.id)"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#updateguidesetting"><i
                                                                                data-feather="edit"
                                                                                class="mr-50"></i><span>Güncelle</span>
                                                                        </button>
                                                                        <button ngbDropdownItem
                                                                            (click)="deletedGuideSettingId=item.id"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#deleteguidesetting"><i
                                                                                data-feather="trash"
                                                                                class="mr-50"></i><span>Sil</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-body" style="text-align: center;">

                                                    </div>

                                                </div>
                                            </div>
                                        </swiper>
                                    </div>
                                </core-card-snippet>

                            </ng-template>
                        </li>
                    </ul>
                </div>
                <div [ngbNavOutlet]="navVertical" class="col-12 col-md-9"></div>
            </div>
        </section>





    </div>
</div>

<div class="modal fade" id="addmailsetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Mail Bilgisini Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" [formGroup]="mailSettingForm" (ngSubmit)="add()">
                    <div class="modal-body">
                        <div class="form-group">

                            <div class="row mb-2">
                                <label for="name-vertical">Mail Başlığı Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtAddName">
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddName').hasError('required') && mailSettingForm.get('txtAddName').touched">
                                    Kullanıcı Adı boş bırakılamaz!
                                </small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Mail Adresi Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtAddMail">
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddMail').hasError('required') && mailSettingForm.get('txtAddMail').touched">Mail
                                    Adresi boş
                                    bırakılamaz!</small>
                                <small class="text-danger" *ngIf="mailSettingForm.get('txtAddMail').hasError('email')">
                                    Geçerli bir mail adresi giriniz!
                                </small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Url Adresi Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtAddUrl">
                                <small
                                    *ngIf="mailSettingForm.get('txtAddUrl').hasError('required') && mailSettingForm.get('txtAddUrl').touched">Url
                                    Adresi boş
                                    bırakılamaz!</small>
                            </div>

                            <div class="row mb-2">


                                <label for="name-vertical">Mail Şifresi</label>
                                <div class="input-group form-password-toggle input-group-merge">

                                    <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                        formControlName="txtAddPassword" class="form-control"
                                        placeholder="Mail Şifresi" />
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                                [ngClass]="{
                                                                            'icon-eye-off': passwordTextTypeNew,
                                                                            'icon-eye': !passwordTextTypeNew
                                                                          }"
                                                (click)="togglePasswordTextTypeNew()"></i></span>
                                    </div>


                                </div>
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddPassword').hasError('required') && mailSettingForm.get('txtAddPassword').touched">
                                    Mail Şifresi boş bırakılamaz!
                                </small>
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddPassword').hasError('minlength')">
                                    Şifre en az 8 karakter olmalıdır.
                                </small>
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddPassword').hasError('pattern')">
                                    Şifre en az bir büyük harf, bir küçük harf ve bir rakam içermelidir.
                                </small>



                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Smtp Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtAddSmtp">
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddSmtp').hasError('required') && mailSettingForm.get('txtAddSmtp').touched">Smtp
                                    boş
                                    bırakılamaz!</small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Port Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtAddPort">
                                <small class="text-danger"
                                    *ngIf="mailSettingForm.get('txtAddPort').hasError('required') && mailSettingForm.get('txtAddPort').touched">Port
                                    boş
                                    bırakılamaz!</small>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="modal-footer">
                            <button type="submit" [disabled]="mailSettingForm.invalid" rippleEffect
                                class="btn btn-success mr-1">Kaydet</button>

                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updatemailsetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Mail Bilgisini Güncelle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" [formGroup]="mailSettingUpdateForm" (ngSubmit)="update()">
                    <div class="modal-body">
                        <div class="form-group">

                            <div class="row mb-2">
                                <label for="name-vertical">Mail Başlığı Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtUpdateName">
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdateName').hasError('required') && mailSettingUpdateForm.get('txtUpdateName').touched">
                                    Kullanıcı Adı boş bırakılamaz!
                                </small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Mail Adresi Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtUpdateMail">
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdateMail').hasError('required') && mailSettingUpdateForm.get('txtUpdateMail').touched">Mail
                                    Adresi boş
                                    bırakılamaz!</small>
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdateMail').hasError('email')">
                                    Geçerli bir mail adresi giriniz!
                                </small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Url Adresi Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtUpdateUrl">
                                <small
                                    *ngIf="mailSettingUpdateForm.get('txtUpdateUrl').hasError('required') && mailSettingUpdateForm.get('txtUpdateUrl').touched">Url
                                    Adresi boş
                                    bırakılamaz!</small>
                            </div>

                            <div class="row mb-2">
                                <label for="name-vertical">Smtp Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtUpdateSmtp">
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdateSmtp').hasError('required') && mailSettingUpdateForm.get('txtUpdateSmtp').touched">Smtp
                                    boş
                                    bırakılamaz!</small>
                            </div>
                            <div class="row mb-2">
                                <label for="name-vertical">Port Giriniz</label>
                                <input type="text" class="form-control" formControlName="txtUpdatePort">
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdatePort').hasError('required') && mailSettingUpdateForm.get('txtUpdatePort').touched">Port
                                    boş
                                    bırakılamaz!</small>
                            </div>
                            <div class="row mb-2">


                                <label for="name-vertical">Mail Şifresi</label>
                                <div class="input-group form-password-toggle input-group-merge">

                                    <input [type]="passwordTextTypeNew ? 'text' : 'password'"
                                        formControlName="txtUpdatePassword" class="form-control"
                                        placeholder="Mail Şifresi" />
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4"
                                                [ngClass]="{
                                                                'icon-eye-off': passwordTextTypeNew,
                                                                'icon-eye': !passwordTextTypeNew
                                                              }" (click)="togglePasswordTextTypeNew()"></i></span>
                                    </div>


                                </div>
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdatePassword').hasError('required') && mailSettingUpdateForm.get('txtUpdatePassword').touched">
                                    Mail Şifresi boş bırakılamaz!
                                </small>
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdatePassword').hasError('minlength')">
                                    Şifre en az 8 karakter olmalıdır.
                                </small>
                                <small class="text-danger"
                                    *ngIf="mailSettingUpdateForm.get('txtUpdatePassword').hasError('pattern')">
                                    Şifre en az bir büyük harf, bir küçük harf ve bir rakam içermelidir.
                                </small>



                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="modal-footer">
                            <button type="submit" [disabled]="mailSettingUpdateForm.invalid" rippleEffect
                                class="btn btn-success mr-1" data-bs-dismiss="modal">Kaydet</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="deletemailsetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Mail Bilgilerini Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="delete(deletedMailSettingId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="addguidesetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Kılavuz Bilgisini Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" [formGroup]="guideSettingForm" (ngSubmit)="addGuideSetting()">
                    <div class="modal-body">
                        <div class="form-group">

                            <div class="row mb-2">
                                <label for="name-vertical">Kılavuz Tipi Seçiniz</label>
                                <select class="form-control" formControlName="slcType">
                                    <option *ngFor="let item of guideTypes" [value]="item.id">{{item.name}}
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="guideSettingForm.get('slcType').hasError('required') && guideSettingForm.get('slcType').touched">
                                    Kılavuz Türü Boş Bırakılamaz.
                                </small>
                            </div>

                            <div class="media">
                                <div class="media-body">
                                    <fieldset class="form-group">
                                        <label for="file-upload-guide">Evo Destek Sistemi için Bir Kullanma Kılavuzunu
                                            Seçiniz</label>
                                        <div class="custom-file">
                                            <input class="custom-file-input" formControlName="txtGuideDocument"
                                                type="file" (change)="getDocument($event)" #document
                                                id="file-upload-guide" />
                                            <label class="custom-file-label">
                                                {{documents[0] ==null ? "Dosya Seçiniz": documents[0].name}}
                                            </label>

                                        </div>
                                    </fieldset>
                                    <small class="text-danger"
                                        *ngIf="guideSettingForm.get('txtGuideDocument').hasError('required') && guideSettingForm.get('txtGuideDocument').touched">
                                        Kılavuz Seçimi boş bırakılamaz!
                                    </small>
                                    <br>
                                    <br>
                                    <p>Sadece PNG,JPG,JPEG Formatında Ve Maksiumum 5Mb Büyüklüğünde</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="modal-footer">
                            <button type="submit" [disabled]="guideSettingForm.invalid" rippleEffect
                                class="btn btn-success mr-1" data-bs-dismiss="modal">Kaydet</button>

                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateguidesetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Kılavuz Bilgisini Güncelle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <form class="" [formGroup]="guideSettingUpdateForm" (ngSubmit)="guideDocumentUpdate()">
                    <div class="modal-body">
                        <div class="form-group">

                            <div class="row mb-2">
                                <label for="name-vertical">Kılavuz Tipi Seçiniz</label>
                                <select class="form-control" formControlName="slcUpdateType">
                                    <option *ngFor="let item of guideTypes" [value]="item.id">{{item.name}}
                                    </option>
                                </select>
                                <small class="text-danger"
                                    *ngIf="guideSettingUpdateForm.get('slcUpdateType').hasError('required') && guideSettingUpdateForm.get('slcUpdateType').touched">
                                    Kılavuz Türü Boş Bırakılamaz.
                                </small>
                            </div>
                            <div class="media">
                                <div class="media-body mt-75">
                                    <fieldset class="form-group">
                                        <label for="file-upload-multiple">Evo Destek Sistemi için Bir Kullanma
                                            Kılavuzunu Seçiniz</label>
                                        <div class="custom-file">
                                            <input class="custom-file-input" type="file" (change)="getDocument($event)"
                                                #document id="file-upload-multiple" />
                                            <label class="custom-file-label">Dosya Seçiniz</label>
                                        </div>
                                    </fieldset>

                                    <br>
                                    <br>
                                    <p>Sadece PDF,PNG,JPG,JPEG Formatında Ve Maksiumum 5Mb Büyüklüğünde</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="modal-footer">
                            <button type="submit" [disabled]="guideSettingUpdateForm.invalid" rippleEffect
                                class="btn btn-success mr-1" data-bs-dismiss="modal">Kaydet</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteguidesetting" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Kılavuz Bilgilerini Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="deleteGuideSetting(deletedGuideSettingId)"><i data-feather="trash"
                            class="mr-50"></i>Sil</button>
                </div>
            </div>
        </div>
    </div>
</div>