import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Contract } from 'app/evo/models/contract';
import { Partner } from 'app/evo/models/partner';
import { Module } from 'app/evo/models/module';
import { Project } from 'app/evo/models/project';
import { User } from 'app/evo/models/user';
import { EvoContractService } from 'app/evo/services/evo-contract.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';

@Component({
  selector: 'app-evo-contract-add-form',
  templateUrl: './evo-contract-add-form.component.html',
  styles: [
  ]
})
export class EvoContractAddFormComponent implements OnInit {

  constructor(
    private contractService: EvoContractService,
    private partnerService: EvoPartnerService,
    private userService: EvoUserService,
    private projectService: EvoProjectService,
    private moduleServive: EvoModuleService,
    private transactionService:EvoTransactionService,
    private router: Router,
    private decodeService: DecodeService,
    private informationService: InformationService,
  ) {
    let today = new Date();
    let endDate = new Date();
    let startDate = new Date();

    startDate.setDate(today.getDate());
    startDate.setHours(3, 0, 0, 0);
    endDate.setHours(2, 59, 0, 0);
    endDate.setDate(today.getDate() + 1);
    endDate.setMonth(today.getMonth() + 1);

    let startDateString = startDate.toISOString().slice(0, 16);
    this.startDateControl = new FormControl(startDateString);

    let endDateString = endDate.toISOString().slice(0, 16);
    this.endDateControl = new FormControl(endDateString);
  }
  myId: number;
  startDateControl: FormControl;
  endDateControl: FormControl;

  public txtStartDate: Date;
  public txtEndDate: Date;

  partners: Partner[] = [];
  projects: Project[] = [];
  users: User[] = [];
  modules: Module[] = [];

  user: User = new User();
  project: Project = new Project();
  module: Module = new Module();
  partner: Partner = new Partner();
  contract: Contract = new Contract();

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 4, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.moduleServive.getList().subscribe((res: any) => {
        this.modules = res.data;
      });
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
      this.userService.getList().subscribe((res: any) => {
        this.users = res.data;
      });
    }

  }

  changePartner() {
    this.project = new Project();
    this.project.id = 0;
    this.projectService.getListByPartnerId(this.partner.id).subscribe((res: any) => {
      this.projects = res.data;
    });
  }

  add(starDate: string, endDate: string) {
    let fromDate = new Date(starDate);
    let toDate = new Date(endDate);

    fromDate.setHours(new Date(starDate).getHours() + 3, new Date(starDate).getMinutes(), 0, 0);
    toDate.setHours(new Date(endDate).getHours() + 3, new Date(endDate).getMinutes(), 0, 0);

    if (fromDate > toDate) {
      let temp;
      temp = fromDate;
      fromDate = toDate;
      toDate = temp;
    }
    if (this.project.id == 0) {
      this.informationService.warning("Lütfen Proje Seçiniz")
    }
    else {
      this.contract = {
        id: 0,
        projectId: this.project.id,
        startDate: new Date(fromDate),
        endDate: new Date(toDate),
        projectName: "",
        partnerName: "",
        contractNumber: "",

        createdBy: this.myId,
        updatedBy: 0,
        deletedBy: 0,
      }
      this.contractService.add(this.contract).subscribe((res: any) => {
        this.informationService.add(res.message);
        this.router.navigate(["/home/updatecontract/" + res.data.id]);
      });
    }
  }
}
