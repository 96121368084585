<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="ngx-datatable-kitchen-sink">
      <core-card-snippet>
        <h4 class="card-title">Kullanıcı Yetkilerin Listesi</h4>
        <div class="row">
          <div class="col-12 mb-50">
            <button class="btn btn-primary ml-1" routerLink="/home/adduseroperationclaim" rippleEffect>
              <i data-feather="plus" class="mr-0 mr-sm-1"></i><span class="d-none d-sm-inline-block">Yeni Kullanıcı
                Yetkisi</span>
            </button>
          </div>
          <div class="col-md-6 col-12">
            <div class="d-flex justify-content-between align-items-center m-1">
              <label class="d-flex align-items-center">Göster
                <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </label>
            </div>
          </div>
          <div class="col-md-6 col-12 d-flex justify-content-md-end">
            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
              <input type="text" class="form-control mr-1" name="filterText" placeholder="Arayın.."
                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
            </div>
          </div>
        </div>
        <ngx-datatable [rows]="userOperationClaims|evoUserOperationClaim:filterText" [rowHeight]="58"
          class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
          [limit]="basicSelectedOption">
          <ngx-datatable-column [width]="200" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                  (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                <label class="custom-control-label" for="headerChkbxRef"></label>
              </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
              let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Kullanıcı" prop="userName" [width]="400">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.userName}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Yetkileri" prop="operationClaimTitle" [width]="400">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.operationClaimTitle}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div ngbDropdown container="body">
                  <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a routerLink="/home/infouseroperationclaim/{{row.userId}}" ngbDropdownItem><i data-feather="info"
                        class="mr-50"></i><span>Detay</span></a>
                    <a routerLink="/home/updateuseroperationclaim/{{row.id}}" ngbDropdownItem><i data-feather="edit-2"
                        class="mr-50"></i><span>Güncelle</span></a>
                    <button ngbDropdownItem (click)="deletedUserOperationClaimId=row.id" data-bs-toggle="modal"
                      data-bs-target="#deleteuseroperationclaim">
                      <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                    </button>
                  </div>
                </div>
                <a routerLink="/home/updateuseroperationclaim/{{row.id}}"  ngbDropdownItem  title="Güncelle"><i
                  data-feather="edit"  class="text-primary cursor-pointer"></i></a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </core-card-snippet>
      <div class="modal fade" id="deleteuseroperationclaim" tabindex="-1" aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Kullanıcıya Atanmış Yetkiyi Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="delete(deletedUserOperationClaimId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>