import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Contract } from 'app/evo/models/contract';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoContractService } from 'app/evo/services/evo-contract.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-contract-list',
  templateUrl: './evo-contract-list.component.html',
  styles: [
  ]
})
export class EvoContractListComponent implements OnInit {

  constructor(private contractService:EvoContractService,
    private transactionService:EvoTransactionService,
    
    private errorService:ErrorService,
    private helperService:HelperService,
    private informationService:InformationService,
    private decodeService:DecodeService,
    private router: Router
    ) { }

  public basicSelectedOption: number = 10;
  myId:number;

  contracts:Contract[];
  filterText: string = "";

  deletedContractId:number;

  ngOnInit(): void {
    let item=this.decodeService.getRole();
    this.myId=this.decodeService.getUserId();
    if(item!="Admin"){
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 4, 5, this.myId).subscribe((res: any) => {
      });
    }
    else{
      this.list();
    }
  }

  list() {
    this.contractService.getList().subscribe((items: any) => {
      this.contracts = items.data;
      this.informationService.list(items.message)
    },(err)=>{
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.contractService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
      this.contractService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1500);
      });
    });
  }
  
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Sözleşmeler";
    this.helperService.exportExcel(element, title);
  }

}
