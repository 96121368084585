import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { DecodeService } from 'app/evo/login/service/decode.service';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  userRole:string;
  constructor(
    private decedoService:DecodeService,
  ) { }

  ngOnInit() {
    this.userRole=this.decedoService.getRole();
  }
    
}
