<div class="modal-dialog sidebar-lg">
  <div class="modal-content p-0">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleEventSidebar()">
      ×
    </button>
    <div class="modal-header mb-1">
      <h5 class="modal-title">{{ isDataEmpty === true ? 'Add' : 'Update' }} Event</h5>
    </div>
    <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
      <form class="event-form needs-validation" (ngSubmit)="(PlanForm.form.valid); PlanForm.reset()" #PlanForm="ngForm">
        <div class="form-group">
          <label for="name-vertical">Plan Başlığını Giriniz</label>
          <input type="text" ngModel required id="name-vertical" class="form-control" name="txtTitle"
            placeholder="Plan Başlığı" #txtTitle="ngModel" />

          <small class="text-danger" *ngIf="txtTitle.invalid&& txtTitle.touched">Plan Başlığı boş
            bırakılamaz!</small>
        </div>

        <div class="form-group">
          <label for="select-label" class="form-label">Label</label>
          <ng-select [items]="selectLabel" name="selectlabel" [(ngModel)]="event.calendar" bindLabel="label"
            bindValue="label" required [class.error]="PlanForm.submitted && formLabelRef.invalid"
            #formLabelRef="ngModel">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <span class="bullet bullet-sm mr-50 bullet-{{ item.bullet }}"></span>
              {{ item.label }}
            </ng-template>
          </ng-select>
          <span *ngIf="PlanForm.submitted && formLabelRef.invalid" class="invalid-form">
            <small class="form-text text-danger" *ngIf="formLabelRef.errors.required">Label is required!</small>
          </span>
        </div>

        <div class="form-group">
          <div class="form-group">
            <label for="name-vertical">Plan Açıklamasını Giriniz</label>
            <input type="text" ngModel required id="name-vertical" class="form-control" name="txtDescription"
              placeholder="Plan Açıklaması" #txtDescription="ngModel" />

            <small class="text-danger" *ngIf="txtDescription.invalid&& txtDescription.touched">Plan Açıklaması boş
              bırakılamaz!</small>
          </div>
        </div>

        <div class="form-group position-relative">
          <div class="form-group">
            <label for="name-vertical">Plan Başlangıç Tarihini Giriniz</label>
            <input type="datetime-local" ngModel required id="name-vertical" class="form-control" name="txtStartDate"
              placeholder="Başlangıç Tarihi" #txtStartDate="ngModel" />

            <small class="text-danger" *ngIf="txtStartDate.invalid&& txtStartDate.touched">Plan Başlangıç Tarhi boş
              bırakılamaz!</small>
          </div>
        </div>

        <div class="form-group position-relative">
          <label for="name-vertical">Plan Bitiş Tarihini Giriniz</label>
          <input type="datetime-local" ngModel required id="name-vertical" class="form-control" name="txtEndDate"
            placeholder="Bitiş Tarihi" #txtEndDate="ngModel" />

          <small class="text-danger" *ngIf="txtEndDate.invalid&& txtEndDate.touched">Plan Bitiş Tarhi boş
            bırakılamaz!</small>
        </div>

        <div class="form-group">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input allDay-switch" id="customSwitch3"
              [(ngModel)]="event.allDay" name="allDay" />
            <label class="custom-control-label" for="customSwitch3">All Day</label>
          </div>
        </div>
        <div class="form-group select2-primary">
          <label for="selectPartner" class="form-label">Partner Ekle</label>
          <ng-select [items]="partners" name="selectPartner" [(ngModel)]="event.extendedProps.addPartner"
            bindLabel="name">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <div class="media align-items-center">
                <div class="media-body">
                  <p class="mb-0">{{ item.name }}</p>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group select2-primary">
          <label for="selectUser" class="form-label">Kullanıcı Ekle</label>
          <ng-select [items]="users" name="selectUser" [(ngModel)]="event.extendedProps.addUser"
            bindLabel="name">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              <div class="media align-items-center">
                <div class="media-body">
                  <p class="mb-0">{{ item.firstName }} {{item.lastName}}</p>
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>

        <div class="form-group">
          <label for="event-location" class="form-label">Location</label>
          <input type="text" class="form-control" id="event-location" placeholder="Enter Location"
            [(ngModel)]="event.extendedProps.location" name="location" />
        </div>

        <div class="form-group">
          <label class="form-label">Description</label>
          <textarea name="event-description-editor" id="event-description-editor" class="form-control"
            [(ngModel)]="event.extendedProps.description" name="description"></textarea>
        </div>
        <div class="form-group d-flex">
          <button type="submit" class="btn btn-primary add-event-btn mr-1"
            *ngIf="isDataEmpty !== false; else addEventRef" (click)="addEvent(eventForm)" rippleEffect>
            Add
          </button>
          <button type="button" class="btn btn-outline-secondary btn-cancel" data-dismiss="modal"
            (click)="toggleEventSidebar()" *ngIf="isDataEmpty !== false; else deleteEventRef" rippleEffect>
            Cancel
          </button>
          <ng-template #addEventRef>
            <button type="submit" class="btn btn-primary update-event-btn mr-1" (click)="updateEvent()" rippleEffect>
              Update
            </button>
          </ng-template>
          <ng-template #deleteEventRef>
            <button class="btn btn-outline-danger btn-delete-event" (click)="deleteEvent()" rippleEffect>Delete</button>
          </ng-template>
        </div>
      </form>
    </div>
  </div>
</div>