export class TripPlan{
    id:number;
    planId:number;
    tripTypeId:number;
    tripDetailId:number;

    planTitle:string;
    planDescription:string;
    planUserId:number;
    planUserName:string;
    planTypeId:number;
    planTypeName:string;
    planPartnerId:number;
    planPartnerName:string;
    planStartDate:Date;
    planEndDate:Date;
    
    tripTypeName:string;
    tripDetailName:string;

}