import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { OperationClaim } from '../models/operationclaim';

@Injectable({
  providedIn: 'root'
})
export class EvoOperationClaimService {

  
  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<OperationClaim[]>{
    return this.httpClient.get<OperationClaim[]>(this.baseApiUrl+'GetOperationClaimList');
  }
  get (id: number):Observable <OperationClaim> {
    return this.httpClient.get<OperationClaim>(this.baseApiUrl+'GetOperationClaim/'+id);
  }
  add(operationClaim:OperationClaim):Observable<OperationClaim>{
    return this.httpClient.post<OperationClaim>(this.baseApiUrl+'AddOperationClaim/',operationClaim);
  }
  update(operationClaim:OperationClaim):Observable<OperationClaim>{
    return this.httpClient.post<OperationClaim>(this.baseApiUrl+"UpdateOperationClaim/",operationClaim);
  }
  delete(id:number):Observable<OperationClaim>{
    return this.httpClient.post<OperationClaim>(this.baseApiUrl+"DeleteOperationClaim/"+id,"");
  }
  

}
