<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="ngx-datatable-kitchen-sink">
        <core-card-snippet>
          <h4 class="card-title">Kullanıcının Sahip Olduğu Yetkilerin Listesi</h4>
          <div class="row">
            <div class="col-12 mb-50">
                <div class="content-wrapper container-xxl p-0">
                    <div class="content-body">
                      <section id="basic-vertical-layouts">
                        <div class="row match-height">
                          <div class="col-md-12 col-12">
                            <core-card-snippet>
                              <div class="card-body">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <label for="name-vertical">Yetkiyi Seçiniz</label>
                                      <select class="form-control" ngModel name="slcOperationClaim"
                                        [(ngModel)]="operationClaim.id" #slcOperationClaim="ngModel">
                                        <option *ngFor="let item of operationClaims" value="{{item.id}}">{{item.name}}</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div style="display:flex;justify-content: end;">
                                        <button rippleEffect class="btn btn-success"
                                      (click)="add()">Ekle</button>
                                    </div>
                                  </div>
                              </div>
                            </core-card-snippet>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex justify-content-between align-items-center m-1">
                <label class="d-flex align-items-center"
                  >Göster
                  <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
          <ngx-datatable
            [rows]="userOperationClaims"
            [rowHeight]="58"
            class="bootstrap core-bootstrap"
            [limit]="10"
            [headerHeight]="40"
            [footerHeight]="50"
            [scrollbarH]="true"
            [limit]="basicSelectedOption"
          >
            <ngx-datatable-column
              [width]="200"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                    id="headerChkbxRef"
                  />
                  <label class="custom-control-label" for="headerChkbxRef"></label>
                </div>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    [checked]="isSelected"
                    (change)="onCheckboxChangeFn($event)"
                    id="rowChkbxRef{{ rowIndex }}"
                  />
                  <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Kullanıcı" prop="userName" [width]="400">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                {{row.userName}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Yetkileri" prop="operationClaimTitle" [width]="400">
                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                  {{row.operationClaimTitle}}
                </ng-template>
              </ngx-datatable-column>
            <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
              <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                <div class="d-flex align-items-center">
                  <div ngbDropdown container="body">
                    <a
                      ngbDropdownToggle
                      href="javascript:void(0);"
                      class="hide-arrow"
                      id="dropdownBrowserState"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                      <a routerLink="/home/updateuseroperationclaim/{{row.id}}" ngbDropdownItem><i data-feather="edit-2"
                        class="mr-50"></i><span>Güncelle</span></a>
                      <button ngbDropdownItem (click)="delete(row.id)">
                        <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </core-card-snippet>
      </section>
    </div>
  </div>