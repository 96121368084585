<ng-container *ngIf="layout === 'vertical'">

  <ng-container *ngFor="let item of menu">

    <ng-container *ngIf="item.type == 'section'">
      <ng-container *ngFor="let item of item.children">
        <li core-menu-vertical-item *ngIf="item.type == 'item'" [item]="item"
          [ngClass]="{ disabled: item.disabled === true }" [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
          <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
        </li>
        <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible'" [item]="item" class="nav-item has-sub">
        </li>
      </ng-container>
    </ng-container>

    <li core-menu-vertical-item *ngIf="item.type == 'item'"s [item]="item"
      [ngClass]="{ disabled: item.disabled === true }" [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>


    <li core-menu-vertical-collapsible *ngIf="item.type == 'collapsible'" [item]="item" class="nav-item has-sub"></li>
  </ng-container>
</ng-container>


<ng-container *ngIf="layout === 'horizontal'">
  <ng-container *ngFor="let item of menu">
    <li core-menu-horizontal-collapsible *ngIf="item.type == 'section'" [item]="item" class="dropdown nav-item no-top-margin"></li>

    <li core-menu-horizontal-item *ngIf="item.type == 'item'" [item]="item"
      [ngClass]="{ disabled: item.disabled === true }" [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }">
      <span [routerLink]="item.openInNewTab ? [] : [item.url]" class="d-none"></span>
    </li>

    <li core-menu-horizontal-collapsible *ngIf="item.type == 'collapsible'" [item]="item"
      class="dropdown dropdown-submenu"></li>

  </ng-container>

</ng-container>



<footer class="ml-5 fixed ">
  <br><br><br>
  <div id="applicationContainer" class="card mb-0 text-truncate">

    <div>
      <p style="font-size: 10px;">
        <span class="text-center">Uygulamalarımız</span>
        <br>
        <a href="https://play.google.com/store/apps/details?id=com.arca.evomobile" target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-google-play" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7367f0" fill="none" stroke-linecap="round"
            stroke-linejoin="round" style="margin:2px;">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M4 3.71v16.58a.7 .7 0 0 0 1.05 .606l14.622 -8.42a.55 .55 0 0 0 0 -.953l-14.622 -8.419a.7 .7 0 0 0 -1.05 .607z" />
            <path d="M15 9l-10.5 11.5" />
            <path d="M4.5 3.5l10.5 11.5" />
          </svg>
          <span class="applicationContent">
            Google Play
          </span>
        </a>
        ve
        <a href=" https://apps.apple.com/app/evo/id6450925028" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-appstore" width="24"
            height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7367f0" fill="none" stroke-linecap="round"
            stroke-linejoin="round" style="margin:2px;">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
            <path d="M8 16l1.106 -1.99m1.4 -2.522l2.494 -4.488" />
            <path d="M7 14h5m2.9 0h2.1" />
            <path d="M16 16l-2.51 -4.518m-1.487 -2.677l-1 -1.805" />
          </svg>
          <span class="applicationContent">
            App Store
          </span></a>
      </p>
    </div>
  </div>
</footer>



<!-- <footer class="ml-5 fixed-bottom mb-2">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p class="clearfix mb-0">
          <span class="float-md-right d-block d-md-inline-block mt-25 mr-5">
            COPYRIGHT &copy; 
            <a class="ml-25" href="https://arcayazilim.com" target="_blank">Arca Yazılım</a>
            <span class="d-none d-sm-inline-block">, Bütün hakları saklıdır.</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</footer> -->