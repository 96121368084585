import { Component, OnInit } from '@angular/core';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-operationclaim-list',
  templateUrl: './evo-operationclaim-list.component.html',
  styles: [
  ]
})
export class EvoOperationClaimListComponent implements OnInit {

  constructor(private operationClaimService: EvoOperationClaimService,
    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
  ) { }

  public basicSelectedOption: number = 10;

  operationClaims: OperationClaim[];
  filterText: string = "";
  deletedOperationClaimId: number;

  ngOnInit(): void {
    this.list();
  }

  list() {
    this.operationClaimService.getList().subscribe((items: any) => {
      this.operationClaims = items.data;
      this.informationService.list(items.message)

    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.operationClaimService.delete(id).subscribe((item: any) => {
      this.informationService.delete(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 1500);
    });
  }

  refresh() {
    window.location.reload();
  }
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Yetkiler";
    this.helperService.exportExcel(element, title);
  }

}
