import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Partner } from 'app/evo/models/partner';
import { Project } from 'app/evo/models/project';
import { ProjectStatus } from 'app/evo/models/projectstatus';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPartnerService } from 'app/evo/services/evo-customer.service';
import { EvoProjectService } from 'app/evo/services/evo-project.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-project-update-form',
  templateUrl: './evo-project-update-form.component.html',
  styles: [
  ]
})
export class EvoProjectUpdateFormComponent implements OnInit {
  id: number;
  myId: number;
  partners: Partner[] = [];
  project: Project;

  projectStatus: ProjectStatus = new ProjectStatus();
  projectStatuses: ProjectStatus[] = [
    { id: 1, name: "Devam Ediyor" },
    { id: 2, name: "Tamamlandı" }
  ];

  constructor(
    private projectService: EvoProjectService,
    private partnerService: EvoPartnerService,
    private transactionService: EvoTransactionService,

    private route: ActivatedRoute,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
    private decodeService: DecodeService,
  ) { }


  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.id = this.route.snapshot.params['Id'];
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(this.id, 3, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.projectService.get(this.id).subscribe((item: any) => {
        this.project = item.data;
      });
      this.partnerService.getList().subscribe((res: any) => {
        this.partners = res.data;
      });
    }


  }
  update() {
    this.project.updatedBy = this.myId;
    this.project.deletedBy = 0;
    this.projectService.update(this.project).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/projects"]);
      }, 1500);
    });
  }
}