import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { User } from 'app/evo/models/user';
import { UserOpertaionClaim } from 'app/evo/models/useroperationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { EvoUseroperationclaimService } from 'app/evo/services/evo-useroperationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-useroperationclaim-update-form',
  templateUrl: './evo-useroperationclaim-update-form.component.html',
  styles: [
  ]
})
export class EvoUserOperationClaimUpdateFormComponent implements OnInit {

  userOperationClaim: UserOpertaionClaim;

  constructor(
    private userOperationClaimService:EvoUseroperationclaimService, 
    private userService:EvoUserService,
    private operationClaimService:EvoOperationClaimService,
    private route:ActivatedRoute, 
    private router:Router,
    private informationService:InformationService,
    private errorService:ErrorService,
  ) { }
  id:number;
  operationClaims:OperationClaim[]=[];

  users:User[]=[];

  ngOnInit(): void {
    this.userService.getList().subscribe((res:any)=>{
      this.users=res.data;
    });

    this.operationClaimService.getList().subscribe((res:any)=>{

      this.operationClaims=res.data;
    });
    this.id=this.route.snapshot.params['Id'];

    this.userOperationClaimService.get(this.id).subscribe((item:any)=>{
      this.userOperationClaim=item.data;
    });
  }
  update(){
    this.userOperationClaimService.update(this.userOperationClaim).subscribe((item: any) => {
      this.informationService.update(item.message);
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
    setTimeout(() => {
      this.router.navigate(["/home/useroperationclaims"]);
    }, 1500);  
    });
  }
}