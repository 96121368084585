<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="ngx-datatable-kitchen-sink">
      <core-card-snippet>
        <h4 class="card-title">Bölümlerin Listesi</h4>
        <div class="row">
          <div class="firstRow mt-1">
            <div style="margin-left:9px; margin-bottom: 20px;">
              <div class="col-12">
                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control mr-1" name="filterText" placeholder="Bölüm Arayın.."
                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mb-1 ml-1">
            <button class="btn btn-primary btn-sm ml-1 mr-1" routerLink="/home/adddepartment" rippleEffect
              title="Yeni Departman">
              <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
            </button>
            <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
              <i data-feather="file-text" class=""></i>
            </button>
          </div>
        </div>
        <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
          <thead>
            <th>Departman Adı</th>
          </thead>
          <tbody>
            <tr *ngFor="let item of departments">
              <td>{{item.name}}</td>
            </tr>
          </tbody>
        </table>
        <ngx-datatable [rows]="departments |evoDepartment:filterText" [rowHeight]="58" class="bootstrap core-bootstrap"
          [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
          <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
            [resizeable]="false">
            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                  (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                <label class="custom-control-label" for="headerChkbxRef"></label>
              </div>
            </ng-template>
            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
              let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Adı" prop="name" [width]="600">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              {{row.name.length > 80 ? row.name.slice(0, 80) + '...' : row.name}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
              <div class="d-flex align-items-center">
                <div ngbDropdown container="body">
                  <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                  </a>
                  <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                    <a routerLink="/home/updatedepartment/{{row.id}}" ngbDropdownItem><i data-feather="edit-2"
                        class="mr-50"></i><span>Güncelle</span></a>
                    <button ngbDropdownItem (click)="deletedDeparmentId=row.id" data-bs-toggle="modal"
                      data-bs-target="#deletedepartment">
                      <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                    </button>
                  </div>
                </div>
                <a routerLink="/home/updatedepartment/{{row.id}}" ngbDropdownItem title="Güncelle"><i
                    data-feather="edit" class="text-primary cursor-pointer"></i></a>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </core-card-snippet>
      <div class="modal fade" id="deletedepartment" tabindex="-1" aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">Departman Sil</h1>
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                  class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
              Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
              <div>
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                  (click)="delete(deletedDeparmentId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>