import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoTicket'
})
export class EvoTicketPipe implements PipeTransform {
  
  transform(value: any[], filterText: string): any[] {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }
  
    return value.filter(p => {
      
      const productName = p.subject?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const departmentName = p.departmentName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const trackingNumber = String(p.trackingNumber)?.includes(filterText);
      const partnerName = p.partnerName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const partnerTicketNo = p.partnerTicketNo?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
  
      return (productName || departmentName || trackingNumber || partnerName || partnerTicketNo);
    });
  }
  
}
