import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Goal } from 'app/evo/models/goal';
import { GoalDetail } from 'app/evo/models/goaldetail';
import { GoalStatus } from 'app/evo/models/goalstatus';
import { ObservationPeriod } from 'app/evo/models/observationperiod';
import { Scale } from 'app/evo/models/scale';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoGoalDetailService } from 'app/evo/services/evo-goal-detail.service';
import { EvoGoalStatusService } from 'app/evo/services/evo-goal-status.service';
import { EvoGoalService } from 'app/evo/services/evo-goal.service';
import { EvoPermissionUserService } from 'app/evo/services/evo-permission-user.service';
import { EvoPermissionService } from 'app/evo/services/evo-permission.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-evo-goal',
  templateUrl: './evo-goal.component.html',
  styles: [
  ]
})
export class EvoGoalComponent implements OnInit {

  basicSelectedOption: number = 10;


  alertClose: boolean = false;
  listingRestriction: boolean;

  myId: number = 0;
  userRole: string = "";
  userName: string = "";
  filterText: string = "";
  deletedGoalId: number = 0;
  deletedGoalDetailId: number;
  deletedGoalStatusId: any;

  users: User[] = [];
  goals: Goal[] = [];
  goalDetails: any = {};
  groupedGoals: any[] = [];
  goalsForSelect: Goal[] = [];
  goalStatuses: GoalStatus[] = [];
  newGoalDetails: GoalDetail[] = [];

  user: User = new User();
  goal: Goal = new Goal();
  scale: Scale = new Scale();
  goalForAdd: Goal = new Goal();
  goalDetail: GoalDetail = new GoalDetail();
  goalStatus: GoalStatus = new GoalStatus();
  editGoalStatus: GoalStatus = new GoalStatus();
  goalStatusForSelect: GoalStatus = new GoalStatus();
  observationPeriod: ObservationPeriod = new ObservationPeriod();

  groupedData: any = {};
  tableColumns: any[] = [];

  observationPeriods: ObservationPeriod[] = [
    { id: 1, name: "Yıllık" },
    { id: 2, name: "Aylık" }
  ];
  scales: Scale[] = [
    { id: 1, name: "Rakam" },
    { id: 2, name: "Dönem" },
  ];

  constructor(
    private goalService: EvoGoalService,
    private goalDetailService: EvoGoalDetailService,
    private goalStatusService: EvoGoalStatusService,
    private permissionService: EvoPermissionService,
    private permissionUserService: EvoPermissionUserService,
    private userService: EvoUserService,

    private informationService: InformationService,
    private router: Router,
    private helperService: HelperService,
    private errorService: ErrorService,
    private decodeService: DecodeService,
  ) { }

  ngOnInit(): void {
    this.userRole = this.decodeService.getRole();
    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
      this.router.navigate(['/home']);
    }
    else {
      this.myId = this.decodeService.getUserId();
      this.user.id = this.myId;
      this.getPermissions();
      if (this.userRole == "Admin" || this.userRole == "Consultant") {
        this.userService.getList().subscribe((res: any) => {
          this.users = res.data;
        });
      }
    }
  }

  getPermissions() {
    this.permissionUserService.getPermissionUserListByPermissionId(12, this.myId).subscribe((items: any) => {
      this.listingRestriction = !items.data;
      if (this.listingRestriction) {
        this.list();
      }
      else {
        this.informationService.warning("Bu Sayfayı Görmek için Yetkiniz Bulunmamaktadır.")
        this.router.navigate(['/home']);
      }
    });

  }
  list() {
    this.goalService.getStatusList().subscribe((res: any) => {
      this.goalStatuses = res.data;
      this.newGoalDetails = [];

      const observables = this.goalStatuses.map((status) => {
        return this.goalDetailService.getListByStatusId(this.user.id, status.id).pipe(
          map((response: any) => ({
            statusName: status.name,
            data: response.data
          }
          ))

        );

      });

      forkJoin(observables).subscribe((results: any) => {
        const groupedData = {};

        results.forEach((result) => {
          const statusName = result.statusName;
          const goalDetails = result.data;

          goalDetails.forEach((detail) => {
            const goalId = detail.goalId;

            if (!groupedData[statusName]) {
              groupedData[statusName] = {};
            }
            if (!groupedData[statusName][goalId]) {
              groupedData[statusName][goalId] = [];
            }
            groupedData[statusName][goalId].push(detail);
          });
        });
        this.goalStatuses.forEach((status) => {
          const statusName = status.name;

          if (!groupedData[statusName]) {
            groupedData[statusName] = {};
          }
        });
        this.groupedData = groupedData;
      });
    });
    this.goalService.getList().subscribe((res: any) => {
      this.goals = res.data;
    });

  }
  add(scaleIndicator: string, targeted: number, relaised: number, weight: number) {

    if (this.goalForAdd.id == 0) {
      this.informationService.warning("Lütfen Bir Stratejik Amaç Seçiniz.")
    }
    else {
      this.goalDetail = {
        id: 0,
        userId: this.user.id,
        goalId: this.goalForAdd.id,
        scaleIndicator: scaleIndicator,
        observationPeriodId: this.observationPeriod.id,
        scaleId: this.scale.id,
        targeted: targeted,
        relaised: relaised,
        weight: weight,
        point: 0,

        userName: "",
        scaleName: "",
        goalName: "",
        goalStatusId: 0,
        goalStatusName: "",
        observationPeriodName: ""
      };
      this.goalDetailService.add(this.goalDetail).subscribe((items: any) => {
        this.informationService.add(items.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    }
  }

  addGoal(name: string) {
    this.goal = {
      id: 0,
      name: name,
      statusId: this.goalStatus.id,
      goalStatusName: "",

    };
    this.goalService.add(this.goal).subscribe((items: any) => {
      this.informationService.add(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  addGoalStatus(name: string) {
    for (let i = 0; i < this.goalStatuses.length; i++) {
      if (this.goalStatuses[i].name.toLowerCase() == name.toLowerCase()) {
        this.informationService.warning("Bu Hedef Durumu Zaten Mevcut.");
        return;
      }
    }

    this.goalStatus = {
      id: 0,
      name: name,
    };
    this.goalStatusService.add(this.goalStatus).subscribe((items: any) => {
      this.informationService.add(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  update() {
    this.goalDetail.point = 0;
    if (this.goalDetail.goalId == 0) {
      this.informationService.warning("Lütfen Bir Stratejik Amaç Seçiniz.")
    }
    else {
      this.goalDetail.userId = this.user.id;
      this.goalDetailService.update(this.goalDetail).subscribe((items: any) => {
        this.informationService.update(items.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    }
  }

  updateGoal() {
    this.goalService.update(this.goal).subscribe((items: any) => {
      this.informationService.update(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  updateGoalStatus() {

    this.goalStatusService.update(this.goalStatus).subscribe((items: any) => {
      this.informationService.update(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  saveGoal(item: any, type: string) {
    this.goal = new Goal();
    if (item == null) {
      this.goalForAdd.id = 0;
    }
    else {
      if (type == "add") {
        this.goalService.get(item.goalId).subscribe((res: any) => {
          this.goal = res.data;
        });
      }
      if (type == "update") {
        this.goalService.get(item.goalId).subscribe((res: any) => {
          this.goal = res.data;
        });
      }
      if (type == "delete") {
        this.deletedGoalId = item.goalId;
        this.goalService.get(item.goalId).subscribe((res: any) => {
          this.goal = res.data;
          this.changeGoalStatus(this.goal.statusId);
        });
      }
    }


  }

  saveGoalDetail(item: any) {
    if (item == null) {
      this.goalForAdd.id = 0;
      this.goalStatusForSelect.id = 0;

    }
    if (item != null) {
      this.goalDetail = item;
      this.goalForAdd.id = this.goalDetail.goalId;
      this.goalStatusForSelect.id = this.goalDetail.goalStatusId;
      this.changeGoalStatus(this.goalStatusForSelect.id);
    }
  }

  saveGoalStatus(item: any) {
    this.goalStatuses.forEach((status) => {
      if (status.name == item) {
        this.goalStatus.id = status.id;
        this.goalStatus.name = status.name;
      }
    });
  }

  delete(id: number) {
    this.goalDetailService.delete(id).subscribe((items: any) => {
      this.informationService.delete(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  deleteGoal(id: number) {
    this.goalService.delete(id).subscribe((items: any) => {
      this.informationService.delete(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  deleteGoalStatus(id: number) {
    this.goalStatusService.delete(id).subscribe((items: any) => {
      this.informationService.delete(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }

  changeUser(userId: number) {
    this.user = new User();
    this.user.id = userId;
    this.getPermissions();
  }
  changeGoalStatus(statusId: number) {
    this.goal = new Goal();
    this.goal.id = 0;
    this.goalService.getGoalListByStatusId(statusId).subscribe((res: any) => {
      this.goalsForSelect = res.data;
    });
  }

  exportExcel() {
    let element = document.getElementById("table-excel");
    let title = "Hedef Kartları";
    this.helperService.exportExcel(element, title);
  }

  getGoalDetail(id: number) {
    this.goalDetailService.get(id).subscribe((res: any) => {
      this.goalDetail = res.data;
    });
  }
}
