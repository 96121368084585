import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-module-add-form',
  templateUrl: './evo-module-add-form.component.html',
})
export class EvoModuleAddFormComponent implements OnInit {

  constructor(
    private moduleService: EvoModuleService,
    private userService: EvoUserService,
    private transactionService: EvoTransactionService,
    private router: Router,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private errorService: ErrorService) { }

  module: Module = new Module();
  user: User = new User();
  users: User[] = [];
  myId: number;

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    let item = this.decodeService.getRole();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 7, 5, this.myId).subscribe((res: any) => {
      });
    }
    this.userService.getList().subscribe((items: any) => {
      this.users = items.data;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }
  add(name: string) {
    this.module = {
      id: 0,
      userId: this.user.id,
      name: name,
      userMail: "",
      userName: "",

      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };

    this.moduleService.add(this.module).subscribe((item: any) => {
      this.informationService.add(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/modules"]);
      }, 1500);
    });
  }
}
