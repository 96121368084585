import { Component, OnInit } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { PlanType } from 'app/evo/models/plantype';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';

@Component({
  selector: 'app-evo-plan-detail-main-sidebar',
  templateUrl: './evo-plan-detail-main-sidebar.component.html',
})
export class EvoPlanDetailMainSidebarComponent implements OnInit {

  public calendarRef = [];
  public tempRef = [];
  public checkAll = true;

  constructor(
    private _coreSidebarService: CoreSidebarService,
    private _calendarService: EvoPlanService) { }

  toggleEventSidebar() {
    this._coreSidebarService.getSidebarRegistry('calendar-event-sidebar').toggleOpen();
  }

  types:PlanType[]=[
    {id:1, name:"Destek Planı"},
    {id:2, name:"Proje Planı"},
    {id:3, name:"İzinler"},
    {id:4, name:"Resmi Tatiller"},
  ];

  AddEvent() {
    this.toggleEventSidebar();
    this._coreSidebarService.getSidebarRegistry('calendar-main-sidebar').toggleOpen();
  }
  allChecked() {
    return this.calendarRef.every(v => v.checked === true);
  }
  
  checkboxChange(event, id) {
    const index = this.calendarRef.findIndex(r => {
      if (r.id === id) {
        return id;
      }
    });
    this.calendarRef[index].checked = event.target.checked;
    //this._calendarService.calendarUpdate(this.calendarRef);
    this.checkAll = this.allChecked();
  }

  toggleCheckboxAll(event) {
    this.checkAll = event.target.checked;
    if (this.checkAll) {
      this.calendarRef.map(res => {
        res.checked = true;
      });
    } else {
      this.calendarRef.map(res => {
        res.checked = false;
      });
    }
    //this._calendarService.calendarUpdate(this.calendarRef);
  }

  ngOnInit(): void {
    this._calendarService.onCalendarChange.subscribe(res => {
      this.calendarRef = res;
    });
  }
}
