import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TicketActivity } from '../models/ticketactivity';

@Injectable({
  providedIn: 'root'
})
export class EvoTicketActivityService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList(): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetTicketActivityList');
  }
  getListByTicketUserId(ticketId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetTicketActivityListByTicketUserId/' + ticketId);
  }
  
  getUsersTicketActivities(userId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetUsersTicketActivities/' + userId);
  }
  getUsersTicketActivitiesWithTicketId(userId: number, ticketId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetUsersTicketActivitiesWithTicketId/' + userId + '/' + ticketId);
  }
  restrictedTicketActivities(userId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'RestrictedTicketActivities/' + userId);
  }
  restrictedTicketActivitiesWithTicketId(userId: number, ticketId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'RestrictedTicketActivitiesWithTicketId/' + userId + '/' + ticketId);
  }

  getDeletedTicketActivities (): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetDeletedTicketActivities/' );
  }
  getDeletedTicketActivitiesWithTicketId(ticketId: number): Observable<TicketActivity[]> {
    return this.httpClient.get<TicketActivity[]>(this.baseApiUrl + 'GetDeletedTicketActivitiesWithTicketId/' + ticketId);
  }

  get(id: number): Observable<TicketActivity> {
    return this.httpClient.get<TicketActivity>(this.baseApiUrl + 'GetTicketActivity/' + id);
  }
  add(ticketActivity: TicketActivity): Observable<TicketActivity> {
    return this.httpClient.post<TicketActivity>(this.baseApiUrl + 'AddTicketActivity/', ticketActivity);
  }
  update(ticketActivity: TicketActivity): Observable<TicketActivity> {
    return this.httpClient.post<TicketActivity>(this.baseApiUrl + "UpdateTicketActivity/", ticketActivity);
  }
  delete(ticketActivity: TicketActivity): Observable<TicketActivity> {
    return this.httpClient.post<TicketActivity>(this.baseApiUrl + "DeleteTicketActivity/",ticketActivity);
  }

  UpdateMultipleActivity(ticketActivities : TicketActivity[]):Observable<TicketActivity[]>{
    return this.httpClient.post<TicketActivity[]>(this.baseApiUrl +"UpdateMultipleActivity",ticketActivities )
  }

}
