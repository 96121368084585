import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Task } from '../models/task';

@Injectable({
  providedIn: 'root'
})
export class EvoTaskService {


  private baseApiUrl = environment.apiUrl;

  constructor(public httpClient: HttpClient) { }

  getList(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.baseApiUrl + "GetTaskList");
  }
  getListDto(): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.baseApiUrl + "GetTaskListDto");
  }
  getListDtoByUserId(userId: number): Observable<Task[]> {
    return this.httpClient.get<Task[]>(this.baseApiUrl + "GetTaskListByUserIdDto/" + userId);
  }
  getById(id: number): Observable<Task> {
    return this.httpClient.get<Task>(this.baseApiUrl + "GetTask/" + id);
  }
  add(task: Task): Observable<Task> {
    return this.httpClient.post<Task>(this.baseApiUrl + "AddTask", task);
  }
  update(task: Task): Observable<Task> {
    return this.httpClient.post<Task>(this.baseApiUrl + "UpdateTask", task);
  }
  delete(id: number): Observable<Task> {
    return this.httpClient.post<Task>(this.baseApiUrl + "DeleteTask/" + id, "");
  }
}
