<div class="row" *ngIf="userRole=='Admin'">
    <div class="col-lg-5 col-md-6 col-12" *ngIf="!gridView else gridViewTemplate">

        <core-card-snippet>
            <h4 class="card-title row col-12">
                <div class="col-9">
                    Zaman Çizgisi
                </div>
                <div class="col-3">
                    <button class="btn btn-group btn-outline-primary" (click)="gridViewChange()">
                        Liste görünümü
                    </button>
                </div>
            </h4>

            <div class="card-body">
                <ul class="timeline">
                    <li class="timeline-item" *ngFor="let item of transactions">
                        <span *ngIf="item.statusId==1"
                            class="timeline-point timeline-point-success timeline-point-indicator"></span>
                        <span *ngIf="item.statusId==2"
                            class="timeline-point timeline-point-primary timeline-point-indicator"></span>
                        <span *ngIf="item.statusId==3"
                            class="timeline-point timeline-point-danger timeline-point-indicator"></span>
                        <span *ngIf="item.statusId==4"
                            class="timeline-point timeline-point-warning timeline-point-indicator"></span>
                        <span *ngIf="item.statusId==5"
                            class="timeline-point timeline-point-secondary timeline-point-indicator"></span>
                        <div class="timeline-event ">
                            <span *ngIf="item.statusId==5">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6>{{item.subject}}</h6>
                                    <span class="timeline-event-time">
                                        {{item.createdDate | date: 'dd.MM.yyyy'}} <br>
                                        <span class="ml-2">
                                            {{item.createdDate | date: 'HH.mm'}}
                                        </span>
                                    </span>
                                </div>
                                <div class="media align-items-center">
                                    <span
                                        *ngIf="item.typeId == 1 || (item.typeId >= 2 && item.typeId <= 14 && item.eventNumber != 0)">
                                        {{item.userName}} kullanıcısı tarafından
                                        <ng-container *ngIf="item.typeId == 1; else otherCases">
                                            <a [routerLink]="['/home/details/', item.eventNumber]"
                                                class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2"
                                                target="">
                                                #{{item.eventNumber}}
                                            </a> Numaralı Talep görüntülenmeye çalışıldı
                                        </ng-container>
                                        <span
                                            *ngIf="(item.typeId==6 || item.typeId==12 || item.typeId==9 || item.typeId==10) && item.eventNumber!=0">
                                            {{item.userName}} kullanıcısı tarafından
                                            #{{item.eventNumber}} numaralı {{getTypeText(item.typeId)}} güncellenmeye
                                            çalışıldı
                                        </span>

                                        <ng-template #otherCases>
                                            <a [routerLink]="getRouterLink(item.typeId, item.eventNumber)"
                                                class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                target="">
                                                #{{item.eventNumber}}
                                            </a> Numaralı {{getActionName(item.typeId)}} görüntülenmeye çalışıldı
                                        </ng-template>
                                    </span>
                                    <span *ngIf="item.eventNumber==0">
                                        {{item.userName}} kullanıcısı tarafından {{item.typeName}} sayfası
                                        götüntülenmeye çalışıldı
                                    </span>

                                </div>
                            </span>
                            <span *ngIf="item.statusId==1">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6>Yeni {{getActionName(item.typeId)}} Eklendi</h6>
                                    <span class="timeline-event-time">
                                        {{item.createdDate | date: 'dd.MM.yyyy'}} <br>
                                        <span class="ml-2">
                                            {{item.createdDate | date: 'HH.mm'}}
                                        </span>
                                    </span>
                                </div>
                                <span *ngIf="item.typeId == 1 || (item.typeId >= 2 && item.typeId <= 14 )">
                                    {{item.userName}} kullanıcısı tarafından
                                    <ng-container *ngIf="item.typeId == 1; else otherCases">
                                        <a [routerLink]="['/home/details/', item.eventNumber]"
                                            class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı Talep oluşturuldu
                                    </ng-container>

                                    <ng-template #otherCases>
                                        <a [routerLink]="getRouterLink(item.typeId, item.eventNumber)"
                                            class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı {{getActionName(item.typeId)}} oluşturuldu
                                    </ng-template>
                                </span>

                            </span>
                            <span *ngIf="item.statusId==3">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6>{{getActionName(item.typeId)}} Verisi Silindi</h6>
                                    <span class="timeline-event-time">
                                        {{item.createdDate | date: 'dd.MM.yyyy'}} <br>
                                        <span class="ml-2">
                                            {{item.createdDate | date: 'HH.mm'}}
                                        </span>
                                    </span>
                                </div>
                                <span *ngIf="item.typeId == 1 || (item.typeId >= 2 && item.typeId <= 14 )">
                                    <ng-container *ngIf="item.typeId == 1; else otherCases">
                                        <a [routerLink]="['/home/details/', item.eventNumber]"
                                            class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a>
                                    </ng-container>
                                    <ng-template #otherCases>
                                        <a [routerLink]="getRouterLink(item.typeId, item.eventNumber)"
                                            class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı {{getActionName(item.typeId)}} Silindi
                                    </ng-template>
                                </span>
                                <div class="media align-items-center mt-2">
                                    <div *ngIf="item.imageURL==null; else imageTemplate">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-danger avatar-md mr-50">
                                                <span class="avatar-content"> {{ getInitials(item.userName) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #imageTemplate>
                                        <div class="avatar">
                                            <img src="../../../../assets/images/img/{{item.imageURL}}" height="30"
                                                width="30" />
                                        </div>
                                    </ng-template>
                                    <div class="ml-1 media-body">{{item.userName}}</div>
                                </div>

                            </span>
                            <span *ngIf="item.statusId==2">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6>{{getActionName(item.typeId)}} Verisi Düzenlendi</h6>
                                    <span class="timeline-event-time">
                                        {{item.createdDate | date: 'dd.MM.yyyy'}} <br>
                                        <span class="ml-2">
                                            {{item.createdDate | date: 'HH.mm'}}
                                        </span>

                                    </span>
                                </div>
                                <span *ngIf="item.typeId == 1 || (item.typeId >= 2 && item.typeId <= 14 )">
                                    <ng-container *ngIf="item.typeId == 1; else otherCases">
                                        <a [routerLink]="['/home/details/', item.eventNumber]"
                                            class="badge badge-pill badge-primary font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı Talepte Şu Değişiklikler Yapıldı;
                                        <br>
                                        <span class="text-muted font-small-3 ml-5"> {{item.subject}}</span>

                                    </ng-container>
                                    <ng-template #otherCases>
                                        <a [routerLink]="getRouterLink(item.typeId, item.eventNumber)"
                                            class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı {{getActionName(item.typeId)}} İçerisinde Şu Değişiklikler
                                        Yapıldı;
                                        <br>
                                        <span class="text-muted font-small-3 ml-5"> {{item.subject}}</span>
                                    </ng-template>
                                </span>
                                <div class="media align-items-center mt-1">
                                    <div *ngIf="item.imageURL==null; else imageTemplate">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-primary avatar-md mr-50">
                                                <span class="avatar-content"> {{ getInitials(item.userName) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #imageTemplate>
                                        <div class="avatar">
                                            <img src="../../../../assets/images/img/{{item.imageURL}}" height="30"
                                                width="30" />
                                        </div>
                                    </ng-template>
                                    <div class="ml-1 media-body">{{item.userName}}</div>
                                    <div
                                        class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mr-1 mb-1">
                                        <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                                            ng-reflect-ng-class="[object Object]">
                                            <span placement="top" container="body" class="avatar-content"
                                                (click)="getTransaction(item.id)" ng-reflect-placement="top"
                                                ngbTooltip="Düzenlenen Kısmı Görüntüle" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" data-bs-toggle="modal"
                                                data-bs-target="#viewdesigned"><i
                                                    class="feather avatar-icon font-medium-1 icon-eye"
                                                    ng-reflect-ng-class="[object Object]"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                            </span>
                            <span *ngIf="item.statusId==4">
                                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                                    <h6>{{getActionName(item.typeId)}} Verisi
                                        {{getTransactionStatusName(item.errorStatusId)}} İşleminde Bir Hata Oluştu</h6>
                                    <span class="timeline-event-time">
                                        {{item.createdDate | date: 'dd.MM.yyyy'}} <br>
                                        <span class="ml-2">
                                            {{item.createdDate | date: 'HH.mm'}}
                                        </span>
                                    </span>
                                </div>
                                <span *ngIf="item.typeId == 1 || (item.typeId >= 2 && item.typeId <= 14 )">
                                    <ng-container *ngIf="item.typeId == 1; else otherCases">
                                        <a [routerLink]="['/home/details/', item.eventNumber]"
                                            class="badge badge-pill badge-warning font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a>
                                    </ng-container>
                                    <ng-template #otherCases>
                                        <a [routerLink]="getRouterLink(item.typeId, item.eventNumber)"
                                            class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                            target="">
                                            #{{item.eventNumber}}
                                        </a> Numaralı {{getActionName(item.typeId)}}
                                        {{getTransactionStatusName(item.errorStatusId)}} İşlemi Gerçekleştirilemedi
                                    </ng-template>
                                </span>
                                <div class="media align-items-center mt-2">
                                    <div *ngIf="item.imageURL==null; else imageTemplate">
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-danger avatar-md mr-50">
                                                <span class="avatar-content"> {{ getInitials(item.userName) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-template #imageTemplate>
                                        <div class="avatar">
                                            <img src="../../../../assets/images/img/{{item.imageURL}}" height="30"
                                                width="30" />
                                        </div>
                                    </ng-template>
                                    <div class="ml-1 media-body">{{item.userName}}</div>
                                    <div
                                        class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mr-1 mb-1">
                                        <div class="avatar avatar-status ng-star-inserted bg-light-warning"
                                            ng-reflect-ng-class="[object Object]">
                                            <span placement="top" container="body" class="avatar-content"
                                                (click)="getTransaction(item.id)" ng-reflect-placement="top"
                                                ngbTooltip="Sorunun Sebebini İncele" ng-reflect-container="body"
                                                ng-reflect-ngb-tooltip="[object Object]" data-bs-toggle="modal"
                                                data-bs-target="#viewerrorsubject"><i
                                                    class="feather avatar-icon font-medium-1 icon-eye"
                                                    ng-reflect-ng-class="[object Object]"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </li>
                </ul>
                <div class="text-center mt-2">
                    <button class="btn btn-group text btn-primary" (click)="increasePageSize(pageSize)"> Önceki
                        İşlemler</button>
                </div>
            </div>
        </core-card-snippet>
    </div>

    <ng-template #gridViewTemplate>
        <div class="col-lg-12 col-md-12 col-12">
            <div class="content-body">
                <section id="ngx-datatable-kitchen-sink">
                    <core-card-snippet>
                        <div class="row col-12">
                            <div class="col-9">
                                <h4 class="card-title">Zaman Çizgisi</h4>
                            </div>
                            <div class="col-2">
                                <button class="btn btn-group btn-outline-primary mr-2" (click)="gridViewChange()">
                                    Çizelge Görünümü
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="firstRow mt-1">
                                <div style="margin-left:9px;">
                                    <div class="col-12">
                                        <select class="form-control form-select"
                                            [(ngModel)]="basicSelectedOptionForTransaction">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="filter mt-1">
                                <div class="col-12">
                                    <ng-select [multiple]="true" [items]="users"
                                        [(ngModel)]="selectedUsersForTransaction" placeholder="Kullanıcı Seçiniz "
                                        bindLabel="firstName" bindValue="id"
                                        (change)="tableByFiltersIdForTransaction()">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label">{{
                                                item.firstName }}
                                                {{item.lastName}}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="filter mt-1">
                                <div class="col-12">
                                    <ng-select [multiple]="true" [items]="types"
                                        [(ngModel)]="selectedTypesForTransaction" placeholder="Tür Seçiniz "
                                        bindLabel="name" bindValue="id" (change)="tableByFiltersIdForTransaction()">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label">{{
                                                item.name }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div class="filter mt-1">
                                <div class="col-12">
                                    <ng-select [multiple]="true" [items]="statusesForTransaction"
                                        [(ngModel)]="selectedStatusesForTransaction" placeholder="Durum Seçiniz "
                                        bindLabel="name" bindValue="id" (change)="tableByFiltersIdForTransaction()">
                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                            <span class="ng-value-label">{{ item.name }}</span>
                                            <span class="ng-value-icon right" (click)="clear(item)"
                                                aria-hidden="true">×</span>
                                        </ng-template>
                                        <ng-template ng-header-tmp>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>

                            <div class="row col-12 ml-1 mt-1 mb-2">
                                <div class="col-1">
                                    <button class="btn btn-info btn-sm ml-1 " title="Excele Aktar" rippleEffect
                                        (click)="exportExcelTransactions()">
                                        <i data-feather="file-text"></i>
                                    </button>
                                </div>
                                <div class="col-3"></div>
                                <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
                                    <form class="form-inline">
                                        <div class="form-group">
                                            <div class="input-group w-0">
                                                <input name="datepicker" class="form-control" ngbDatepicker
                                                    #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                    (dateSelect)="onDateSelectionForTransaction($event)"
                                                    [displayMonths]="2" [dayTemplate]="rangeSelectionDP"
                                                    outsideDays="hidden" [startDate]="fromDateExampleForTransaction!" />
                                                <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                    <span class="custom-day" type="datetime" [class.focused]="focused"
                                                        [class.range]="isRangeForTransaction(date)"
                                                        [class.faded]="isHoveredForTransaction(date) || isInsideForTransaction(date)"
                                                        (mouseenter)="hoveredDateForTransaction = date"
                                                        (mouseleave)="hoveredDateForTransaction = null">
                                                        {{ date.day }}
                                                    </span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input #dpFromDate class="form-control" type="date"
                                                    placeholder="dd-MM-yyyy" name="dpFromDate"
                                                    [ngModel]="checkType(fromDateExampleForTransaction) ? formatter.format(fromDateExampleForTransaction) : fromDateExampleForTransaction"
                                                    (ngModelChange)="fromDateExampleForTransaction = $event; tableByDatesForTransaction();" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary feather icon-calendar"
                                                        (click)="datepicker.toggle()" type="button"
                                                        rippleEffect></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group ml-sm-2">
                                            <div class="input-group">
                                                <input #dpToDate class="form-control" type="date"
                                                    placeholder="dd-MM-yyyy" name="dpToDate"
                                                    [ngModel]="checkType(toDateExampleForTransaction) ? formatter.format(toDateExampleForTransaction) : toDateExampleForTransaction"
                                                    (ngModelChange)="toDateExampleForTransaction = $event; tableByDatesForTransaction();" />
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary feather icon-calendar"
                                                        (click)="datepicker.toggle()" type="button"
                                                        rippleEffect></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <table class="table" id="excel-table-transaction" style="text-align: center; display: none;">
                            <tbody>
                                <tr>
                                    <td>İşlem Türü</td>
                                    <td>Konusu</td>
                                    <td>İşlem Durumu</td>
                                    <td>Kullanıcı Adı</td>
                                    <td>İşlem Tarihi</td>
                                </tr>
                                <tr *ngFor="let item of transactionsForGrid">
                                    <td *ngIf="item.typeId==1">Talep</td>
                                    <td *ngIf="item.typeId==2">Talep Kullanıcı</td>
                                    <td *ngIf="item.typeId==3">Proje</td>
                                    <td *ngIf="item.typeId==4">Sözleşme</td>
                                    <td *ngIf="item.typeId==5">Sözleşme Detayı</td>
                                    <td *ngIf="item.typeId==6">Efor</td>
                                    <td *ngIf="item.typeId==7">Modül</td>
                                    <td *ngIf="item.typeId==8">Cari</td>
                                    <td *ngIf="item.typeId==9">Cari Kullanıcısı</td>
                                    <td *ngIf="item.typeId==10">Kullanıcı İzinleri</td>
                                    <td *ngIf="item.typeId==11">Plan</td>
                                    <td *ngIf="item.typeId==12">Aktivite</td>
                                    <td *ngIf="item.typeId==13">Kullanıcı</td>
                                    <td *ngIf="item.typeId==14">Kullanıcının Yetkileri</td>

                                    <td>{{item.subject}}</td>

                                    <td *ngIf="item.statusId==1">Ekleme İşlemi</td>
                                    <td *ngIf="item.statusId==2">Güncelleme İşlemi</td>
                                    <td *ngIf="item.statusId==3">Silme İşlemi</td>
                                    <td *ngIf="item.statusId==4">Hata</td>
                                    <td *ngIf="item.statusId==5">Yetkisiz Deneme</td>

                                    <td>{{item.userName}}</td>
                                    <td [innerHTML]="item.createdDate | date:'yyyy-MM-dd HH:mm:ss'"></td>

                                </tr>
                            </tbody>
                        </table>
                        <ngx-datatable [rows]="transactionsForGrid" [rowHeight]="58" class="bootstrap core-bootstrap"
                            [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                            [limit]="basicSelectedOption">
                            <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false"
                                [draggable]="false" [resizeable]="false">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    <div class="d-flex align-items-center">
                                        <div ngbDropdown container="body">
                                            <span *ngIf="row.statusId==2">
                                                <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                                                    ng-reflect-ng-class="[object Object]">
                                                    <span placement="top" container="body"
                                                        class="avatar-content pull-up" (click)="getTransaction(row.id)"
                                                        ng-reflect-placement="top" ngbTooltip="Görüntüle"
                                                        ng-reflect-container="body"
                                                        ng-reflect-ngb-tooltip="[object Object]" data-bs-toggle="modal"
                                                        data-bs-target="#viewdesigned"><i
                                                            class="feather avatar-icon font-medium-1 icon-eye"
                                                            ng-reflect-ng-class="[object Object]"></i></span>
                                                </div>
                                            </span>
                                            <span *ngIf="row.statusId==1">
                                                <div class="avatar avatar-status ng-star-inserted bg-light-success"
                                                    ng-reflect-ng-class="[object Object]">
                                                    <span placement="top" container="body" class="avatar-content"
                                                        ng-reflect-ngb-tooltip="[object Object]"><i
                                                            class="feather avatar-icon font-medium-1 icon-target"
                                                            ng-reflect-ng-class="[object Object]"></i></span>
                                                </div>
                                            </span>
                                            <span *ngIf="row.statusId==3">
                                                <div class="avatar avatar-status ng-star-inserted bg-light-danger"
                                                    ng-reflect-ng-class="[object Object]">
                                                    <span placement="top" container="body" class="avatar-content"
                                                        ng-reflect-ngb-tooltip="[object Object]"><i
                                                            class="feather avatar-icon font-medium-1 icon-target"
                                                            ng-reflect-ng-class="[object Object]"></i></span>
                                                </div>
                                            </span>
                                            <span *ngIf="row.statusId==4">
                                                <div class="avatar avatar-status ng-star-inserted bg-light-warning"
                                                    ng-reflect-ng-class="[object Object]">
                                                    <span placement="top" container="body"
                                                        class="avatar-content pull-up" (click)="getTransaction(row.id)"
                                                        ng-reflect-placement="top" ngbTooltip="Görüntüle"
                                                        ng-reflect-container="body"
                                                        ng-reflect-ngb-tooltip="[object Object]" data-bs-toggle="modal"
                                                        data-bs-target="#viewerrorsubject"><i
                                                            class="feather avatar-icon font-medium-1 icon-eye"
                                                            ng-reflect-ng-class="[object Object]"></i></span>
                                                </div>
                                            </span>
                                            <span *ngIf="row.statusId==5">
                                                <div class="avatar avatar-status ng-star-inserted bg-light-secondary"
                                                    ng-reflect-ng-class="[object Object]">
                                                    <span placement="top" container="body" class="avatar-content"
                                                        ng-reflect-ngb-tooltip="[object Object]"><i
                                                            class="feather avatar-icon font-medium-1 icon-target"
                                                            ng-reflect-ng-class="[object Object]"></i></span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>

                            </ngx-datatable-column>

                            <ngx-datatable-column name="İşlem Türü" prop="typeId" [width]="150">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    {{getActionName(row.typeId)}}
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Kullanıcısı" prop="createdBy" [width]="250">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    <span>
                                        <div *ngIf="row.imageURL!=null" class="avatar pull-up mr-2" placement="top">
                                            <img src="../../../../assets/images/img/{{row.imageURL}}" class="circle"
                                                alt="{{getInitials(row.userName) }}" width="40" height="40" />
                                        </div>
                                        <div *ngIf="row.imageURL==null"
                                            class="avatar ml-0 ng-star-inserted bg-light-success pull-up mr-2"
                                            style="width: 40px; height: 40px;" placement="top">
                                            <div class="avatar-content text-center"
                                                style="font-size: medium; margin: 5px 0px 0px 5px;">
                                                {{ getInitials(row.userName) }}
                                            </div>
                                        </div>
                                        {{row.userName}}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>

                            <ngx-datatable-column name="Konu" prop="subject" [width]="500">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    <span *ngIf="row.statusId==1">
                                        <span *ngIf="row.typeId == 1 || (row.typeId >= 2 && row.typeId <= 14 )">
                                            <ng-container *ngIf="row.typeId == 1; else otherCases">
                                                <a [routerLink]="['/home/details/', row.eventNumber]"
                                                    class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı Talep oluşturuldu
                                            </ng-container>

                                            <ng-template #otherCases>
                                                <a [routerLink]="getRouterLink(row.typeId, row.eventNumber)"
                                                    class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı {{getActionName(row.typeId)}} oluşturuldu
                                            </ng-template>
                                        </span>
                                    </span>
                                    <span *ngIf="row.statusId==2">
                                        <span *ngIf="row.typeId == 1 || (row.typeId >= 2 && row.typeId <= 14 )">
                                            <ng-container *ngIf="row.typeId == 1; else otherCases">
                                                <a [routerLink]="['/home/details/', row.eventNumber]"
                                                    class="badge badge-pill badge-primary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı Talepte Şu Değişiklikler Yapıldı;
                                                <br>
                                                <span class="text-muted font-small-3 ml-5"> {{row.subject}}</span>
                                            </ng-container>
                                            <ng-template #otherCases>
                                                <a [routerLink]="getRouterLink(row.typeId, row.eventNumber)"
                                                    class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı {{getActionName(row.typeId)}} İçerisinde Şu Değişiklikler
                                                Yapıldı;
                                                <br>
                                                <span class="text-muted font-small-3 ml-5"> {{row.subject}}</span>
                                            </ng-template>
                                        </span>
                                    </span>
                                    <span *ngIf="row.statusId==3">
                                        <span *ngIf="row.typeId == 1 || (row.typeId >= 2 && row.typeId <= 14 )">
                                            <ng-container *ngIf="row.typeId == 1; else otherCases">
                                                <a [routerLink]="['/home/details/', row.eventNumber]"
                                                    class="badge badge-pill badge-success font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a>
                                            </ng-container>

                                            <ng-template #otherCases>
                                                <a class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="" style="color: white;">
                                                    #{{row.eventNumber}}
                                                </a>
                                                Numaralı {{getActionName(row.typeId)}} Silindi
                                            </ng-template>

                                        </span>
                                    </span>
                                    <span *ngIf="row.statusId==5">
                                        <span
                                            *ngIf="row.typeId == 1 || (row.typeId >= 2 && row.typeId <= 14 && row.eventNumber != 0)">
                                            <ng-container *ngIf="row.typeId == 1; else otherCases">
                                                <a [routerLink]="['/home/details/', row.eventNumber]"
                                                    class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı Talep görüntülenmeye çalışıldı
                                            </ng-container>
                                            <span
                                                *ngIf="(row.typeId==6 || row.typeId==12 || row.typeId==9 || row.typeId==10) && row.eventNumber!=0">
                                                #{{row.eventNumber}} numaralı {{getTypeText(row.typeId)}} güncellenmeye
                                                çalışıldı
                                            </span>

                                            <ng-template #otherCases>
                                                <a [routerLink]="getRouterLink(row.typeId, row.eventNumber)"
                                                    class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı {{getActionName(row.typeId)}} görüntülenmeye çalışıldı
                                            </ng-template>
                                        </span>
                                        <span *ngIf="row.eventNumber==0">
                                            {{row.typeName}} sayfası
                                            götüntülenmeye çalışıldı
                                        </span>
                                    </span>
                                    <span *ngIf="row.statusId==4">
                                        <span *ngIf="row.typeId == 1 || (row.typeId >= 2 && row.typeId <= 14 )">
                                            <ng-container *ngIf="row.typeId == 1; else otherCases">
                                                <a [routerLink]="['/home/details/', row.eventNumber]"
                                                    class="badge badge-pill badge-warning font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a>
                                            </ng-container>
                                            <ng-template #otherCases>
                                                <a [routerLink]="getRouterLink(row.typeId, row.eventNumber)"
                                                    class="badge badge-pill badge-secondary font-small-3 font-weight-bold line-height-2"
                                                    target="">
                                                    #{{row.eventNumber}}
                                                </a> Numaralı {{getActionName(row.typeId)}} için
                                                {{getTransactionStatusName(row.errorStatusId)}} İşlemi Gerçekleştirilemedi
                                            </ng-template>
                                        </span>
                                    </span>

                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Başlangıç Tarihi" prop="createdDate" [width]="250">
                                <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                    {{row.createdDate |date:'dd-MM-YYYY HH:mm'}}
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                        <!-- <div class="row mb-2 mt-1">
                            <div class="col-2">
                                <div class="text-center">
                                    <button class="btn btn-group btn-outline-primary"
                                        (click)="previousPage(pageNumber)">Sonraki</button>
                                </div>
                            </div>
                            <div class="col-8"></div>
                            <div class="col-2">

                                <div class="text-center">
                                    <button class="btn btn-group btn-outline-primary"
                                        (click)="nextPage(pageNumber)">Önceki</button>
                                </div>
                            </div>
                        </div> -->
                    </core-card-snippet>

                    <!-- <div class="modal fade" id="deleteticketactivity" tabindex="-1"
                            aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Aktivitesini
                                            Sil</h1>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                    </div>
                                    <div class="modal-body">
                                        Kaydı Silmek İstediğinize Emin Misiniz?
                                    </div>
                                    <div class="modal-footer">
                                        <div>
                                            <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                (click)="deleteActivity(deletedTicketActivityId)"><i
                                                    data-feather="trash" class="mr-50"></i>Sil</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                </section>
            </div>

        </div>

    </ng-template>

    <div [class]="gridView ? 'col-lg-12 col-md-12 col-12' : 'col-lg-7 col-md-6 col-12'">
        <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills">
            <li ngbNavItem>
                <a ngbNavLink>Silinen Aktiviteler</a>
                <ng-template ngbNavContent>
                    <div class="content-body">
                        <section id="ngx-datatable-kitchen-sink">
                            <core-card-snippet>
                                <h4 class="card-title">Aktivitelerin Listesi</h4>
                                <div class="row">
                                    <div class="firstRow mt-1">
                                        <div style="margin-left:9px;">
                                            <div class="col-12">
                                                <select class="form-control form-select"
                                                    [(ngModel)]="basicSelectedOption">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search mt-1">
                                        <div class="col-12">
                                            <input type="text" class="form-control mr-1" name="filterText"
                                                placeholder="Aktivite Arayın.." [(ngModel)]="filterText"
                                                [ngModelOptions]="{standalone:true}">
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                                placeholder="Kullanıcı Seçiniz " bindLabel="firstName" bindValue="id"
                                                (change)="tableByFiltersId()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{
                                                        item.firstName }}
                                                        {{item.lastName}}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <div *ngIf="userRole=='Admin' || userRole=='Consultant'">
                                                <ng-select [multiple]="true" [items]="partners"
                                                    [(ngModel)]="selectedPartners" placeholder="Cari Seçiniz"
                                                    bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                        <span class="ng-value-label"> {{
                                                            item.name }}</span>
                                                        <span class="ng-value-icon right" (click)="clear(item)"
                                                            aria-hidden="true">×</span>
                                                    </ng-template>
                                                    <ng-template ng-header-tmp>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row col-12 ml-1 mt-1 mb-2">
                                        <div class="col-1">
                                            <button class="btn btn-info btn-sm ml-1 " title="Excele Aktar" rippleEffect
                                                (click)="exportExcelActivities()">
                                                <i data-feather="file-text"></i>
                                            </button>
                                        </div>
                                        <div class="col-3"></div>
                                        <div id="dateFilter" class="row col-7"
                                            style="display: flex;justify-content: end;">
                                            <form class="form-inline">
                                                <div class="form-group">
                                                    <div class="input-group w-0">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                            [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                                            [startDate]="fromDateExample!" />
                                                        <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                            <span class="custom-day" type="datetime"
                                                                [class.focused]="focused" [class.range]="isRange(date)"
                                                                [class.faded]="isHovered(date) || isInside(date)"
                                                                (mouseenter)="hoveredDate = date"
                                                                (mouseleave)="hoveredDate = null">
                                                                {{ date.day }}
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #dpFromDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpFromDate"
                                                            [ngModel]="checkType(fromDateExample) ? formatter.format(fromDateExample) : fromDateExample"
                                                            (ngModelChange)="fromDateExample = $event; tableByDates();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group ml-sm-2">
                                                    <div class="input-group">
                                                        <input #dpToDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpToDate"
                                                            [ngModel]="checkType(toDateExample) ? formatter.format(toDateExample) : toDateExample"
                                                            (ngModelChange)="toDateExample = $event; tableByDates();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <table class="table" id="excel-table-ticketactivity"
                                    style="text-align: center;display: none;">
                                    <tbody>
                                        <tr>
                                            <td>Başlangıç Tarihi</td>
                                            <td>Takip Numarası</td>
                                            <td>Talep Konusu</td>
                                            <td>Açıklama</td>
                                            <td>Danışmman Adı</td>
                                            <td>Modül</td>
                                            <td>Süre (saat)</td>
                                            <td>Faturalandırma Durumu</td>
                                            <td>Lokasyon Tipi</td>
                                            <td>Cari Bilgisi</td>
                                            <td>Müşteri Talep Numarası</td>
                                            <td>Talep Durumu</td>
                                            <td>Faturalandırma Durumu</td>
                                        </tr>
                                        <tr *ngFor="let item of ticketActivities">
                                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                            <td>{{item.trackingNumber}}</td>
                                            <td>{{item.trackingNumber}}</td>
                                            <td [innerHTML]="item.ticketSubject"></td>
                                            <td [innerHTML]="item.description"></td>
                                            <td>{{item.userName}}</td>
                                            <td>{{item.moduleName}}</td>
                                            <td>{{item.time}}</td>
                                            <td *ngIf="item.billTypeId==4">Faturalandırılmadı</td>
                                            <td *ngIf="item.billTypeId==5">Faturalandı</td>
                                            <td *ngIf="item.billTypeId==6">Feragat Edildi</td>
                                            <td>{{item.locationTypeName}}</td>
                                            <td>{{item.partnerName }}</td>
                                            <td>{{item.partnerTicketNo}}</td>
                                            <td>{{item.ticketStatusName}}</td>
                                            <td>{{item.ticketBillTypeName}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngx-datatable [rows]="ticketActivities |evoTicketActivity:filterText" [rowHeight]="58"
                                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                    [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOption">
                                    <ngx-datatable-column name="Önizle" [width]="80" [sortable]="false"
                                        [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items-center">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);"
                                                        (click)="getTicketActivityforPreview(row.id)"
                                                        data-bs-toggle="modal" data-bs-target="#viewticketactivity"
                                                        class="hide-arrow" id="dropdownBrowserState"
                                                        data-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <div class="avatar avatar-status ng-star-inserted bg-light-danger"
                                                            ng-reflect-ng-class="[object Object]">
                                                            <span placement="top" container="body"
                                                                [ngbTooltip]="tipContent" class="avatar-content"
                                                                ng-reflect-placement="top" ng-reflect-container="body"
                                                                ng-reflect-ngb-tooltip="[object Object]"><i
                                                                    class="feather avatar-icon font-medium-1 icon-eye"
                                                                    ng-reflect-ng-class="[object Object]"></i></span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.startDate |date:'dd-MM-YYYY HH:mm'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="120">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <a class="badge badge-pill badge-light-danger font-small-3 font-weight-bold line-height-2 mb-25"
                                                routerLink="/home/details/{{row.ticketId}}" target="">#{{ name
                                                }}</a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Açıklama" prop="description" [width]="270">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div
                                                [innerHTML]="row.description.length > 40 ? row.description.slice(0, 40) + '...' : row.description">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Talep Konusu" prop="ticketSubject" [width]="270">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div
                                                [innerHTML]="row.ticketSubject?.length > 25 ? row.ticketSubject.substring(0, 25) + '...' : row.ticketSubject">
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Danışman Adı" prop="userName" [width]="230">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.userName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Modülü" prop="module" [width]="170">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.moduleName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Süre" prop="time" [width]="130">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.time}} Saat
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Lokasyon Tipi" prop="locationTypeName" [width]="180">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.locationTypeName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="180"
                                        *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{row.partnerName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <!-- <ngx-datatable-column name="İşlemler" [width]="150" [sortable]="false"
                                            *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                <div class="d-flex align-items- ">
                                                    <div ngbDropdown container="body">
                                                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                            id="dropdownBrowserState" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <i data-feather="more-vertical"
                                                                class="text-primary cursor-pointer mr-50"></i>
                                                        </a>
                                                         <div ngbDropdownMenu class="dropdown-menu-right"
                                                            aria-labelledby="dropdownBrowserState">
                                                           
                                                        </div>
                                                        <button class="btn btn-primary btn-sm mr-2" title="Önizleme"
                                                            data-bs-toggle="modal" data-bs-target="#viewticketactivity"
                                                            (click)="getTicketActivityforPreview(row.id)"><i
                                                                data-feather="eye"></i></button>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column> -->
                                </ngx-datatable>
                            </core-card-snippet>

                            <!-- <div class="modal fade" id="deleteticketactivity" tabindex="-1"
                                    aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Aktivitesini
                                                    Sil</h1>
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                        data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                            </div>
                                            <div class="modal-body">
                                                Kaydı Silmek İstediğinize Emin Misiniz?
                                            </div>
                                            <div class="modal-footer">
                                                <div>
                                                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                        (click)="deleteActivity(deletedTicketActivityId)"><i
                                                            data-feather="trash" class="mr-50"></i>Sil</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                        </section>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem>
                <a ngbNavLink>Aktivitesi Olmayan Planlı Günler</a>
                <ng-template ngbNavContent>
                    <div class="content-wrapper container-xxl p-0">
                        <div class="content-body">
                            <section id="ngx-datatable-kitchen-sink">
                                <core-card-snippet>
                                    <h4 class="card-title">Planların Listesi</h4>
                                    <div class="row">
                                        <div class="firstRow mt-1">
                                            <div style="margin-left:9px; margin-bottom: 20px;">
                                                <div class="col-12">

                                                    <select class="form-control form-select"
                                                        [(ngModel)]="basicSelectedOptionForPlans">
                                                        <option value="10">10</option>
                                                        <option value="25">25</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="search mt-1">
                                            <div class="col-12">
                                                <input type="text" class="form-control mr-1" name="filterText"
                                                    placeholder="Plan Arayın.." [(ngModel)]="filterTextForPlans"
                                                    [ngModelOptions]="{standalone:true}">
                                            </div>
                                        </div>
                                        <div class="filter mt-1">
                                            <div class="col-12">
                                                <ng-select [multiple]="true" [items]="users"
                                                    [(ngModel)]="selectedUsersForPlan" placeholder="Kullanıcı Seçiniz "
                                                    bindLabel="firstName" bindValue="id"
                                                    (change)="tableByFiltersIdForPlan()">
                                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                        <span class="ng-value-label">{{
                                                            item.firstName }}
                                                            {{item.lastName}}</span>
                                                        <span class="ng-value-icon right" (click)="clear(item)"
                                                            aria-hidden="true">×</span>
                                                    </ng-template>
                                                    <ng-template ng-header-tmp>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="filter mt-1">
                                            <div class="col-12">
                                                <ng-select [multiple]="true" [items]="typeForFilter"
                                                    [(ngModel)]="selectedTypes" placeholder="Plan Türünü Seçiniz"
                                                    bindLabel="name" bindValue="id"
                                                    (change)="tableByFiltersIdForPlan()">
                                                    <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                        <span class="ng-value-label"> {{
                                                            item.name }}</span>
                                                        <span class="ng-value-icon right" (click)="clear(item)"
                                                            aria-hidden="true">×</span>
                                                    </ng-template>
                                                    <ng-template ng-header-tmp>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row col-12 ml-1 mb-2">
                                        <div class="col-3">
                                            <button *ngIf="userRole=='Admin'" class="btn btn-primary btn-sm mr-1"
                                                routerLink="/home/addplan" rippleEffect title="Yeni Plan">
                                                <i data-feather="plus"></i><span
                                                    class="d-none d-sm-inline-block"></span>
                                            </button>
                                            <button class="btn btn-info btn-sm" (click)="exportExcelForPlans()"
                                                rippleEffect title="Excele Aktar">
                                                <i data-feather="file-text" class=""></i>
                                            </button>
                                        </div>


                                        <div class="col-2"></div>
                                        <div id="dateFilter" class="row col-7"
                                            style="display: flex;justify-content: end;">
                                            <form class="form-inline">
                                                <div class="form-group">
                                                    <div class="input-group w-0">
                                                        <input name="datepicker" class="form-control" ngbDatepicker
                                                            #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                            (dateSelect)="onDateSelectionForPlan($event)"
                                                            [displayMonths]="2" [dayTemplate]="rangeSelectionDP"
                                                            outsideDays="hidden"
                                                            [startDate]="fromDateExampleForPlan!" />
                                                        <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                            <span class="custom-day" type="datetime"
                                                                [class.focused]="focused"
                                                                [class.range]="isRangeForPlan(date)"
                                                                [class.faded]="isHoveredForPlan(date) || isInsideForPlan(date)"
                                                                (mouseenter)="hoveredDateForPlan = date"
                                                                (mouseleave)="hoveredDateForPlan = null">
                                                                {{ date.day }}
                                                            </span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <input #dpFromDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpFromDate"
                                                            [ngModel]="checkType(fromDateExampleForPlan) ? formatter.format(fromDateExampleForPlan) : fromDateExampleForPlan"
                                                            (ngModelChange)="fromDateExampleForPlan = $event; tableByDatesForPlan();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group ml-sm-2">
                                                    <div class="input-group">
                                                        <input #dpToDate class="form-control" type="date"
                                                            placeholder="dd-MM-yyyy" name="dpToDate"
                                                            [ngModel]="checkType(toDateExampleForPlan) ? formatter.format(toDateExampleForPlan) : toDateExampleForPlan"
                                                            (ngModelChange)="toDateExampleForPlan = $event; tableByDatesForPlan();" />
                                                        <div class="input-group-append">
                                                            <button
                                                                class="btn btn-outline-secondary feather icon-calendar"
                                                                (click)="datepicker.toggle()" type="button"
                                                                rippleEffect></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <table class="table table-hover table-bordered" id="excel-table-plan"
                                        style="display: none;">
                                        <thead>
                                            <th>Başlığı</th>
                                            <th>Kullanıcı Adı</th>
                                            <th>Partner Adı</th>
                                            <th>Başlangıç Tarihi</th>
                                            <th>Bitiş Tarihi</th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of plans">
                                                <td>{{item.title}}</td>
                                                <td>{{item.userName}}</td>
                                                <td>{{item.partnerName}}</td>
                                                <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                                <td>{{item.trackingNumber}}</td>
                                                <td [innerHTML]="item.endDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                                <td>{{item.trackingNumber}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <ngx-datatable [rows]="plans |evoPlan:filterTexrForPlans" [rowHeight]="58"
                                        class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                        [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOptionForPlans">
                                        <ngx-datatable-column name="Önizle" [width]="80" [sortable]="false"
                                            [canAutoResize]="false">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                <div class="d-flex align-items-center">
                                                    <div ngbDropdown container="body">
                                                        <a ngbDropdownToggle href="javascript:void(0);"
                                                            (click)="getPlanforPreview(row.id)" data-bs-toggle="modal"
                                                            data-bs-target="#viewplan" class="hide-arrow"
                                                            id="dropdownBrowserState" data-toggle="dropdown"
                                                            aria-haspopup="true" aria-expanded="false">
                                                            <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                                                                ng-reflect-ng-class="[object Object]">
                                                                <span placement="top" container="body"
                                                                    [ngbTooltip]="tipContent" class="avatar-content"
                                                                    ng-reflect-placement="top"
                                                                    ng-reflect-container="body"
                                                                    ng-reflect-ngb-tooltip="[object Object]"><i
                                                                        class="feather avatar-icon font-medium-1 icon-eye"
                                                                        ng-reflect-ng-class="[object Object]"></i></span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Plan Başlığı" prop="title" [width]="300">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.title}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="200">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.userName}}
                                            </ng-template>
                                        </ngx-datatable-column>

                                        <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.partnerName}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.startDate| date:'dd-MM-YYYY HH:mm'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="200">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                {{ row.endDate| date:'dd-MM-YYYY HH:mm'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="İşlemler" *ngIf="userRole=='Admin'" [width]="120"
                                            [sortable]="false">
                                            <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                                <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                                                    <div ngbDropdown container="body">
                                                        <a ngbDropdownToggle href="javascript:void(0);"
                                                            class="hide-arrow" id="dropdownBrowserState"
                                                            data-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <i data-feather="more-vertical"
                                                                class="text-primary cursor-pointer mr-50"></i>
                                                        </a>
                                                        <div ngbDropdownMenu class="dropdown-menu-right"
                                                            aria-labelledby="dropdownBrowserState">
                                                            <a routerLink="/home/updateplan/{{row.id}}"
                                                                ngbDropdownItem><i data-feather="edit-2"
                                                                    class="mr-50"></i><span>Güncelle</span></a>
                                                            <button ngbDropdownItem href="javascript:void(0);"
                                                                (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                                                data-bs-target="#deleteplan">
                                                                <i data-feather="trash"
                                                                    class="mr-50"></i><span>Sil</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem
                                                        title="Güncelle"><i data-feather="edit"
                                                            class="text-primary cursor-pointer"></i></a>
                                                </div>
                                            </ng-template>
                                        </ngx-datatable-column>
                                    </ngx-datatable>
                                </core-card-snippet>


                            </section>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-1"></div>
    </div>
</div>
<div *ngIf="userRole=='Consultant'">

    <ul ngbNav #nav="ngbNav" class="nav nav-fill nav-pills">
        <li ngbNavItem>
            <a ngbNavLink>Silinen Aktiviteler</a>
            <ng-template ngbNavContent>
                <div class="content-body">
                    <section id="ngx-datatable-kitchen-sink">
                        <core-card-snippet>
                            <h4 class="card-title">Aktivitelerin Listesi</h4>
                            <div class="row">
                                <div class="firstRow mt-1">
                                    <div style="margin-left:9px;">
                                        <div class="col-12">
                                            <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="search mt-1">
                                    <div class="col-12">
                                        <input type="text" class="form-control mr-1" name="filterText"
                                            placeholder="Aktivite Arayın.." [(ngModel)]="filterText"
                                            [ngModelOptions]="{standalone:true}">
                                    </div>
                                </div>
                                <div class="filter mt-1">
                                    <div class="col-12">
                                        <ng-select [multiple]="true" [items]="users" [(ngModel)]="selectedUsers"
                                            placeholder="Kullanıcı Seçiniz " bindLabel="firstName" bindValue="id"
                                            (change)="tableByFiltersId()">
                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                <span class="ng-value-label">{{
                                                    item.firstName }}
                                                    {{item.lastName}}</span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </ng-template>
                                            <ng-template ng-header-tmp>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="filter mt-1">
                                    <div class="col-12">
                                        <div *ngIf="userRole=='Admin' || userRole=='Consultant'">
                                            <ng-select [multiple]="true" [items]="partners"
                                                [(ngModel)]="selectedPartners" placeholder="Cari Seçiniz"
                                                bindLabel="name" bindValue="id" (change)="tableByFiltersId()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label"> {{
                                                        item.name }}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-12 ml-1 mt-1 mb-2">
                                    <div class="col-1">
                                        <button class="btn btn-info btn-sm ml-1 " title="Excele Aktar" rippleEffect
                                            (click)="exportExcelActivities()">
                                            <i data-feather="file-text"></i>
                                        </button>
                                    </div>
                                    <div class="col-3"></div>
                                    <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
                                        <form class="form-inline">
                                            <div class="form-group">
                                                <div class="input-group w-0">
                                                    <input name="datepicker" class="form-control" ngbDatepicker
                                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                        (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                                                        [dayTemplate]="rangeSelectionDP" outsideDays="hidden"
                                                        [startDate]="fromDateExample!" />
                                                    <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                        <span class="custom-day" type="datetime"
                                                            [class.focused]="focused" [class.range]="isRange(date)"
                                                            [class.faded]="isHovered(date) || isInside(date)"
                                                            (mouseenter)="hoveredDate = date"
                                                            (mouseleave)="hoveredDate = null">
                                                            {{ date.day }}
                                                        </span>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #dpFromDate class="form-control" type="date"
                                                        placeholder="dd-MM-yyyy" name="dpFromDate"
                                                        [value]="formatter.format(fromDateExample)"
                                                        (change)="tableByDates()" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            (click)="datepicker.toggle()" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ml-sm-2">
                                                <div class="input-group">
                                                    <input #dpToDate class="form-control" type="date"
                                                        placeholder="dd-MM-yyyy" name="dpToDate"
                                                        (change)="tableByDates()"
                                                        [value]="formatter.format(toDateExample)" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            (click)="datepicker.toggle()" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <table class="table" id="excel-table-ticketactivity"
                                style="text-align: center;display: none;">
                                <tbody>
                                    <tr>
                                        <td>Başlangıç Tarihi</td>
                                        <td>Takip Numarası</td>
                                        <td>Talep Konusu</td>
                                        <td>Açıklama</td>
                                        <td>Danışmman Adı</td>
                                        <td>Modül</td>
                                        <td>Süre (saat)</td>
                                        <td>Lokasyon Tipi</td>
                                        <td>Cari Bilgisi</td>
                                        <td>Müşteri Talep Numarası</td>
                                        <td>Talep Durumu</td>
                                        <td>Faturalandırma Durumu</td>
                                    </tr>
                                    <tr *ngFor="let item of ticketActivities">
                                        <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                        <td>{{item.trackingNumber}}</td>
                                        <td>{{item.trackingNumber}}</td>
                                        <td [innerHTML]="item.ticketSubject"></td>
                                        <td [innerHTML]="item.description"></td>
                                        <td>{{item.userName}}</td>
                                        <td>{{item.moduleName}}</td>
                                        <td>{{item.time}}</td>
                                        <td>{{item.locationTypeName}}</td>
                                        <td>{{item.partnerName }}</td>
                                        <td>{{item.partnerTicketNo}}</td>
                                        <td>{{item.ticketStatusName}}</td>
                                        <td>{{item.ticketBillTypeName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ngx-datatable [rows]="ticketActivities |evoTicketActivity:filterText" [rowHeight]="58"
                                class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                                [scrollbarH]="true" [limit]="basicSelectedOption">
                                <ngx-datatable-column name="Önizle" [width]="80" [sortable]="false"
                                    [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div class="d-flex align-items-center">
                                            <div ngbDropdown container="body">
                                                <a ngbDropdownToggle href="javascript:void(0);"
                                                    (click)="getTicketActivityforPreview(row.id)" data-bs-toggle="modal"
                                                    data-bs-target="#viewticketactivity" class="hide-arrow"
                                                    id="dropdownBrowserState" data-toggle="dropdown"
                                                    aria-haspopup="true" aria-expanded="false">
                                                    <div class="avatar avatar-status ng-star-inserted bg-light-danger"
                                                        ng-reflect-ng-class="[object Object]">
                                                        <span placement="top" container="body" [ngbTooltip]="tipContent"
                                                            class="avatar-content" ng-reflect-placement="top"
                                                            ng-reflect-container="body"
                                                            ng-reflect-ngb-tooltip="[object Object]"><i
                                                                class="feather avatar-icon font-medium-1 icon-eye"
                                                                ng-reflect-ng-class="[object Object]"></i></span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </ng-template>

                                </ngx-datatable-column>
                                <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.startDate |date:'dd-MM-YYYY HH:mm'}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Takip No" prop="trackingNumber" [width]="120">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <a class="badge badge-pill badge-light-danger font-small-3 font-weight-bold line-height-2 mb-25"
                                            routerLink="/home/details/{{row.ticketId}}" target="">#{{ name
                                            }}</a>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Açıklama" prop="description" [width]="270">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div
                                            [innerHTML]="row.description.length > 40 ? row.description.slice(0, 40) + '...' : row.description">
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Talep Konusu" prop="ticketSubject" [width]="270">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        <div
                                            [innerHTML]="row.ticketSubject?.length > 25 ? row.ticketSubject.substring(0, 25) + '...' : row.ticketSubject">
                                        </div>
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Danışman Adı" prop="userName" [width]="230">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.userName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Modülü" prop="module" [width]="170">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.moduleName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Süre" prop="time" [width]="130">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.time}} Saat
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Lokasyon Tipi" prop="locationTypeName" [width]="180">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.locationTypeName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="180"
                                    *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                    <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                        {{row.partnerName}}
                                    </ng-template>
                                </ngx-datatable-column>
                                <!-- <ngx-datatable-column name="İşlemler" [width]="150" [sortable]="false"
                                        *ngIf="userRole=='Admin'|| userRole=='ConsultantUser' || userRole=='Consultant'">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items- ">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <i data-feather="more-vertical"
                                                            class="text-primary cursor-pointer mr-50"></i>
                                                    </a>
                                                     <div ngbDropdownMenu class="dropdown-menu-right"
                                                        aria-labelledby="dropdownBrowserState">
                                                       
                                                    </div>
                                                    <button class="btn btn-primary btn-sm mr-2" title="Önizleme"
                                                        data-bs-toggle="modal" data-bs-target="#viewticketactivity"
                                                        (click)="getTicketActivityforPreview(row.id)"><i
                                                            data-feather="eye"></i></button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column> -->
                            </ngx-datatable>
                        </core-card-snippet>

                        <!-- <div class="modal fade" id="deleteticketactivity" tabindex="-1"
                                aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Talep Aktivitesini
                                                Sil</h1>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                        </div>
                                        <div class="modal-body">
                                            Kaydı Silmek İstediğinize Emin Misiniz?
                                        </div>
                                        <div class="modal-footer">
                                            <div>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    (click)="deleteActivity(deletedTicketActivityId)"><i
                                                        data-feather="trash" class="mr-50"></i>Sil</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                    </section>
                </div>
            </ng-template>
        </li>
        <li ngbNavItem>
            <a ngbNavLink>Aktivitesi Olmayan Planlı Günler</a>
            <ng-template ngbNavContent>
                <div class="content-wrapper container-xxl p-0">
                    <div class="content-body">
                        <section id="ngx-datatable-kitchen-sink">
                            <core-card-snippet>
                                <h4 class="card-title">Planların Listesi</h4>
                                <div class="row">
                                    <div class="firstRow mt-1">
                                        <div style="margin-left:9px; margin-bottom: 20px;">
                                            <div class="col-12">

                                                <select class="form-control form-select"
                                                    [(ngModel)]="basicSelectedOptionForPlans">
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="search mt-1">
                                        <div class="col-12">
                                            <input type="text" class="form-control mr-1" name="filterText"
                                                placeholder="Plan Arayın.." [(ngModel)]="filterTextForPlans"
                                                [ngModelOptions]="{standalone:true}">
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="users"
                                                [(ngModel)]="selectedUsersForPlan" placeholder="Kullanıcı Seçiniz "
                                                bindLabel="firstName" bindValue="id"
                                                (change)="tableByFiltersIdForPlan()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label">{{
                                                        item.firstName }}
                                                        {{item.lastName}}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="filter mt-1">
                                        <div class="col-12">
                                            <ng-select [multiple]="true" [items]="typeForFilter"
                                                [(ngModel)]="selectedTypes" placeholder="Plan Türünü Seçiniz"
                                                bindLabel="name" bindValue="id" (change)="tableByFiltersIdForPlan()">
                                                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                    <span class="ng-value-label"> {{
                                                        item.name }}</span>
                                                    <span class="ng-value-icon right" (click)="clear(item)"
                                                        aria-hidden="true">×</span>
                                                </ng-template>
                                                <ng-template ng-header-tmp>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>

                                </div>
                                <div class="row col-12 ml-1 mb-2">
                                    <div class="col-2">
                                        <button *ngIf="userRole=='Admin'" class="btn btn-primary btn-sm mr-1"
                                            routerLink="/home/addplan" rippleEffect title="Yeni Plan">
                                            <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                                        </button>
                                        <button class="btn btn-info btn-sm" (click)="exportExcelForPlans()" rippleEffect
                                            title="Excele Aktar">
                                            <i data-feather="file-text" class=""></i>
                                        </button>
                                    </div>


                                    <div class="col-3"></div>
                                    <div id="dateFilter" class="row col-7" style="display: flex;justify-content: end;">
                                        <form class="form-inline">
                                            <div class="form-group">
                                                <div class="input-group w-0">
                                                    <input name="datepicker" class="form-control" ngbDatepicker
                                                        #datepicker="ngbDatepicker" [autoClose]="'outside'"
                                                        (dateSelect)="onDateSelectionForPlan($event)"
                                                        [displayMonths]="2" [dayTemplate]="rangeSelectionDP"
                                                        outsideDays="hidden" [startDate]="fromDateExampleForPlan!" />
                                                    <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                        <span class="custom-day" type="datetime"
                                                            [class.focused]="focused"
                                                            [class.range]="isRangeForPlan(date)"
                                                            [class.faded]="isHoveredForPlan(date) || isInsideForPlan(date)"
                                                            (mouseenter)="hoveredDateForPlan = date"
                                                            (mouseleave)="hoveredDateForPlan = null">
                                                            {{ date.day }}
                                                        </span>
                                                    </ng-template>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="input-group">
                                                    <input #dpFromDate class="form-control" type="date"
                                                        placeholder="dd-MM-yyyy" name="dpFromDate"
                                                        [value]="formatter.format(fromDateExampleForPlan)"
                                                        (change)="tableByDatesForPlan()" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            (click)="datepicker.toggle()" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group ml-sm-2">
                                                <div class="input-group">
                                                    <input #dpToDate class="form-control" type="date"
                                                        placeholder="dd-MM-yyyy" name="dpToDate"
                                                        (change)="tableByDatesForPlan()"
                                                        [value]="formatter.format(toDateExampleForPlan)" />
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary feather icon-calendar"
                                                            (click)="datepicker.toggle()" type="button"
                                                            rippleEffect></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <table class="table table-hover table-bordered" id="excel-table-plan"
                                    style="display: none;">
                                    <thead>
                                        <th>Başlığı</th>
                                        <th>Kullanıcı Adı</th>
                                        <th>Partner Adı</th>
                                        <th>Başlangıç Tarihi</th>
                                        <th>Bitiş Tarihi</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of plans">
                                            <td>{{item.title}}</td>
                                            <td>{{item.userName}}</td>
                                            <td>{{item.partnerName}}</td>
                                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                            <td>{{item.trackingNumber}}</td>
                                            <td [innerHTML]="item.endDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                                            <td>{{item.trackingNumber}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngx-datatable [rows]="plans |evoPlan:filterTexrForPlans" [rowHeight]="58"
                                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40"
                                    [footerHeight]="50" [scrollbarH]="true" [limit]="basicSelectedOptionForPlans">
                                    <ngx-datatable-column name="Önizle" [width]="80" [sortable]="false"
                                        [canAutoResize]="false">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div class="d-flex align-items-center">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);"
                                                        (click)="getPlanforPreview(row.id)" data-bs-toggle="modal"
                                                        data-bs-target="#viewplan" class="hide-arrow"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                                                            ng-reflect-ng-class="[object Object]">
                                                            <span placement="top" container="body"
                                                                [ngbTooltip]="tipContent" class="avatar-content"
                                                                ng-reflect-placement="top" ng-reflect-container="body"
                                                                ng-reflect-ngb-tooltip="[object Object]"><i
                                                                    class="feather avatar-icon font-medium-1 icon-eye"
                                                                    ng-reflect-ng-class="[object Object]"></i></span>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </ng-template>

                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Plan Başlığı" prop="title" [width]="300">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{ row.title}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Kullanıcı Adı" prop="userName" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{ row.userName}}
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{ row.partnerName}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Başlangıç Tarihi" prop="startDate" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{ row.startDate| date:'dd-MM-YYYY HH:mm'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Bitiş Tarihi" prop="endDate" [width]="200">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            {{ row.endDate| date:'dd-MM-YYYY HH:mm'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="İşlemler" *ngIf="userRole=='Admin'" [width]="120"
                                        [sortable]="false">
                                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                                            <div *ngIf="row.typeId != 4" class="d-flex align-items-center">
                                                <div ngbDropdown container="body">
                                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                                        id="dropdownBrowserState" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <i data-feather="more-vertical"
                                                            class="text-primary cursor-pointer mr-50"></i>
                                                    </a>
                                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                                        aria-labelledby="dropdownBrowserState">
                                                        <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem><i
                                                                data-feather="edit-2"
                                                                class="mr-50"></i><span>Güncelle</span></a>
                                                        <button ngbDropdownItem href="javascript:void(0);"
                                                            (click)="deletedPlanId=row.id" data-bs-toggle="modal"
                                                            data-bs-target="#deleteplan">
                                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <a routerLink="/home/updateplan/{{row.id}}" ngbDropdownItem
                                                    title="Güncelle"><i data-feather="edit"
                                                        class="text-primary cursor-pointer"></i></a>
                                            </div>
                                        </ng-template>
                                    </ngx-datatable-column>
                                </ngx-datatable>
                            </core-card-snippet>


                            <div class="modal fade" id="deleteplan" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Planı
                                                Sil</h1>
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                                        </div>
                                        <div class="modal-body">
                                            Kaydı Silmek İstediğinize Emin Misiniz?
                                        </div>
                                        <div class="modal-footer">
                                            <div>
                                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                                    (click)="delete(deletedPlanId)"><i data-feather="trash"
                                                        class="mr-50"></i>Sil</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </ng-template>
        </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-1"></div>
</div>


<div class="modal fade" id="deleteplan" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Planı
                    Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
            </div>
            <div class="modal-footer">
                <div>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                        (click)="delete(deletedPlanId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="viewdesigned" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                    {{getActionName(transaction.typeId)}} Kısımları Düzenlendi
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <i data-feather="x-circle" class="mr-50"></i>Çıkış
                </button>
            </div>
            <div class="modal-body">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Değişen Veri</th>
                            <th>Eski Veri</th>
                            <th>Yeni Veri</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of contents; let i=index">
                            <ng-container>
                                <td>{{ getDisplayName(item.name) }}</td>
                                <td [innerHTML]="getDisplayValue(item.information, item.name)"></td>
                                <td [innerHTML]="getDisplayValue(item.changedInformation, item.name) "></td>
                            </ng-container>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="viewerrorsubject" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">
                    {{getActionName(transaction.typeId)}} Verisinde  Oluşan {{getTransactionStatusName(transaction.errorStatusId)}} Hatası
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <i data-feather="x-circle" class="mr-50"></i>Çıkış
                </button>
            </div>
            <div class="modal-body">
                <div>{{transaction.subject}}</div>
            </div>
            <div class="modal-footer">

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="viewticketactivity" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Aktivite
                    Önizlemesi
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <table class="table text-center">
                    <thead class="text-primary">
                        <th></th>
                        <th>
                        </th>
                        <th class="text-right">
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3">{{ticketActivity.userName}}</td>

                            <td colspan="2">{{ticketActivity.time}} Saat</td>
                            <td>
                                <p class="verticalLine"> </p>
                            </td>
                            <td>
                                {{ticketActivity.startDate|date:'dd-MM-YYYY'}}
                                <br>
                                {{ticketActivity.startDate|date:'HH:mm'}}
                            </td>

                        <tr>
                            <td colspan="5" rowspan="4">
                                <div style="width: 500px;">
                                    <quill-editor name="quillTicketActivity " id="quillTicketActivityDescription"
                                        #quillTicketActivityDescription="ngModel"
                                        [(ngModel)]="ticketActivity.description">
                                        <div quill-editor-toolbar ngModel required>
                                        </div>
                                    </quill-editor>
                                </div>
                            </td>
                            <td>
                                <p class="verticalLine"> </p>
                            </td>
                            <td>
                                {{ticketActivity.moduleName}}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p class="verticalLine"></p>
                            </td>
                            <td>{{ticketActivity.locationTypeName}} Çalışma ile
                                Yapılmıştır
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="viewplan" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Plan Önizlemesi
                </h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                        class="mr-50"></i>Çıkış</button>
            </div>
            <div class="modal-body">
                <table class="table text-center">
                    <thead class="text-primary">
                        <th></th>
                        <th>
                        </th>
                        <th class="text-right">
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="3">{{plan.userName}}</td>

                            <td colspan="2">{{plan.partnerName}}</td>
                            <td>
                                <p class="verticalLine"> </p>
                            </td>
                            <td>
                                {{plan.startDate|date:'dd-MM-YYYY'}}
                                <br>
                                {{plan.startDate|date:'HH:mm'}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">{{plan.title}}</td>

                            <td>
                                <p class="verticalLine"> </p>
                            </td>
                            <td>
                                {{plan.endDate|date:'dd-MM-YYYY'}}
                                <br>
                                {{plan.endDate|date:'HH:mm'}}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5" rowspan="4">
                                <div style="width: 500px;">
                                    <quill-editor name="quillTicketActivity " id="quillTicketActivityDescription"
                                        #quillTicketActivityDescription="ngModel" [(ngModel)]="plan.description">
                                        <div quill-editor-toolbar ngModel required>
                                        </div>
                                    </quill-editor>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td>
                                <p class="verticalLine"></p>
                            </td>
                            <td>
                                {{plan.planTypeName}} Türünde Planlanmıştır
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>