<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="ngx-datatable-kitchen-sink">
            <core-card-snippet>
                <h4 class="card-title">Sözleşmelerin Listesi</h4>
                <div class="row">
                    <div class="firstRow mt-1">
                        <div style="margin-left:9px; margin-bottom: 20px;">
                            <div class="col-12">
                                <select class="form-control form-select" [(ngModel)]="basicSelectedOption">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search mt-1">
                        <div class="col-12">
                            <input type="text" class="form-control mr-1" name="filterText"
                                placeholder="Sözleşme Arayın.." [(ngModel)]="filterText"
                                [ngModelOptions]="{standalone:true}">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-1 ml-1">
                        <button class="btn btn-primary btn-sm ml-1 mr-1" routerLink="/home/addcontract" rippleEffect
                            title="Yeni Sözleşme">
                            <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                        </button>
                        <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                            <i data-feather="file-text" class=""></i>
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
                    <thead>
                        <tr>
                            <td>Sözleşme Numarası</td>
                            <td>Proje Adı</td>
                            <td>Partner Adı</td>
                            <td>Başlangıç Tarihi</td>
                            <td>Bitiş Tarihi</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of contracts">
                            <td>{{item.contractNumber}}</td>
                            <td>{{item.projectName}}</td>
                            <td>{{item.partnerName}}</td>
                            <td [innerHTML]="item.startDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                            <td [innerHTML]="item.endDate | date:'yyyy-MM-dd HH:mm:ss'"></td>
                        </tr>
                    </tbody>
                </table>
                <ngx-datatable [rows]="contracts |evoContract:filterText" [rowHeight]="58"
                    class="bootstrap core-bootstrap" [limit]="10" [headerHeight]="40" [footerHeight]="50"
                    [scrollbarH]="true" [limit]="basicSelectedOption">
                    <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                        [resizeable]="false">
                        <ng-template ngx-datatable-header-template let-value="value"
                            let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="allRowsSelected"
                                    (change)="selectFn(!allRowsSelected)" id="headerChkbxRef" />
                                <label class="custom-control-label" for="headerChkbxRef"></label>
                            </div>
                        </ng-template>
                        <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value"
                            let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" [checked]="isSelected"
                                    (change)="onCheckboxChangeFn($event)" id="rowChkbxRef{{ rowIndex }}" />
                                <label class="custom-control-label" for="rowChkbxRef{{rowIndex}}"></label>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Sözleşme Numarası" prop="contractNumber" [width]="200">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <a class="badge badge-pill badge-light-info font-small-3 font-weight-bold line-height-2 mb-25"
                                routerLink="/home/updatecontract/{{row.id}}" target="">#{{ row.contractNumber }}</a>
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Proje Başlığı" prop="projectName" [width]="500">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.projectName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Partner Adı" prop="partnerName" [width]="400">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            {{row.partnerName}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="İşlemler" [width]="120" [sortable]="false">
                        <ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
                            <div class="d-flex align-items-center">
                                <div ngbDropdown container="body">
                                    <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                        id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                    </a>
                                    <div ngbDropdownMenu class="dropdown-menu-right"
                                        aria-labelledby="dropdownBrowserState">
                                        <a routerLink="/home/updatecontract/{{row.id}}" ngbDropdownItem><i
                                                data-feather="edit-2" class="mr-50"></i><span>Güncelle</span></a>
                                        <button ngbDropdownItem (click)="deletedContractId=row.id"
                                            data-bs-toggle="modal" data-bs-target="#deletedepartment">
                                            <i data-feather="trash" class="mr-50"></i><span>Sil</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngx-datatable-column>
                </ngx-datatable>
            </core-card-snippet>
            <div class="modal fade" id="deletedepartment" tabindex="-1" aria-labelledby="staticBackdropLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Sözleşme Sil</h1>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i
                                    data-feather="x-circle" class="mr-50"></i>Çıkış</button>
                        </div>
                        <div class="modal-body">
                            Kaydı Silmek İstediğinize Emin Misiniz?
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                                    (click)="delete(deletedContractId)"><i data-feather="trash"
                                        class="mr-50"></i>Sil</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>