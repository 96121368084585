import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Plan } from 'app/evo/models/plan';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { type } from 'os';

@Component({
  selector: 'app-evo-vacation-list',
  templateUrl: './evo-vacation-list.component.html',
  styles: [
  ]
})
export class EvoVacationListComponent implements OnInit {


  public hoveredDate: NgbDate | null = null;
  public fromDateExample: NgbDate | null;
  public toDateExample: NgbDate | null;

  constructor(private planService: EvoPlanService,
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeSerivce: DecodeService,
    public formatter: NgbDateParserFormatter) { }

  public basicSelectedOption: number = 10;

  vacations: Plan[];
  filterText: string = "";
  deletedVacationId: number;

  startDateStr: string;
  endDateStr: string;

  vacation: Plan = new Plan();
  myId: number;
  fromDate: Date;
  toDate: Date;

  ngOnInit(): void {
    this.list();
    this.myId = this.decodeSerivce.getUserId();
    const defaultStartDate = new Date();
    const defaultEndDate = new Date();

    defaultStartDate.setHours(3, 0, 0, 0);
    defaultEndDate.setHours(2, 59, 0, 0);
    defaultEndDate.setDate(defaultEndDate.getDate() + 1);

    this.startDateStr = defaultStartDate.toISOString().slice(0, 16);
    this.endDateStr = defaultEndDate.toISOString().slice(0, 16);
  }

  list() {
    this.planService.getList().subscribe((items: any) => {
      this.vacations = items.data.filter(item => item.typeId == 4);
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  get(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      this.vacation = item.data;
      this.informationService.get(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.planService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        window.location.reload();
      });
    });
  }

  add(title: string, description: string, startDate: string, endDate: string) {
    this.vacation.id = 0;
    this.vacation.title = title;
    this.vacation.description = description;
    this.vacation.typeId = 4;
    this.vacation.startDate = new Date(startDate);
    this.vacation.startDate.setHours(this.vacation.startDate.getHours() + 3)
    this.vacation.endDate = new Date(endDate);
    this.vacation.endDate.setHours(this.vacation.endDate.getHours() + 3)
    this.vacation.createdBy = this.myId;
    this.vacation.updatedBy = 0;
    this.vacation.deletedBy = 0;

    this.planService.add(this.vacation).subscribe((item: any) => {
      this.informationService.add(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      window.location.reload();
    });

  }
  update() {
    this.vacation.updatedBy = this.myId;
    this.vacation.deletedBy = 0;
    this.planService.update(this.vacation).subscribe((item: any) => {
      this.informationService.update(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      window.location.reload();
    });
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {

    var tempDate : any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate){
      tempDate = {day: this.fromDateExample.day , month:this.fromDateExample.month -1, year:this.fromDateExample.year};
    }
    else{
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear()}
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate : any = {};

      if (this.toDateExample instanceof NgbDate){
        tempToDate = {day: this.toDateExample.day , month:this.toDateExample.month -1, year:this.toDateExample.year};
      }
      else{
        var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
        tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear()}
      }

      this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

    if (!this.fromDate == null || !this.toDate == null) {
      this.list();
    } else {
      this.planService.getList().subscribe((items: any) => {
        this.vacations = items.data.filter(item =>
        (new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate ||
          new Date(item.endDate) >= this.fromDate &&
          new Date(item.endDate) <= this.toDate)
        ).filter(item => item.typeId == 4)

      });
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Planlar";
    this.helperService.exportExcel(element, title);
  }

}
