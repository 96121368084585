import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EvoSystemSettingService {
  private baseApiUrl=environment.apiUrl;
  
  constructor(public httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.baseApiUrl+'GetSystemSettingList');
  }
  get(id:number){
    return this.httpClient.get(this.baseApiUrl+'GetSystemSetting/'+id);
  }
  getFirstOrDefaultValue()
  {
    return this.httpClient.get(this.baseApiUrl+'GetFirstOrDefaultValue');
  }
  add(systemSetting:any){
    return this.httpClient.post(this.baseApiUrl+'AddSystemSetting/',systemSetting);
  }
  update(systemSetting:any){
    return this.httpClient.post(this.baseApiUrl+"UpdateSystemSetting/",systemSetting);
  }
  delete(systemSetting:any){
    return this.httpClient.post(this.baseApiUrl+"DeleteSystemSetting/",systemSetting);
  }
  companyImageUpdate(company:any):Observable<any>{
    return this.httpClient.post<any>(this.baseApiUrl+"CompanyImageUpdate/",company);
  }

}
