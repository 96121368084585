import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Module } from '../models/module';

@Injectable({
  providedIn: 'root'
})
export class EvoModuleService {

  private baseApiUrl=environment.apiUrl;

  constructor(public httpClient:HttpClient) { }

  getList():Observable<Module[]>{
    return this.httpClient.get<Module[]>(this.baseApiUrl+'GetModuleList');
  }
  getListDto():Observable<Module[]>{
    return this.httpClient.get<Module[]>(this.baseApiUrl+'GetModuleListDto');
  }
  get(id:number) :Observable <Module>{
    return this.httpClient.get<Module>(this.baseApiUrl+"GetModule/"+id);
  }

  add(module:Module):Observable<Module>{
    return this.httpClient.post<Module>(this.baseApiUrl+"AddModule",module)
  }

  update(module:Module):Observable<Module>{
    return this.httpClient.post<Module>(this.baseApiUrl+"UpdateModule",module)
  }

  delete(module:Module):Observable<Module>{
    return this.httpClient.post<Module>(this.baseApiUrl+"DeleteModule/",module)
  }
}
