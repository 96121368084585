import { Component, OnInit } from '@angular/core';
import { Department } from 'app/evo/models/department';
import { UserOpertaionClaim } from 'app/evo/models/useroperationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoUseroperationclaimService } from 'app/evo/services/evo-useroperationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-useroperationclaim-list',
  templateUrl: './evo-useroperationclaim-list.component.html',
  styles: [
  ]
})
export class EvoUserOperationClaimListComponent implements OnInit {

  constructor(private userOperationClaimServicew: EvoUseroperationclaimService,
    private errorService: ErrorService,
    private informationService: InformationService,
  ) { }

  public basicSelectedOption: number = 10;
  myId: number;
  userOperationClaims: UserOpertaionClaim[];
  filterText: string = "";
  deletedUserOperationClaimId: number;

  ngOnInit(): void {
    this.list();
  }

  list() {
    this.userOperationClaimServicew.getList().subscribe((items: any) => {
      this.userOperationClaims = items.data;
      this.informationService.list(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.userOperationClaimServicew.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.userOperationClaimServicew.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list();
        }, 1500);
      });
    });
  }
}
