import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EvoPermissionService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList(){
    return this.httpClient.get(this.baseApiUrl+'GetPermissionList/' );
  }
  get(id: number) {
    return this.httpClient.get(this.baseApiUrl + 'GetPermission/' + id);
  }
}
