import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Department } from 'app/evo/models/department';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoDepartmentService } from 'app/evo/services/evo-department.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-department-list',
  templateUrl: './evo-department-list.component.html',
  styles: [
  ],
  providers: [EvoDepartmentService]

})
export class EvoDepartmentListComponent implements OnInit {

  constructor(private departmentService:EvoDepartmentService,
    private errorService:ErrorService,
    private helperService:HelperService,
    private informationService:InformationService,
    private decodeService:DecodeService,
    private router: Router
    ) { }

  public basicSelectedOption: number = 10;

  departments:Department[];
  filterText: string = "";

  deletedDeparmentId:number;

  ngOnInit(): void {
    let item=this.decodeService.getRole();
    if(item!="Admin"){
      this.router.navigate(['/home']);
    }
    else{
      this.list();
    }
  }

  list() {
    this.departmentService.getList().subscribe((items: any) => {
      this.departments = items.data;
      this.informationService.list(items.message)
    },(err)=>{
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.departmentService.delete(id).subscribe((item: any) => {
    this.informationService.delete(item.message)
  },(err)=>{
    this.errorService.errorHandler(err);
  },()=>{
    setTimeout(() => {
      this.list();
    }, 1500);
  });
  }
  
  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Departmanlar";
    this.helperService.exportExcel(element, title);
  }

}
