import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Competence } from '../models/competence';

@Injectable({
  providedIn: 'root'
})
export class EvoCompetenceService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Competence[]>{
    return this.httpClient.get<Competence[]>(this.baseApiUrl+'GetCompetenceList');
  }
  getListByUserId(id:number):Observable<Competence[]>{
    return this.httpClient.get<Competence[]>(this.baseApiUrl+'GetCompetenceListByUserId/'+id);
  }

  get (id: number):Observable <Competence> {
    return this.httpClient.get<Competence>(this.baseApiUrl+'GetCompetence/'+id);
  }

  add(competence:Competence):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+'AddCompetence/',competence);
  }

  update(competence:Competence):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+"UpdateCompetence/",competence);
  }

  delete(id:number):Observable<Competence>{
    return this.httpClient.post<Competence>(this.baseApiUrl+"DeleteCompetence/"+ id,"");
  }
}
