<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-12">
          <core-card-snippet>
            <h4 class="card-title">Kullanıcıya Yetki Atama</h4>

            <div class="card-body">
              <form class="form form-vertical" #DepartmentForm="ngForm">
                <div class="col-12">
                  <div class="form-group">
                    <label for="name-vertical">Kullanıcıyı Seçiniz</label>
                    <select class="form-control" ngModel required name="slcUser" [(ngModel)]="user.id"
                      #slcUser="ngModel">
                      <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}</option>
                    </select>
                    <small class="text-danger" *ngIf="slcUser.invalid&& slcUser.touched">Bir Kullanıcı seçmeniz
                      gerekiyor!</small>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="name-vertical">Yetkiyi Seçiniz</label>
                    <select class="form-control" ngModel required name="slcOperationClaim"
                      [(ngModel)]="operationClaim.id" #slcOperationClaim="ngModel">
                      <option *ngFor="let item of operationClaims" value="{{item.id}}">{{item.name}}</option>
                    </select>
                    <small class="text-danger" *ngIf="slcOperationClaim.invalid&& slcOperationClaim.touched">Bir Yetki
                      seçmeniz gerekiyor!</small>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" rippleEffect class="btn btn-success mr-1" [disabled]="!DepartmentForm.valid"
                    (click)="add()">Kaydet</button>
                </div>
              </form>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
  </div>
</div>