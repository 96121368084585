import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Plan } from 'app/evo/models/plan';
import { PlanType } from 'app/evo/models/plantype';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoPlanService } from 'app/evo/services/evo-plan.service';
import { EvoTransactionService } from 'app/evo/services/evo-transaction.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-plan-list',
  templateUrl: './evo-plan-list.component.html',
  styles: [
  ]
})
export class EvoPlanListComponent implements OnInit {


  public hoveredDate: NgbDate | null = null;
  public fromDateExample: NgbDate | null;
  public toDateExample: NgbDate | null;

  constructor(private planService: EvoPlanService,
    private transactionService: EvoTransactionService,
    
    private errorService: ErrorService,
    private informationService: InformationService,
    private helperService: HelperService,
    private decodeService: DecodeService,
    private router: Router,
    public formatter: NgbDateParserFormatter) { }

  public basicSelectedOption: number = 10;
  myId:number;

  plans: Plan[];
  selectedTypes:any[]=[];
  filterText: string = "";
  deletedPlanId: number;

  fromDate: Date;
  toDate: Date;

  type: PlanType = new PlanType();
  typeForFilter: PlanType[] = [
    { id: 1, name: "Destek Planı" },
    { id: 2, name: "Proje Planı" },
    { id: 3, name: "İzin" },
    { id: 4, name: "Resmi Tatiller" },
  ];

  ngOnInit(): void {
    let item = this.decodeService.getRole();
    this.myId = this.decodeService.getUserId();
    if (item != "Admin") {
      this.router.navigate(['/home']);
      this.transactionService.saveTransaction(0, 11, 5, this.myId).subscribe((res: any) => {
      });
    }
    else {
      this.list();
    }
  }

  list() {
    this.planService.getList().subscribe((items: any) => {
      this.plans = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.planService.get(id).subscribe((item: any) => {
      item.data.updatedBy = 0;
      item.data.deletedBy = this.myId;
    this.planService.delete(item.data).subscribe((item: any) => {
      this.informationService.delete(item.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 1500);
    });
  });
  }


  tableByFiltersId() {
    this.planService.getList().subscribe((items: any) => {
      let filteredItems = items.data;

      if (this.selectedTypes.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedTypes.includes(item.typeId));
      }
      if (this.fromDate && this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate ||
          new Date(item.endDate) >= this.fromDate &&
          new Date(item.endDate) <= this.toDate
        );
      }
      this.plans = filteredItems;
    });
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  tableByDates() {
    var tempDate : any = {};
    var datePipe = new DatePipe("en-US");

    if (this.fromDateExample instanceof NgbDate){
      tempDate = {day: this.fromDateExample.day , month:this.fromDateExample.month -1, year:this.fromDateExample.year};
    }
    else{
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear()}
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate : any = {};

      if (this.toDateExample instanceof NgbDate){
        tempToDate = {day: this.toDateExample.day , month:this.toDateExample.month -1, year:this.toDateExample.year};
      }
      else{
        var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
        tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear()}
      }

      this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);

    if (!this.fromDate == null || !this.toDate == null) {
      this.list();
    } else {
      this.planService.getList().subscribe((items: any) => {
        this.plans = items.data.filter(item =>
          new Date(item.startDate) >= this.fromDate &&
          new Date(item.startDate) <= this.toDate ||
          new Date(item.endDate) >= this.fromDate &&
          new Date(item.endDate) <= this.toDate
        );
      });
    }
    this.tableByFiltersId();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Planlar";
    this.helperService.exportExcel(element, title);
  }
}
