import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evoMailSetting'
})
export class EvoMailSettingPipe implements PipeTransform {

 
  transform(value: any, filterText: string): any {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }

    return value.filter(p=> {
      const name = p.name.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const mail = p.mail.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      const urlAddress=p.urlAddress.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
      return (name+mail+urlAddress);
    });
  }

}
