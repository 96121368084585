<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Table Basic -->
    <div class="row" id="basic-table">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Table Basic</h4></div>
          <div class="card-body">
            <p class="card-text">
              Using the most basic table Leanne Grahamup, here’s how <code>.table</code> -based tables look in
              Bootstrap. You can use any example of below table for your table and it can be use with any type of
              bootstrap tables.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Table Basic -->

    <!-- Dark Table -->
    <div class="row" id="dark-table">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Dark Table</h4></div>
          <div class="card-body">
            <p class="card-text">
              You can also invert the colors—with light text on dark backgrounds—with <code>.table-dark</code> class
              with <code>.table</code> class.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-dark">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Dark Table -->

    <!-- Table head options -->
    <div class="row" id="table-head">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Table head options</h4></div>
          <div class="card-body">
            <p class="card-text">
              Similar to tables and dark tables, use the modifier classes
              <code class="highlighter-rouge">.thead-dark</code> to make
              <code class="highlighter-rouge">&lt;thead&gt;</code> s appear dark.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-dark">
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body mt-2">
            <p class="card-text">
              Use the modifier class <code class="highlighter-rouge">.thead-light</code> to make
              <code class="highlighter-rouge">&lt;thead&gt;</code> s appear light.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead class="thead-light">
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Table head options -->

    <!-- Striped rows -->
    <div class="row" id="table-striped">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Striped rows</h4></div>
          <div class="card-body">
            <p class="card-text">
              Use <code class="highlighter-rouge">.table-striped</code> to add zebra-striping to any table row within
              the <code class="highlighter-rouge">&lt;tbody&gt;</code> . This styling doesn't work in IE8 and below as
              <code>:nth-child</code> CSS selector isn't supported.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Striped rows -->

    <!-- Striped rows with inverse dark -->
    <div class="row" id="table-striped-dark">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Striped rows with inverse dark</h4></div>
          <div class="card-body">
            <p class="card-text">
              Use <code>.table-dark</code> with <code>.table-striped</code> to add zebra-striping to any inverse table
              row within the <code>&lt;tbody&gt;</code> . This styling doesn't work in IE8 and below as
              <code>:nth-child</code> CSS selector isn't supported.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-dark">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td>
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-sm text-white hide-arrow"
                        ngbDropdownToggle
                        data-toggle="dropdown"
                      >
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Striped rows with inverse dark -->

    <!-- Bordered table -->
    <div class="row" id="table-bordered">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Bordered table</h4></div>
          <div class="card-body">
            <p class="card-text">
              Add <code>.table-bordered</code> for borders on all sides of the table and cells. For Inverse Dark Table,
              add <code>.table-dark</code> along with <code> .table-bordered</code>.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Bordered table -->

    <!-- Borderless Table -->
    <div class="row" id="table-borderless">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Borderless Table</h4></div>
          <div class="card-body">
            <p class="card-text">
              Add <code>.table-borderless</code> for a table without borders. It can also be used on dark tables.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Borderless Table -->

    <!-- Hoverable rows -->
    <div class="row" id="table-hover-row">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Hoverable rows</h4></div>
          <div class="card-body">
            <p class="card-text">
              Add <code class="highlighter-rouge">.table-hover</code> to enable a hover state on table rows within a
              <code class="highlighter-rouge">&lt;tbody&gt;</code>.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Hoverable rows -->

    <!-- Small Table -->
    <div class="row" id="table-small">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Small Table</h4></div>
          <div class="card-body">
            <p class="card-text">
              Add <code class="highlighter-rouge">.table-sm</code> class with <code>.table</code> to display small size
              table.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      alt="Angular"
                      width="18"
                      height="18"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" alt="React" width="18" height="18" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" alt="Vuejs" width="18" height="18" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      alt="Bootstrap"
                      width="18"
                      height="18"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="22" width="22" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Small Table -->

    <!-- Contextual classes -->
    <div class="row" id="table-contextual">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Contextual classes</h4></div>
          <div class="card-body">
            <p class="card-text">
              Use contextual classes to color table rows or individual cells. Read full documnetation
              <a href="https://getbootstrap.com/docs/4.3/content/tables/IDcontextual-classes" target="_blank">here.</a>
            </p>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-default">
                  <td>
                    <img src="assets/images/icons/figma.svg" class="mr-75" height="20" width="20" alt="Figma" /><span
                      class="font-weight-bold"
                      >Figma Project</span
                    >
                  </td>
                  <td>Ronnie Shane</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-active">
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-primary">
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-secondary">
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-secondary mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-success">
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-danger">
                  <td>
                    <img src="assets/images/icons/figma.svg" class="mr-75" height="20" width="20" alt="Figma" /><span
                      class="font-weight-bold"
                      >Figma Project</span
                    >
                  </td>
                  <td>Janne Ale</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-danger mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-warning">
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Custom</span
                    >
                  </td>
                  <td>Ted Richer</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-info">
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Latest Bootstrap</span>
                  </td>
                  <td>Perry Parker</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-light">
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular UI</span>
                  </td>
                  <td>Ana Bell</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="table-dark">
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap UI</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-dark mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Contextual classes -->

    <!-- Table without card -->
    <div class="row" id="table-without-card">
      <div class="col-12 my-2">
        <h5 class="mb-1">Table without card</h5>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Project</th>
                <th>Client</th>
                <th>Users</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src="assets/images/icons/angular.svg" class="mr-75" height="20" width="20" alt="Angular" /><span
                    class="font-weight-bold"
                    >Angular Project</span
                  >
                </td>
                <td>Peter Charls</td>
                <td>
                  <div class="avatar-group">
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Lilian Nenez"
                    >
                      <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                  </div>
                </td>
                <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                      <i data-feather="more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                      ><a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                      >
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                    class="font-weight-bold"
                    >React Project</span
                  >
                </td>
                <td>Ronald Frest</td>
                <td>
                  <div class="avatar-group">
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Lilian Nenez"
                    >
                      <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                  </div>
                </td>
                <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                      <i data-feather="more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                      ><a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                      >
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                    class="font-weight-bold"
                    >Vuejs Project</span
                  >
                </td>
                <td>Jack Obes</td>
                <td>
                  <div class="avatar-group">
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Lilian Nenez"
                    >
                      <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                  </div>
                </td>
                <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                      <i data-feather="more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                      ><a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                      >
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src="assets/images/icons/bootstrap.svg"
                    class="mr-75"
                    height="20"
                    width="20"
                    alt="Bootstrap"
                  /><span class="font-weight-bold">Bootstrap Project</span>
                </td>
                <td>Jerry Milton</td>
                <td>
                  <div class="avatar-group">
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Lilian Nenez"
                    >
                      <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                    <div
                      data-toggle="tooltip"
                      data-popup="tooltip-custom"
                      placement="top"
                      container="body"
                      title=""
                      class="avatar pull-up my-0"
                      ngbTooltip="Alberto Glotzbach"
                    >
                      <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                    </div>
                  </div>
                </td>
                <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                <td class="overflow-hidden">
                  <div ngbDropdown container="body">
                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                      <i data-feather="more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                      ><a ngbDropdownItem href="javascript:void(0);"
                        ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!--/ Table without card -->

    <!-- Responsive tables -->
    <div class="row" id="table-responsive">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Responsive tables</h4></div>
          <div class="card-body">
            <p class="card-text">
              Responsive tables allow tables to be scrolled horizontally with ease. Make any table responsive across all
              viewports by adding <code class="highlighter-rouge">.table-responsive</code> class on
              <code class="highlighter-rouge">.table</code> . Or, pick a maximum breakpoint with which to have a
              responsive table up to by adding
              <code class="highlighter-rouge">.table-responsive&#123;-sm|-md|-lg|-xl &#125;</code> . Read full
              documentation
              <a href="https://getbootstrap.com/docs/4.3/content/tables/#responsive-tables" target="_blank">here.</a>
            </p>
            <div class="alert alert-info">
              <div class="alert-body">
                <h4 class="text-warning">Vertical clipping/truncation</h4>
                <p>
                  Responsive tables make use of <code class="highlighter-rouge">overflow-y:hidden</code>, which clips
                  off any content that goes beyond the bottom or top edges of the table. In particular, this can clip
                  off dropdown menus and other third-party widgets.
                </p>
              </div>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th scope="col" class="text-nowrap">#</th>
                  <th scope="col" class="text-nowrap">Heading 1</th>
                  <th scope="col" class="text-nowrap">Heading 2</th>
                  <th scope="col" class="text-nowrap">Heading 3</th>
                  <th scope="col" class="text-nowrap">Heading 4</th>
                  <th scope="col" class="text-nowrap">Heading 5</th>
                  <th scope="col" class="text-nowrap">Heading 6</th>
                  <th scope="col" class="text-nowrap">Heading 7</th>
                  <th scope="col" class="text-nowrap">Heading 8</th>
                  <th scope="col" class="text-nowrap">Heading 9</th>
                  <th scope="col" class="text-nowrap">Heading 10</th>
                  <th scope="col" class="text-nowrap">Heading 11</th>
                  <th scope="col" class="text-nowrap">Heading 12</th>
                  <th scope="col" class="text-nowrap">Heading 13</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-nowrap">1</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                  <td class="text-nowrap">Table cell</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                  <td>Table cell</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Responsive tables -->

    <!-- Table Hover Animation -->
    <div class="row" id="table-hover-animation">
      <div class="col-12">
        <div class="card">
          <div class="card-header"><h4 class="card-title">Table Hover Animation</h4></div>
          <div class="card-body">
            <p class="card-text">
              Add <code>.table-hover-animation</code> to enable a hover stat with animation on table rows within a
              <code class="highlighter-rouge">&lt;tbody&gt;</code>.
            </p>
          </div>
          <div class="table-responsive">
            <table class="table table-hover-animation">
              <thead>
                <tr>
                  <th>Project</th>
                  <th>Client</th>
                  <th>Users</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/angular.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Angular"
                    /><span class="font-weight-bold">Angular Project</span>
                  </td>
                  <td>Peter Charls</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-primary mr-1">Active</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/react.svg" class="mr-75" height="20" width="20" alt="React" /><span
                      class="font-weight-bold"
                      >React Project</span
                    >
                  </td>
                  <td>Ronald Frest</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-success mr-1">Completed</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="assets/images/icons/vuejs.svg" class="mr-75" height="20" width="20" alt="Vuejs" /><span
                      class="font-weight-bold"
                      >Vuejs Project</span
                    >
                  </td>
                  <td>Jack Obes</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-info mr-1">Scheduled</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="assets/images/icons/bootstrap.svg"
                      class="mr-75"
                      height="20"
                      width="20"
                      alt="Bootstrap"
                    /><span class="font-weight-bold">Bootstrap Project</span>
                  </td>
                  <td>Jerry Milton</td>
                  <td>
                    <div class="avatar-group">
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Lilian Nenez"
                      >
                        <img src="assets/images/portrait/small/avatar-s-5.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                      <div
                        data-toggle="tooltip"
                        data-popup="tooltip-custom"
                        placement="top"
                        container="body"
                        title=""
                        class="avatar pull-up my-0"
                        ngbTooltip="Alberto Glotzbach"
                      >
                        <img src="assets/images/portrait/small/avatar-s-7.jpg" alt="Avatar" height="26" width="26" />
                      </div>
                    </div>
                  </td>
                  <td><span class="badge badge-pill badge-light-warning mr-1">Pending</span></td>
                  <td class="overflow-hidden">
                    <div ngbDropdown container="body">
                      <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                        <i data-feather="more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="edit-2" class="mr-50"></i><span>Edit</span></a
                        ><a ngbDropdownItem href="javascript:void(0);"
                          ><i data-feather="trash" class="mr-50"></i><span>Delete</span></a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--/ Table Hover Animation -->


    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-6 col-12">
          <core-card-snippet>
            <h4 class="card-title">Vertical Form</h4>

            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="first-name-vertical">First Name</label>
                      <input
                        type="text"
                        id="first-name-vertical"
                        class="form-control"
                        name="fname"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="email-id-vertical">Email</label>
                      <input
                        type="email"
                        id="email-id-vertical"
                        class="form-control"
                        name="email-id"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="contact-info-vertical">Mobile</label>
                      <input
                        type="number"
                        id="contact-info-vertical"
                        class="form-control"
                        name="contact"
                        placeholder="Mobile"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="password-vertical">Password</label>
                      <input
                        type="password"
                        id="password-vertical"
                        class="form-control"
                        name="contact"
                        placeholder="Password"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck3" />
                        <label class="custom-control-label" for="customCheck3">Remember me</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" rippleEffect class="btn btn-primary mr-1">Submit</button>
                    <button type="reset" rippleEffect class="btn btn-outline-secondary">Reset</button>
                  </div>
                </div>
              </form>
            </div>
          </core-card-snippet>
        </div>
        <div class="col-md-6 col-12">
          <core-card-snippet>
            <h4 class="card-title">Vertical Form with Icons</h4>
            <div class="card-body">
              <form class="form form-vertical">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="first-name-icon">First Name</label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                        </div>
                        <input
                          type="text"
                          id="first-name-icon"
                          class="form-control"
                          name="fname-icon"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="email-id-icon">Email</label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span [data-feather]="'mail'"></span></span>
                        </div>
                        <input
                          type="email"
                          id="email-id-icon"
                          class="form-control"
                          name="email-id-icon"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="contact-info-icon">Mobile</label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span [data-feather]="'smartphone'"></span></span>
                        </div>
                        <input
                          type="number"
                          id="contact-info-icon"
                          class="form-control"
                          name="contact-icon"
                          placeholder="Mobile"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="password-icon">Password</label>
                      <div class="input-group input-group-merge">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><span [data-feather]="'lock'"></span></span>
                        </div>
                        <input
                          type="password"
                          id="password-icon"
                          class="form-control"
                          name="contact-icon"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck4" />
                        <label class="custom-control-label" for="customCheck4">Remember me</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" rippleEffect class="btn btn-primary mr-1">Submit</button>
                    <button type="reset" rippleEffect class="btn btn-outline-secondary">Reset</button>
                  </div>
                </div>
              </form>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>

    <section id="checkbox-basic">
      <div class="row">
        <div class="col-12">
          <core-card-snippet>
            <h4 class="card-title">Basic Checkboxes</h4>
            <div class="card-body">
              <div class="demo-inline-spacing">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="checked" checked />
                  <label class="form-check-label" for="inlineCheckbox1">Checked</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="unchecked" />
                  <label class="form-check-label" for="inlineCheckbox2">Unchecked</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox3"
                    value="checked-disabled"
                    checked
                    disabled
                  />
                  <label class="form-check-label" for="inlineCheckbox3">Checked disabled</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox4"
                    value="unchecked-disabled"
                    disabled
                  />
                  <label class="form-check-label" for="inlineCheckbox4">Unchecked disabled</label>
                </div>
              </div>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>

    <section id="input-file-browser">
      <div class="row">
        <div class="col-md-12">
          <core-card-snippet>
            <h4 class="card-title">File input</h4>

            <div class="card-body">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label for="basicInputFile">Simple File Input</label>
                    <input type="file" class="form-control-file" id="basicInputFile" />
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="form-group">
                    <label for="basicInputFile">With Browse button</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="customFile" />
                      <label class="custom-file-label" for="customFile">Choose file</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
    <!-- Basic File Browser end -->

    <!-- validations start -->
    <section class="validations" id="validation">
      <div class="row">
        <div class="col-12">
          <core-card-snippet >
            <h4 class="card-title">Input Validation States</h4>

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="card-text mb-1">
                    You can indicate invalid and valid form fields with <code>.is-invalid</code> and
                    <code>.is-valid</code>. Note that <code>.invalid-feedback</code> is also supported with these
                    classes.
                  </p>
                </div>
                <div class="col-sm-6 col-12">
                  <label for="valid-state">Valid State</label>
                  <input
                    type="text"
                    class="form-control is-valid"
                    id="valid-state"
                    placeholder="Valid"
                    value="Valid"
                    required
                  />
                  <div class="valid-feedback">This is valid state.</div>
                </div>
                <div class="col-sm-6 col-12">
                  <label for="invalid-state">Invalid State</label>
                  <input
                    type="text"
                    class="form-control is-invalid"
                    id="invalid-state"
                    placeholder="Invalid"
                    value="Invalid"
                    required
                  />
                  <div class="invalid-feedback">This is invalid state.</div>
                </div>
              </div>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>


    <section class="bootstrap-select">
      <div class="row">
        <div class="col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Bootstrap Select</h4>
            </div>
            <div class="card-body">
              <!-- Basic Select -->
              <div class="form-group">
                <label for="basicSelect">Basic Select</label>
                <select class="form-control" id="basicSelect">
                  <option>IT</option>
                  <option>Blade Runner</option>
                  <option>Thor Ragnarok</option>
                </select>
              </div>

              <!-- Custom Select -->
              <div class="form-group">
                <label for="customSelect">Custom Select</label>
                <select class="custom-select" id="customSelect">
                  <option selected>Open this menu</option>
                  <option value="IT">IT</option>
                  <option value="Blade Runner">Blade Runner</option>
                  <option value="Thor Ragnarok">Thor Ragnarok</option>
                </select>
              </div>

              <!-- Disabled Select -->
              <div class="form-group">
                <label for="disabledSelect">Disabled Select</label>
                <select class="form-control" disabled="disabled" id="disabledSelect">
                  <option>Green</option>
                  <option>Red</option>
                  <option>Blue</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Bootstrap Select Sizing</h4>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label for="selectLarge">Select Large</label>
                <select class="form-control form-control-lg mb-1" id="selectLarge">
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <div class="form-group">
                <label for="selectDefault">Default</label>
                <select class="form-control mb-1" id="selectDefault">
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <div class="form-group">
                <label for="selectSmall">Select Small</label>
                <select class="form-control form-control-sm" id="selectSmall">
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Bootstrap Multi Select</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- Multiple Select -->
                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="normalMultiSelect">Multiple Select</label>
                    <select class="form-control" id="normalMultiSelect" multiple="multiple">
                      <option selected="selected">Square</option>
                      <option>Rectangle</option>
                      <option selected="selected">Rombo</option>
                      <option>Romboid</option>
                      <option>Trapeze</option>
                      <option>Triangle</option>
                      <option selected="selected">Polygon</option>
                      <option>Regular polygon</option>
                      <option>Circumference</option>
                      <option>Circle</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="customSelectMulti">Custom Multiple Select</label>
                    <select class="custom-select" id="customSelectMulti" multiple>
                      <option selected="selected">Square</option>
                      <option>Rectangle</option>
                      <option selected="selected">Rombo</option>
                      <option>Romboid</option>
                      <option>Trapeze</option>
                      <option>Triangle</option>
                      <option selected="selected">Polygon</option>
                      <option>Regular polygon</option>
                      <option>Circumference</option>
                      <option>Circle</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-4 col-12">
                  <div class="form-group">
                    <label for="customSelectSize">Custom Select Size</label>
                    <select class="custom-select" id="customSelectSize" size="4">
                      <option selected="selected">Square</option>
                      <option>Rectangle</option>
                      <option selected="selected">Rombo</option>
                      <option>Romboid</option>
                      <option>Trapeze</option>
                      <option>Triangle</option>
                      <option selected="selected">Polygon</option>
                      <option>Regular polygon</option>
                      <option>Circumference</option>
                      <option>Circle</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section id="basic-and-time">
      <div class="row">
        <div class="col-12 col-md-6">
          <core-card-snippet>
            <h4 class="card-title">Basic Date Picker</h4>
            <div class="card-body">
              <p class="card-text">
                Use <code>ngbDatepicker</code> along with <code>input</code> element to create basic date picker.
              </p>
              <!-- Basic Date Picker -->
              <form class="form-inline">
                <div class="form-group">
                  <div class="input-group">
                    <input
                      class="form-control"
                      placeholder="yyyy-mm-dd"
                      name="Basic Date Picker"
                      ngbDatepicker
                      #basicDP="ngbDatepicker"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary feather icon-calendar"
                        (click)="basicDP.toggle()"
                        type="button"
                        rippleEffect
                      ></button>
                    </div>
                  </div>
                </div>
              </form>
              <!--/ Basic Date Picker -->
            </div>
          </core-card-snippet>
          <core-card-snippet >
            <h4 class="card-title">Basic dnençdçamdçam Picker</h4>
            <div class="card-body">
              <p class="card-text">Use <code>ngb-timepicker</code> to create basic Time Picker with spinner.</p>
              <!-- Basic Time Picker -->
              <ngb-timepicker [(ngModel)]="basicTP"></ngb-timepicker>
              <hr />
              <!--/ Basic Time Picker -->
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>

  </div>
</div>
