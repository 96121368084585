import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { User } from 'app/evo/models/user';
import { UserOpertaionClaim } from 'app/evo/models/useroperationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { EvoUseroperationclaimService } from 'app/evo/services/evo-useroperationclaim.service';
import { InformationService } from 'app/evo/services/information.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-evo-useroperationclaim-info-form',
  templateUrl: './evo-useroperationclaim-info-form.component.html',
  styles: [
  ]
})
export class EvoUseroperationclaimInfoFormComponent implements OnInit {

  constructor(
    private userOperationClaimService: EvoUseroperationclaimService,
    private informationService: InformationService,
    private route: ActivatedRoute,
    private operationClaimService: EvoOperationClaimService,
    private decodeService: DecodeService,
    private errorService: ErrorService,
  ) { }

  public basicSelectedOption: number = 10;

  operationClaims: OperationClaim[];
  userOperationClaims: UserOpertaionClaim[];
  operationClaim: OperationClaim = new OperationClaim();
  userOperationClaim: UserOpertaionClaim = new UserOpertaionClaim();

  id: number;
  myId: number;

  ngOnInit(): void {
    this.operationClaimService.getList().subscribe((res: any) => {
      this.operationClaims = res.data;
    });
    this.id = this.route.snapshot.params['Id'];
    this.myId = this.decodeService.getUserId();
    this.list(this.id);
  }

  list(id: number) {
    this.userOperationClaimService.getListByUserId(id).subscribe((items: any) => {
      this.userOperationClaims = items.data;
      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }

  delete(id: number) {
    this.userOperationClaimService.get(id).subscribe((item: any) => {
      item.data.deletedBy = this.myId;
      item.data.updatedBy = 0;

      this.userOperationClaimService.delete(item.data).subscribe((item: any) => {
        this.informationService.delete(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.list(this.id);
        }, 1500);
      });
    });
  }

  add() {
    this.userOperationClaim = {
      id: 0,
      userId: this.id,
      operationClaimId: this.operationClaim.id,
      userName: "",
      operationClaimTitle: "",
      createdBy: this.myId,
      updatedBy: 0,
      deletedBy: 0,
    };
    this.userOperationClaimService.add(this.userOperationClaim).subscribe((res: any) => {
      this.informationService.add(res.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list(this.id);
      }, 1500);
    });
  }
}

