<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <section id="ngx-datatable-kitchen-sink">
      <core-card-snippet>

        <h4 class="card-title">Hedef Kartları</h4>


        <div class="row">
          <!-- <div class="search mt-1">
            <div class="col-12">
              <input type="text" class="form-control mr-1" name="filterText" placeholder="Hedef Arayın.."
                [(ngModel)]="filterText" [ngModelOptions]="{standalone:true}">
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-2 mb-1">
                <button class="btn btn-primary btn-sm ml-1 mr-1" data-bs-toggle="modal" data-bs-target="#addgoaldetail"
                  (click)="saveGoalDetail(null)" rippleEffect title="Yeni Hedef Detayı Ekle">
                  <i data-feather="plus"></i><span class="d-none d-sm-inline-block"></span>
                </button>
                <button class="btn btn-info btn-sm" (click)="exportExcel()" rippleEffect title="Excele Aktar">
                  <i data-feather="file-text" class=""></i>
                </button>

              </div>
              <div class="col-6" *ngIf="userRole=='Admin' || userRole=='Consultant'">
                <button class="btn btn-secondary btn-sm " data-bs-toggle="modal" data-bs-target="#addgoalstatus"
                  (click)="saveGoalStatus(null)" rippleEffect title="Yeni Hedef Kartı Türü Ekle">
                  <i data-feather="plus"></i><span class="d-none d-sm-inline-block">Hedef Kartı Türü Oluştur</span>
                </button>
                <button class="btn btn-secondary btn-sm ml-4" data-bs-toggle="modal" data-bs-target="#addgoal"
                  (click)="saveGoal(null,'add')" rippleEffect title="Yeni Hedef Kartı Ekle">
                  <i data-feather="plus"></i><span class="d-none d-sm-inline-block">Hedef Kartı Oluştur</span>
                </button>
              </div>
              <div class="col-2 mr-2 mb-1" *ngIf="userRole=='Admin' || userRole=='Consultant'">
                <select class="form-control" required ngModel name="sclUser" (ngModelChange)="changeUser(user.id)"
                  [(ngModel)]="user.id" #sclUser="ngModel">
                  <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-12">
            <table class="table table-hover table-bordered" id="excel-table" style="display: none;">
              <thead>
                <th>Adı</th>
                <th>Türü</th>
              </thead>
              <tbody>
                <tr *ngFor="let item of goals">
                  <td>{{item.name}}</td>
                  <td>{{item.goalStatusName}}</td>
                </tr>
              </tbody>
            </table>
            <div class="card-body collapse-icon">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                <ngb-panel *ngFor="let status of groupedData | keyvalue">
                  <ng-template ngbPanelTitle>
                    <!-- <span class="lead collapse-title"> -->
                    <div class="row">
                      <div class="col-2">
                        <div *ngIf="userRole=='Admin' || userRole=='Consultant'" class="d-flex align-items-center">
                          <div ngbDropdown container="body">
                            <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                            </a>
                            <div ngbDropdownMenu class="dropdown-menu-right" title="Hedef Kartı Türünü Kaldır"
                              aria-labelledby="dropdownBrowserState">
                              <a ngbDropdownItem (click)="saveGoalStatus(status.key)" data-bs-toggle="modal"
                                data-bs-target="#deletegoalstatus"><i data-feather="trash"
                                  class="mr-50"></i><span>Sil</span></a>
                              <a ngbDropdownItem (click)="saveGoalStatus(status.key)" title="Hedef Kartı Türünü Düzenle"
                                data-bs-toggle="modal" data-bs-target="#updategoalstatus"><i data-feather="edit-2"
                                  class="mr-50"></i><span>Güncelle</span></a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-9"> {{ status.key }} </div>

                    </div>
                    <!-- </span> -->
                  </ng-template>

                  <ng-template ngbPanelContent>
                    <div class="table-responsive">
                      <table class="table table-hover table-bordered text-center">
                        <thead>
                          <tr>
                            <th>STRATEJİK AMAÇ</th>
                            <th></th>
                            <th>ÖLÇÜM / PERFORMANS GÖSTERGESİ</th>
                            <th>ÖLÇÜ BİRİMİ</th>
                            <th>HEDEF DURUMU</th>
                            <th>GERÇEKLEŞEN DEĞER</th>
                            <th>GÖZLEM PERİYODU</th>
                            <th>AĞIRLIK %</th>
                            <th>PUAN</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let goal of status.value | keyvalue ">
                            <td>
                              <div *ngIf="userRole=='Admin' || userRole=='Consultant'; else goalActionTemplate">
                                <div ngbDropdown container="body">
                                  <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                    id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                  </a>
                                  <div ngbDropdownMenu class="dropdown-menu-right" title="Stratejik Amacı Kaldır"
                                    aria-labelledby="dropdownBrowserState">
                                    <a ngbDropdownItem (click)="saveGoal(goal.value[0],'delete')" data-bs-toggle="modal"
                                      data-bs-target="#deletegoal"><i data-feather="trash"
                                        class="mr-50"></i><span>Sil</span></a>
                                    <a ngbDropdownItem (click)="saveGoalDetail(goal.value[0])"
                                      title="Bu Amaca Yeni Veri Ekle" data-bs-toggle="modal"
                                      data-bs-target="#addgoaldetail"><i data-feather="plus"
                                        class="mr-50"></i><span>Ekle</span></a>
                                    <a ngbDropdownItem (click)="saveGoal(goal.value[0],'update')"
                                      title="Stratejik Amacı Düzenle" data-bs-toggle="modal"
                                      data-bs-target="#updategoal"><i data-feather="edit-2"
                                        class="mr-50"></i><span>Güncelle</span></a>

                                  </div>
                                </div>
                              </div>
                              <ng-template #goalActionTemplate>
                                <div ngbDropdown container="body" (click)="saveGoalDetail(goal.value[0])">
                                  <a ngbDropdownToggle href="javascript:void(0);" data-bs-toggle="modal"
                                    data-bs-target="#addgoaldetail" class="hide-arrow" id="dropdownBrowserState"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div class="avatar avatar-status ng-star-inserted bg-light-primary"
                                      ng-reflect-ng-class="[object Object]">
                                      <span placement="top" container="body" [ngbTooltip]="tipContent"
                                        class="avatar-content" ng-reflect-placement="top" ng-reflect-container="body"
                                        ng-reflect-ngb-tooltip="[object Object]"><i
                                          class="feather avatar-icon font-medium-1 icon-plus"
                                          ng-reflect-ng-class="[object Object]"></i></span>
                                    </div>
                                  </a>
                                </div>
                              </ng-template>
                              {{ goal.value[0].goalName }}
                            </td>

                            <td colspan="2">
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">
                                  <div class="row">
                                    <div class="col-2">
                                      <div class="d-flex align-items-center">
                                        <div ngbDropdown container="body">
                                          <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow"
                                            id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i data-feather="more-vertical"
                                              class="text-primary cursor-pointer mr-50"></i>
                                          </a>
                                          <div ngbDropdownMenu class="dropdown-menu-right"
                                            aria-labelledby="dropdownBrowserState">
                                            <a ngbDropdownItem (click)="deletedGoalDetailId=detail.id"
                                              data-bs-toggle="modal" data-bs-target="#deletegoaldetail"><i
                                                data-feather="trash" class="mr-50"></i><span>Sil</span></a>
                                            <a ngbDropdownItem (click)="saveGoalDetail(detail)" data-bs-toggle="modal"
                                              data-bs-target="#updategoaldetail"><i data-feather="edit-2"
                                                class="mr-50"></i><span>Güncelle</span></a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-10"> {{ detail.scaleIndicator }}</div>
                                  </div>
                                </li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.scaleName }}</li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.targeted }}</li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.relaised }}</li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.observationPeriodName }}
                                </li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.weight }}%</li>
                              </ul>
                            </td>
                            <td>
                              <ul class="list-unstyled">
                                <li class="mt-1" *ngFor="let detail of goal.value">{{ detail.point }}</li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>



        <div class="modal fade" id="addgoal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Oluştur</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #addGoalForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="goalSelect">Hedef Kartı Türünü Seçiniz</label>
                          <select class="form-control" ngModel required name="sclGoalStatus" [(ngModel)]="goalStatus.id"
                            #sclGoalStatus="ngModel">
                            <option *ngFor="let item of goalStatuses" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclGoalStatus.invalid && sclGoalStatus.touched">Bir Hedef
                            Kartı Türü
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Stratejik Amacı Griniz</label>
                          <input type="text" ngModel required id="txtGoalName" class="form-control" name="txtGoalName"
                            placeholder="Stratejiik Amacı Düzenle" #txtGoalName="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!addGoalForm.valid;else emptyaddGoalButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyaddGoalButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="addGoal(txtGoalName.value)">Kaydet</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="addgoaldetail" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Detayı Ekle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #addGoalDetailForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="name-vertical">Hedef Kartı Türünü Seçiniz</label>
                          <select class="form-control" required name="slcGoalStatus"
                            [(ngModel)]="goalStatusForSelect.id" #slcGoalStatus="ngModel"
                            (ngModelChange)="changeGoalStatus(goalStatusForSelect.id)">
                            <option value="0">Hedef Kartı Türü Seçiniz</option>
                            <option *ngFor="let item of goalStatuses" value="{{item.id}}">{{item.name}}
                            </option>
                          </select>
                          <small class="text-danger" *ngIf="sclGoal.invalid && sclGoal.touched">Bir Hedef Kartı Türü
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="goalSelect">Stratejik Amacı Seçiniz</label>
                          <select class="form-control" ngModel required name="sclGoal" [(ngModel)]="goalForAdd.id"
                            #sclGoal="ngModel">
                            <option value="0">Stratejik Amaç Seçiniz</option>
                            <option *ngFor="let item of goalsForSelect" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclGoal.invalid && sclGoal.touched">Stratejik Amacı
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Ölçüm /Performans Göstergesi Giriniz</label>
                          <input type="text" ngModel id="txtScaleIndicator" class="form-control"
                            name="txtScaleIndicator" placeholder="Ölçüm /Performans Göstergesiı"
                            #txtScaleIndicator="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="scaleSelect">Ölçüm Birimi Seçiniz</label>
                          <select class="form-control" ngModel required name="sclScale" [(ngModel)]="scale.id"
                            #sclScale="ngModel">
                            <option *ngFor="let item of scales" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclScale.invalid && sclScale.touched">Bir Ölçüm Birimi
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="observationPeriodSelect">Gözlem Periyodu Seçiniz</label>
                          <select class="form-control" ngModel required name="sclObservationPeriod"
                            [(ngModel)]="observationPeriod.id" #sclObservationPeriod="ngModel">
                            <option *ngFor="let item of observationPeriods" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger"
                            *ngIf="sclObservationPeriod.invalid && sclObservationPeriod.touched">Bir Gözlem Periyodu
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Hedef Durumu Giriniz</label>
                          <input type="number" ngModel required id="txtTargeted" class="form-control" name="txtTargeted"
                            placeholder="Hedef Durumu" #txtTargeted="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Gerçekleşen Durumu Giriniz</label>
                          <input type="number" ngModel required id="txtRelaised" class="form-control" name="txtRelaised"
                            placeholder="Hedef Durumu" #txtRelaised="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Ağırlık Değerini Giriniz (% Olarak)</label>
                          <input type="number" ngModel required id="txtWeight" class="form-control" name="txtWeight"
                            placeholder="Hedef Durumu" #txtWeight="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Puan Değerini Giriniz (% Olarak)</label>
                          <input type="number" ngModel required id="txtPoint" class="form-control" name="txtPoint"
                            placeholder="Hedef Durumu" #txtPoint="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div> -->
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!addGoalDetailForm.valid; else emptyaddButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyaddButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="add(txtScaleIndicator.value, txtTargeted.value, txtRelaised.value, txtWeight.value )">Kaydet</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="addgoalstatus" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Türü Oluştur</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #addGoalStatusForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Hedef Kartı Türünü Giriniz</label>
                          <input type="text" ngModel required id="txtGoalStatusName" class="form-control"
                            name="txtGoalStatusName" placeholder="Hedef Kartı Türü" #txtGoalStatusName="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!addGoalStatusForm.valid;else emptyaddGoalStatusButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyaddGoalStatusButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="addGoalStatus(txtGoalStatusName.value)">Kaydet</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="updategoal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartını Düzenle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #updateGoalForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="goalSelect">Hedef Kartı Türünü Seçiniz</label>
                          <select class="form-control" ngModel required name="sclGoalStatus" [(ngModel)]="goal.statusId"
                            #sclGoalStatus="ngModel">
                            <option *ngFor="let item of goalStatuses" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclGoalStatus.invalid && sclGoalStatus.touched">Bir Hedef
                            Kartı Türü
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Stratejik Amacı Düzenle</label>
                          <input type="text" ngModel required id="txtGoalName" [(ngModel)]="goal.name"
                            class="form-control" name="txtGoalName" placeholder="Stratejiik Amacı Düzenle"
                            #txtGoalName="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!updateGoalForm.valid;else emptyupdateGoalButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyupdateGoalButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="updateGoal()">Güncelle</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="updategoaldetail" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Detayını Düzenle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #updateGoalDetailForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="name-vertical">Hedef Kartı Türünü Seçiniz</label>
                          <select class="form-control" required name="slcGoalStatus"
                            [(ngModel)]="goalDetail.goalStatusId" #slcGoalStatus="ngModel"
                            (ngModelChange)="changeGoalStatus(goalDetail.goalStatusId)">
                            <option *ngFor="let item of goalStatuses" value="{{item.id}}">{{item.name}}
                            </option>
                          </select>
                          <small class="text-danger" *ngIf="slcGoalStatus.invalid && slcGoalStatus.touched">Bir Hedef
                            Kartı Türü
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="goalSelect">Stratejik Amacı Seçiniz</label>
                          <select class="form-control" ngModel required name="sclGoal" [(ngModel)]="goalDetail.goalId"
                            #sclGoal="ngModel">
                            <option value="0">Stratejik Amaç Seçiniz</option>
                            <option *ngFor="let item of goalsForSelect" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclGoal.invalid && sclGoal.touched">Stratejik Amacı
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Ölçüm /Performans Göstergesi Giriniz</label>
                          <input type="text" ngModel id="txtScaleIndicator" [(ngModel)]="goalDetail.scaleIndicator"
                            class="form-control" name="txtScaleIndicator" placeholder="Ölçüm /Performans Göstergesiı"
                            #txtScaleIndicator="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="scaleSelect">Ölçüm Birimi Seçiniz</label>
                          <select class="form-control" ngModel required name="sclScale" [(ngModel)]="goalDetail.scaleId"
                            #sclScale="ngModel">
                            <option *ngFor="let item of scales" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger" *ngIf="sclScale.invalid && sclScale.touched">Bir Ölçüm Birimi
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Hedef Durumu Giriniz</label>
                          <input type="text" ngModel required id="txtTargeted" [(ngModel)]="goalDetail.targeted"
                            class="form-control" name="txtTargeted" placeholder="Hedef Durumu" #txtTargeted="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Gerçekleşen Durumu Giriniz</label>
                          <input type="text" ngModel required id="txtRelaised" [(ngModel)]="goalDetail.relaised"
                            class="form-control" name="txtRelaised" placeholder="Hedef Durumu" #txtRelaised="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Ağırlık Değerini Giriniz (% Olarak)</label>
                          <input type="text" ngModel required id="txtWeight" [(ngModel)]="goalDetail.weight"
                            class="form-control" name="txtWeight" placeholder="Hedef Durumu" #txtWeight="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Puan Değerini Giriniz (% Olarak)</label>
                          <input type="text" ngModel required id="txPoint" [(ngModel)]="goalDetail.point"
                            class="form-control" name="txPoint" placeholder="Hedef Durumu" #txPoint="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="observationPeriodSelect">Gözlem Periyodu Seçiniz</label>
                          <select class="form-control" ngModel required name="sclObservationPeriod"
                            [(ngModel)]="goalDetail.observationPeriodId" #sclObservationPeriod="ngModel">
                            <option *ngFor="let item of observationPeriods" value="{{item.id}}">{{item.name}}</option>
                          </select>
                          <small class="text-danger"
                            *ngIf="sclObservationPeriod.invalid && sclObservationPeriod.touched">Bir
                            Gözlem Periyodu
                            seçmeniz gerekiyor!</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!updateGoalDetailForm.valid;else emptyupdateDetailButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyupdateDetailButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="update()">Güncelle</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="updategoalstatus" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Türünü Düzenle</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                <form class="form form-vertical" #updateGoalStatusForm="ngForm">

                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12 mr-0 ">
                          <label for="subject-vertical">Hedef Kartı Türünü Giriniz</label>
                          <input type="text" ngModel id="txtGoalStatus" [(ngModel)]="goalStatus.name"
                            class="form-control" name="txtGoalStatus" placeholder="Hedef Kartı Türü"
                            #txtGoalStatus="ngModel" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <div>
                  <div class="col-12" *ngIf="!updateGoalStatusForm.valid;else emptyupdateStatusButton">
                    <button type="button" rippleEffect class="btn btn-success mr-1 cursor-not-allowed"
                      disabled>Kaydet</button>
                  </div>
                  <ng-template #emptyupdateStatusButton>
                    <div class="col-12">
                      <button type="button" data-bs-dismiss="modal" rippleEffect class="btn btn-success mr-1"
                        (click)="updateGoalStatus()">Güncelle</button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="deletegoal" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartını Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
              </div>
              <div class="modal-footer">
                <div>
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="deleteGoal(deletedGoalId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="deletegoaldetail" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Detayını Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
              </div>
              <div class="modal-footer">
                <div>
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="delete(deletedGoalDetailId)"><i data-feather="trash" class="mr-50"></i>Sil</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="deletegoalstatus" tabindex="-1" aria-labelledby="staticBackdropLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Hedef Kartı Türünü Sil</h1>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i data-feather="x-circle"
                    class="mr-50"></i>Çıkış</button>
              </div>
              <div class="modal-body">
                Kaydı Silmek İstediğinize Emin Misiniz?
              </div>
              <div class="modal-footer">
                <div>
                  <button type="button" class="btn btn-danger" data-bs-dismiss="modal"
                    (click)="deleteGoalStatus(goalStatus.id)"><i data-feather="trash" class="mr-50"></i>Sil</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </core-card-snippet>
    </section>
  </div>
</div>