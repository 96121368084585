export class Partner{
    id:number;
    name:string;
    title:string;
    mailAddress:string;
    mainAddress:string;
    invoceAddress:string;
    phoneNumber:string;
    information:string;
    description:string;
    taxOffice:string;
    taxNumber:string;
    agreementRate:string;
    isCustomer:boolean;
    isSupplier:boolean;

    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}