import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Recurre } from 'app/evo/models/reccure';
import { Task } from 'app/evo/models/task';
import { TaskStatus } from 'app/evo/models/tasskstatus';
import { Todo } from 'app/evo/models/todo.model';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoRecurreService } from 'app/evo/services/evo-recurre.service';
import { EvoTaskStatusService } from 'app/evo/services/evo-task-status.service';
import { EvoTaskService } from 'app/evo/services/evo-task.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { InformationService } from 'app/evo/services/information.service';
import { DragulaService } from 'ng2-dragula';

import { DatePipe } from '@angular/common';

import { DropEffect } from 'ngx-drag-drop';
@Component({
  selector: 'app-evo-todo',
  templateUrl: './evo-todo.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
  ],
  host: { class: 'todo-application' }
})
export class EvoTodoComponent implements OnInit {
  todos: Todo[] = [

    // Buraya istediğiniz kadar todo öğesi ekleyebilirsiniz
  ];
  alertClose: boolean = false;
  @Input() todo: any;
  public filters: Array<{}>;
  public tags: Array<{}>;
  filterText: string = "";

  public hoveredDate: any = null;
  public fromDateExample: any = null;
  public toDateExample: any = null

  fromDate: Date;
  toDate: Date;

  nostartpage: number = 1;
  progresspage: number = 1;
  donepage: number = 1;

  selectedFrequencies = []
  public isDataEmpty;
  public selectTags;
  public selectAssignee;

  @ViewChild('dueDateRef') private dueDateRef: any;

  public dueDateOptions = {
    altInput: true,
    mode: 'single',
    altInputClass: 'form-control flat-picker flatpickr-input invoice-edit-input',
    altFormat: 'F j, Y',
    dateFormat: 'Y-m-d'
  };
  //Denemelerim
  task1: Task = new Task();
  recurre1: Recurre = new Recurre();


  userId: number = 0;
  userRole: string = "";

  task: Task = new Task();
  recurre: Recurre = new Recurre();
  taskStatus: TaskStatus = new TaskStatus();

  tasks: Task[] = [];

  isReccuren: boolean = true;

  doneTasks: Task[] = []
  inprogressTasks: Task[] = []
  users: User[] = [];
  taskTypes: any[] = [
    { id: 1, name: "Düşük" },
    { id: 2, name: "Normal" },
    { id: 3, name: "Yüksek" },
  ];
  frequencyTypes: any[] = [
    { id: 1, name: "Günlük" },
    { id: 2, name: "Haftalık" },
    { id: 3, name: "Aylık" },
    { id: 4, name: "Yıllık" },
  ];

  constructor(
    private taskService: EvoTaskService,
    private taskStatusService: EvoTaskStatusService,
    private recurreService: EvoRecurreService,
    private userService: EvoUserService,
    public formatter: NgbDateParserFormatter,
    private decodeService: DecodeService,
    private _coreSidebarService: CoreSidebarService,
    private informationService: InformationService,
    private errorService: ErrorService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.userId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();

    if (this.userRole == "Customer" || this.userRole == "CustomerUser") {
      this.informationService.warning("Bu sayfaya erişim yetkiniz bulunmamaktadır.");
      this.router.navigate(['/home']);
    }
    this.userService.getList().subscribe((res: any) => {
      this.users = res.data
    })

    this.getListByUserId(this.userId);
  }

  onDrop(event: any, statusId: number, list?: any[]) {
    if (list && (event.dropEffect == 'copy' || event.dropEffect == 'move')) {
      let index = event.index;
      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
      let todo = event.data;
      todo.statusId = statusId
      todo.updatedBy = this.userId;
      this.taskService.update(todo).subscribe((res: any) => {
        this.getListByUserId(this.userId)
      })
    }
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }



  getList() {
    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;
    });
  }

  checkType(value: any): value is NgbDate {
    return value instanceof NgbDate;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDateExample && !this.toDateExample) {
      this.fromDateExample = date;
    } else if (this.fromDateExample && !this.toDateExample && date && date.after(this.fromDateExample)) {
      this.toDateExample = date;
    } else {
      this.toDateExample = null;
      this.fromDateExample = date;
    }
    this.tableByDates();
  }

  tableByFiltersId() {
    let filterFunction = (filteredItems: any) => {
      if (this.fromDate || this.toDate) {
        filteredItems = filteredItems.filter(item =>
          new Date(item.startDate) >= new Date(this.fromDate) &&
          new Date(item.startDate) <= new Date(this.toDate)
        );
      }
      if (this.selectedFrequencies.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedFrequencies.includes(item.frequencyTypeId));
      }
      this.tasks = []
      this.inprogressTasks = []
      this.doneTasks = []
      filteredItems.forEach(element => {
        if (element.statusId == 1) this.tasks.push(element)
        if (element.statusId == 2) this.inprogressTasks.push(element)
        if (element.statusId == 4) this.doneTasks.push(element)
      });
    };

    this.taskService.getListDtoByUserId(this.userId).subscribe((res: any) => {
      filterFunction(res.data)
    })
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDateExample && !this.toDateExample && this.hoveredDate && date.after(this.fromDateExample) && date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDateExample && date.after(this.fromDateExample) && date.before(this.toDateExample);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDateExample) ||
      (this.toDateExample && date.equals(this.toDateExample)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  getListByUserId(userId: number) {
    this.tasks = []
    this.doneTasks = []
    this.inprogressTasks = []
    this.taskService.getListDtoByUserId(userId).subscribe((res: any) => {
      res.data.forEach(element => {
        if (element.statusId == 1) this.tasks.push(element)
        if (element.statusId == 2) this.inprogressTasks.push(element)
        if (element.statusId == 4) this.doneTasks.push(element)
      });
    })
  }



  /**
   * Open Todo
   *
   * @param idRef
   */
  openTodo(idRef) {
    //this._todoService.setCurrentTodo(idRef);
    this.recurre = new Recurre();
    this.task = new Task();
    this.taskService.getById(idRef).subscribe((item: any) => {
      this.task = item.data;
      this.isReccuren = !item.data.isReccuren
      this.taskStatusService.checkTaskInformation(this.task.id).subscribe((item: any) => {
        this.task.isCompleted = item.data;
        if (this.task.isReccuren) {
          this.recurreService.getRecurreByTaskId(idRef).subscribe((item: any) => {
            this.recurre = item.data;
          });
        }
      });
      this.isDataEmpty = false;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
    //this._coreSidebarService.getSidebarRegistry('todo-sidebar-right').toggleOpen();
  }


  updateTodo() {
    this.task.updatedBy = this.userId;
    this.task.deletedBy = 0;
    this.task.statusName = "";
    this.updateTask();
    if (this.task.isReccuren && this.recurre.frequencyTypeId != null) {
      if (this.recurre.id == null) {

      }
      this.recurre.updatedBy = this.userId;
      this.updateRecurre();
    }

    //! Fix: Temp fix till ng2-flatpicker support ng-modal (Getting NG0100: Expression has changed after it was checked error if we use ng-model with ng2-flatpicker)
    this.todo.dueDate = this.dueDateRef.flatpickrElement.nativeElement.children[0].value;

    //this._todoService.updateCurrentTodo(this.todo);
    // this.closeSidebar();

  }

  /**
   * Add Todo
   */

  getTaskById(id: number) {
    this.recurre = new Recurre();
    this.task = new Task();
    this.taskService.getById(id).subscribe((item: any) => {
      this.task = item.data;
      this.taskStatusService.checkTaskInformation(id).subscribe((item: any) => {
        this.task.isCompleted = item.data;
        if (this.task.isReccuren) {
          this.recurreService.getRecurreByTaskId(id).subscribe((item: any) => {
            this.recurre = item.data;
          });
        }
      });
      this.isDataEmpty = false;
    }, (err) => {
      this.errorService.errorHandler(err);
    });
  }
  addTodo(todoForm) {
    if (todoForm.valid) {
      this.task = {
        id: 0,
        title: this.task1.title,
        description: todoForm.value.txtDescription == null ? "" : todoForm.value.txtDescription,
        userId: this.userId,
        typeId: todoForm.value.slcTask,
        startDate: todoForm.value.txtStartDate,
        endDate: todoForm.value.txtEndDate,
        isReccuren: this.task.isReccuren,
        isImportant: this.task.isImportant,
        isCompleted: false,
        statusId: 1,
        statusName: "",
        createdBy: this.userId,
        createdDate: new Date(),
        updatedBy: 0,
        deletedBy: 0,
      }
      this.taskService.add(this.task).subscribe((item: any) => {
        if (this.task.isReccuren && todoForm.value.slcFrequencyType != null) {
          this.recurre = {
            id: 0,
            taskId: item.data.id,
            frequencyNumber: 0,
            frequencyTypeId: todoForm.value.slcFrequencyType,
            startDate: new Date(),
            endDate: todoForm.value.txtRecurreEndDate,
            nextDueDate: new Date(),
            isComplated: false,
            deletedBy: 0,
            createdBy: this.userId,
            updatedBy: 0,
          }
        }
        this.recurreService.add(this.recurre).subscribe((item: any) => {

        });
        this.informationService.add(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit()
        }, 300);
      });
    }
  }
  addTodo1() {
    this.task1.statusName = ""
    this.task1.userId = this.userId
    this.task1.createdBy = this.userId
    this.task1.updatedBy = 0
    this.task1.deletedBy = 0
    this.task1.statusId = 1
    this.task1.isImportant = false
    this.taskService.add(this.task1).subscribe((res: any) => {
      if (this.task1.isReccuren == true) {
        this.recurre1.taskId = res.data.id
        this.recurre1.frequencyNumber = 0
        this.recurreService.add(this.recurre1).subscribe((res: any) => {
        })
      }
    }, (err) => {
      this.errorService.errorHandler(err)
    }, () => {
      setTimeout(() => {
        this.ngOnInit()
      })
    })

  }
  /**
   * Delete Todo
   */
  deleteTodo() {
    //this.todo.deleted = !this.todo.deleted;
    //this._todoService.updateCurrentTodo(this.todo);
    this.taskService.delete(this.task.id).subscribe((item: any) => {
      this.informationService.delete(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
        this.tableByFiltersId()
      }, 500);
    });
  }

  toggleComplete(id: number) {
    this.task.updatedBy = this.userId;
    this.task.isCompleted = !this.task.isCompleted;
    if (this.task.isCompleted) {
      this.taskStatus = {
        id: 0,
        taskId: id,
        createdBy: this.userId,
        createdDate: new Date(),
        updatedBy: 0,
      };
      this.taskStatusService.add(this.taskStatus).subscribe((item: any) => {
        this.informationService.add(item.message);
      }, (err) => {
        this.errorService.errorHandler(err);
      }, () => {
        setTimeout(() => {
          this.ngOnInit();
        }, 500);
      });
    }
    else {
      this.taskStatusService.getFirstOrDefaulTaskStatus(id).subscribe((item: any) => {
        this.taskStatus = item.data;

        this.taskStatusService.delete(this.taskStatus.id).subscribe((item: any) => {
          this.informationService.delete(item.message);
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            this.ngOnInit();
          }, 500);
        });
      });
    }
  }

  /**
   * Toggle Important
   */
  toggleImportant(id: number) {
    this.taskService.getById(id).subscribe((item: any) => {
      this.task = item.data;
      this.task.isImportant = !this.task.isImportant;
      this.task.updatedBy = this.userId;
      this.task.deletedBy = 0;
      this.updateTask();
    });
  }

  updateTask() {
    this.taskService.update(this.task).subscribe((item: any) => {
      this.informationService.update(item.message);
      document.getElementById("updatemodalclose").click()
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit()
        this.tableByFiltersId()
      }, 500);
    });
  }

  updateRecurre() {
    this.recurre.updatedBy = this.userId
    this.recurre.deletedBy = 0;
    this.recurreService.update(this.recurre).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });
  }
  createNewTask(): void {
    this.task = new Task();
    this.isDataEmpty = true;
    this.task.isImportant = false;
    this.task.isReccuren = false;
  }
  changeRecurreType() {
    this.task1.isReccuren = !this.task1.isReccuren;
  }
  changeImportantType() {
    this.task.isImportant = !this.task.isImportant;
  }
  getInitials(name: string) {
    const userName = name;
    const firstInitial = userName.charAt(0);
    const lastInitial = userName.lastIndexOf(' ') > -1
      ? userName.substring(userName.lastIndexOf(' ') + 1, userName.lastIndexOf(' ') + 2)
      : '';

    return firstInitial + lastInitial;
  }


  getTurkishMonth(dateString: string): string {
    const months = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    return months[monthIndex];
  }

  tableByDates() {
    var tempDate: any = {};
    var datePipe = new DatePipe("en-US");
    if (this.fromDateExample instanceof NgbDate) {
      tempDate = { day: this.fromDateExample.day, month: this.fromDateExample.month - 1, year: this.fromDateExample.year };
    }
    else {
      var fromDateTransformed = new Date(datePipe.transform(this.fromDateExample, 'yyyy-MM-dd'));
      tempDate = { day: fromDateTransformed.getDate(), month: fromDateTransformed.getMonth(), year: fromDateTransformed.getFullYear() }
    }

    this.fromDate = new Date(tempDate.year, tempDate.month, tempDate.day, 0, 0, 0);

    if (this.toDateExample == null)
      this.toDate = new Date(tempDate.year, tempDate.month, tempDate.day, 23, 59, 59);
    else
      var tempToDate: any = {};

    if (this.toDateExample instanceof NgbDate) {
      tempToDate = { day: this.toDateExample.day, month: this.toDateExample.month - 1, year: this.toDateExample.year };
    }
    else {
      var toDateTransformed = new Date(datePipe.transform(this.toDateExample, 'yyyy-MM-dd'));
      tempToDate = { day: toDateTransformed.getDate(), month: toDateTransformed.getMonth(), year: toDateTransformed.getFullYear() }
    }

    this.toDate = new Date(tempToDate.year, tempToDate.month, tempToDate.day, 23, 59, 59);


    if (!this.fromDate || !this.toDate) {
      this.getListByUserId(this.userId);
    } else {
      if (this.userRole == "Admin" || this.userRole == "Consultant") {
        this.taskService.getListDtoByUserId(this.userId).subscribe((items: any) => {
          this.tasks = items.data.filter(item =>
            new Date(item.paymentDate) >= this.fromDate &&
            new Date(item.paymentDate) <= this.toDate
          );

        });
      } else {
        this.taskService.getListDtoByUserId(this.userId).subscribe((items: any) => {
          this.tasks = items.data.filter(item =>
            new Date(item.paymentDate) >= this.fromDate &&
            new Date(item.paymentDate) <= this.toDate
          );
        });
      }
    }
    this.tableByFiltersId();
  }
}
