import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { GoalDetail } from '../models/goaldetail';

@Injectable({
  providedIn: 'root'
})
export class EvoGoalDetailService {
  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList( userId:number): Observable<GoalDetail[]> {
    return this.httpClient.get<GoalDetail[]>(this.baseApiUrl + 'GetGoalDetailList/'+ userId);
  }
  getListByStatusId( userId:number , statusId:number): Observable<GoalDetail[]> {
    return this.httpClient.get<GoalDetail[]>(this.baseApiUrl + 'GetSubDetailListByStatusId/'+ userId+"/" + statusId);
  }
  get(id: number): Observable<GoalDetail> {
    return this.httpClient.get<GoalDetail>(this.baseApiUrl + 'GetGoalDetail/' + id);
  }
  add(goalDetail: GoalDetail): Observable<GoalDetail> {
    return this.httpClient.post<GoalDetail>(this.baseApiUrl + 'AddGoalDetail/', goalDetail);
  }
  update(goalDetail: GoalDetail): Observable<GoalDetail> {
    return this.httpClient.post<GoalDetail>(this.baseApiUrl + "UpdateGoalDetail/", goalDetail);
  }
  delete(id: number): Observable<GoalDetail> {
    return this.httpClient.post<GoalDetail>(this.baseApiUrl + "DeleteGoalDetail/" + id, "");
  }
}
