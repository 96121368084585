import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { TicketFollow } from '../models/ticketfollow';
import { TicketUser } from '../models/ticketuser';

@Injectable({
  providedIn: 'root'
})
export class EvoTicketUserService {

  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  getList(): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetTicketUserList');
  }
  getUrgentList(pageNumber: number): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetUrgentTicketUserList/' + pageNumber);
  }

  getMyTicketUserList(userId: number): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetMyTicketUserList/' + userId);
  }
  restrictionForExternalSourceTicketUsers(userId: number): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'RestrictionForExternalSourceTicketUsers/' + userId);
  }

  getUrgentTicketUserListWithPermissionDto(pageNumber: number, userId: number,firstRestriction:boolean, secondRestriction:boolean): Observable<TicketUser[]> {
    return this.httpClient.get<TicketUser[]>(this.baseApiUrl + 'GetUrgentTicketUserListWithPermissionDto/' + pageNumber + '/' + userId + '/' + firstRestriction + '/' + secondRestriction);
  }


  add(ticketUser: TicketUser): Observable<TicketUser> {
    return this.httpClient.post<TicketUser>(this.baseApiUrl + "AddTicketUser", ticketUser)
  }

  follow(ticketUser: TicketFollow): Observable<TicketFollow> {
    return this.httpClient.post<TicketFollow>(this.baseApiUrl + "AddTicketUser", ticketUser)
  }

  unFollow(ticketUser: TicketFollow): Observable<TicketFollow> {
    return this.httpClient.post<TicketFollow>(this.baseApiUrl + "DeleteTicketUser", ticketUser)
  }

  get(ticketId: number, userId: number): Observable<TicketUser> {
    return this.httpClient.get<TicketUser>(this.baseApiUrl + 'GetTicketUser/' + ticketId + '/' + userId);
  }

  getDto(ticketId: number): Observable<TicketUser> {
    return this.httpClient.get<TicketUser>(this.baseApiUrl + 'GetTicketUserDto/' + ticketId);
  }
  getTicketUserById(id:number):Observable<TicketUser>{
    return this.httpClient.get<TicketUser>(this.baseApiUrl + 'GetTicketUserById/' + id);
  }

  update(ticket: TicketUser): Observable<TicketUser> {
    return this.httpClient.post<TicketUser>(this.baseApiUrl + "UpdateTicketUser/", ticket);
  }

  delete(ticket: TicketUser): Observable<TicketUser> {
    return this.httpClient.post<TicketUser>(this.baseApiUrl + "DeleteTicketUser/" ,ticket);
  }

}
