import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [SampleComponent, HomeComponent ],
  imports: [
     NgbModule,
     ContentHeaderModule, 
     TranslateModule, 
     CoreCommonModule,
     CardSnippetModule,
     Ng2FlatpickrModule,
     NgxDatatableModule,
     NgSelectModule,

    ],
  exports: [SampleComponent, HomeComponent]
})
export class SampleModule {}
