export class Competence{
    id: number;
    description: string;
    moduleId: number;
    userId: number;
    score: number;
    lastScore: number;
    createdBy:number;
    createdDate:Date;
    updatedBy:number;
    updatedDate:Date;
    deletedBy:number;
    deletedDate:Date;

    moduleName: string;
    userName: string;
}