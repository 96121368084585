export class Transaction{
    id:number;
    typeId:number;
    statusId:number;
    eventNumber:number;
    subject:string;
    errorStatusId:number;
    createdBy:number;
    
    createdDate:Date;
    typeName:string;
    statusName:string;
    userName:string;
    imageURL:string;
}