import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Department } from '../models/department';

@Injectable({
  providedIn: 'root'
})
export class EvoDepartmentService {

  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Department[]>{
    return this.httpClient.get<Department[]>(this.baseApiUrl+'GetDepartmentList');
  }
  get (id: number):Observable <Department> {
    return this.httpClient.get<Department>(this.baseApiUrl+'GetDepartment/'+id);
  }
  add(department:Department):Observable<Department>{
    return this.httpClient.post<Department>(this.baseApiUrl+'AddDepartment/',department);
  }
  update(department:Department):Observable<Department>{
    return this.httpClient.post<Department>(this.baseApiUrl+"UpdateDepartment/",department);
  }
  delete(id:number):Observable<Department>{
    return this.httpClient.post<Department>(this.baseApiUrl+"DeleteDepartment/"+id,"");
  }
}
