<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="basic-vertical-layouts">
            <div class="row match-height">
                <div class="col-md-2"></div>
                <div class="col-md-8 col-12">
                    <core-card-snippet>
                        <h4 class="card-title">Partner Kullanıcısı Oluştur</h4>

                        <div class="card-body">
                            <form class="form form-vertical" #UserForm="ngForm">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Cari Seçiniz</label>
                                            <select class="form-control" required name="slcPartner" [(ngModel)]="partnerUser.partnerId" #slcPartner="ngModel">
                                                <option *ngFor="let item of partners" value="{{item.id}}">{{item.name}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="name-vertical">Kullanıcı Seçiniz</label>
                                            <select class="form-control" required name="slcUser" [(ngModel)]="partnerUser.userId" #slcUser="ngModel">
                                                <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}}  {{item.lastName}}</option>
                                              </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" rippleEffect class="btn btn-success mr-1"
                                            [disabled]="!UserForm.valid" (click)="update()">Kaydet</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </core-card-snippet>
                </div>

            </div>
        </section>
    </div>
</div>