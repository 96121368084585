import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'evoTicketuser'
})
export class EvoTicketuserPipe implements PipeTransform {

  transform(value: any[], filterText: string): any[] {
    filterText = filterText.trim()
    if (filterText == "" || filterText == null) {
      return value;
    }
  
    return value.filter(tickets => {
      
      return tickets.some(ticket => {
        const productName = ticket.subject?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
        const departmentName = ticket.departmentName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
        const trackingNumber = String(ticket.trackingNumber)?.includes(filterText);
        const userName = (ticket.userLastName?.toLocaleLowerCase() + ticket.userFirstName?.toLocaleLowerCase()).includes(filterText.toLowerCase());
        const roleName = ticket.typeName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
        const answerName = ticket.answerTypeName?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
        const partnerTicketNo = ticket.partnerTicketNo?.toLocaleLowerCase().includes(filterText.toLocaleLowerCase());
  
        return (productName || departmentName || trackingNumber || userName || roleName || answerName || partnerTicketNo);
      });
    });
  } 
}

