import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Competence } from 'app/evo/models/competence';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-competence-list',
  templateUrl: './evo-competence-list.component.html',
  styles: [
  ]
})
export class EvoCompetenceListComponent implements OnInit {

  constructor(
    private competenceService: EvoCompetenceService,
    private userService: EvoUserService,
    private moduleService: EvoModuleService,

    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private router: Router
  ) { }
  myId: number;
  userRole: string = "";
  filterText: string = "";
  deletedCompetenceId: number = 0;
  public basicSelectedOption: number = 10;

  users: User[] = [];
  modules: Module[] = [];
  competences: Competence[] = [];

  selectedUsers:User[]=[];
  selectedModules:Module[]=[];

  user: User = new User();
  module: Module = new Module();
  competence: Competence = new Competence();

  ngOnInit(): void {
    this.myId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();

    if (this.userRole == "Admin" || this.userRole == "Consultant") {
      this.competenceService.getList().subscribe((items: any) => {
        this.competences = items.data;
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });
      this.list();
    }
    else if (this.userRole = "ConsultantUser") {
      this.competenceService.getListByUserId(this.myId).subscribe((items: any) => {
        this.competences = items.data;
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      });
      this.list();
    }
    else {
      this.router.navigate(["/home"]);
    }

  }

  list() {
    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;
    });
    this.moduleService.getList().subscribe((items: any) => {
      this.modules = items.data;
    });
  }

  get(id: number) {
    this.competenceService.get(id).subscribe((item: any) => {
      this.competence = item.data;
    });
  }

  add(description: string, score: number) {
    this.competence = {
      id: 0,
      userId: this.userRole == "Consultant" ? this.myId : this.user.id,
      moduleId: this.module.id,
      score: score,
      lastScore: 0,
      description: description,

      createdBy: this.myId,
      createdDate: new Date(),
      updatedBy: 0,
      updatedDate: new Date(),
      deletedBy: 0,
      deletedDate: new Date(),

      moduleName: "",
      userName: ""
    }
    this.competenceService.add(this.competence).subscribe((items: any) => {

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 150);
    });
  }

  update() {
    this.competence.updatedBy = this.myId;
    this.competence.deletedBy = 0;
    this.competenceService.update(this.competence).subscribe((items: any) => {
      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 150);
    });
  }

  delete(id: number) {
    this.competenceService.delete(id).subscribe((items: any) => {
      this.competences = this.competences.filter(x => x.id !== id);
      this.informationService.delete(items.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.ngOnInit();
      }, 150);
    });
  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Yetkinlikler";
    this.helperService.exportExcel(element, title);
  }

  tableByFiltersId() {
    if (this.userRole == "Admin" || this.userRole == "Consultant") {

      this.competenceService.getList().subscribe((items: any) => {
        let filteredItems = items.data;

        if (this.selectedModules.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedModules.includes(item.moduleId));
        }
        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }
        this.competences = filteredItems;
      });
    }
    if (this.userRole == "ConsultantUser") {
      this.competenceService.getListByUserId(this.myId).subscribe((items: any) => {
        let filteredItems = items.data;
        if (this.selectedModules.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedModules.includes(item.moduleId));
        }
        if (this.selectedUsers.length > 0) {
          filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
        }
        this.competences = filteredItems;
      });
    }
  }
}
