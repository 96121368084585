<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-12">
          <core-card-snippet>
            <h4 class="card-title">Proje Oluşur</h4>
            <div class="card-body">
              <form class="form form-vertical" #ProjectFrom="ngForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="title-vertical">Projenin Adı</label>
                      <input type="text" required ngModel id="title-vertical" class="form-control" name="txtTitle"
                        #txtTitle="ngModel" placeholder="Proje ismini giriniz" />
                      <small class="text-danger" *ngIf="txtTitle.invalid&& txtTitle.touched">Proje adı boş
                        bırakılamaz!</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="description-vertical">Açıklama giriniz</label>
                      <input type="text" id="description-vertical" class="form-control" name="txtDescription" required
                        ngModel #txtDescription="ngModel" placeholder="Açıklaması" />
                      <small class="text-danger" *ngIf="txtDescription.invalid&& txtDescription.touched">Açıklama boş
                        bırakılamaz!</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="tracking-number-vertical">Cari Seçiniz</label>
                      <select class="form-control" required name="slcPartner" [(ngModel)]="partner.id" #slcPartner>
                        <option value="0"selected>Cari Seçiniz</option>
                        <option *ngFor="let item of partners" value="{{item.id}}" >{{item.name}}</option>
                      </select>
                      <small class="text-danger" *ngIf="slcPartner.invalid&&slcPartner.touched">Bir Cari seçmeniz
                        gerekiyor!</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <button type="submit" rippleEffect class="btn btn-success mr-1"
                      [disabled]="!ProjectFrom.valid" (click)="add(txtTitle.value,txtDescription.value)">Kaydet</button>
                  </div>
                </div>
              </form>
            </div>
          </core-card-snippet>
        </div>
      </div>
    </section>
  </div>
</div>