export class TicketActivity{
    id:number;
    ticketId:number;
    userId:number;
    partnerId:number;
    partnerName:string;
    partnerTicketNo:string;
    locationTypeId:number;
    locationTypeName:string;
    ticketStatusId:number;
    ticketStatusName:string;
    ticketBillTypeId:number;
    ticketBillTypeName:string;
    ticketSubject:string;
    approvementId:number;
    isApprovement:boolean;
    trackingNumber:string;
    description:string;
    userName:string;
    moduleId:number;
    moduleName:string;
    startDate:Date;
    endDate:Date;
    time:number;
    billTypeId:number;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}
