import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class InformationService {

  constructor(private toastrService:ToastrService) { }


  list(message:string){
    this.toastrService.info(message);
  }

  get(message:string){
    this.toastrService.info(message);
  }

  add(message:string){
    this.toastrService.success(message);
  }

  update(message:string){
    this.toastrService.success(message);
  }

  delete(message:string){
    this.toastrService.warning(message);
  }

  warning(message:string){
    this.toastrService.error(message);
  }



}
