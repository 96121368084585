export const locale = {
  lang: 'tr',
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: 'AnaSayfa',
        BADGE: '2',
        ANALYTICS: 'analatikler',
        ECOMMERCE: 'eCommerce'
      },
      HOME: 'Home',
      SAMPLE: 'Sample',
    },
    CALENDAR:
    {
      April:'Nisan'
    }
  }
}