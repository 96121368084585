import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Recurre } from '../models/reccure';

@Injectable({
  providedIn: 'root'
})
export class EvoRecurreService {
  
  private baseApiUrl=environment.apiUrl;

  constructor(private httpClient:HttpClient) { }

  getList():Observable<Recurre[]>{
    return this.httpClient.get<Recurre[]>(this.baseApiUrl+'GetRecurreList');
  }
  getRecurreByTaskId(taskId:number):Observable<Recurre>{
    return this.httpClient.get<Recurre>(this.baseApiUrl+'GetRecurreByTaskId/'+taskId);
  }
  get(id:number):Observable<Recurre>{
    return this.httpClient.get<Recurre>(this.baseApiUrl+'GetRecurre/'+id);
  }
  add(model:Recurre):Observable<Recurre>{
    return this.httpClient.post<Recurre>(this.baseApiUrl+'AddRecurre',model);
  }
  update(model:Recurre):Observable<Recurre>{
    return this.httpClient.post<Recurre>(this.baseApiUrl+'UpdateRecurre',model);
  }
  delete(id:number):Observable<Recurre>{
    return this.httpClient.delete<Recurre>(this.baseApiUrl+'DeleteRecurre/'+id);
  }
}
