import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Transaction } from '../models/transaction';

@Injectable({
  providedIn: 'root'
})
export class EvoTransactionService {
  private baseApiUrl=environment.apiUrl;
  
  constructor(public httpClient:HttpClient) { }

  getList():Observable<Transaction[]>{
    return this.httpClient.get<Transaction[]>(this.baseApiUrl+'GetTransactionList');
  }
  getListForTimeline(pageNumber: number, pageSize:number):Observable<Transaction[]>{
    return this.httpClient.get<Transaction[]>(this.baseApiUrl+'GetTransactionListDto/'+pageNumber+'/'+pageSize);
  }
  get(id:number):Observable<Transaction>{
    return this.httpClient.get<Transaction>(this.baseApiUrl+'GetTransactionById/'+id);
  }
  saveTransaction(eventNumber:number, typeId:number, statusId:number,  createdBy:number):Observable<any>{
    return this.httpClient.post<any>(this.baseApiUrl+'SaveTransaction/'+eventNumber+'/'+typeId+'/'+statusId+'/'+createdBy, "");
  }
  add(transaction:Transaction):Observable<Transaction>{
    return this.httpClient.post<Transaction>(this.baseApiUrl+'AddTransaction/',transaction);
  }
  update(transaction:Transaction):Observable<Transaction>{
    return this.httpClient.post<Transaction>(this.baseApiUrl+"UpdateTransaction/",transaction);
  }
  delete(transaction:Transaction):Observable<Transaction>{
    return this.httpClient.post<Transaction>(this.baseApiUrl+"DeleteTransaction/",transaction);
  }
}
