export class GoalDetail {
    id: number;
    goalId: number;
    userId: number;
    scaleIndicator: string;
    scaleId: number;
    targeted:number;
    relaised:number;
    observationPeriodId:number;
    weight:number;
    point:number;
    userName:string;
    scaleName:string;
    goalName:string;
    goalStatusId:number;
    goalStatusName:string;
    observationPeriodName:string;

}