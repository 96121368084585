import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationClaim } from 'app/evo/models/operationclaim';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoOperationClaimService } from 'app/evo/services/evo-operationclaim.service';
import { InformationService } from 'app/evo/services/information.service';

@Component({
  selector: 'app-evo-operationclaim-update-form',
  templateUrl: './evo-operationclaim-update-form.component.html',
  styles: [
  ]
})
export class EvoOperationClaimUpdateFormComponent implements OnInit {

  operationClaim: OperationClaim;

  constructor(
    private operationClaimService: EvoOperationClaimService,
    private route: ActivatedRoute,
    private router: Router,
    private informationService: InformationService,
    private errorService: ErrorService,
  ) { }
  id: number;

  ngOnInit(): void {
    this.id = this.route.snapshot.params['Id'];

    this.operationClaimService.get(this.id).subscribe((item: any) => {
      this.operationClaim = item.data;
    });
  }
  update() {
    this.operationClaimService.update(this.operationClaim).subscribe((item: any) => {
      this.informationService.update(item.message);
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.router.navigate(["/home/departments"]);
      }, 1500);
    });
  }
}
