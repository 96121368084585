<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="basic-vertical-layouts">
      <div class="row match-height">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-12">
          <core-card-snippet>
            <h4 class="card-title">Modül Oluşur</h4>

            <div class="card-body">
              <form class="form form-vertical" #ModulForm="ngForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="name-vertical">Modül Adını Giriniz</label>
                      <input type="text" ngModel required id="name-vertical" class="form-control" name="fname"
                        placeholder="Modül adı" #txtName="ngModel" />

                      <small class="text-danger" *ngIf="txtName.invalid&& txtName.touched">Bölüm adı boş
                        bırakılamaz!</small>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="name-vertical">Kullanıcı Seçiniz</label>
                      <select class="form-control" ngModel required name="slcUser" [(ngModel)]="user.id"
                        #slcUser="ngModel">
                        <option *ngFor="let item of users" value="{{item.id}}">{{item.firstName}} {{item.lastName}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" rippleEffect class="btn btn-success mr-1" [disabled]="!ModulForm.valid"
                    (click)="add(txtName.value)">Kaydet</button>
                </div>
              </form>
            </div>
          </core-card-snippet>
        </div>

      </div>
    </section>
  </div>
</div>