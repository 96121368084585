export class Module{
    id:number;
    userId:number;
    name:string;
    userMail:string;
    userName:string;
    
    createdBy:number;
    updatedBy:number;
    deletedBy:number;
}